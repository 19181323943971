export const validationErrors = (values = {}, memberships) => {
  let msg;

  if (!values.credit_limit || +values.credit_limit < 0)
    msg = 'Mėnesio limito reikšmė negalima!';

  if (values.membership_id && memberships) {
    const selectedMembershipId = +values.membership_id;
    const selectedMembership = memberships.filter(
      m => m.id == selectedMembershipId
    )[0];

    if (selectedMembership) {
      if (
        selectedMembership.license_count -
          selectedMembership.assigned_license_count <=
        0
      ) {
        msg = 'Nepakankamas licencijų skaičius! ';
      }
    } else msg = 'Pasirinktas planas neegzistuoja!';
  }

  return msg;
};
