import React, { createContext, useState } from 'react';

export const InvoicesContext = createContext();

export const InvoicesProvider = ({ children }) => {
  const [paymentFilter, setPaymentFilter] = useState([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState([]);
  const [contractFilter, setContractFilter] = useState([]);
  const [invoiceFilter, setInvoiceFilter] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [companyCodeFilter, setCompanyCodeFilter] = useState([]);
  const [businessClientFilter, setBusinessClientFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBody, setFilterBody] = useState();
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState();
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState();
  const [activeTab, setActiveTab] = useState(0);
  return (
    <InvoicesContext.Provider
      value={{
        paymentFilter,
        setPaymentFilter,
        paymentStatusFilter,
        setPaymentStatusFilter,
        invoiceFilter,
        setInvoiceFilter,
        contractFilter,
        setContractFilter,
        phoneFilter,
        setPhoneFilter,
        companyFilter,
        setCompanyFilter,
        companyCodeFilter,
        setCompanyCodeFilter,
        businessClientFilter,
        setBusinessClientFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};
