import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';
import { errorMessage } from '../../utils';

export function* getBoxesTableDataSaga({ body }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));
    const { devices, count, header, footer } = yield call(
      Api.getBoxesTableData,
      body
    );
    yield put(actions.boxes.setBoxesAction(devices, count, header, footer));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}
