import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';
import {
  selectAddEditServicesdata,
  selectLocationId,
  selectCurrentLocationStatus,
} from '../selectors';
import {
  removeNullValues,
  errorMessage,
  convertPricesAndTime,
} from '../../utils';
import { config } from '../../api/config';

export function* addLocationsSaga({ history }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));
    const selectedData = yield select(selectAddEditServicesdata);
    const formattedData = removeNullValues(selectedData);
    const formattedDataWithFormattedTime = convertPricesAndTime(
      formattedData,
      'to'
    );

    if (history !== undefined) {
      const newLocation = yield call(
        Api.addLocations,
        formattedDataWithFormattedTime
      );

      history.push(config.APP_PATHS.editLocation(newLocation.location_id));

      /****
       * set location that its data would be in "locations/selectedLoxations"
       * const location = yield call(Api.getLocation, newLocation.location_id);
       * yield put(actions.locations.setSelectedLocationAction(location));
       ****/

      yield put(
        actions.error.setAlertAction('Lokacija sėkmingai pridėta', 'success')
      );
    } else {
      const id = yield select(selectLocationId);
      const status = yield select(selectCurrentLocationStatus);

      const body = {
        ...formattedDataWithFormattedTime,
        status,
      };

      yield call(Api.updateLocation, body, id);
      const location = yield call(Api.getLocation, id);

      yield put(
        actions.error.setAlertAction('Lokacija sėkmingai atnaujinta', 'success')
      );
      yield put(actions.locations.setSelectedLocationAction(location));
    }
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}

export function* handleLocationStatusChangeSaga({ source, status }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));
    const id = yield select(selectLocationId);
    switch (source) {
      case 'all':
        const body = {
          id: id,
          status: status === 0 ? 1 : 0,
        };
        yield call(Api.updateLocation, body, id);
        break;
      default:
        const body2 = {
          id: id,
          [source]: {},
        };
        yield call(Api.updateLocation, body2, id);
        break;
    }
    const location = yield call(Api.getLocation, id);
    yield put(actions.locations.setSelectedLocationAction(location));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}

export function* regenerateDeviceKey(payload) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));
    yield call(Api.regenerateKey, payload.id);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}
