import { constants } from '../../constants';

const INITIAL_STATE = {
  loading: false,
  employees: [],
  total: 0,
  header: {},
  footer: {},
  selectedEmployee: {},
};

export const employeesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.employees.SET_EMPLOYEES_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.employees.SET_EMPLOYEES: {
      return {
        ...state,
        employees: action.employees,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    }
    case constants.employees.SET_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.employee,
      };
    case constants.employees.CLEAR_EMPLOYEES:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
