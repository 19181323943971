import { constants } from '../constants';

const setAutomaticWashCodesLoadingAction = flag => ({
  type: constants.textile.SET_AUTOMATIC_WASH_CODES_LOADING,
  flag,
});

const getAutomaticWashCodesAction = body => ({
  type: constants.textile.GET_AUTOMATIC_WASH_CODES,
  body,
});

const setAutomaticWashCodesAction = (
  automatic_wash_codes,
  count,
  header,
  footer
) => ({
  type: constants.textile.SET_AUTOMATIC_WASH_CODES,
  automatic_wash_codes,
  count,
  header,
  footer,
});

const clearAutomaticWashCodesAction = () => ({
  type: constants.textile.CLEAR_AUTOMATIC_WASH_CODES,
});

const useWashCodeAction = id => ({
  type: constants.textile.USE_WASH_CODE,
  id,
});

const removeWashCodeAction = id => ({
  type: constants.textile.REMOVE_WASH_CODE,
  id,
});

export const textileActions = {
  setAutomaticWashCodesLoadingAction,
  getAutomaticWashCodesAction,
  setAutomaticWashCodesAction,
  clearAutomaticWashCodesAction,
  useWashCodeAction,
  removeWashCodeAction,
};
