import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  getTime,
  isEqual,
  startOfDay,
  endOfDay,
  addDays,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
} from 'date-fns';

import styles from './rangeButton.module.scss';

const RangeButton = ({ name, range, setRange, currentRange }) => {
  const NOW = new Date();
  const start = day => getTime(startOfDay(day));
  const end = day => getTime(endOfDay(day));

  const ranges = {
    today: {
      start: start(NOW),
      end: end(NOW),
    },
    yesterday: {
      start: start(subDays(NOW, 1)),
      end: end(subDays(NOW, 1)),
    },
    tomorrow: {
      start: start(addDays(NOW, 1)),
      end: end(addDays(NOW, 1)),
    },
    thisWeek: {
      start: start(startOfWeek(NOW, { weekStartsOn: 1 })),
      end: end(endOfWeek(NOW, { weekStartsOn: 1 })),
    },
    lastWeek: {
      start: start(startOfWeek(subWeeks(NOW, 1), { weekStartsOn: 1 })),
      end: end(endOfWeek(subWeeks(NOW, 1), { weekStartsOn: 1 })),
    },
    thisMonth: {
      start: start(startOfMonth(NOW)),
      end: end(endOfMonth(NOW)),
    },
    lastMonth: {
      start: start(startOfMonth(subMonths(NOW, 1))),
      end: end(endOfMonth(subMonths(NOW, 1))),
    },
    thisYear: {
      start: start(startOfYear(NOW)),
      end: end(endOfYear(NOW)),
    },
    lastYear: {
      start: start(startOfYear(subYears(NOW, 1))),
      end: end(endOfYear(subYears(NOW, 1))),
    },
  };

  const isActive =
    isEqual(currentRange.start, ranges[range].start) &&
    isEqual(currentRange.end, ranges[range].end);

  return (
    <button
      onClick={() => setRange(ranges[range])}
      className={cn(styles.button, isActive && styles.active)}
      type="button"
    >
      {name}
    </button>
  );
};

RangeButton.propTypes = {
  name: PropTypes.string.isRequired,
  range: PropTypes.string.isRequired,
  setRange: PropTypes.func.isRequired,
  currentRange: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
};

export default RangeButton;
