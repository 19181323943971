import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './selectFilter.module.scss';
import { useClickOutside } from '../../../utils';
import { Button, Icon, Multiselect, Checkbox } from '../..';
import FilterTitle from '../_elements/filterTitle/filterTitle';

const SelectFilter = ({
  array = [],
  title,
  valueType,
  filterState,
  setFilterState,
  onSubmit,
  sortOrder,
  setSortOrder,
  name,
  position,
  isActive,
  sortArray,
}) => {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    const filteredList = array.filter(item =>
      item.name.toLowerCase().includes(value.trim().toLowerCase())
    );
    setList(filteredList);
  }, [value, array]);

  const filterRef = useRef();
  const [isOpen, setIsOpen] = useClickOutside(filterRef);

  const isAllChecked = array.length === filterState.length;

  const handleCheckAll = () => {
    const newList = list.map(item => item.value);
    isAllChecked ? setFilterState([]) : setFilterState(newList);
  };

  const handleTitleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const handleOrderClick = () => {
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSubmit(name);
  };

  return (
    <div className={styles.container} ref={filterRef}>
      <FilterTitle
        title={title}
        onTitleClick={handleTitleClick}
        isActive={isActive}
      />

      {isOpen && (
        <div className={cn(styles.filter, styles[position])}>
          <div className={styles.search}>
            <input
              type="search"
              placeholder="Paieška"
              className={styles.input}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
            <Icon name="search" classes={{ icon: styles.icon }} />
          </div>

          <div className={styles.list}>
            {!value.length && (
              <Checkbox
                label="Visi"
                name="Visi"
                value="-1"
                checked={isAllChecked}
                onChange={handleCheckAll}
              />
            )}

            <Multiselect
              array={list}
              type="object"
              name="name"
              value="value"
              state={filterState}
              setState={setFilterState}
              valueType={valueType}
            />
          </div>

          <div className={styles.actions}>
            <Button
              color="light"
              classes={{ button: styles.button }}
              onClick={handleOrderClick}
            >
              {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
            </Button>

            <Button
              color="primary"
              classes={{ button: styles.button }}
              onClick={handleSubmit}
            >
              Filtruoti
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SelectFilter.defaultProps = {
  position: 'left',
};

SelectFilter.propTypes = {
  array: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  filterState: PropTypes.array.isRequired,
  setFilterState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
  setSortOrder: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
};

export default SelectFilter;
