export const downloadConstants = {
  SET_XLS_LOADING: 'download/SET_XLS_LOADING',
  SET_XML_LOADING: 'download/SET_XML_LOADING',
  SET_QR_LOADING: 'download/SET_QR_LOADING',
  FETCH_EXPORTED_FILE: 'download/FETCH_EXPORTED_FILE',
  SET_DOWNLOADED_FILE: 'download/SET_DOWNLOADED_FILE',
  CLEAN_DOWNLOAD: 'download/CLEAN_DOWNLOAD',
  SET_ASYNC_EXPORT_RESULT: 'download/SET_ASYNC_EXPORT_RESULT',
  CONNECT_SOCKET: 'download/CONNECT_SOCKET',
};
