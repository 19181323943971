export const businessClientsConstants = {
  SET_CLIENTS_LOADING: 'businessClients/SET_CLIENTS_LOADING',
  SET_CONTRACT_LOADING: 'businessClients/SET_CONTRACT_LOADING',
  GET_CLIENTS: 'businessClients/GET_CLIENTS',
  SET_CLIENTS: 'businessClients/SET_CLIENTS',
  CLEAR_CLIENTS: 'businessClients/CLEAR_CLIENTS',
  GET_CLIENT: 'businessClients/GET_CLIENT',
  SET_CLIENT: 'businessClients/SET_CLIENT',
  UPDATE_CLIENT: 'businessClients/UPDATE_CLIENT',
  ADD_BUSINESS_CLIENT: 'businessClients/ADD_BUSINESS_CLIENT',
  SET_UPLOADED_CONTRACT: 'businessClients/SET_UPLOADED_CONTRACT',
  UPLOAD_CONTRACT: 'businessClients/UPLOAD_CONTRACT',
  UPLOAD_LOGOTYPE: 'businessClients/UPLOAD_LOGOTYPE',
  UPDATE_STATUS: 'businessClients/UPDATE_STATUS',
  GET_CLIENTS_MEMBERSHIPS: 'businessClients/GET_CLIENTS_MEMBERSHIPS',
  SET_CLIENTS_MEMBERSHIPS: 'businessClients/SET_CLIENTS_MEMBERSHIPS',
  ADD_CLIENTS_MEMBERSHIP: 'businessClients/ADD_CLIENTS_MEMBERSHIP',
  UPDATE_CLIENTS_MEMBERSHIP: 'businessClients/UPDATE_CLIENTS_MEMBERSHIP',
  UPDATE_CLIENTS_MEMBERSHIPS_LIST:
    'businessClients/UPDATE_CLIENTS_MEMBERSHIPS_LIST',
  SET_UPLOADED_LOGOTYPE: 'businessClients/SET_UPLOADED_LOGOTYPE',
  GET_MANAGER_ADMINS: 'businessClients/GET_MANAGER_ADMINS',
  SEARCH_COMPANY_DETAILS: 'businessClients/SEARCH_COMPANY_DETAILS',
  SEARCH_COMPANY_DETAILS_BY_CODE:
    'businessClients/SEARCH_COMPANY_DETAILS_BY_CODE',
  SET_MANAGER_ADMINS: 'businessClients/SET_MANAGER_ADMINS',
  SET_COMPANY_DETAILS: 'businessClients/SET_COMPANY_DETAILS',
  SET_COMPANY_DETAILS_BY_CODE: 'businessClients/SET_COMPANY_DETAILS_BY_CODE',
  SET_COMPANY_DETAILS_LOADING: 'businessClients/SET_COMPANY_DETAILS_LOADING',
  SET_COMPANY_DETAILS_ERROR: 'businessClients/SET_COMPANY_DETAILS_ERROR',
  SET_COMPANY_DETAILS_BY_CODE_ERROR:
    'businessClients/SET_COMPANY_DETAILS_BY_CODE_ERROR',
  GET_SINGLE_COMPANY_DETAILS_ACTION:
    'businessClients/GET_SINGLE_COMPANY_DETAILS_ACTION',
  SET_SINGLE_COMPANY_DETAILS_ACTION:
    'businessClients/SET_SINGLE_COMPANY_DETAILS_ACTION',
  EXPORT_BUSINESS_CLIENTS: 'businessClients/EXPORT_BUSINESS_CLIENTS',
};
