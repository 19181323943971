import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  locations: [],
  total: 0,
  header: {},
  footer: {},
  selectedLocation: {},
};

export const locationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.locations.SET_LOCATIONS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.locations.SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.locations.SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case constants.locations.CLEAR_LOCATIONS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
