import { constants } from '..';

const setOrdersLoadingAction = flag => ({
  type: constants.orders.SET_ORDERS_LOADING,
  flag,
});

const getOrdersAction = body => ({
  type: constants.orders.GET_ORDERS,
  body,
});

const setOrdersAction = (orders, count, header, footer) => ({
  type: constants.orders.SET_ORDERS,
  orders,
  count,
  header,
  footer,
});

const getOrderAction = id => ({
  type: constants.orders.GET_ORDER,
  id,
});

const setOrderAction = order => ({
  type: constants.orders.SET_ORDER,
  order,
});

const clearOrdersAction = () => ({
  type: constants.orders.CLEAR_ORDERS,
});

const retryPaymentAction = (orderId, paymentId) => ({
  type: constants.orders.RETRY_PAYMENT,
  orderId,
  paymentId,
});

const exportOrdersAction = (filename, body) => ({
  type: constants.orders.EXPORT_ORDERS,
  filename,
  body,
});

const changeOrderStatusToPaid = id => ({
  type: constants.orders.CHANGE_ORDER_STATUS_TO_PAID,
  id,
});

const exportOrdersAsyncAction = filterBody => ({
  type: constants.orders.EXPORT_ORDERS_ASYNC,
  filter: filterBody,
});

const setExportIdentifierAction = identifier => ({
  type: constants.orders.SET_ORDERS_EXPORT_IDENTIFIER,
  identifier,
});

export const ordersActions = {
  setOrdersLoadingAction,
  getOrdersAction,
  setOrdersAction,
  getOrderAction,
  setOrderAction,
  clearOrdersAction,
  retryPaymentAction,
  exportOrdersAsyncAction,
  setExportIdentifierAction,
  exportOrdersAction,
  changeOrderStatusToPaid,
};
