import { constants } from '..';

const INITIAL_STATE = {
  error: null,
  alert: null,
};

export const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.error.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case constants.error.PLACE_ALERT:
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
};
