import { isPast, compareAsc, isValid } from 'date-fns';

export const LIMITS = {
  description: 55,
  discountBannerName: 55,
  discountBannerDescription: 120,
};

export const validationErrors = (values = {}) => {
  let msg;

  const fromDate = new Date(`${values.dateFrom} ${values.timeFrom}`);
  const toDate = new Date(`${values.dateTo} ${values.timeTo}`);

  if (values.description_ru.length > LIMITS.desc) {
    msg = `Kampanijos pavadinimas (RU) negali būti ilgesnis nei ${
      LIMITS.desc
    } ženklai`;
  }

  if (values.description_en.length > LIMITS.desc) {
    msg = `Kampanijos pavadinimas (EN) negali būti ilgesnis nei ${
      LIMITS.desc
    } ženklai`;
  }

  if (values.description_lt.length > LIMITS.desc) {
    msg = `Kampanijos pavadinimas (LT) negali būti ilgesnis nei ${
      LIMITS.desc
    } ženklai`;
  }

  if (!values.description_ru) {
    msg = 'Įveskite kampanijos detalų pavadinimą (RU)';
  }

  if (!values.description_en) {
    msg = 'Įveskite kampanijos detalų pavadinimą (EN)';
  }

  if (!values.description_lt) {
    msg = 'Įveskite kampanijos detalų pavadinimą (LT)';
  }

  if (values.createDiscountBanner) {
    if (!values.announcement.title_lt)
      msg = 'Įveskite trumpą nuolaidos atvaizdavimo pavadinimą (LT)';

    if (!values.announcement.title_en)
      msg = 'Įveskite trumpą nuolaidos atvaizdavimo pavadinimą (EN)';

    if (!values.announcement.title_ru)
      msg = 'Įveskite trumpą nuolaidos atvaizdavimo pavadinimą (RU)';

    if (!values.announcement.description_lt)
      msg = 'Įveskite nuolaidos atvaizdavimo aprašymą (LT)';

    if (!values.announcement.description_lt)
      msg = 'Įveskite nuolaidos atvaizdavimo aprašymą (EN)';

    if (!values.announcement.description_lt)
      msg = 'Įveskite nuolaidos atvaizdavimo aprašymą (RU)';

    if (values.announcement.app_notification) {
      if (!values.announcement.sendDate)
        msg = 'Įveskite pranešimo siuntimo datą!';
      if (!values.announcement.sendTime)
        msg = 'Įveskite pranešimo siuntimo laiką!';
    }

    if (
      values.announcement.discountBannerFile &&
      Object.keys(values.announcement.discountBannerFile).length === 0 &&
      values.announcement.discountBannerFile === Object
    )
      msg = 'Būtina pasirinkti nuotrauką!';
  }

  if (isPast(toDate)) {
    msg = '"Laikotarpis iki" turi būti data ateityje';
  }

  if (!isValid(fromDate) || !isValid(toDate)) {
    msg = 'Blogai įvestas laikotarpis';
  }

  if (compareAsc(toDate, fromDate) !== 1) {
    msg = '"Laikotarpis nuo" turi būti vėliau nei "Laikotarpis iki"';
  }

  if (!values.code) {
    msg = 'Įveskite kampanijos pavadinimą';
  }

  return msg;
};
