import { useState, useEffect, useCallback } from 'react';

const UseClickOutside = ref => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = useCallback(
    ({ target }) => {
      if (ref && ref.current && !ref.current.contains(target)) {
        setIsOpen(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, [handleClickOutside]);

  return [isOpen, setIsOpen];
};

export default UseClickOutside;
