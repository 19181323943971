import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  clients: [],
  total: 0,
  header: {},
  selectedClient: {},
  clientCards: [],
  clientExport: {},
};

export const clientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.clients.SET_CLIENTS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.clients.SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
        total: action.count,
        header: action.header,
      };
    case constants.clients.SET_CLIENT:
      return {
        ...state,
        selectedClient: action.client,
      };
    case constants.clients.SET_CLIENT_CARDS:
      return {
        ...state,
        clientCards: action.clientCards,
      };
    case constants.clients.CLEAR_CLIENTS:
      return {
        ...INITIAL_STATE,
      };
    case constants.clients.SET_CLIENTS_EXPORT_IDENTIFIER:
      return {
        ...state,
        clientExport: action.identifier,
      };
    case constants.clients.SET_SELECTED_CLIENT_MEMBERSHIP:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          memberships: [...state.selectedClient.memberships, action.membership],
        },
      };

    case constants.clients.DELETE_CLIENTS_MEMBERSHIP_ORDER:
      return {
        ...state,
        selectedClient: {
          ...state.selectedClient,
          memberships: [
            ...state.selectedClient.memberships?.filter(
              membership => membership.id !== action.orderId
            ),
          ],
        },
      };
    default:
      return state;
  }
};
