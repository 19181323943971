import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './twoFactorLogin.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import logo from '../logo.png';
import {
  WithSpinner,
  Spinner,
  Input,
  Button,
  Paper,
  Alert,
} from '../../../components';

const TwoFactorLogin = ({
  history,
  dispatch,
  auth: { loading, credentials },
  admins,
}) => {
  const [values, setValues] = useState({ code: '' });

  const onInputChange = ({ target: { value, name } }) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(actions.auth.verify2faCode(values.code, history));
  };

  const handleResendCode = e => {
    dispatch(actions.auth.resend2faCode(credentials));
  };

  return (
    <section className={styles.login}>
      <WithSpinner loading={loading}>
        <Paper size="xs">
          <div className={styles.container}>
            <Link to={config.APP_PATHS.home}>
              <img className={styles.logo} src={logo} alt="H2AUTO" />
            </Link>
            <form onSubmit={onSubmit} method="POST">
              <Input
                type="code"
                name="code"
                label="Kodas gautas SMS žinute"
                value={values.code}
                onChange={onInputChange}
              />

              <Button
                color="primary"
                classes={{ button: styles.button }}
                type="submit"
              >
                Patvirtinti
              </Button>
            </form>
            <p className={styles.text}>
              Negavote kodo?
              <button
                className={styles.link}
                type="button"
                onClick={handleResendCode}
              >
                Siųsti iš naujo
              </button>
            </p>
          </div>
        </Paper>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  admins: state.admins,
});

TwoFactorLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    credentials: PropTypes.object,
  }).isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(TwoFactorLogin);
