export const receiptsConstants = {
  SET_RECEIPTS_LOADING: 'receipts/SET_RECEIPTS_LOADING',
  GET_RECEIPTS: 'receipts/GET_RECEIPTS',
  SET_RECEIPTS: 'receipts/SET_RECEIPTS',
  CLEAR_RECEIPTS: 'receipts/CLEAR_RECEIPTS',
  GET_RECEIPT: 'receipts/GET_RECEIPT',
  SET_RECEIPT: 'receipts/SET_RECEIPT',
  EXPORT_RECEIPTS: 'receipts/EXPORT_RECEIPTS',
  EXPORT_RECEIPTS_ASYNC: 'receipts/EXPORT_RECEIPTS_ASYNC',
  SET_RECEIPTS_EXPORT_IDENTIFIER: 'receipts/SET_RECEIPTS_EXPORT_IDENTIFIER',
  EXPORT_RECEIPTS_XML: 'receipts/EXPORT_RECEIPTS_XML',
  DOWNLOAD_PDF: 'receipts/DOWNLOAD_PDF',
};
