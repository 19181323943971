import { constants } from '..';

const setDropdownsLoadingAction = flag => ({
  type: constants.dropdowns.SET_DROPDOWNS_LOADING,
  flag,
});

const getCountriesAction = () => ({
  type: constants.dropdowns.GET_COUNTRIES,
});

const setCountriesAction = countries => ({
  type: constants.dropdowns.SET_COUNTRIES,
  countries,
});

const setDropdownsErrorAction = error => ({
  type: constants.dropdowns.SET_DROPDOWNS_ERROR,
  error,
});

export const dropdownsActions = {
  setDropdownsLoadingAction,
  getCountriesAction,
  setCountriesAction,
  setDropdownsErrorAction,
};
