import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  formatMoney,
  mapInvoicePaymentStatus,
  mapInvoicePaymentStatusColor,
} from '../../utils';

import styles from './invoices.module.scss';
import { INVOICE_STATUS_PAID } from './constants';

const InvoicesInfo = ({ invoice: { status, total_debt } }) => {
  const displayDebtSum = useMemo(() => {
    return status !== INVOICE_STATUS_PAID;
  }, [status]);

  const info = [
    {
      title: 'Būsena',
      data: (
        <span style={{ color: mapInvoicePaymentStatusColor(status) }}>
          {mapInvoicePaymentStatus(status)}
        </span>
      ),
    },
    ...(displayDebtSum
      ? [
          {
            title: 'Skola su PVM',
            data: <span>{formatMoney(total_debt)}</span>,
          },
        ]
      : []),
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <div className={styles.document} style={{ marginTop: 49 }}>
      <div className={styles.invoiceInfo}>{renderInfo()}</div>
    </div>
  );
};

InvoicesInfo.defaultProps = {
  status: '-',
  total_debt: 0,
};

InvoicesInfo.propTypes = {
  invoice: PropTypes.shape({
    status: PropTypes.number,
    total_debt: PropTypes.number,
  }).isRequired,
};

export default InvoicesInfo;
