import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { actions } from './../../../state';
import styles from './services.module.scss';
import SelfServices from './selfServices';
import AutoTextile from './autoTextile';
import AutoContactless from './autoContactless';
import VacuumCleaner from './vacuumCleaner';
import SmartVacuumCleaner from './smartVacuumCleaner';
import PaperTowelDispenser from './paperTowelDispenser';
import CarMatCleaner from './carMatCleaner';
import WindshieldWasherLiquidDispenser from './windshieldWasherLiquidDispenser';
import { ServicesHeader } from '../../../components';
import TyreBlackener from './tyreBlackener';
import TowelDispenser from './towelDispenser';

const ServicesBox = ({
  dispatch,
  selectedLocation,
  data: {
    self_service_washing,
    automatic_textile_washing,
    automatic_contactless_washing,
    vacuum_cleaner,
    smart_vacuum_cleaner,
    car_mat_cleaner,
    paper_towel_dispenser,
    windshield_washer_liquid_dispenser,
    tyre_blackener,
    towel_dispenser,
  },
  requestError,
  fetchSelectedLocation,
}) => {
  const [selfServices, openSelfServices] = useState(!!self_service_washing);
  const [autoTextile, openAutoTextile] = useState(!!automatic_textile_washing);

  const [autoContactless, openAutoContactless] = useState(
    !!automatic_contactless_washing
  );

  const [vacuumCleaner, openVacuumCleaner] = useState(!!vacuum_cleaner);

  const [smartVacuumCleaner, openSmartVacuumCleaner] = useState(
    !!smart_vacuum_cleaner
  );

  const [carMatCleaner, openCarMatCleaner] = useState(!!car_mat_cleaner);

  const [paperTowelDispenser, openPaperTowelDispenser] = useState(
    !!paper_towel_dispenser
  );

  const [windshieldWasher, openWindshieldWasher] = useState(
    !!windshield_washer_liquid_dispenser
  );

  const [tyreBlackener, openTyreBlackener] = useState(!!tyre_blackener);
  const [towelDispenser, openTowelDispenser] = useState(!!towel_dispenser);

  const changeServiceStatus = (name, isNew) => {
    // dispatch(
    //   actions.addEditServices.handleLocationStatusChangeAction(name)
    // );
    switch (name) {
      case 'self_service_washing':
        dispatch(
          actions.addEditServices.pushSelfServicesAction(
            isNew ? null : { deleted: true }
          )
        );
        openSelfServices(false);
        break;
      case 'automatic_textile_washing':
        dispatch(
          actions.addEditServices.pushAutoTextileAction(
            isNew ? null : { deleted: true }
          )
        );
        openAutoTextile(false);
        break;
      case 'automatic_contactless_washing':
        dispatch(
          actions.addEditServices.pushAutoContactlessAction(
            isNew ? null : { deleted: true }
          )
        );
        openAutoContactless(false);
        break;
      case 'vacuum_cleaner':
        dispatch(
          actions.addEditServices.pushVacuumCleanerAction(
            isNew ? null : { deleted: true }
          )
        );
        openVacuumCleaner(false);
        break;
      case 'smart_vacuum_cleaner':
        dispatch(
          actions.addEditServices.pushSmartVacuumCleanerAction(
            isNew ? null : { deleted: true }
          )
        );
        openSmartVacuumCleaner(false);
        break;
      case 'car_mat_cleaner':
        dispatch(
          actions.addEditServices.pushCarMatCleanerAction(
            isNew ? null : { deleted: true }
          )
        );
        openCarMatCleaner(false);
        break;
      case 'paper_towel_dispenser':
        dispatch(
          actions.addEditServices.pushPaperTowelDispenserAction(
            isNew ? null : { deleted: true }
          )
        );
        openPaperTowelDispenser(false);
        break;
      case 'windshield_washer_liquid_dispenser':
        dispatch(
          actions.addEditServices.pushWindshieldWasherLiquidDispenserAction(
            isNew ? null : { deleted: true }
          )
        );
        openWindshieldWasher(false);
        break;
      case 'tyre_blackener':
        dispatch(
          actions.addEditServices.pushTyreBlackenerAction(
            isNew ? null : { deleted: true }
          )
        );
        openTyreBlackener(false);
        break;
      default:
        break;
    }

    if (!isNew) {
      setTimeout(() => {
        dispatch(actions.locations.addLocationsAction());
        setTimeout(() => {
          changeServiceStatus(name, true);
        }, 100);
      }, 100);
    }
  };

  const newService = service => {
    return !selectedLocation[service];
  };

  return (
    <div className={styles.servicesContainer}>
      <ServicesHeader
        service="self_service_washing"
        opened={selfServices}
        onOpen={openSelfServices}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <SelfServices data={self_service_washing} requestError={requestError} />
      </ServicesHeader>

      <ServicesHeader
        service="automatic_textile_washing"
        opened={autoTextile}
        onOpen={openAutoTextile}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <AutoTextile
          data={automatic_textile_washing}
          requestError={requestError}
        />
      </ServicesHeader>

      <ServicesHeader
        service="automatic_contactless_washing"
        opened={autoContactless}
        onOpen={openAutoContactless}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <AutoContactless
          data={automatic_contactless_washing}
          requestError={requestError}
        />
      </ServicesHeader>

      <ServicesHeader
        service="vacuum_cleaner"
        opened={vacuumCleaner}
        onOpen={openVacuumCleaner}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <VacuumCleaner data={vacuum_cleaner} requestError={requestError} />
      </ServicesHeader>

      <ServicesHeader
        service="smart_vacuum_cleaner"
        opened={smartVacuumCleaner}
        onOpen={openSmartVacuumCleaner}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <SmartVacuumCleaner
          data={smart_vacuum_cleaner}
          requestError={requestError}
        />
      </ServicesHeader>

      <ServicesHeader
        service="car_mat_cleaner"
        opened={carMatCleaner}
        onOpen={openCarMatCleaner}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <CarMatCleaner data={car_mat_cleaner} requestError={requestError} />
      </ServicesHeader>

      <ServicesHeader
        service="paper_towel_dispenser"
        opened={paperTowelDispenser}
        onOpen={openPaperTowelDispenser}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <PaperTowelDispenser
          data={paper_towel_dispenser}
          requestError={requestError}
        />
      </ServicesHeader>

      <ServicesHeader
        service="windshield_washer_liquid_dispenser"
        opened={windshieldWasher}
        onOpen={openWindshieldWasher}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <WindshieldWasherLiquidDispenser
          data={windshield_washer_liquid_dispenser}
          requestError={requestError}
        />
      </ServicesHeader>

      <ServicesHeader
        service="tyre_blackener"
        opened={tyreBlackener}
        onOpen={openTyreBlackener}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <TyreBlackener data={tyre_blackener} requestError={requestError} />
      </ServicesHeader>

      <ServicesHeader
        service="towel_dispenser"
        opened={towelDispenser}
        onOpen={openTowelDispenser}
        onRemove={changeServiceStatus}
        isNew={newService}
      >
        <TowelDispenser data={towel_dispenser} requestError={requestError} />
      </ServicesHeader>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedLocation: state.locations.selectedLocation,
});

export default connect(mapStateToProps)(ServicesBox);
