import React, { useContext, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions } from '../../../state';
import { config } from '../../../api/config';

import { BusinessInvoicesContext } from './businessInvoices.context';
import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
  RangeFilter,
} from '../../../components';

import {
  formatFilename,
  formatDate,
  formatMoney,
  mapUserStatus,
  mapUserStatusColor,
  isFilterActive,
  generateHeader,
  mapBusinessInvoiceStatusColor,
  mapBusinessInvoiceStatus,
  moneyToCents,
  mapInvoicePaymentStatus,
  mapInvoicePaymentStatusColor,
} from '../../../utils';

const BusinessInvoices = ({
  dispatch,
  match: { params, url },
  history,
  invoices: { invoices, total, header, loading },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    statusFilter,
    setStatusFilter,
    invoiceNumberFilter,
    setInvoiceNumberFilter,
    dateLtFilter,
    setDateLtFilter,
    dateGtFilter,
    setDateGtFilter,
    amountLtFilter,
    setAmountLtFilter,
    amountGtFilter,
    setAmountGtFilter,
    wholeAmountLtFilter,
    setWholeAmountLtFilter,
    wholeAmountGtFilter,
    setWholeAmountGtFilter,
    debtLtFilter,
    setDebtLtFilter,
    debtGtFilter,
    setDebtGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(BusinessInvoicesContext);

  const fetchInvoices = useCallback(
    data => {
      dispatch(actions.businessInvoices.getInvoicesAction(data));
    },
    [dispatch]
  );

  const clearInvoices = useCallback(() => {
    dispatch(actions.businessInvoices.clearInvoicesAction());
  }, [dispatch]);

  useEffect(() => {
    fetchInvoices(filterBody);
    return () => clearInvoices();
  }, [fetchInvoices, clearInvoices, filterBody]);

  const handleSubmit = name => {
    if (params.phone) {
      history.push(config.APP_PATHS.baOrderHistory);
    }

    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (invoiceNumberFilter.length) {
      generatedFilter.push({
        field: 'number',
        value: invoiceNumberFilter,
      });
    }
    if (dateLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: dateLtFilter,
        type: 'timestamp',
      });
    }
    if (dateGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: dateGtFilter,
        type: 'timestamp',
      });
    }
    if (amountLtFilter) {
      generatedFilter.push({
        field: 'total_cost_without_tax',
        expression: 'lte',
        value: moneyToCents(amountLtFilter),
        type: 'number',
      });
    }
    if (amountGtFilter) {
      generatedFilter.push({
        field: 'total_cost_without_tax',
        expression: 'gte',
        value: moneyToCents(amountGtFilter),
        type: 'number',
      });
    }
    if (wholeAmountLtFilter) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'lte',
        value: moneyToCents(wholeAmountLtFilter),
        type: 'number',
      });
    }
    if (wholeAmountGtFilter) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'gte',
        value: moneyToCents(wholeAmountGtFilter),
        type: 'number',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setInvoiceNumberFilter([]);
    setDateLtFilter();
    setDateGtFilter();
    setAmountLtFilter();
    setAmountGtFilter();
    setWholeAmountLtFilter();
    setWholeAmountGtFilter();
    setDebtLtFilter();
    setDebtGtFilter();

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapInvoicePaymentStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectAsyncFilter
      title="Sąskaitos Nr."
      name="number"
      valueType="string"
      params={{ model: 'invoices', field: 'number' }}
      filterState={invoiceNumberFilter}
      setFilterState={setInvoiceNumberFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', invoiceNumberFilter)}
    />,
    <DateFilter
      title="Data"
      name="created_at"
      lt={dateLtFilter}
      setLt={setDateLtFilter}
      gt={dateGtFilter}
      setGt={setDateGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('range', dateLtFilter, dateGtFilter)}
    />,
    <RangeFilter
      title="Suma be PVM"
      name="total_cost_without_tax"
      lt={amountLtFilter}
      setLt={setAmountLtFilter}
      gt={amountGtFilter}
      setGt={setAmountGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', amountLtFilter, amountGtFilter)}
    />,
    <RangeFilter
      title="Suma"
      name="total_cost"
      lt={wholeAmountLtFilter}
      setLt={setWholeAmountLtFilter}
      gt={wholeAmountGtFilter}
      setGt={setWholeAmountGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        wholeAmountLtFilter,
        wholeAmountGtFilter
      )}
    />,
    <RangeFilter
      title="Skola"
      name="debt"
      lt={debtLtFilter}
      setLt={setDebtLtFilter}
      gt={debtLtFilter}
      setGt={setDebtLtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', debtLtFilter, debtGtFilter)}
    />,
  ];

  const renderTable = () =>
    invoices.map(
      ({
        id,
        status,
        invoice_number,
        created_at,
        total_cost_without_tax,
        total_cost,
        total_debt,
      }) => (
        <TableRow url={config.APP_PATHS.baInvoiceDetails(id)} key={id}>
          <TableCell>
            <span style={{ color: mapInvoicePaymentStatusColor(status) }}>
              {mapInvoicePaymentStatus(status)}
            </span>
          </TableCell>
          <TableCell>{invoice_number}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell>{formatMoney(total_cost_without_tax)}</TableCell>
          <TableCell>{formatMoney(total_cost)}</TableCell>
          <TableCell>{formatMoney(total_debt)}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.baInvoices);
    //dispatch(actions.businessInvoices.(filename));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.baInvoices}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}

          {/* <ButtonDownload onClick={xlsExport} loading={xlsLoading} /> */}
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        isEmpty={!invoices.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  invoices: state.businessInvoices,
  xlsLoading: state.download.xlsLoading,
});

BusinessInvoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    invoices: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      orders: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(BusinessInvoices);
