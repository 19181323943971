import React from 'react';

import styles from './confirmModal.module.scss';
import ModalLayout from '../_modalLayout/modalLayout';
import { Button } from '../../../components';

const ConfirmModal = ({ title, desc, onConfirm, onCancel }) => {
  const handleConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <ModalLayout title={title} onCancel={onCancel}>
      <p className={styles.desc} id="modal__desc">
        {desc}
      </p>

      <div className={styles.actions}>
        <Button color="outline" onClick={onCancel}>
          Atšaukti
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Patvirtinti
        </Button>
      </div>
    </ModalLayout>
  );
};

export default ConfirmModal;
