import React, { createContext, useState } from 'react';

export const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [discountFilter, setDiscountFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [companyCodeFilter, setCompanyCodeFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [businessClientFilter, setBusinessClientFilter] = useState();
  const [servicesFilter, setServicesFilter] = useState([]);
  const [costLtFilter, setCostLtFilter] = useState('');
  const [costGtFilter, setCostGtFilter] = useState('');
  const [costTaxLtFilter, setCostTaxLtFilter] = useState('');
  const [costTaxGtFilter, setCostTaxGtFilter] = useState('');
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState();
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState();
  const [timeLtFilter, setTimeLtFilter] = useState('00:00');
  const [timeGtFilter, setTimeGtFilter] = useState('00:00');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sourceFilter, setSourceFilter] = useState([]);

  return (
    <OrdersContext.Provider
      value={{
        filterBody,
        setFilterBody,
        statusFilter,
        setStatusFilter,
        paymentMethodFilter,
        setPaymentMethodFilter,
        locationFilter,
        setLocationFilter,
        discountFilter,
        setDiscountFilter,
        companyFilter,
        setCompanyFilter,
        companyCodeFilter,
        setCompanyCodeFilter,
        clientFilter,
        setClientFilter,
        businessClientFilter,
        setBusinessClientFilter,
        servicesFilter,
        setServicesFilter,
        costLtFilter,
        setCostLtFilter,
        costGtFilter,
        setCostGtFilter,
        costTaxLtFilter,
        setCostTaxLtFilter,
        costTaxGtFilter,
        setCostTaxGtFilter,
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
        timeLtFilter,
        setTimeLtFilter,
        timeGtFilter,
        setTimeGtFilter,
        sortOrder,
        setSortOrder,
        sourceFilter,
        setSourceFilter,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
