export const validationErrors = (values = {}, isCompanyType) => {
  let msg;

  if (isCompanyType && !values.company_code) {
    msg = 'Įmonės kodas yra privalomas!';
    return msg;
  }

  if (isCompanyType && !values.company_name) {
    msg = 'Įmonės pavadinimas yra privalomas!';
    return msg;
  }

  if (!isCompanyType && !values.billing_name) {
    msg = 'Vardas ir pavardė yra privalomi!';
    return msg;
  }

  if (!values.address) {
    msg = 'Adresas yra privalomas!';
    return msg;
  }

  if (!values.city) {
    msg = 'Įmonės miestas yra privalomas!';
    return msg;
  }

  if (!values.postal_code) {
    msg = 'Įmonės pašto kodas yra privalomas!';
    return msg;
  }

  if (!values.country_code) {
    msg = 'Įmonės šalies pasirinkimas yra privalomas!';
    return msg;
  }

  return msg;
};
