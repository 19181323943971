import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import print from 'print-js';

import styles from './businessInvoices.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { isEmpty } from '../../../utils';
import {
  WithSpinner,
  Spinner,
  Header,
  Breadcrumb,
  Paper,
  PaperNav,
  PaperContainer,
  Button,
  ButtonLink,
  Checkbox,
} from '../../../components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BusinessInvoiceDetails = ({
  dispatch,
  match: { params },
  invoices: { selectedInvoice, loading },
}) => {
  const [values, setValues] = useState(selectedInvoice);
  const [isPaid, setIsPaid] = useState(false);

  const fetchBusinessInvoice = useCallback(
    id => {
      dispatch(actions.businessInvoices.getInvoiceAction(id));
    },
    [dispatch]
  );

  const clearBusinessInvoices = useCallback(() => {
    dispatch(actions.businessInvoices.clearInvoicesAction());
  }, [dispatch]);

  useEffect(() => {
    setValues(selectedInvoice);
  }, [selectedInvoice]);

  useEffect(() => {
    fetchBusinessInvoice(params.id);
    setIsPaid(selectedInvoice.status === 342);
    return () => clearBusinessInvoices();
  }, [fetchBusinessInvoice, clearBusinessInvoices, params.id]);

  useEffect(() => {
    setIsPaid(selectedInvoice.status === 342);
  }, [selectedInvoice]);

  const handlePdfDownload = () => {
    dispatch(
      actions.businessInvoices.downloadInvoicePdf(
        selectedInvoice.id,
        selectedInvoice.invoice_number
      )
    );
  };

  return (
    <section>
      <Header>
        <Breadcrumb
          title={config.PAGES.baInvoices}
          subtitle="Sąskaitos informacija"
          baseUrl={config.APP_PATHS.baInvoices}
        />
        <Button color="outline" icon="download" onClick={handlePdfDownload}>
          Atsisiųsti
        </Button>
      </Header>
      <WithSpinner loading={loading}>
        <PaperContainer>
          <Paper>
            <div className={styles.pdfContainer}>
              {selectedInvoice.pdf && (
                <Document
                  file={`data:application/pdf;base64,${selectedInvoice.pdf}`}
                  error="Klaida! Nepavyko užkrauti .pdf dokumento"
                  noData="Dokumentas nerastas"
                  loading={<Spinner />}
                >
                  <Page className={styles.centeredPdf} pageNumber={1} />
                </Document>
              )}
            </div>
          </Paper>
          {!isEmpty(selectedInvoice) && (
            <PaperNav>
              <Checkbox
                label="Apmokėta"
                name="is_paid"
                checked={isPaid}
                success={isPaid}
                disabled={true}
              />
              {/* <ButtonLink
                icon="nav-orders"
                url={config.APP_PATHS.historicalOrderDetails(
                  selectedInvoice.order_id
                )}
              >
                Užsakymas
              </ButtonLink> */}
              {/* <ButtonLink
                icon="nav-clients"
                url={config.APP_PATHS.editEmployee(
                  values.business_client_employee.id
                )}
              >
                Darbuotojas
              </ButtonLink> */}
            </PaperNav>
          )}
        </PaperContainer>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  invoices: state.businessInvoices,
});

BusinessInvoiceDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  invoices: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedInvoice: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(BusinessInvoiceDetails);
