const FIVE_SEC_TO_MIN_MULT = 1;
const ONE_SEC_TO_MIN_MULT = 60;

const ADMIN_ROLE = 1201;
const BUSINESS_ADMIN_ROLE = 1202;

const EMPLOYEE_TYPE_CARD = 1402;

export const restrictConsoleLog = () => {
  if (process.env.NODE_ENV !== 'development') {
  }
};

export const isEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const hasPermission = (requiredPermission, UserPermissions) =>
  UserPermissions.includes(requiredPermission);

export const generateHeader = (array = [], convert) =>
  array.map(item => ({ name: convert ? convert(item) : item, value: item }));

export const downloadFile = (response, name, filetype = 'xlsx') => {
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.${filetype}`);
  document.body.appendChild(link);
  link.click();
};

export const getQueryParam = (query, param) =>
  new URLSearchParams(query).get(param);

export const removeNullValues = obj => {
  let newObj = {};
  Object.keys(obj).forEach(prop => {
    if (obj[prop] !== null) {
      newObj[prop] = obj[prop];
    }
  });
  delete newObj['order_count'];
  return newObj;
};

export const convertPricesAndTime = (location, type) => {
  const to = type === 'to';

  return {
    ...location,
    ...(location.self_service_washing
      ? {
          self_service_washing: {
            ...location.self_service_washing,
            options: location.self_service_washing.options.map(opt => ({
              ...opt,
              price: to
                ? Math.round(opt.price / FIVE_SEC_TO_MIN_MULT)
                : opt.price * FIVE_SEC_TO_MIN_MULT,
            })),
          },
        }
      : {}),
    ...(location.vacuum_cleaner
      ? {
          vacuum_cleaner: {
            ...location.vacuum_cleaner,
            time: to
              ? location.vacuum_cleaner.time * ONE_SEC_TO_MIN_MULT
              : location.vacuum_cleaner.time / ONE_SEC_TO_MIN_MULT,
          },
        }
      : {}),
    ...(location.smart_vacuum_cleaner
      ? {
          smart_vacuum_cleaner: {
            ...location.smart_vacuum_cleaner,
            time: to
              ? location.smart_vacuum_cleaner.time * ONE_SEC_TO_MIN_MULT
              : location.smart_vacuum_cleaner.time / ONE_SEC_TO_MIN_MULT,
          },
        }
      : {}),
    ...(location.paper_towel_dispenser
      ? {
          paper_towel_dispenser: {
            ...location.paper_towel_dispenser,
            time: undefined,
          },
        }
      : {}),
    ...(location.car_mat_cleaner
      ? {
          car_mat_cleaner: {
            ...location.car_mat_cleaner,
            time: to
              ? location.car_mat_cleaner.time * ONE_SEC_TO_MIN_MULT
              : location.car_mat_cleaner.time / ONE_SEC_TO_MIN_MULT,
          },
        }
      : {}),
  };
};

export const formatAddress = address => {
  //eslint-disable-next-line
  return address.replace(/[\. ,:-]+/g, '-');
};

export const isFilterActive = (filterType, filter, filter2) => {
  switch (filterType) {
    case 'time':
      return filter !== '00:00' || filter2 !== '00:00' ? true : false;
    case 'select':
      return filter?.length ? true : false;
    case 'range':
    case 'date':
      return filter || filter2 ? true : false;
    default:
      return false;
  }
};

export const sortArray = (key, order, array) => {
  switch (order) {
    case 'asc':
      return array.sort((a, b) => (a[key] > b[key] ? 1 : -1));
    case 'desc':
      return array.sort((a, b) => (a[key] < b[key] ? 1 : -1));
    default:
      return array;
  }
};

export const generatePhoneQuery = query => {
  switch (true) {
    case query.startsWith('370'):
      return query.substr(3);
    case query.startsWith('370', 1):
      return query.substr(4);
    case query.startsWith('86'):
      return query.substr(1);
    default:
      return query;
  }
};

export const chooseSidebarItems = (role, adminItems, businessAdminItems) => {
  if (role === ADMIN_ROLE) return adminItems;

  if (role === BUSINESS_ADMIN_ROLE) return businessAdminItems;

  return [];
};

export const isBusinessAdmin = role => role === BUSINESS_ADMIN_ROLE;

export const moneyToCents = amount => +amount * 100;

export const centsToMoney = amount => +amount / 100;

export const employeeIsCard = employee => employee.type == EMPLOYEE_TYPE_CARD;
