import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './orders.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import OrderDocument from './orders.document.component';
import { mapOrderStatus } from '../../utils/global/mapper';
import RetryPayment from './retryPayment';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Button,
  ButtonLink,
  Paper,
  PaperNav,
  PaperContainer,
} from '../../components';
import ChangeOrderStatus from './changeOrderStatus';

const OrderDetails = ({
  dispatch,
  match: { params },
  orders: { loading, selectedOrder },
}) => {
  const fetchOrder = useCallback(
    id => {
      dispatch(actions.orders.getOrderAction(id));
    },
    [dispatch]
  );

  const clearOrder = useCallback(() => {
    dispatch(actions.orders.clearOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    fetchOrder(params.id);
    return () => clearOrder();
  }, [fetchOrder, clearOrder, params.id]);

  const containsFailedPayment = order => {
    const isPaymentFailed = order.payments
      .map(payment => payment.status)
      .includes(455);

    return isPaymentFailed;
  };

  const openRetryPaymentModal = () => {
    const payload = {
      modalName: 'defaultModal',
      title: 'Kartoti užsakymo mokėjimą',
      children: (
        <RetryPayment
          clientId={selectedOrder.client.id}
          orderId={selectedOrder.id}
        />
      ),
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  const openChangePaymentStatusModal = () => {
    const payload = {
      modalName: 'defaultModal',
      title: 'Pakeisti užsakymo statusą',
      children: <ChangeOrderStatus orderId={selectedOrder.id} />,
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.orders}
          subtitle="Užsakymo informacija"
          baseUrl={config.APP_PATHS.orders}
        />
        {!isEmpty(selectedOrder) && containsFailedPayment(selectedOrder) && (
          <div style={{ display: 'flex' }}>
            {selectedOrder.status === 356 && (
              <Button
                color="outline"
                onClick={openChangePaymentStatusModal}
                style={{ marginRight: 16 }}
              >
                {mapOrderStatus(selectedOrder.status)}
              </Button>
            )}
            <Button color="primary" onClick={openRetryPaymentModal}>
              Kartoti mokėjimą
            </Button>
          </div>
        )}
      </Header>
      <WithSpinner loading={loading || (isEmpty(selectedOrder) && loading)}>
        {!isEmpty(selectedOrder) && (
          <PaperContainer>
            <Paper>
              <OrderDocument order={selectedOrder} />
            </Paper>
            <PaperNav>
              {selectedOrder.invoice_id && (
                <ButtonLink
                  icon="pdf"
                  url={config.APP_PATHS.invoiceDetails(
                    selectedOrder.invoice_id
                  )}
                >
                  #{selectedOrder.invoice_id}
                </ButtonLink>
              )}
              <ButtonLink
                icon="nav-clients"
                url={config.APP_PATHS.editClient(selectedOrder.client.id)}
              >
                {selectedOrder.client.phone}
              </ButtonLink>
              <ButtonLink
                icon="nav-locations"
                url={config.APP_PATHS.editLocation(selectedOrder.location.id)}
              >
                <span>
                  {selectedOrder.location.city}
                  <span className={styles.secondary}>
                    {selectedOrder.location.address}
                  </span>
                </span>
              </ButtonLink>
            </PaperNav>
          </PaperContainer>
        )}
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  orders: state.orders,
});

OrderDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  orders: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(OrderDetails);
