import React from 'react';

import { connect } from 'react-redux';
import styles from './exportModal.module.scss';
import ModalLayout from '../_modalLayout/modalLayout';
import { useState } from 'react';
import { useEffect } from 'react';
import { ButtonDownload, Spinner } from '../../../components';
import { downloadFile, downloadFileV2 } from '../../../utils';
import { actions } from '../../../state';

const ExportModal = ({
  dispatch,
  title,
  onCancel,
  exportResult,
  fileContent,
  fileName = null,
}) => {
  const [modalText, setModalText] = useState(
    'Prašome palaukti, failas eksportuojamas...'
  );
  const [enableDownload, setEnableDownload] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (exportResult) {
      if (exportResult.payload && exportResult.payload.error) {
        setIsError(true);
        setModalText('Įvyko klaida!');
      }

      if (exportResult.payload.file_url) {
        setIsError(false);
        dispatch(
          actions.download.fetchExportedFile(
            exportResult.payload.id,
            exportResult.payload.file_url
          )
        );
      }
    }
  }, [exportResult]);

  const handleDownload = () => {
    downloadFile(fileContent, fileName ? fileName : exportResult.payload.id);
  };

  useEffect(() => {
    if (fileContent) setEnableDownload(true);
  }, fileContent);

  return (
    <ModalLayout title={title} onCancel={onCancel}>
      <div className={styles.desc} id="modal__desc">
        {enableDownload ? (
          <div className={styles.downloadText}>
            <span>Failas paruoštas parsiuntimui</span>
            <ButtonDownload loading={false} onClick={handleDownload} />
          </div>
        ) : (
          <div className={styles.downloadText}>
            <span>{modalText}</span>
            {isError ? <></> : <Spinner />}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

const mapStateToProps = state => ({
  exportResult: state.download.exportResult,
  fileContent: state.download.fileContent,
});

export default connect(mapStateToProps)(ExportModal);
