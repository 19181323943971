import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './clients.module.scss';
import { config } from '../../api/config';
import { actions } from '../../state';
import {
  formatFilename,
  formatDate,
  mapUserStatus,
  mapUserStatusColor,
  isFilterActive,
  generateHeader,
  mapClientPromotions,
  mapClientCities,
} from '../../utils';
import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
} from '../../components';
import { ClientsContext } from './clients.context';
import { socketsClient } from '../../api/sockets';

const Clients = ({
  dispatch,
  user,
  history,
  clients: { clients, total, header, loading, clientExport },
  match: { params, url },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    emailFilter,
    setEmailFilter,
    phoneFilter,
    setPhoneFilter,
    cityFilter,
    setCityFilter,
    lastDiscountFilter,
    setLastDiscountFilter,
    statusFilter,
    setStatusFilter,
    promotionsFilter,
    setPromotionsFilter,
    lastOrderLtFilter,
    setLastOrderLtFilter,
    lastOrderGtFilter,
    setLastOrderGtFilter,
    sortOrder,
    setSortOrder,
    businessClientFilter,
    setBusinessClientFilter,
  } = useContext(ClientsContext);

  const fetchClients = useCallback(
    data => {
      dispatch(actions.clients.getClientsAction(data));
    },
    [dispatch]
  );

  const clearClients = useCallback(() => {
    dispatch(actions.clients.clearClientsAction());
  }, [dispatch]);

  useEffect(() => {
    let filterToSubmit;
    if (filterBody) {
      filterToSubmit = filterBody;
    } else if (params.business_client_id) {
      filterToSubmit = {
        filter: {
          business_client_ids: [+params.business_client_id],
        },
      };
      setBusinessClientFilter([+params.business_client_id]);
    }

    fetchClients(filterToSubmit);
    return () => clearClients();
  }, [fetchClients, clearClients, filterBody, params.business_client_id]);

  const handleSubmit = name => {
    if (params.business_client_id) {
      history.push(config.APP_PATHS.clients);
    }

    const generatedFilter = [];

    if (emailFilter.length) {
      generatedFilter.push({ field: 'email', value: emailFilter });
    }
    if (phoneFilter.length) {
      generatedFilter.push({ field: 'phone', value: phoneFilter });
    }
    if (lastDiscountFilter.length) {
      generatedFilter.push({
        field: 'last_discount_code',
        value: lastDiscountFilter,
      });
    }
    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (cityFilter.length) {
      generatedFilter.push({ field: 'city', value: cityFilter });
    }
    if (lastOrderGtFilter) {
      generatedFilter.push({
        field: 'last_order_date',
        expression: 'gte',
        value: lastOrderGtFilter,
        type: 'timestamp',
      });
    }
    if (lastOrderLtFilter) {
      generatedFilter.push({
        field: 'last_order_date',
        expression: 'lte',
        value: lastOrderLtFilter,
        type: 'timestamp',
      });
    }
    if (promotionsFilter.length) {
      generatedFilter.push({
        field: 'promotions',
        value: promotionsFilter,
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setEmailFilter([]);
    setPhoneFilter([]);
    setCityFilter([]);
    setLastDiscountFilter([]);
    setPromotionsFilter([]);

    setLastOrderLtFilter();
    setLastOrderGtFilter();

    handleSubmit();
  };

  useEffect(() => {
    const channel = socketsClient.subscribe(`admin/${user.id}`);
    if (clientExport && clientExport.id) {
      const payload = {
        modalName: 'exportModal',
        title: 'Failo eksportavimas',
        fileName: clientExport.filename,
        onCancel: () => {
          socketsClient.unsubscribe(`admin/${user.id}`);
          dispatch(actions.download.cleanDownload());
        },
      };
      dispatch(actions.modals.setModalVisible(payload));

      channel.watch(message => {
        dispatch(actions.download.setAsyncExportResult(message));
      });
    }

    return () => {
      socketsClient.unsubscribe(`admin/${user.id}`);
    };
  }, [clientExport]);

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapUserStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectAsyncFilter
      title="El. paštas"
      name="email"
      valueType="string"
      params={{ model: 'clients', field: 'email' }}
      filterState={emailFilter}
      setFilterState={setEmailFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', emailFilter)}
    />,
    <SelectAsyncFilter
      title="Numeris"
      name="phone"
      valueType="string"
      params={{ model: 'clients', field: 'phone' }}
      filterState={phoneFilter}
      setFilterState={setPhoneFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', phoneFilter)}
    />,
    <SelectFilter
      title="Miestas"
      name="city"
      valueType="string"
      array={generateHeader(header.city)}
      filterState={cityFilter}
      setFilterState={setCityFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', cityFilter)}
    />,
    <DateFilter
      title="Paskutinis pirkimas"
      name="last_order_date"
      lt={lastOrderLtFilter}
      setLt={setLastOrderLtFilter}
      gt={lastOrderGtFilter}
      setGt={setLastOrderGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', lastOrderLtFilter, lastOrderGtFilter)}
    />,
    <SelectAsyncFilter
      title="Paskutinė nuolaida"
      name="last_discount_code"
      valueType="string"
      params={{ model: 'clients', field: 'last_discount_code' }}
      filterState={lastDiscountFilter}
      setFilterState={setLastDiscountFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', lastDiscountFilter)}
    />,
    <SelectFilter
      title="Marketingas"
      name="promotions"
      valueType="number"
      array={generateHeader(header.promotions, mapClientPromotions)}
      filterState={promotionsFilter}
      setFilterState={setPromotionsFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('select', promotionsFilter)}
    />,
  ];

  const renderTable = () =>
    clients.map(
      ({
        id,
        status,
        email,
        phone,
        last_order_date,
        city,
        last_discount_code,
        promotions,
      }) => (
        <TableRow url={config.APP_PATHS.editClient(id)} key={id}>
          <TableCell>
            <span style={{ color: mapUserStatusColor(status) }}>
              {mapUserStatus(status)}
            </span>
          </TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{phone}</TableCell>
          <TableCell>{city}</TableCell>
          <TableCell>
            {last_order_date && formatDate(last_order_date)}
          </TableCell>
          <TableCell>{last_discount_code}</TableCell>
          <TableCell>{mapClientPromotions(promotions)}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.clients);
    //dispatch(actions.clients.exportClientsAction(filename));
    dispatch(actions.clients.exportClientsAsyncAction(filterBody));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.clients}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        isEmpty={!clients.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  clients: state.clients,
  user: state.auth.user,
  xlsLoading: state.download.xlsLoading,
});

Clients.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    clients: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      promotions: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Clients);
