import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { downloadFile, errorMessage } from '../../utils';

export function* getReceiptsSaga({ body: initialBody }) {
  try {
    yield put(actions.receipts.setReceiptsLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { receipts, count, header, footer } = yield call(
      Api.getReceipts,
      body
    );

    yield put(
      actions.receipts.setReceiptsAction(receipts, count, header, footer)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.receipts.setReceiptsLoadingAction(false));
  }
}

export function* getReceiptSaga({ id }) {
  try {
    yield put(actions.receipts.setReceiptsLoadingAction(true));

    const receipt = yield call(Api.getReceipt, id);

    yield put(actions.receipts.setReceiptAction(receipt));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.receipts.setReceiptsLoadingAction(false));
  }
}

export function* exportReceiptsSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportReceipts, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportReceiptsXmlSaga({ filename, body }) {
  try {
    yield put(actions.download.setXmlLoadingAction(true));
    const response = yield call(Api.exportReceiptsXml, body);
    downloadFile(response, filename, 'xml');
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXmlLoadingAction(false));
  }
}

export function* downloadReceiptPdfSaga({ id, invoiceNumber }) {
  try {
    yield put(actions.receipts.setReceiptsLoadingAction(true));

    const receipt = yield call(Api.downloadInvoicePdf, id);

    downloadFile(receipt, invoiceNumber, 'pdf');

    yield put(actions.receipts.setReceiptsLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.receipts.setReceiptsLoadingAction(false));
  }
}

export function* exportReceiptsAsyncSaga({ filter }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportReceiptsAsync, filter);

    yield put(actions.receipts.setExportIdentifierAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
