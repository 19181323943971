export const authConstants = {
  SET_AUTH_LOADING: 'auth/SET_AUTH_LOADING',
  LOGIN_USER: 'auth/LOGIN_USER',
  SET_USER: 'auth/SET_USER',
  MOUNT_USER: 'auth/MOUNT_USER',
  CLEAR_USER: 'auth/CLEAR_USER',
  UPDATE_PASS: 'auth/UPDATE_PASS',
  RESET_PASS_REQUEST: 'auth/RESET_PASS_REQUEST',
  RESET_PASS_FROM_TOKEN: 'auth/RESET_PASS_FROM_TOKEN',
  SET_FIRST_ITEM: 'auth/SET_FIRST_ITEM',
  IMPERSONATE_BUSINESS_CLIENT: 'auth/IMPERSONATE_BUSINESS_CLIENT',
  SET_IMPERSONATION: 'auth/SET_IMPERSONATION',
  MOUNT_ON_IMPERSONATION: 'auth/MOUNT_ON_IMPERSONATION',
  END_IMPERSONATION: 'auth/END_IMPERSONATION',
  IMPERSONATION_MOUNTING: 'auth/IMPERSONATION_MOUNTING',
  CLEAR_IMPERSONATION: 'auth/CLEAR_IMPERSONATION',
  CONFIRMATION_REQUIRED: 'auth/CONFIRMATION_REQUIRED',
  VERIFY_2FA_CODE: 'auth/VERIFY_2FA_CODE',
  RESEND_2FA_CODE: 'auth/RESEND_2FA_CODE',
};
