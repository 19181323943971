import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  contractUploading: false,
  businessClients: [],
  total: 0,
  header: {},
  footer: {},
  selectedClient: {},
  selectedClientMemberships: [],
  contract: {},
  logotype: {},
  clientCards: [],
  managerAdmins: [],
  companyDetails: [],
  companyDetailsByCode: [],
  companyDetailsLoading: false,
  companyDetailsError: '',
  companyDetailsByCodeError: '',
  singleCompanyDetails: null,
};

const updateMemberships = (list, newMembership) => {
  const alreadyExists = list.find(
    membership => membership.id === newMembership.id
  );
  if (alreadyExists) {
    const index = list.indexOf(alreadyExists);
    list.splice(index, 1, newMembership);
    return;
  }
  list.unshift(newMembership);
};

export const businessClientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.businessClients.SET_CLIENTS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.businessClients.SET_CONTRACT_LOADING:
      return {
        ...state,
        contractUploading: action.flag,
      };
    case constants.businessClients.SET_CLIENTS:
      return {
        ...state,
        businessClients: action.clients,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.businessClients.SET_CLIENT:
      return {
        ...state,
        selectedClient: action.client,
      };
    case constants.businessClients.SET_UPLOADED_CONTRACT: {
      return {
        ...state,
        contract: action.file,
      };
    }
    case constants.businessClients.SET_UPLOADED_LOGOTYPE: {
      return {
        ...state,
        logotype: action.file,
      };
    }
    case constants.businessClients.SET_CLIENTS_MEMBERSHIPS: {
      return {
        ...state,
        selectedClientMemberships: [...action.memberships],
      };
    }
    case constants.businessClients.UPDATE_CLIENTS_MEMBERSHIPS_LIST: {
      updateMemberships(state.selectedClientMemberships, action.membership);
      return state;
    }
    case constants.businessClients.SET_MANAGER_ADMINS:
      return {
        ...state,
        managerAdmins: action.admins,
      };
    case constants.businessClients.CLEAR_CLIENTS:
      return {
        ...INITIAL_STATE,
      };
    case constants.businessClients.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.details,
        companyDetailsError: '',
      };
    case constants.businessClients.SET_COMPANY_DETAILS_BY_CODE:
      return {
        ...state,
        companyDetailsByCode: action.details,
        companyDetailsByCodeError: '',
      };
    case constants.businessClients.SET_COMPANY_DETAILS_LOADING:
      return {
        ...state,
        companyDetailsLoading: action.flag,
      };
    case constants.businessClients.SET_COMPANY_DETAILS_ERROR:
      return {
        ...state,
        companyDetailsError: action.error,
      };
    case constants.businessClients.SET_COMPANY_DETAILS_BY_CODE_ERROR:
      return {
        ...state,
        companyDetailsByCodeError: action.error,
      };
    case constants.businessClients.SET_SINGLE_COMPANY_DETAILS_ACTION:
      return {
        ...state,
        singleCompanyDetails: action.code,
        companyDetailsError: '',
        companyDetailsByCodeError: '',
      };
    default:
      return state;
  }
};
