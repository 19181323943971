import React, { useState } from 'react';
import styles from './refreshButton.module.scss';
import Icon from '../icon/icon';

const RefreshButton = ({ onClick }) => {
  const [spin, setSpin] = useState(false);
  const handleClick = () => {
    if (!spin) {
      onClick();
      setSpin(true);
      setTimeout(() => {
        setSpin(false);
      }, 3000);
    }
  };
  return (
    <div
      className={spin ? styles.rotateRefreshIcon : styles.refreshIcon}
      onClick={handleClick}
    >
      <Icon name="refresh" />
    </div>
  );
};

export default RefreshButton;
