import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './header.module.scss';

const Header = ({ children, size }) => (
  <header className={cn(styles.header, styles[size])}>{children}</header>
);

Header.defaultProps = {
  size: 'lg',
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['lg', 'sm', 'custom']),
};

export default Header;
