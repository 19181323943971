import React from 'react';

import styles from './servicesHeader.module.scss';
import { mapServiceTypesNames } from '../../utils';
import { Icon, ButtonSimple } from '..';
import RemoveButton from '../removeButton/RemoveButton';

const ServicesHeader = ({
  service,
  opened,
  onOpen,
  onRemove,
  isNew,
  children,
}) => {
  return (
    <>
      <div className={styles.boxHeader}>
        <div className={opened ? styles.serviceItemOpened : styles.serviceItem}>
          <div className={styles.iconContainer}>
            <Icon name={service} />
          </div>
          <span className={styles.label}>{mapServiceTypesNames(service)}</span>
        </div>

        {!opened && (
          <ButtonSimple type="add" onClick={() => onOpen(true)}>
            Pridėti
          </ButtonSimple>
        )}

        {opened && (
          <RemoveButton
            onClick={() => onRemove(service, isNew(service))}
            title="Pašalinti paslaugą"
            desc="Ar tikrai norite pašalinti paslaugą?"
          >
            Pašalinti
          </RemoveButton>
        )}
      </div>
      {opened && children}
    </>
  );
};

export default ServicesHeader;
