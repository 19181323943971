import React from 'react';
import PropTypes from 'prop-types';

import styles from './labelElement.module.scss';

const LabelElement = ({ label, htmlFor }) => (
  <label className={styles.label} htmlFor={htmlFor}>
    {label}
  </label>
);

LabelElement.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default LabelElement;
