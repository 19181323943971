import React, { createContext, useState } from 'react';

export const CouponsContext = createContext();

export const CouponsProvider = ({ children }) => {
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState();
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState();
  const [expiresAtLtFilter, setExpiresAtLtFilter] = useState();
  const [expiresAtGtFilter, setExpiresAtGtFilter] = useState();
  const [typeFilter, setTypeFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBody, setFilterBody] = useState();

  return (
    <CouponsContext.Provider
      value={{
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
        expiresAtLtFilter,
        setExpiresAtLtFilter,
        expiresAtGtFilter,
        setExpiresAtGtFilter,
        nameFilter,
        setNameFilter,
        typeFilter,
        setTypeFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
      }}
    >
      {children}
    </CouponsContext.Provider>
  );
};
