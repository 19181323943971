import React, { createContext, useState } from 'react';

export const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [emailFilter, setEmailFilter] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState([]);
  const [cityFilter, setCityFilter] = useState([]);
  const [lastDiscountFilter, setLastDiscountFilter] = useState([]);
  const [promotionsFilter, setPromotionsFilter] = useState([]);
  const [lastOrderLtFilter, setLastOrderLtFilter] = useState();
  const [lastOrderGtFilter, setLastOrderGtFilter] = useState();
  const [businessClientFilter, setBusinessClientFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <ClientsContext.Provider
      value={{
        filterBody,
        setFilterBody,
        emailFilter,
        setEmailFilter,
        phoneFilter,
        setPhoneFilter,
        cityFilter,
        setCityFilter,
        lastDiscountFilter,
        setLastDiscountFilter,
        statusFilter,
        setStatusFilter,
        promotionsFilter,
        setPromotionsFilter,
        lastOrderLtFilter,
        setLastOrderLtFilter,
        lastOrderGtFilter,
        setLastOrderGtFilter,
        businessClientFilter,
        setBusinessClientFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};
