import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { config } from '../../api/config';
import {
  formatMoney,
  generateHeader,
  isEmpty,
  mapServiceTypes,
  mapServicesStatus,
  checkStatusColor,
  isFilterActive,
} from '../../utils';
import {
  SelectFilter,
  RangeFilter,
  WithSpinner,
  Table,
  TableRow,
  TableCell,
} from '../../components';
import { ServicesContext } from './services.context';

const ServicesTable = ({
  dispatch,
  loading,
  services: { services, header, footer },
  timeRangeFilter,
  getFilteredBody,
  clearAllFilters,
  setClearToFalse,
}) => {
  const {
    filterBody,
    setFilterBody,
    typeFilter,
    setTypeFilter,
    locationFilter,
    setLocationFilter,
    statusFilter,
    setStatusFilter,
    costLtFilter,
    setCostLtFilter,
    costGtFilter,
    setCostGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(ServicesContext);

  const fetchServices = useCallback(
    data => {
      dispatch(actions.services.getServicesAction(data));
    },
    [dispatch]
  );

  const clearServices = useCallback(() => {
    dispatch(actions.services.clearServicesAction());
  }, [dispatch]);

  const clearSelectedLocation = useCallback(() => {
    dispatch(actions.locations.setSelectedLocationAction({}));
  }, [dispatch]);

  useEffect(() => {
    let filterToSubmit;

    if (filterBody) {
      if (clearAllFilters) {
        setLocationFilter([]);
        setStatusFilter([]);
        setTypeFilter([]);
        setCostLtFilter('');
        setCostGtFilter('');
        setFilterBody();
        setSortOrder('asc');
        setClearToFalse(false);
      } else if (timeRangeFilter) {
        const filterSum = { ...filterBody, ...timeRangeFilter };
        filterToSubmit = filterSum;
      } else {
        filterToSubmit = filterBody;
      }
    } else if (timeRangeFilter) {
      filterToSubmit = timeRangeFilter;
    }
    getFilteredBody(filterToSubmit);
    fetchServices(filterToSubmit);
    return () => clearServices(filterToSubmit);
  }, [
    fetchServices,
    clearServices,
    filterBody,
    timeRangeFilter,
    getFilteredBody,
    clearAllFilters,
    setClearToFalse,
  ]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (locationFilter.length) {
      generatedFilter.push({ field: 'full_address', value: locationFilter });
    }
    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }

    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }

    if (costLtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'lte',
        value: costLtFilter * 100,
      });
    }

    if (costGtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'gte',
        value: costGtFilter * 100,
      });
    }

    setFilterBody({
      filter: {
        and: generatedFilter,
      },
      order: [
        {
          by: name,
          order: sortOrder,
        },
      ],
    });
  };

  const tableHeader = [
    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapServiceTypes)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    <SelectFilter
      title="Lokacija"
      name="full_address"
      valueType="string"
      array={generateHeader(header.full_address)}
      filterState={locationFilter}
      setFilterState={setLocationFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', locationFilter)}
    />,
    <SelectFilter
      title="Būsena"
      name="type"
      valueType="number"
      array={generateHeader(header.status, mapServicesStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <RangeFilter
      title="Apyvarta su PVM"
      name="income"
      lt={costLtFilter}
      setLt={setCostLtFilter}
      gt={costGtFilter}
      setGt={setCostGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', costLtFilter, costGtFilter)}
    />,
  ];
  const tableFooter = ['', '', '', formatMoney(footer.income)];

  const renderTable = () =>
    services.map(({ id, full_address, status, type, income, location_id }) => (
      <TableRow
        onClick={clearSelectedLocation}
        url={config.APP_PATHS.editLocation(location_id)}
        key={id}
      >
        <TableCell>{mapServiceTypes(type)}</TableCell>
        <TableCell>{full_address}</TableCell>
        <TableCell>
          <span style={{ color: checkStatusColor(status) }}>
            {mapServicesStatus(status)}
          </span>
        </TableCell>
        <TableCell align="right">{formatMoney(income)}</TableCell>
      </TableRow>
    ));

  return (
    <WithSpinner loading={loading}>
      {!isEmpty(header) && (
        <Table
          header={tableHeader}
          footer={tableFooter}
          filter={true}
          isEmpty={!services.length}
          width="xs"
          rightAlignedFooterCells={[3]}
        >
          {renderTable()}
        </Table>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = state => ({
  services: state.servicesTable,
  loading: state.locations.loading,
});

ServicesTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  services: PropTypes.shape({
    services: PropTypes.array.isRequired,
    header: PropTypes.object.isRequired,
    footer: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  timeRangeFilter: PropTypes.object,
  getFilteredBody: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.bool.isRequired,
  setClearToFalse: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ServicesTable);
