import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';

export function* getservicesTableDataSaga({ body }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));

    const { services, count, header, footer } = yield call(
      Api.getServicesTableData,
      body
    );
    yield put(
      actions.services.setServicesAction(services, count, header, footer)
    );
  } catch ({ error: { error } }) {
    yield put(actions.error.setErrorAction(error));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}
