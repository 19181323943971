import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './timeFilter.module.scss';
import { useClickOutside } from '../../../utils';
import { Button } from '../..';
import FilterTitle from '../_elements/filterTitle/filterTitle';

const TimeFilter = ({
  title,
  lt,
  setLt,
  gt,
  setGt,
  onSubmit,
  sortOrder,
  setSortOrder,
  name,
  position,
  isActive,
}) => {
  const filterRef = useRef();
  const [isOpen, setIsOpen] = useClickOutside(filterRef);

  const handleTitleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const handleOrderClick = () => {
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSubmit(name);
  };

  return (
    <div className={styles.container} ref={filterRef}>
      <FilterTitle
        title={title}
        onTitleClick={handleTitleClick}
        isActive={isActive}
      />

      {isOpen && (
        <div className={cn(styles.filter, styles[position])}>
          <div className={styles.range}>
            <input
              className={styles.input}
              type="time"
              min="0:00"
              max="24:00"
              value={gt}
              onChange={e => setGt(e.target.value)}
            />
            <input
              className={styles.input}
              type="time"
              min="0:00"
              max="24:00"
              value={lt}
              onChange={e => setLt(e.target.value)}
            />
          </div>

          <div className={styles.actions}>
            <Button
              color="light"
              classes={{ button: styles.button }}
              onClick={handleOrderClick}
            >
              {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
            </Button>
            <Button
              color="primary"
              classes={{ button: styles.button }}
              onClick={handleSubmit}
            >
              Filtruoti
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

TimeFilter.defaultProps = {
  position: 'left',
};

TimeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lt: PropTypes.string.isRequired,
  setLt: PropTypes.func.isRequired,
  gt: PropTypes.string.isRequired,
  setGt: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
  setSortOrder: PropTypes.func.isRequired,
  position: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
};

export default TimeFilter;
