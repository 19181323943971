// status,
//         company,
//         userEmail,
//         userFullName,
//         phoneNumber,
//         companyCode,
//         vatCode,
//         address,
//         creditLimit,
//         discount,
//         sendPassword,
//         contractValidFrom,
//         contractValidTo,
import { isPast, compareAsc, isValid } from 'date-fns';

export const validationErrors = (values = {}) => {
  let msg;

  const contractValidFrom = values.contract_start_date
    ? new Date(values.contract_start_date)
    : null;
  const contractValidTo = values.contract_end_date
    ? new Date(values.contract_end_date)
    : null;

  if (!values.name) {
    msg = 'Įmonės pavadinimas yra privalomas!';
    return msg;
  }

  if (!values.email) {
    msg = 'Vartotojo el. paštas yra privalomas!';
    return msg;
  }

  if (!values.owner_full_name) {
    msg = 'Vartotojo pilnas vardas yra privalomas!';
    return msg;
  }

  if (!values.phone) {
    msg = 'Telefono numeris yra privalomas!';
    return msg;
  }

  if (!values.company_code) {
    msg = 'Įmonės kodas yra privalomas!';
    return msg;
  }

  if (!values.address) {
    msg = 'Įmonės adresas yra privalomas!';
    return msg;
  }

  if (!values.city) {
    msg = 'Įmonės miestas yra privalomas!';
    return msg;
  }

  if (!values.postal_code) {
    msg = 'Įmonės pašto kodas yra privalomas!';
    return msg;
  }

  if (!values.country_code) {
    msg = 'Įmonės šalies pasirinkimas yra privalomas!';
    return msg;
  }

  if (!values.credit_limit || values.credit_limit <= 0) {
    msg = 'Kredito limitas yra privalomas!';
    return msg;
  }

  if (values.payment_term < 1 || values.payment_term > 99) {
    msg = 'Neteisinga apmokėjimo termino reikšmė!';
    return msg;
  }

  if (!contractValidFrom) {
    msg = 'Iveskite "Sutarties galiojiams nuo" lauką!';
    return msg;
  }

  if (contractValidTo && isPast(contractValidTo)) {
    msg = '"Sutarties galiojimas iki" turi būti data ateityje';
    return msg;
  }

  if (
    contractValidFrom &&
    contractValidTo &&
    (!isValid(contractValidFrom) || !isValid(contractValidTo))
  ) {
    msg = 'Blogai įvestas laikotarpis';
    return msg;
  }

  if (contractValidTo && compareAsc(contractValidTo, contractValidFrom) !== 1) {
    msg =
      '"Sutarties galiojimas nuo" turi būti vėliau nei "Sutarties galiojimas iki"';
    return msg;
  }

  return msg;
};
