import { constants } from '..';
import vacuumCleaner from '../../pages/locations/services/vacuumCleaner';

const INITIAL_STATE = {
  loading: true,
  user: null,
  token: null,
  loggedIn: false,
  permissions: [],
  firstItem: {},
  isImpersonating: false,
  impersonationToken: null,
  mounting: false,
  confirmationRequired: false,
  credentials: {},
};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.auth.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.auth.SET_USER: {
      return {
        ...state,
        user: action.userDetails,
        token: action.token,
        loggedIn: true,
        permissions: action.userDetails.permissions,
      };
    }
    case constants.auth.LOGIN_USER: {
      return {
        ...state,
        credentials: action.credentials,
      };
    }
    case constants.auth.SET_FIRST_ITEM: {
      return {
        ...state,
        firstItem: action.sidebarItem,
      };
    }
    case constants.auth.SET_IMPERSONATION:
      return {
        ...state,
        isImpersonating: true,
        impersonationToken: action.token,
      };
    case constants.auth.CLEAR_IMPERSONATION:
      return {
        ...state,
        isImpersonating: false,
        impersonationToken: null,
      };
    case constants.auth.IMPERSONATION_MOUNTING:
      return {
        ...state,
        mounting: action.mounting,
      };
    case constants.auth.CONFIRMATION_REQUIRED:
      return {
        ...state,
        confirmationRequired: action.required,
      };
    case constants.auth.CLEAR_USER:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
