import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './coupons.module.scss';

import {
  Header,
  Breadcrumb,
  PaperContainer,
  Paper,
  Button,
  WithSpinner,
  HeaderActions,
  ButtonDownload,
} from '../../components';

import { formatFilename } from '../../utils';

import { actions } from '../../state';
import { config } from '../../api/config';

const CouponDetails = ({
  dispatch,
  match: { params },
  coupons: { selectedCoupon, xlsLoading, loading },
}) => {
  const fetchCoupon = useCallback(
    id => {
      dispatch(actions.coupons.getCouponAction(id));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchCoupon(params.id);
  }, [fetchCoupon, params.id]);

  const handleSubmit = () => ({});

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.coupons + ' panaudojimas');

    dispatch(
      actions.coupons.exportCouponsCodeByCodeAction(filename, params.id)
    );
  };

  return (
    <section>
      <Header>
        <Breadcrumb
          title={config.PAGES.coupons}
          baseUrl={config.APP_PATHS.coupons}
        />
        <HeaderActions>
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>

      {/* <PaperContainer>
        <Paper>
          <div className={styles.physicalCardToggler}>
                <Toggle
                  onChange={toggleIsPhysicalCard}
                  checked={isPhysicalCard}
                  showMeta={true}
                  onText="Fizinė kortelė"
                  offText="Programėlė"
                />
              </div>

          <div className={styles.submitContainer}>
            <Button color="primary" onClick={handleSubmit}>
              Išsaugoti
            </Button>
          </div>

          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>
      </PaperContainer> */}
    </section>
  );
};

const mapStateToProps = state => ({
  coupons: state.coupons,
  xlsLoading: state.coupons.xlsLoading,
});

CouponDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  coupons: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedCoupon: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(CouponDetails);
