import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './login.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import logo from './logo.png';
import ResetPassRequest from './resetPassRequest/resetPassRequest';
import {
  WithSpinner,
  Spinner,
  Input,
  Button,
  Paper,
  Alert,
} from '../../components';

const Login = ({ history, dispatch, auth: { loading }, admins }) => {
  const [values, setValues] = useState({ email: '', password: '' });

  const onInputChange = ({ target: { value, name } }) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(actions.auth.loginUserAction(values, history));
  };

  const openModal = () => {
    const payload = {
      modalName: 'defaultModal',
      title: 'Atstatyti slaptažodį',
      children: <ResetPassRequest />,
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  const renderMessage = () => {
    if (admins.loading) {
      return <Spinner />;
    } else {
      return <Alert />;
    }
  };

  return (
    <section className={styles.login}>
      <WithSpinner loading={loading}>
        <Paper size="xs">
          <div className={styles.container}>
            <Link to={config.APP_PATHS.home}>
              <img className={styles.logo} src={logo} alt="H2AUTO" />
            </Link>
            <form onSubmit={onSubmit} method="POST">
              <Input
                type="email"
                name="email"
                label="El. paštas"
                value={values.email}
                onChange={onInputChange}
              />
              <Input
                type="password"
                name="password"
                label="Slaptažodis"
                value={values.password}
                onChange={onInputChange}
              />
              <Button
                color="primary"
                classes={{ button: styles.button }}
                type="submit"
              >
                Prisijungti
              </Button>
            </form>
            <p className={styles.text}>
              Pamiršai slaptažodį?
              <button className={styles.link} type="button" onClick={openModal}>
                Atstatyti
              </button>
            </p>
            {renderMessage()}
          </div>
        </Paper>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  admins: state.admins,
});

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Login);
