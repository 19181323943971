import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './dropdown.module.scss';
import { actions } from '../../../../state';
import { config } from '../../../../api/config';
import { hasPermission } from '../../../../utils';
import { Icon } from '../../../../components';

const Dropdown = ({ dispatch, user: { email, permissions } }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    dispatch(actions.auth.clearUserAction());
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div className={styles.header}>
        <Icon name="user" classes={{ icon: styles.icon }} />
        {email}
        <Icon name="chevron-down" classes={{ icon: styles.down }} />
      </div>
      {isOpen && (
        <ul className={styles.dropdown}>
          {hasPermission(config.PERMISSIONS.admins.view, permissions) && (
            <li onClick={() => setIsOpen(false)}>
              <Link to={config.APP_PATHS.admins} className={styles.item}>
                {config.PAGES.admins}
              </Link>
            </li>
          )}
          <li onClick={() => setIsOpen(false)}>
            <Link to={config.APP_PATHS.updatePassword} className={styles.item}>
              Slaptažodžio keitimas
            </Link>
          </li>
          <li onClick={() => setIsOpen(false)}>
            <button onClick={handleLogout} className={styles.item}>
              Atsijungti
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

Dropdown.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Dropdown);
