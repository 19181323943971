import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './selectAsyncFilter.module.scss';
import { Api } from '../../../api/api';
import { config } from '../../../api/config';
import { useClickOutside, generatePhoneQuery } from '../../../utils';
import { Button, Icon, Multiselect, Checkbox, Spinner } from '../..';
import FilterTitle from '../_elements/filterTitle/filterTitle';

const SelectAsyncFilter = ({
  title,
  valueType,
  filterState,
  setFilterState,
  onSubmit,
  sortOrder,
  setSortOrder,
  name,
  position,
  isActive,
  params,
}) => {
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);
        setError('');

        let query;
        if (params.model === 'clients' && params.field === 'phone') {
          query = generatePhoneQuery(value);
        } else {
          query = value;
        }

        const results = await Api.searchHeader(query, params);

        setList(results);

        if (results.length === 0) {
          setError('Rezultatų nėra');
        }
      } catch (err) {
        setError('Klaida, pakartokite paiešką');
      } finally {
        setLoading(false);
      }
    };

    if (value.length >= config.DEFAULTS.symbolsToSearch) {
      fetchResults();
    } else {
      setList([]);
      setError(`Įveskite ${config.DEFAULTS.symbolsToSearch} simbolius`);
    }
    // eslint-disable-next-line
  }, [value]);

  const filterRef = useRef();
  const [isOpen, setIsOpen] = useClickOutside(filterRef);

  const isAllChecked = list.length === filterState.length;

  const handleCheckAll = () => {
    isAllChecked ? setFilterState([]) : setFilterState(list);
  };

  const handleTitleClick = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const handleOrderClick = () => {
    sortOrder === 'asc' ? setSortOrder('desc') : setSortOrder('asc');
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSubmit(name);
  };

  return (
    <div className={styles.container} ref={filterRef}>
      <FilterTitle
        title={title}
        onTitleClick={handleTitleClick}
        isActive={isActive}
      />

      {isOpen && (
        <div className={cn(styles.filter, styles[position])}>
          <div className={styles.search}>
            <input
              type="search"
              placeholder="Paieška"
              className={styles.input}
              value={value}
              onChange={e => setValue(e.target.value)}
            />
            <Icon name="search" classes={{ icon: styles.icon }} />
          </div>

          <div className={styles.list}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {error && <p className={styles.tip}>{error}</p>}

                {list.length > 0 && (
                  <Checkbox
                    label="Visi"
                    name="Visi"
                    value="-1"
                    checked={isAllChecked}
                    onChange={handleCheckAll}
                  />
                )}

                <Multiselect
                  array={list}
                  type="array"
                  name="name"
                  value="value"
                  state={filterState}
                  setState={setFilterState}
                  valueType={valueType}
                />
              </>
            )}
          </div>

          <div className={styles.actions}>
            <Button
              color="light"
              classes={{ button: styles.button }}
              onClick={handleOrderClick}
            >
              {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
            </Button>

            <Button
              color="primary"
              classes={{ button: styles.button }}
              onClick={handleSubmit}
            >
              Filtruoti
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

SelectAsyncFilter.defaultProps = {
  position: 'left',
};

SelectAsyncFilter.propTypes = {
  array: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  filterState: PropTypes.array.isRequired,
  setFilterState: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf(['asc', 'desc']).isRequired,
  setSortOrder: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
};

export default SelectAsyncFilter;
