import React from 'react';
import PropTypes from 'prop-types';

import AuthLayout from './authLayout/authLayout';
import GuestLayout from './guestLayout/guestLayout';
import GlobalModal from './modals/globalModal';
import ErrorModal from './modals/errorModal/errorModal';

const Layout = ({ children, loggedIn }) => (
  <>
    {loggedIn ? (
      <AuthLayout>{children}</AuthLayout>
    ) : (
      <GuestLayout>{children}</GuestLayout>
    )}
    <ErrorModal />
    <GlobalModal />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default Layout;
