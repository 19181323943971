import React from 'react';
import ReactDatePicker from 'react-datepicker';
import cn from 'classnames';

import inputStyles from '../../components/form/_elements/inputElement/inputElement.module.scss';
import styles from './datePicker.module.scss';

const DatePicker = ({ className, showTimeSelectOnly, ...otherProps }) => {
  return (
    <ReactDatePicker
      className={cn(
        className,
        inputStyles.input,
        styles.datePicker,
        showTimeSelectOnly && styles.timePicker
      )}
      showTimeSelectOnly={showTimeSelectOnly}
      {...otherProps}
    />
  );
};

export default DatePicker;
