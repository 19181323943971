const storageHasProperty = (itemKey, itemSetupTimeKey) => {
  return (
    localStorage.hasOwnProperty(itemKey) &&
    localStorage.hasOwnProperty(itemSetupTimeKey)
  );
};

export const getLocalStorageItemIfNotExpired = (
  itemKey,
  itemSetupTimeKey,
  itemExpiry
) => {
  if (storageHasProperty(itemKey, itemSetupTimeKey)) {
    const hours = itemExpiry;
    const now = new Date().getTime();
    const setupTime = localStorage.getItem(itemSetupTimeKey);

    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.removeItem(itemKey);
      localStorage.removeItem(itemSetupTimeKey);
      return null;
    } else {
      return localStorage.getItem(itemKey);
    }
  } else {
    return null;
  }
};

export const getLocalStorageItem = key => {
  if (localStorage.hasOwnProperty(key)) {
    return localStorage.getItem(key);
  }
};

export const removeImpersonationItemsFromLocalStorage = (
  tokenKey,
  isImpersonatingKey
) => {
  if (localStorage.hasOwnProperty(tokenKey)) localStorage.removeItem(tokenKey);

  if (localStorage.hasOwnProperty(isImpersonatingKey))
    localStorage.removeItem(isImpersonatingKey);
};

export const resetLocalStorageItem = (itemKey, itemSetupTimeKey, itemValue) => {
  const now = new Date().getTime();

  if (storageHasProperty(itemKey, itemSetupTimeKey)) {
    localStorage.removeItem(itemKey);
    localStorage.removeItem(itemSetupTimeKey);
  }

  localStorage.setItem(itemKey, itemValue);
  localStorage.setItem(itemSetupTimeKey, now);
};

export const resetLocalStorageImpersonationToken = (
  key,
  token,
  isImpersonatingKey
) => {
  if (localStorage.hasOwnProperty(key)) localStorage.removeItem(key);

  if (localStorage.hasOwnProperty(isImpersonatingKey))
    localStorage.removeItem(isImpersonatingKey);

  localStorage.setItem(key, token);
  localStorage.setItem(isImpersonatingKey, true);
};

export const removeLocalStorageItem = (itemKey, itemSetupTimeKey) => {
  if (storageHasProperty(itemKey, itemSetupTimeKey)) {
    localStorage.removeItem(itemKey);
    localStorage.removeItem(itemSetupTimeKey);
  }
};

export const removeLocalStorageItemIfExists = item => {
  if (localStorage.hasOwnProperty(item)) {
    localStorage.removeItem(item);
  }
};
