import { constants } from '../../constants';

const INITIAL_STATE = {
  loading: false,
  invoices: [],
  total: 0,
  header: {},
  footer: {},
  selectedInvoice: {},
  pdfDownloading: false,
};

export const businessInvoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.businessInvoices.SET_INVOICES_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.businessInvoices.SET_INVOICES: {
      return {
        ...state,
        invoices: action.invoices,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    }
    case constants.businessInvoices.SET_INVOICE:
      return {
        ...state,
        selectedInvoice: action.invoice,
      };
    case constants.businessInvoices.SET_PDF_DOWNLOADING:
      return {
        ...state,
        pdfDownloading: action.flag,
      };
    case constants.businessInvoices.CLEAR_INVOICES:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
