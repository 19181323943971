import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../icon/icon';
import styles from './button.module.scss';

const Button = ({
  children,
  color,
  icon,
  url,
  classes,
  disabled,
  transparent,
  ...rest
}) => {
  if (url) {
    return (
      <Link
        className={cn(styles.button, styles[color], classes.button)}
        to={url}
        {...rest}
      >
        {icon && <Icon name={icon} classes={{ icon: styles.icon }} />}
        {children}
      </Link>
    );
  } else {
    return (
      <button
        className={
          transparent
            ? cn(styles['transparent'], styles.button)
            : cn(styles.button, styles[color], classes.button)
        }
        disabled={disabled}
        {...rest}
      >
        {icon && <Icon name={icon} classes={{ icon: styles.icon }} />}
        {children}
      </button>
    );
  }
};

Button.defaultProps = {
  icon: null,
  url: null,
  disabled: false,
  transparent: false,
  classes: {},
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'dim', 'light', 'dark', 'outline'])
    .isRequired,
  icon: PropTypes.string,
  url: PropTypes.string,
  classes: PropTypes.shape({
    button: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
};

export default Button;
