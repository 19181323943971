import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './orderHistory.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { isEmpty } from '../../../utils';
import HistoricalOrderInfo from './orderHistory.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Toggle,
  Paper,
  PaperNav,
  PaperContainer,
  ButtonLink,
  Button,
} from '../../../components';

const HistoricalOrderDetails = ({
  dispatch,
  match: { params },
  orders: { loading, selectedOrder },
}) => {
  const [values, setValues] = useState({});

  const fetchOrder = useCallback(
    id => {
      dispatch(actions.orderHistory.getOrderAction(id));
    },
    [dispatch]
  );

  const clearOrder = useCallback(() => {
    dispatch(actions.orderHistory.clearOrderHistoryAction());
  }, [dispatch]);

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedOrder)) {
      setValues({
        id: selectedOrder.id,
        employee_name: selectedOrder.business_client_employee.full_name,
        employee_phone: selectedOrder.business_client_employee.phone,
        services: selectedOrder.items,
        total_cost: selectedOrder.total_cost,
        total_discount: selectedOrder.total_discount,
        employee_id: selectedOrder.business_client_employee.id,
        invoice_id: selectedOrder.invoice_id,
      });
    }
  }, [selectedOrder]);

  useEffect(() => {
    fetchOrder(params.id);
    return () => clearOrder();
  }, [fetchOrder, clearOrder, params.id]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.baHistory}
            baseUrl={config.APP_PATHS.baHistory}
          />
        </div>
      </Header>

      <PaperContainer>
        <Paper>
          {!isEmpty(selectedOrder) && <HistoricalOrderInfo order={values} />}

          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>

        <PaperNav>
          <ButtonLink
            icon="nav-clients"
            url={config.APP_PATHS.editEmployee(values.employee_id)}
          >
            {selectedOrder.business_client_employee
              ? selectedOrder.business_client_employee.phone
              : ''}
          </ButtonLink>

          {/* <ButtonLink
            icon="nav-invoices"
            url={config.APP_PATHS.baInvoiceDetails(values.invoice_id)}
          >
            Sąskaita
          </ButtonLink> */}
        </PaperNav>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  orders: state.orderHistory,
});

HistoricalOrderDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  orders: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedOrder: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(HistoricalOrderDetails);
