export const MembershipTypes = {
  CUSTOM: 30,
  SILVER: 31,
  GOLD: 32,
  DIAMOND: 33,
  WHITE: 34,
  GREEN: 35,
  RED: 36,
  MAIN: 38,
  SHADOW: 39,
};

export const MembershipTypesTitles = {
  CUSTOM: 'Pasirinktinis',
  SILVER: 'Sidabras',
  GOLD: 'Auksas',
  DIAMOND: 'Deimantas',
  WHITE: 'Balta',
  GREEN: 'Žalia',
  RED: 'Raudona',
  MAIN: 'Viešas',
  SHADOW: 'Privatus',
};
