import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  bannerUploading: false,
  discounts: [],
  discountBanner: {},
  total: 0,
  selectedDiscount: {},
};

export const discountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.discounts.SET_DISCOUNTS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.discounts.SET_BANNER_UPLOADING:
      return {
        ...state,
        bannerUploading: action.flag,
      };
    case constants.discounts.SET_BANNER:
      return {
        ...state,
        discountBanner: action.banner,
      };
    case constants.discounts.SET_DISCOUNTS:
      return {
        ...state,
        discounts: action.discounts,
        total: action.count,
      };
    case constants.discounts.CLEAR_DISCOUNTS:
      return {
        ...INITIAL_STATE,
      };
    case constants.discounts.SET_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.discount,
      };
    default:
      return state;
  }
};
