export const orderHistoryConstants = {
  SET_ORDER_HISTORY_LOADING: 'orderHistory/SET_ORDER_HISTORY_LOADING',
  GET_ORDER_HISTORY: 'orderHistory/GET_ORDER_HISTORY',
  SET_ORDER_HISTORY: 'orderHistory/SET_ORDER_HISTORY',
  GET_ORDER_HISTORY_RECORD: 'orderHistory/GET_ORDER_HISTORY_RECORD',
  SET_ORDER_HISTORY_RECORD: 'orderHistory/SET_ORDER_HISTORY_RECORD',
  CLEAR_ORDER_HISTORY: 'orderHistory/CLEAR_ORDER_HISTORY',
  EXPORT_ORDER_HISTORY: 'orderHistory/EXPORT_ORDER_HISTORY',
  EXPORT_H2_CARD_ASYNC: 'orderHistory/EXPORT_H2_CARD_ASYNC',
  SET_ORDER_HISTORY_EXPORT_IDENTIFIER:
    'orderHistory/SET_ORDER_HISTORY_EXPORT_IDENTIFIER',
};
