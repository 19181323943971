import { serviceTypesNames } from '../../utils';

export const formattedDevices = devices => {
  return devices.map(box => ({
    id: box.id,
    ip: box.ip,
    status: box.status === 11 || box.status === 2 ? 11 : 10,
    title: box.title,
    type: box.type,
    phone: box.phone,
    deleted: box.deleted,
  }));
};

export const deviceStatuses = location => {
  const statusesArray = serviceTypesNames.map(service => {
    if (
      location[service] === null ||
      !location[service].devices ||
      !location[service].devices.length
    ) {
      return [];
    } else {
      return location[service].devices.map(device => device.status);
    }
  });

  const statuses = statusesArray.flat(1);

  return statuses;
};

export const checkForEnabled = statuses => {
  const enabledDevices = statuses.filter(status => status !== 11);

  if (enabledDevices.length > 0) {
    return true;
  } else {
    return false;
  }
};
