import React from 'react';
import PropTypes from 'prop-types';
import styles from './memberships.module.scss';
import { Checkbox, DatePicker, Input } from '../../components';
import Benefit from './memberships.history.form.benefit';
import { MembershipTypes } from '../../utils/global/enums';
import inputStyles from '../../components/form/_elements/inputElement/inputElement.module.scss';
import LabelElement from '../../components/form/_elements/labelElement/labelElement';
import { config } from '../../api/config';

const HistoryForm = ({
  values,
  children,
  onChange,
  onDateChange,
  onCheckboxChange,
  onBenefitChange,
  onSubmit,
  services,
  addNewBenefitClickHandler,
  removeBenefitClickHandler,
  membershipType,
}) => {
  return (
    <form onSubmit={onSubmit} method="POST" className={styles.historyForm}>
      <p className={styles.sectionTitle}>Kaina ir galiojimas</p>
      <div className={styles.flexWrapper}>
        <div className={styles.flexItem}>
          <div className={styles.labelContainer}>
            <LabelElement label="Galioja nuo" htmlFor="dateFrom" />
          </div>
          <DatePicker
            className={inputStyles.input}
            selected={values.dateFrom}
            onChange={date => onDateChange(date)}
            showYearDropdown
            yearDropdownItemNumber={2}
            popperPlacement="bottom-center"
            dateFormat={config.DEFAULTS.dateFormat}
            label="Galioja nuo"
            name={'dateFrom'}
          />
        </div>
        <div className={styles.flexItem}>
          <Input
            name="warranty"
            label="Garantija, užtikrinanti tą pačią sąranką tiems, kurie jau užsisakė (mėnesiai)"
            placeholder="0"
            type="number"
            min="0"
            max="12"
            step="1"
            onChange={onChange}
            value={values.warranty}
          />
        </div>
        <div className={styles.flexItem}>
          <Input
            name="price"
            label="Mėnesio kaina su PVM (&euro;)"
            placeholder="0"
            type="number"
            min="0"
            step="0.01"
            onChange={onChange}
            value={values.price}
          />
        </div>
      </div>
      <div className={styles.formCheckbox}>
        <Checkbox
          label="Galimas metinis planas"
          name="checkYearlyPlan"
          value={values.checkYearlyPlan}
          onChange={onCheckboxChange}
          checked={values.checkYearlyPlan}
        />
      </div>

      {values.checkYearlyPlan && (
        <div className={styles.flexItem}>
          <Input
            name="yearlyPrice"
            label="Metinė kaina su PVM (&euro;)"
            placeholder="0"
            type="number"
            min="0"
            step="1"
            onChange={onChange}
            value={values.yearlyPrice}
          />
        </div>
      )}

      <div className={styles.formCheckbox}>
        <Checkbox
          label="Galimas bandomasis laikotarpis"
          name="checkTrialPeriod"
          value={values.checkTrialPeriod}
          onChange={onCheckboxChange}
          checked={values.checkTrialPeriod}
        />
      </div>
      {values.checkTrialPeriod && (
        <div className={styles.flexItem}>
          <Input
            name="trialPeriod"
            label="Bandomojo laikotarpio trukmė (mėnesiais)"
            placeholder="0"
            type="number"
            min="0"
            max="12"
            step="1"
            onChange={onChange}
            value={values.trialPeriod}
          />
        </div>
      )}
      {membershipType === MembershipTypes.SHADOW && (
        <div className={styles.formCheckbox}>
          <Checkbox
            label="Mokamas bandomasis laikotarpis (tik privatiems nuolaidų planams)"
            name="checkPaidTrialPeriod"
            value={values.checkPaidTrialPeriod}
            onChange={onCheckboxChange}
            checked={values.checkPaidTrialPeriod}
          />
        </div>
      )}
      {values.checkPaidTrialPeriod && (
        <div className={styles.flexItem}>
          <Input
            name="trialPeriodPrice"
            label="Bandomojo laikotarpio mėnesio kaina su PVM (&euro;)"
            placeholder="0"
            type="number"
            min="0"
            step="0.01"
            onChange={onChange}
            value={values.trialPeriodPrice}
          />
        </div>
      )}

      <div className={styles.benefits}>
        <p className={styles.sectionTitle}>Nuolaidos konfigūracija</p>
        {values.benefits?.length &&
          values.benefits?.map((benefit, id) => (
            <Benefit
              key={benefit.id}
              benefit={benefit}
              services={services}
              onChange={e => onBenefitChange(e, benefit.id)}
              addNewClickHandler={
                values.benefits?.length - 1 === id
                  ? addNewBenefitClickHandler
                  : undefined
              }
              removeClickHandler={
                values.benefits?.length > 1
                  ? () => removeBenefitClickHandler(id)
                  : undefined
              }
            />
          ))}
        {children}
      </div>
    </form>
  );
};

HistoryForm.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    discount: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    price: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    yearlyPrice: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    trialPeriodPrice: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    warranty: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    trialPeriod: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    checkYearlyPlan: PropTypes.bool.isRequired,
    checkTrialPeriod: PropTypes.bool.isRequired,
    checkPaidTrialPeriod: PropTypes.bool.isRequired,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        service_type: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]).isRequired,
        amount: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]).isRequired,
        reset_period: PropTypes.number,
      }).isRequired
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onBenefitChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addNewBenefitClickHandler: PropTypes.func.isRequired,
  removeBenefitClickHandler: PropTypes.func.isRequired,
  membershipType: PropTypes.number.isRequired,
};

export default HistoryForm;
