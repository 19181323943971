export const businessInvoicesConstants = {
  SET_INVOICES_LOADING: 'businessInvoice/SET_INVOICES_LOADING',
  GET_INVOICES: 'businessInvoice/GET_INVOICES',
  SET_INVOICES: 'businessInvoice/SET_INVOICES',
  GET_INVOICE: 'businessInvoice/GET_INVOICE',
  SET_INVOICE: 'businessInvoice/SET_INVOICE',
  CLEAR_INVOICES: 'businessInvoice/CLEAR_INVOICES',
  EXPORT_INVOICES: 'businessInvoice/EXPORT_INVOICES',
  DOWNLOAD_PDF: 'businessInvoice/DOWNLOAD_PDF',
  SET_PDF_DOWNLOADING: 'businessInvoice/SET_PDF_DOWNLOADING',
};
