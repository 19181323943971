import React, { createContext, useState } from 'react';

export const MembershipsContext = createContext();

export const MembershipsProvider = ({ children }) => {
  const [statusFilter, setStatusFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [nameFilter, setNameFilter] = useState([]);
  const [activeClientsLtFilter, setActiveClientsLtFilter] = useState('');
  const [activeClientsGtFilter, setActiveClientsGtFilter] = useState('');
  const [
    activeBusinessClientsLtFilter,
    setActiveBusinessClientsLtFilter,
  ] = useState('');
  const [
    activeBusinessClientsGtFilter,
    setActiveBusinessClientsGtFilter,
  ] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBody, setFilterBody] = useState();
  return (
    <MembershipsContext.Provider
      value={{
        statusFilter,
        setStatusFilter,
        typeFilter,
        setTypeFilter,
        nameFilter,
        setNameFilter,
        activeClientsLtFilter,
        setActiveClientsLtFilter,
        activeClientsGtFilter,
        setActiveClientsGtFilter,
        activeBusinessClientsLtFilter,
        setActiveBusinessClientsLtFilter,
        activeBusinessClientsGtFilter,
        setActiveBusinessClientsGtFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
      }}
    >
      {children}
    </MembershipsContext.Provider>
  );
};
