import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../../api/api';
import { config } from '../../../api/config';
import { actions } from '../../actions';

import { errorMessage, downloadFile } from '../../../utils';

export function* getBusinessInvoicesSaga({ body: initialBody }) {
  try {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { invoices, count, header, footer } = yield call(
      Api.getBusinessInvoices,
      body
    );

    yield put(
      actions.businessInvoices.setInvoicesAction(
        invoices,
        count,
        header,
        footer
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(false));
  }
}

export function* getBusinessInvoiceSaga({ id }) {
  try {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(true));

    const invoice = yield call(Api.getBusinessInvoice, id);

    yield put(actions.businessInvoices.setInvoiceAction(invoice));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(false));
  }
}

export function* downloadBusinessInvoicePdfSaga({ id, invoiceNumber }) {
  try {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(true));

    const invoice = yield call(Api.downloadInvoicePdf, id);

    downloadFile(invoice, invoiceNumber, 'pdf');

    yield put(actions.businessInvoices.setInvoiceLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessInvoices.setInvoiceLoadingAction(false));
  }
}
