import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonFileUpload } from '../../components';

import styles from './discounts.module.scss';
import { config } from '../../api/config';
import { actions } from '../../state';
import {
  serviceTypes,
  mapServiceTypes,
  discountGroups,
  mapDiscountGroup,
} from '../../utils';
import {
  Checkbox,
  Input,
  MultiselectAll,
  SubmitActions,
  WithSpinner,
  DateFilter,
} from '../../components';
import { availableWeekdays } from './discounts.data';
import { LIMITS } from './discounts.validation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import store from '../../utils/redux/store';

const Form = ({
  dispatch,
  values,
  onChange,
  onSubmit,
  loading,
  weekdays,
  setWeekdays,
  services,
  setServices,
  locations,
  setLocations,
  availableLocations,
  locationsLoading,
  groups,
  setGroups,
  setCreateDiscountBanner,
  setDiscountBannerIsAdvertisement,
  setSendPushNotifications,
}) => {
  const [bannerLoading, setBannerUploading] = useState(false);

  const fetchLocations = useCallback(() => {
    const body = {
      order: [
        {
          by: 'full_address',
          order: 'asc',
        },
      ],
    };
    dispatch(actions.locations.getLocationsAction(body));
  }, [dispatch]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    setBannerUploading(store.getState().discounts.bannerUploading);
  }, [store.getState().discounts.bannerUploading]);

  const onFileUploaded = value => {
    values.announcement.discountBannerFile = value;
    dispatch(actions.discounts.uploadDiscountBanner(value));
  };

  return (
    <form onSubmit={onSubmit} method="POST">
      <div className={styles.formdata}>
        <div className={styles.inputsRow}>
          <Input
            name="code"
            label="Kodas"
            type="text"
            disabled={loading}
            onChange={onChange}
            value={values.code}
          />
          <Input
            name="discount"
            label="Nuolaida (%)"
            placeholder="0"
            type="number"
            min="0"
            max="100"
            step="1"
            disabled={loading}
            onChange={onChange}
            value={values.discount}
          />
        </div>
        <div className={styles.inputsRow}>
          <Input
            name="dateFrom"
            label="Laikotarpis nuo"
            type="date"
            max="2999-12-31"
            placeholder={config.DEFAULTS.dateFormat}
            disabled={loading}
            onChange={onChange}
            value={values.dateFrom}
          />
          <Input
            name="dateTo"
            label="Laikotarpis iki"
            type="date"
            placeholder={config.DEFAULTS.dateFormat}
            max="2999-12-31"
            disabled={loading}
            onChange={onChange}
            value={values.dateTo}
          />
        </div>
        <div className={styles.inputsRow}>
          <Input
            name="timeFrom"
            label="Valandos nuo"
            type="time"
            min="0:00"
            max="24:00"
            disabled={loading}
            onChange={onChange}
            value={values.timeFrom}
          />
          <Input
            name="timeTo"
            label="Valandos iki"
            type="time"
            min="0:00"
            max="24:00"
            disabled={loading}
            onChange={onChange}
            value={values.timeTo}
          />
        </div>

        <Input
          name="description_lt"
          label="Kampanijos detalus pavadinimas (LT)"
          placeholder="Naktimis nuo 00:00 iki 05:00 savitarnos plovykla su 20% nuolaida"
          icon="flag-lt"
          type="text"
          disabled={loading}
          maxLength={LIMITS.description}
          onChange={onChange}
          value={values.description_lt}
        />
        <Input
          name="description_en"
          label="Kampanijos detalus pavadinimas (EN)"
          placeholder="20% discount for car wash services from 12AM to 5AM"
          icon="flag-en"
          type="text"
          disabled={loading}
          maxLength={LIMITS.description}
          onChange={onChange}
          value={values.description_en}
        />
        <Input
          name="description_ru"
          label="Kampanijos detalus pavadinimas (RU)"
          placeholder="20% скидка на услуги автомойки с полночи до 5 утра"
          icon="flag-ru"
          type="text"
          disabled={loading}
          maxLength={LIMITS.description}
          onChange={onChange}
          value={values.description_ru}
        />

        <div className={styles.inputsRow}>
          <div>
            <h5 className={styles.selectionLabel}>Galioja</h5>
            <div className={styles.list}>
              <MultiselectAll
                array={availableWeekdays}
                type="object"
                valueType="number"
                allText="Visomis dienomis"
                state={
                  availableWeekdays.length === weekdays.length ? [] : weekdays
                }
                setState={setWeekdays}
              />
            </div>
          </div>
          <div>
            <h5 className={styles.selectionLabel}>Paslaugos</h5>
            <div className={styles.list}>
              <MultiselectAll
                array={serviceTypes}
                type="array"
                renderName={mapServiceTypes}
                valueType="number"
                allText="Visos paslaugos"
                state={serviceTypes.length === services.length ? [] : services}
                setState={setServices}
              />
            </div>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <div>
            <h5 className={styles.selectionLabel}>Klientų grupė</h5>
            <div className={styles.list}>
              <MultiselectAll
                array={discountGroups}
                type="array"
                renderName={mapDiscountGroup}
                valueType="number"
                allText="Visi klientai"
                state={discountGroups.length === groups.length ? [] : groups}
                setState={setGroups}
              />
            </div>
          </div>
          <div>
            <h5 className={styles.selectionLabel}>Lokacijos</h5>
            <WithSpinner loading={locationsLoading}>
              <div className={styles.list}>
                <MultiselectAll
                  array={availableLocations}
                  type="object"
                  name="full_address"
                  value="id"
                  valueType="number"
                  allText="Visos lokacijos"
                  state={locations}
                  setState={setLocations}
                />
              </div>
            </WithSpinner>
          </div>
        </div>

        <div className={styles.inputsRow}>
          <h5 className={styles.selectionLabel}>
            Nuolaidos atvaizdavimas programėlėje
          </h5>
        </div>

        <div className={styles.inputsColumn}>
          <Checkbox
            label="Kurti nuolaidos skydelį"
            name="createDiscountBanner"
            checked={values.createDiscountBanner}
            value={values.createDiscountBanner}
            onClick={setCreateDiscountBanner}
          />
        </div>

        {values.createDiscountBanner ? (
          <div>
            <Checkbox
              label="Reklaminis skydelis"
              name="discountBannerIsAdvertisement"
              checked={values.discountBannerIsAdvertisement}
              value={values.discountBannerIsAdvertisement}
              onClick={setDiscountBannerIsAdvertisement}
            />

            <Input
              name="announcement.title_lt"
              label="Trumpas pavadinimas (LT)"
              placeholder=""
              icon="flag-lt"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerName}
              onChange={onChange}
              value={values.announcement.title_lt}
            />

            <Input
              name="announcement.title_en"
              label="Trumpas pavadinimas (EN)"
              placeholder=""
              icon="flag-en"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerName}
              onChange={onChange}
              value={values.announcement.title_en}
            />

            <Input
              name="announcement.title_ru"
              label="Trumpas pavadinimas (RU)"
              placeholder=""
              icon="flag-ru"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerName}
              onChange={onChange}
              value={values.announcement.title_ru}
            />

            <Input
              name="announcement.description_lt"
              label="Aprašymas (LT)"
              placeholder=""
              icon="flag-lt"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerDescription}
              onChange={onChange}
              value={values.announcement.description_lt}
            />

            <Input
              name="announcement.description_en"
              label="Aprašymas (EN)"
              placeholder=""
              icon="flag-en"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerDescription}
              onChange={onChange}
              value={values.announcement.description_en}
            />

            <Input
              name="announcement.description_ru"
              label="Aprašymas (RU)"
              placeholder=""
              icon="flag-ru"
              type="text"
              disabled={loading}
              maxLength={LIMITS.discountBannerDescription}
              onChange={onChange}
              value={values.announcement.description_ru}
            />

            <div className={styles.inputsRowFull}>
              <Checkbox
                label="Siųsti tiesioginius pranešimus ekrane (angl. push notifications)"
                name="announcement.app_notification"
                checked={values.announcement.app_notification}
                value={values.announcement.app_notification}
                fullLine={true}
                onChange={setSendPushNotifications}
              />
            </div>

            <h5 className={styles.selectionLabel}>
              Tiesioginių pranešimų siuntimo data ir laikas
            </h5>
            <div className={styles.inputsRow}>
              <Input
                name="announcement.sendDate"
                type="date"
                max="2999-12-31"
                disabled={loading || !values.announcement.app_notification}
                onChange={onChange}
                value={values.announcement.sendDate}
              />
              <Input
                name="announcement.sendTime"
                type="time"
                min="0:00"
                max="24:00"
                disabled={loading || !values.announcement.app_notification}
                onChange={onChange}
                value={values.announcement.sendTime}
              />
            </div>

            <h5 className={styles.selectionLabel}>Nuolaidos paveikslėlis</h5>
            <div className={styles.inputsRowFull}>
              <ButtonFileUpload
                file={
                  values.announcement.banner
                    ? { name: values.announcement.banner.filename }
                    : null
                }
                onFileChange={onFileUploaded}
              >
                Pridėti
              </ButtonFileUpload>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <SubmitActions
        back={config.APP_PATHS.discounts}
        loading={loading || bannerLoading}
      />
    </form>
  );
};

const mapStateToProps = state => ({
  availableLocations: state.locations.locations,
  locationsLoading: state.locations.loading,
});

Form.propTypes = {
  values: PropTypes.shape({
    code: PropTypes.string.isRequired,
    discount: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    timeFrom: PropTypes.string.isRequired,
    timeTo: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  availableLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationsLoading: PropTypes.bool.isRequired,
  weekdays: PropTypes.arrayOf(PropTypes.number).isRequired,
  setWeekdays: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(PropTypes.number).isRequired,
  setServices: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.number).isRequired,
  setLocations: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.number).isRequired,
  setGroups: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Form);
