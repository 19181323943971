import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../state';

import styles from './errorModal.module.scss';
import { Backdrop, Icon } from '../../../components';

const ErrorModal = ({ error, dispatch }) => {
  const resetError = () => {
    if (error.code === 105) {
      dispatch(actions.auth.clearUserAction());
    }
    dispatch(actions.error.setErrorAction(null));
  };
  return error ? (
    <>
      <div
        id="modal"
        className={styles.modal}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal__error"
      >
        {error?.subMessage ? (
          <div>
            <p className={styles.error} id="modal__error">
              {error.msg}
            </p>
            <p className={styles.errorSubMessage}>{error.subMessage}</p>
          </div>
        ) : (
          <p className={styles.error} id="modal__error">
            {error.msg}
          </p>
        )}
        <button className={styles.button} onClick={resetError}>
          <Icon
            name="close"
            classes={{
              icon: styles.icon,
            }}
          />
        </button>
      </div>
      <Backdrop onCancel={resetError} />
    </>
  ) : null;
};

const mapStateToProps = state => ({
  error: state.error.error,
});

export default connect(mapStateToProps)(ErrorModal);
