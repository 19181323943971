import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getTime, startOfDay, endOfDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import inputStyles from '../../components/form/_elements/inputElement/inputElement.module.scss';
import styles from './businessClients.module.scss';
import { Input, Checkbox, ButtonFileUpload, Select } from '../../components';

const BusinessClientInfo = ({
  businessClient,
  setContractValidFrom,
  setContractValidTo,
  onInputChange,
  onCheckboxChange,
  onFileUpload,
  onLogotypeUpload,
  managerAdmins,
  countries,
  companyDetails,
  companyDetailsByCode,
  searchCompanyDetails,
  searchCompanyDetailsByCode,
  companyDetailsLoading,
  companyDetailsError,
  companyDetailsByCodeError,
  getSingleCompanyDetails,
}) => {
  const {
    company_code,
    name,
    tax_code,
    address,
    city,
    postal_code,
    country_code,
    email,
    invoice_emails,
    owner_full_name,
    phone,
    credit_limit,
    discount,
    sendPassword,
    contract_start_date,
    contract_end_date,
    contract,
    logo,
    progressive_discount,
    payment_term,
    color,
    manager_id,
  } = businessClient;

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'discount' || name === 'creditLimit') {
      onInputChange({ name, value: +value });
    } else if (name === 'manager_id') {
      onInputChange({ name, value: Number(value) });
    } else if (name === 'name') {
      onInputChange({ name, value });
      // search for company details on company name input change
      searchCompanyDetails(value);
    } else if (name === 'company_code') {
      onInputChange({ name, value });
      searchCompanyDetailsByCode(value);
    } else onInputChange({ name, value });
  };

  const handleGetCompanyPrefill = value => {
    getSingleCompanyDetails(value);
  };

  const companyDetailsResults = useMemo(
    () =>
      companyDetails?.map(company => {
        return {
          label: company.company_name,
          value: company.company_code,
        };
      }) || [],
    [companyDetails]
  );

  const companyDetailsByCodeResults = useMemo(
    () =>
      companyDetailsByCode?.map(company => {
        return {
          label: company.company_name,
          value: company.company_code,
        };
      }) || [],
    [companyDetailsByCode]
  );

  const datePickerChange = (date, setFilter, point) => {
    const timestamp = getTime(
      point === 'start' ? startOfDay(date) : endOfDay(date)
    );

    setFilter(timestamp);
  };

  const handleFileUpload = file => {
    onFileUpload(file);
  };

  const adminManagers = useMemo(
    () =>
      managerAdmins?.length
        ? managerAdmins.map(admin => {
            return {
              name: `${admin.first_name} ${admin.last_name}`,
              value: admin.id,
            };
          })
        : [],
    [managerAdmins]
  );

  const adminCountries = useMemo(
    () =>
      countries?.length
        ? countries.map(country => {
            return {
              name: country.name_lt,
              value: country.code,
            };
          })
        : [],
    [countries]
  );

  const managersList = [
    {
      name: 'Nepasirinktas',
      value: '',
    },
    ...(adminManagers?.length ? adminManagers : []),
  ];

  const countriesList = [
    {
      name: 'Nepasirinkta',
      value: '',
    },
    ...(adminCountries?.length ? adminCountries : []),
  ];

  const info = [
    {
      title: 'Įmonės kodas',
      data: (
        <Input
          name="company_code"
          label=""
          type="text"
          value={company_code}
          withLabel={false}
          onChange={handleInputChange}
          isAutocomplete
          results={companyDetailsByCodeResults}
          autocompleteLoading={companyDetailsLoading}
          autocompleteError={companyDetailsByCodeError}
          onAutcompleteSelect={handleGetCompanyPrefill}
        />
      ),
    },
    {
      title: 'Įmonė',
      data: (
        <Input
          name="name"
          label=""
          type="text"
          value={name}
          withLabel={false}
          onChange={handleInputChange}
          isAutocomplete
          results={companyDetailsResults}
          autocompleteLoading={companyDetailsLoading}
          autocompleteError={companyDetailsError}
          onAutcompleteSelect={handleGetCompanyPrefill}
        />
      ),
    },
    {
      title: 'PVM kodas',
      data: (
        <Input
          name="tax_code"
          label=""
          type="text"
          value={tax_code}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Adresas',
      data: (
        <Input
          name="address"
          label=""
          type="text"
          value={address}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Miestas',
      data: (
        <Input
          name="city"
          label=""
          type="text"
          value={city}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Pašto kodas',
      data: (
        <Input
          name="postal_code"
          label=""
          type="text"
          value={postal_code}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Šalis',
      data: (
        <div className={styles.selectInput}>
          <Select
            name="country_code"
            options={countriesList}
            selectedValue={country_code}
            onChange={handleInputChange}
          />
        </div>
      ),
    },
    {
      title: 'Adm. El. paštas',
      data: (
        <Input
          name="email"
          label=""
          type="text"
          value={email}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Sąsk. El. paštas',
      data: (
        <Input
          name="invoice_emails"
          label=""
          type="text"
          value={invoice_emails}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Vardas, Pavardė',
      data: (
        <Input
          name="owner_full_name"
          label=""
          type="text"
          value={owner_full_name}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Telefono nr.',
      data: (
        <Input
          name="phone"
          label=""
          type="text"
          value={phone}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Kredito limitas (EUR)',
      data: (
        <Input
          name="credit_limit"
          label=""
          type="number"
          min="0"
          step="0.01"
          placeholder="0.00"
          value={credit_limit}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Progresinė nuolaida',
      data: (
        <Checkbox
          name="progressive_discount"
          label="Taip"
          checked={progressive_discount}
          value={progressive_discount}
          onChange={onCheckboxChange}
        />
      ),
    },
    {
      title: 'Nuolaida (%)',
      hide: progressive_discount,
      data: (
        <Input
          name="discount"
          label=""
          type="number"
          min="0"
          setp="1"
          placeholder="0"
          value={discount}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Apmokėjimo terminas',
      data: (
        <Input
          name="payment_term"
          label=""
          type="number"
          min="1"
          max="99"
          setp="1"
          placeholder="0"
          value={payment_term}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Išsiųsti slaptažodį',
      data: (
        <Checkbox
          name="sendPassword"
          label="Taip"
          checked={sendPassword}
          value={sendPassword}
          onChange={onCheckboxChange}
        />
      ),
    },
    {
      title: 'Sutarties galiojimas',
      data: (
        <div className={styles.dateRange}>
          <DatePicker
            className={inputStyles.input}
            selected={contract_start_date}
            onChange={date =>
              datePickerChange(date, setContractValidFrom, 'start')
            }
            showYearDropdown
            placeholderText="Nuo"
            yearDropdownItemNumber={2}
            popperPlacement="bottom-center"
            dateFormat="yyyy.MM.dd"
          />
          <span className={styles.separator}> - </span>
          <DatePicker
            className={inputStyles.input}
            selected={contract_end_date}
            onChange={date => datePickerChange(date, setContractValidTo, 'end')}
            showYearDropdown
            placeholderText="Iki"
            yearDropdownItemNumber={2}
            popperPlacement="bottom-center"
            dateFormat="yyyy.MM.dd"
          />
        </div>
      ),
    },
    {
      title: 'Vadybininkas',
      data: (
        <div className={styles.selectInput}>
          <Select
            name="manager_id"
            options={managersList}
            selectedValue={manager_id}
            onChange={handleInputChange}
          />
        </div>
      ),
    },
    {
      title: 'Spalvos kodas',
      data: (
        <Input
          name="color"
          label=""
          type="text"
          value={color}
          withLabel={false}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Logotipas',
      data: (
        <ButtonFileUpload
          allowDownload={true}
          onFileChange={onLogotypeUpload}
          file={logo}
          withFileLabel={false}
        >
          Pridėti
        </ButtonFileUpload>
      ),
    },
    {
      title: 'Sutartis',
      data: (
        <ButtonFileUpload
          allowDownload={true}
          onFileChange={handleFileUpload}
          file={contract}
          withFileLabel={false}
        >
          Pridėti
        </ButtonFileUpload>
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, hide, data }) =>
      hide ? (
        <></>
      ) : (
        <div className={styles.infoRow} key={title}>
          <span>{title}</span>
          <span>{data}</span>
        </div>
      )
    );

  return (
    <article className={styles.document}>
      <div className={styles.info}>{renderInfo()}</div>
    </article>
  );
};

BusinessClientInfo.defaultProps = {
  name: null,
  email: null,
  owner_full_name: null,
  phone: null,
  sendPassword: false,
  company_code: null,
  tax_code: null,
  address: null,
  credit_limit: 0,
  discount: 0,
  contract_start_date: null,
  contract_end_date: null,
  contract: {},
  progressive_discount: false,
};

BusinessClientInfo.propTypes = {
  businessClient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    owner_full_name: PropTypes.string,
    phone: PropTypes.string,
    company_code: PropTypes.string,
    tax_code: PropTypes.string,
    address: PropTypes.string,
    credit_limit: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    progressive_discount: PropTypes.bool,
    sendPassword: PropTypes.bool,
    contract_start_date: PropTypes.number,
    contract_end_date: PropTypes.number,
    contract: PropTypes.object,
    logo: PropTypes.object,
    manager_id: PropTypes.number,
    city: PropTypes.string,
    postal_code: PropTypes.string,
    country_code: PropTypes.string,
  }).isRequired,
  setContractValidFrom: PropTypes.func.isRequired,
  setContractValidTo: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
};

export default BusinessClientInfo;
