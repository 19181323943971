export const addEditServices = {
  ADD_EDIT_SELFSERVICES_DATA: 'addEditServices/ADD_EDIT_SELFSERVICES_DATA',
  PUSH_PLACES_DATA: 'addEditServices/PUSH_PLACES_DATA',
  ADD_EDIT_AUTOTEXTILE_DATA: 'addEditServices/ADD_EDIT_AUTOTEXTILE_DATA',
  ADD_EDIT_AUTOCONTACTLESS_DATA:
    'addEditServices/ADD_EDIT_AUTOCONTACTLESS_DATA',
  ADD_EDIT_VACUUM_CLEANER_DATA: 'addEditServices/ADD_EDIT_VACUUM_CLEANER_DATA',
  ADD_EDIT_SMART_VACUUM_CLEANER_DATA:
    'addEditServices/ADD_EDIT_SMART_VACUUM_CLEANER_DATA',
  ADD_EDIT_CAR_MAT_CLEANER_DATA:
    'addEditServices/ADD_EDIT_CAR_MAT_CLEANER_DATA',
  ADD_EDIT_PAPER_TOWEL_DISPENSER_DATA:
    'addEditServices/ADD_EDIT_PAPER_TOWEL_DISPENSER_DATA',
  ADD_WIND_SHIELD_WASHER_LIQUID_DISPENSER_DATA:
    'addEditServices/ADD_WIND_SHIELD_WASHER_LIQUID_DISPENSER_DATA',
  ADD_TYRE_BLACKENER_DATA: 'addEditServices/ADD_TYRE_BLACKENER_DATA',
  ADD_TOWEL_DISPENSER_DATA: 'addEditServices/ADD_TOWEL_DISPENSER_DATA',
  EDIT_LOCATION: 'addEditServices/EDIT_LOCATION',
  REPLACE_ADD_EDIT_SERVICES_DATA:
    'addEditServices/REPLACE_ADD_EDIT_SERVICES_DATA',
  CLEAR_ADD_EDIT_SERVICES_ACTION:
    'addEditServices/CLEAR_ADD_EDIT_SERVICES_ACTION',
  PUSH_NEW_COORD: 'addEditServices/PUSH_NEW_COORD',
  HANDLE_LOCATION_STATUS_CHANGE:
    'addEditServices/HANDLE_LOCATION_STATUS_CHANGE',
  REGENERATE_DEVICE_KEY: 'addEditServices/REGENERATE_DEVICE_KEY',
  PUSH_NEW_STATUS: 'addEditServices/PUSH_NEW_STATUS',
};
