import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import { mapUserStatus, mapUserStatusColor } from '../../utils';
import {
  WithSpinner,
  Header,
  Title,
  Button,
  Table,
  TableRow,
  TableCell,
} from '../../components';

const Admins = ({ dispatch, admins: { loading, total, admins } }) => {
  const fetchAdmins = useCallback(() => {
    dispatch(actions.admins.getAdminsAction());
  }, [dispatch]);

  const clearAdmins = useCallback(() => {
    dispatch(actions.admins.clearAdminsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchAdmins();
    return () => clearAdmins();
  }, [fetchAdmins, clearAdmins]);

  const tableHeader = ['Statusas', 'Vardas', 'Pavardė', 'El. paštas'];

  const renderTable = () =>
    admins.map(({ id, status, first_name, last_name, email }) => (
      <TableRow url={config.APP_PATHS.editAdmin(id)} key={id}>
        <TableCell>
          <span style={{ color: mapUserStatusColor(status) }}>
            {mapUserStatus(status)}
          </span>
        </TableCell>
        <TableCell>{first_name}</TableCell>
        <TableCell>{last_name}</TableCell>
        <TableCell>{email}</TableCell>
      </TableRow>
    ));

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.admins}</Title>
        <Button url={config.APP_PATHS.addAdmin} color="primary">
          Pridėti administratorių
        </Button>
      </Header>

      <WithSpinner loading={loading}>
        <Table header={tableHeader} isEmpty={!admins.length}>
          {renderTable()}
        </Table>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  admins: state.admins,
});

Admins.propTypes = {
  dispatch: PropTypes.func.isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    admins: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Admins);
