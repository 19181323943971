import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './buttonFileUplaod.module.scss';
import { Icon } from '../..';

const ButtonFileUpload = ({
  children,
  onFileChange,
  classes,
  file,
  allowDownload,
  withFileLabel,
  ...rest
}) => {
  const hiddenFileInput = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(file);

  const onBtnClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setSelectedFile(fileUploaded);
    onFileChange(fileUploaded);
  };

  const onFileDelete = event => {
    setSelectedFile(null);
    onFileChange(null);
  };

  const fileName = selectedFile?.name || selectedFile?.filename || null;
  const fileLink = selectedFile
    ? selectedFile.url || `/${selectedFile.name}`
    : '';

  return (
    <div className={styles.uploadBtnContainer}>
      <input
        className={styles.btnFileInput}
        ref={hiddenFileInput}
        accept="image/png, image/jpeg, application/pdf"
        type="file"
        onChange={handleChange}
      />

      <button
        className={cn(styles.button, styles['add'], classes.button)}
        type="button"
        onClick={onBtnClick}
        {...rest}
      >
        <Icon
          name="add"
          classes={{ icon: styles.icon }}
          styles={children ? {} : { margin: 0 }}
        />
        {children}
      </button>

      <div>
        {allowDownload ? (
          <span>
            {withFileLabel && 'Pasirinktas:  '}
            <a href={fileLink} download>
              {fileName}
            </a>
          </span>
        ) : (
          <span>Pasirinktas: {fileName}</span>
        )}
        {selectedFile && (
          <span className={styles.btnDeleteFile} onClick={onFileDelete}>
            x
          </span>
        )}
      </div>
    </div>
  );
};

ButtonFileUpload.defaultProps = {
  classes: {},
  allowDownload: false,
  withFileLabel: true,
};

ButtonFileUpload.propTypes = {
  children: PropTypes.node.isRequired,
  onFileChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
  }),
  file: PropTypes.object,
  allowDownload: PropTypes.bool,
  withFileLabel: PropTypes.bool,
};

export default ButtonFileUpload;
