import { constants } from '..';

const setErrorAction = error => ({
  type: constants.error.SET_ERROR,
  error,
});

const setAlertAction = (msg, category) => ({
  type: constants.error.SET_ALERT,
  msg,
  category,
});

const placeAlertAction = alert => ({
  type: constants.error.PLACE_ALERT,
  alert,
});

export const errorActions = {
  setErrorAction,
  setAlertAction,
  placeAlertAction,
};
