import { put, delay } from 'redux-saga/effects';
import { config } from '../../api/config';
import { actions } from '..';

export function* setAlertSaga({ msg, category }) {
  const alert = {
    msg,
    category,
  };

  yield put(actions.error.placeAlertAction(alert));

  yield delay(config.DEFAULTS.alertDuration);

  yield put(actions.error.placeAlertAction(null));
}
