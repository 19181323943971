import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  error: '',
  results: [],
  h2CardsResults: [],
};

export const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.search.SET_SEARCH_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.search.SET_SEARCH_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case constants.search.SET_SEARCH:
      return {
        ...state,
        results: action.results,
        error: '',
      };
    case constants.search.SET_H2_CARD_SEARCH:
      return {
        ...state,
        h2CardsResults: action.results,
        error: '',
      };
    default:
      return state;
  }
};
