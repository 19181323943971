import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './icon.module.scss';

import { ReactComponent as IconAlert } from './svg/alert.svg';
import { ReactComponent as IconChevronRight } from './svg/chevron-right.svg';
import { ReactComponent as IconChevronDown } from './svg/chevron-down.svg';
import { ReactComponent as IconClose } from './svg/close.svg';
import { ReactComponent as IconDefault } from './svg/default.svg';
import { ReactComponent as IconFilter } from './svg/filter.svg';
import { ReactComponent as IconSearch } from './svg/search.svg';
import { ReactComponent as IconUser } from './svg/user.svg';
import { ReactComponent as IconXls } from './svg/xls.svg';
import { ReactComponent as IconXml } from './svg/xml.svg';
import { ReactComponent as IconDownload } from './svg/download.svg';

import { ReactComponent as IconAdd } from './svg/add.svg';
import { ReactComponent as IconEdit } from './svg/edit.svg';
import { ReactComponent as IconDanger } from './svg/danger.svg';
import { ReactComponent as IconFlagEn } from './svg/flag-en.svg';
import { ReactComponent as IconFlagLt } from './svg/flag-lt.svg';
import { ReactComponent as IconFlagRu } from './svg/flag-ru.svg';
import { ReactComponent as IconInfo } from './svg/info.svg';
import { ReactComponent as IconNavClients } from './svg/nav_clients.svg';
import { ReactComponent as IconNavDiscounts } from './svg/nav_discounts.svg';
import { ReactComponent as IconNavInvoices } from './svg/nav_invoices.svg';
import { ReactComponent as IconNavLocations } from './svg/nav_locations.svg';
import { ReactComponent as IconNavMembershipOrders } from './svg/nav_membership-orders.svg';
import { ReactComponent as IconNavMemberships } from './svg/nav_memberships.svg';
import { ReactComponent as IconNavOrders } from './svg/nav_orders.svg';
import { ReactComponent as IconPdf } from './svg/pdf.svg';
import { ReactComponent as IconPrint } from './svg/print.svg';
import { ReactComponent as IconQr } from './svg/qr-code.svg';
import { ReactComponent as IconRemove } from './svg/remove.svg';
import { ReactComponent as IconRemoveRed } from './svg/remove-red.svg';

import { ReactComponent as IconSuccess } from './svg/success.svg';
import { ReactComponent as IconRefresh } from './svg/refresh.svg';

import { ReactComponent as IconSelfService } from './locationsSvg/self-service.svg';
import { ReactComponent as IconAutoTextile } from './locationsSvg/auto-textile.svg';
import { ReactComponent as IconVacuumCleaner } from './locationsSvg/vacuum-cleaner.svg';
import { ReactComponent as IconCarpetCleaner } from './locationsSvg/carpet-cleaner.svg';
import { ReactComponent as IconVipes } from './locationsSvg/vipes.svg';
import { ReactComponent as IconWindSheeld } from './locationsSvg/windSheeld.svg';
import { ReactComponent as IconContactless } from './locationsSvg/contactles.svg';
import { ReactComponent as IconTyreBlackener } from './locationsSvg/tyre-blackener.svg';

const Icon = ({ name, classes }) => {
  switch (name) {
    case 'alert':
      return <IconAlert className={cn(styles.icon, classes.icon)} />;
    case 'chevron-right':
      return <IconChevronRight className={cn(styles.icon, classes.icon)} />;
    case 'chevron-down':
      return <IconChevronDown className={cn(styles.icon, classes.icon)} />;
    case 'close':
      return <IconClose className={cn(styles.icon, classes.icon)} />;
    case 'filter':
      return <IconFilter className={cn(styles.icon, classes.icon)} />;
    case 'search':
      return <IconSearch className={cn(styles.icon, classes.icon)} />;
    case 'user':
      return <IconUser className={cn(styles.icon, classes.icon)} />;
    case 'xls':
      return <IconXls className={cn(styles.icon, classes.icon)} />;
    case 'xml':
      return <IconXml className={cn(styles.icon, classes.icon)} />;
    case 'refresh':
      return <IconRefresh className={cn(styles.icon, classes.icon)} />;
    case 'add':
      return <IconAdd className={cn(styles.icon, classes.icon)} />;
    case 'danger':
      return <IconDanger className={cn(styles.icon, classes.icon)} />;
    case 'flag-en':
      return <IconFlagEn className={cn(styles.icon, classes.icon)} />;
    case 'flag-lt':
      return <IconFlagLt className={cn(styles.icon, classes.icon)} />;
    case 'flag-ru':
      return <IconFlagRu className={cn(styles.icon, classes.icon)} />;
    case 'info':
      return <IconInfo className={cn(styles.icon, classes.icon)} />;
    case 'nav-clients':
      return <IconNavClients className={cn(styles.icon, classes.icon)} />;
    case 'nav-discounts':
      return <IconNavDiscounts className={cn(styles.icon, classes.icon)} />;
    case 'nav-invoices':
      return <IconNavInvoices className={cn(styles.icon, classes.icon)} />;
    case 'nav-locations':
      return <IconNavLocations className={cn(styles.icon, classes.icon)} />;
    case 'nav-membership-orders':
      return (
        <IconNavMembershipOrders className={cn(styles.icon, classes.icon)} />
      );
    case 'nav-memberships':
      return <IconNavMemberships className={cn(styles.icon, classes.icon)} />;
    case 'nav-orders':
      return <IconNavOrders className={cn(styles.icon, classes.icon)} />;
    case 'pdf':
      return <IconPdf className={cn(styles.icon, classes.icon)} />;
    case 'print':
      return <IconPrint className={cn(styles.icon, classes.icon)} />;
    case 'qr-code':
      return <IconQr className={cn(styles.icon, classes.icon)} />;
    case 'remove':
      return <IconRemove className={cn(styles.icon, classes.icon)} />;
    case 'remove-red':
      return <IconRemoveRed className={cn(styles.icon, classes.icon)} />;
    case 'success':
      return <IconSuccess className={cn(styles.icon, classes.icon)} />;
    case 'self_service_washing':
      return <IconSelfService className={cn(styles.icon, classes.icon)} />;
    case 'automatic_textile_washing':
      return <IconAutoTextile className={cn(styles.icon, classes.icon)} />;
    case 'vacuum_cleaner':
    case 'smart_vacuum_cleaner':
      return <IconVacuumCleaner className={cn(styles.icon, classes.icon)} />;
    case 'car_mat_cleaner':
      return <IconCarpetCleaner className={cn(styles.icon, classes.icon)} />;
    case 'paper_towel_dispenser':
    case 'towel_dispenser':
      return <IconVipes className={cn(styles.icon, classes.icon)} />;
    case 'windshield_washer_liquid_dispenser':
      return <IconWindSheeld className={cn(styles.icon, classes.icon)} />;
    case 'automatic_contactless_washing':
      return <IconContactless className={cn(styles.icon, classes.icon)} />;
    case 'tyre_blackener':
      return <IconTyreBlackener className={cn(styles.icon, classes.icon)} />;
    case 'download':
      return <IconDownload className={cn(styles.icon, classes.icon)} />;
    case 'edit':
      return <IconEdit className={cn(styles.icon, classes.icon)} />;
    default:
      return <IconDefault className={cn(styles.icon, classes.icon)} />;
  }
};

Icon.defaultProps = {
  classes: {},
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
