import React from 'react';
import PropTypes from 'prop-types';

import styles from './admins.module.scss';
import { config } from '../../api/config';
import { Input, SubmitActions, Select } from '../../components';
import { businessRoles } from './admins.data';

const Form = ({ children, type, values, onChange, onSubmit, loading }) => {
  return (
    <form className={styles.form} onSubmit={onSubmit} method="POST">
      <div className={styles.formdata}>
        <div className={styles.inputsContainer}>
          <div className={styles.inputs}>
            <Input
              name="first_name"
              label="Vardas"
              type="text"
              onChange={onChange}
              value={values.first_name}
            />
            <Input
              name="last_name"
              label="Pavardė"
              type="text"
              onChange={onChange}
              value={values.last_name}
            />
            <Input
              name="email"
              label="El. paštas"
              type="email"
              onChange={onChange}
              value={values.email}
              disabled={type === 'edit' ? true : false}
            />
            <Input
              name="phone"
              label="Tel. Numeris"
              type="text"
              onChange={onChange}
              value={values.phone}
            />
            <div className={styles.businessRoleSelect}>
              <Select
                name="business_role"
                label="Rolė"
                options={businessRoles}
                selectedValue={values.business_role}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className={styles.permissions}>
          <h4 className={styles.permissionsLabel}>Leidimai</h4>
          {children}
        </div>
      </div>

      <SubmitActions back={config.APP_PATHS.admins} loading={loading} />
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['add', 'edit']).isRequired,
  values: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    email: PropTypes.string,
    business_role: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Form;
