import { errorMapper } from './error.mapper';

export const errorMessage = err => {
  let error;
  const unknownError =
    process.env.NODE_ENV === 'development' ? JSON.stringify(err) : 'Klaida';
  if (err.error) {
    if (err.error.code && err.error.code === 105) {
      error = {
        msg: errorMapper[105] || unknownError,
        code: 105,
      };
    } else if (err.error.code && err.error.code === 107) {
      error = {
        msg: errorMapper[err.error.sub_code],
        code: 107,
      };
    } else if (err.error.sub_code && err.error.sub_code == 3604) {
      error = {
        msg: errorMapper[err.error.sub_code],
        code: err.error.sub_code,
      };
    } else if (err.error.sub_code) {
      error = {
        msg: errorMapper[err.error.sub_code] || unknownError,
        code: err.error.sub_code,
      };
    } else {
      error = {
        msg: errorMapper[err.error.code] || unknownError,
        code: err.error.code,
        subMessage: err.error?.error,
      };
    }
  } else if (err.message) {
    if (err.message === 'Authentication Failed') {
      error = {
        msg: errorMapper[105] || unknownError,
        code: 105,
      };
    } else {
      error = {
        msg: errorMapper[err.statusCode] || unknownError,
        code: err.statusCode,
      };
    }
  } else {
    error = {
      msg: unknownError,
    };
  }

  return error;
};
