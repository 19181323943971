import React from 'react';
import styles from './select.module.scss';
import LabelElement from '../_elements/labelElement/labelElement';

const Select = ({
  options,
  name,
  label,
  onChange,
  selectedValue,
  disabled = false,
}) => {
  const renderOptions = () =>
    options.map(({ name, value }) => (
      <option key={value} value={value}>
        {name}
      </option>
    ));

  return (
    <>
      {label && (
        <div className={styles.labelContainer}>
          <LabelElement label={label} htmlFor={name} />
        </div>
      )}
      <select
        name={name}
        className={styles.select}
        onChange={onChange}
        value={selectedValue}
        disabled={disabled}
      >
        {renderOptions()}
      </select>
    </>
  );
};

export default Select;
