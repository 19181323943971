export const availableWeekdays = [
  {
    name: 'Pirmadieniais',
    value: 1,
  },
  {
    name: 'Antradieniais',
    value: 2,
  },
  {
    name: 'Trečiadieniais',
    value: 3,
  },
  {
    name: 'Ketvirtadieniais',
    value: 4,
  },
  {
    name: 'Penktadieniais',
    value: 5,
  },
  {
    name: 'Šeštadieniais',
    value: 6,
  },
  {
    name: 'Sekmadieniais',
    value: 0,
  },
];
