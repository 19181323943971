import { constants } from '../../constants';

const setInvoiceLoadingAction = flag => ({
  type: constants.businessInvoices.SET_INVOICES_LOADING,
  flag,
});

const getInvoicesAction = body => ({
  type: constants.businessInvoices.GET_INVOICES,
  body,
});

const setInvoicesAction = (invoices, count, header, footer) => ({
  type: constants.businessInvoices.SET_INVOICES,
  invoices,
  count,
  header,
  footer,
});

const getInvoiceAction = id => ({
  type: constants.businessInvoices.GET_INVOICE,
  id,
});

const setInvoiceAction = invoice => ({
  type: constants.businessInvoices.SET_INVOICE,
  invoice,
});

const clearInvoicesAction = () => ({
  type: constants.businessInvoices.CLEAR_INVOICES,
});

const downloadInvoicePdf = (id, invoiceNumber) => ({
  type: constants.businessInvoices.DOWNLOAD_PDF,
  id,
  invoiceNumber,
});

const pdfDownloadingAction = flag => ({
  type: constants.businessInvoices.SET_PDF_DOWNLOADING,
  flag,
});

export const businessInvoicesActions = {
  setInvoiceLoadingAction,
  getInvoicesAction,
  setInvoicesAction,
  getInvoiceAction,
  setInvoiceAction,
  clearInvoicesAction,
  downloadInvoicePdf,
  pdfDownloadingAction,
};
