import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { isEmpty } from '../../utils';
import { config } from '../../api/config';
import OrderInfo from './membershipOrders.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Paper,
  PaperNav,
  PaperContainer,
  ButtonSimple,
  ButtonLink,
} from '../../components';

const MembershipOrderDetails = ({
  dispatch,
  match: { params },
  membershipOrders: { loading, selectedMembershipOrder },
}) => {
  const fetchMembershipOrder = useCallback(
    id => {
      dispatch(actions.membershipOrders.getMembershipOrderAction(id));
    },
    [dispatch]
  );

  const clearMembershipOrders = useCallback(() => {
    dispatch(actions.membershipOrders.clearMembershipOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMembershipOrder(params.id);
    return () => clearMembershipOrders();
  }, [fetchMembershipOrder, clearMembershipOrders, params.id]);

  const openModal = modalName => {
    const payload = {
      modalName,
      title: 'Deaktyvuoti',
      desc: 'Ar tikrai norite išjungti šį pasą?',
      onConfirm: () =>
        dispatch(
          actions.membershipOrders.deactivateMembershipOrderAction(params.id)
        ),
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.membershipOrders}
          subtitle="Užsakymo informacija"
          baseUrl={config.APP_PATHS.membershipOrders}
        />
        {!loading && selectedMembershipOrder.status !== 554 && (
          <ButtonSimple type="remove" onClick={() => openModal('confirmModal')}>
            Deaktyvuoti
          </ButtonSimple>
        )}
      </Header>
      <WithSpinner loading={loading || isEmpty(selectedMembershipOrder)}>
        {!isEmpty(selectedMembershipOrder) && (
          <PaperContainer>
            <Paper>
              <OrderInfo order={selectedMembershipOrder} />
            </Paper>
            <PaperNav>
              <ButtonLink
                icon="nav-clients"
                url={config.APP_PATHS.editClient(
                  selectedMembershipOrder.client_id
                )}
              >
                {selectedMembershipOrder.client_phone}
              </ButtonLink>
              <ButtonLink
                icon="pdf"
                url={
                  selectedMembershipOrder.invoice_type === 321
                    ? config.APP_PATHS.receiptDetails(
                        selectedMembershipOrder.invoice_id
                      )
                    : config.APP_PATHS.invoiceDetails(
                        selectedMembershipOrder.invoice_id
                      )
                }
              >
                #{selectedMembershipOrder.invoice_id}
              </ButtonLink>
            </PaperNav>
          </PaperContainer>
        )}
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  membershipOrders: state.membershipOrders,
});

MembershipOrderDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  membershipOrders: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    membershipOrders: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(MembershipOrderDetails);
