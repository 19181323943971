export const discountsConstants = {
  SET_DISCOUNTS_LOADING: 'discounts/SET_DISCOUNTS_LOADING',
  SET_BANNER_UPLOADING: 'discounts/SET_BANNER_UPLOADING',
  SET_BANNER: 'discounts/SET_BANNER',
  GET_DISCOUNTS: 'discounts/GET_DISCOUNTS',
  SET_DISCOUNTS: 'discounts/SET_DISCOUNTS',
  CLEAR_DISCOUNTS: 'discounts/CLEAR_DISCOUNTS',
  ADD_DISCOUNT: 'discounts/ADD_DISCOUNT',
  GET_DISCOUNT: 'discounts/GET_DISCOUNT',
  SET_DISCOUNT: 'discounts/SET_DISCOUNT',
  EDIT_DISCOUNT: 'discounts/EDIT_DISCOUNT',
  EXPORT_DISCOUNTS: 'discounts/EXPORT_DISCOUNTS',
  DEACTIVATE_DISCOUNT: 'discounts/DEACTIVATE_DISCOUNT',
  UPLOAD_IMAGE: 'discounts/UPLOAD_IMAGE',
};
