import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import servicesBoxStyles from './servicesBoxStyles.module.scss';
import { actions } from './../../../state';
import Boxes from './../boxes/boxes';
import { InputElement, MonthSelection } from './../../../components';
import { formattedDevices } from '../locations.utils';

const WindshieldWasherLiquidDispenser = ({ dispatch, data, requestError }) => {
  const [values, setValues] = useState({
    summerWasherPrice:
      data !== null
        ? data.options.find(obj => obj.type === 601).price / 100
        : '',
    summerDuration:
      data !== null ? data.options.find(obj => obj.type === 601).month : 1,
    winterWasherPrice:
      data !== null
        ? data.options.find(obj => obj.type === 602).price / 100
        : '',
    winterDuration:
      data !== null ? data.options.find(obj => obj.type === 602).month : 1,
  });

  const [devices, setBoxesValues] = useState(data === null ? [] : data.devices);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: +value });
  };

  useEffect(() => {
    const options = [
      {
        type: 601,
        price: values.summerWasherPrice * 100,
        month: values.summerDuration,
      },
      {
        type: 602,
        price: values.winterWasherPrice * 100,
        month: values.winterDuration,
      },
    ];
    const data = {
      options,
      devices: formattedDevices(devices),
    };
    dispatch(
      actions.addEditServices.pushWindshieldWasherLiquidDispenserAction(data)
    );
  });

  const handleStateFromBoxes = x => {
    setBoxesValues(x);
  };

  return (
    <div className={servicesBoxStyles.mainContainer}>
      <table className={servicesBoxStyles.container}>
        <thead>
          <tr>
            <th>Pavadinimas</th>
            <th>Kaina su PVM (EUR)</th>
            <th>Laikotarpis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vasarinis skystis</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="summerWasherPrice"
                type="number"
                onChange={handleInputChange}
                value={values.summerWasherPrice}
                invalid={requestError && !values.summerWasherPrice}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <MonthSelection
                name="summerDuration"
                onChange={handleInputChange}
                value={values.summerDuration}
                invalid={requestError && !values.summerDuration}
              />
            </td>
          </tr>
          <tr>
            <td>Žieminis skystis</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="winterWasherPrice"
                type="number"
                onChange={handleInputChange}
                value={values.winterWasherPrice}
                invalid={requestError && !values.winterWasherPrice}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <MonthSelection
                name="winterDuration"
                onChange={handleInputChange}
                value={values.winterDuration}
                invalid={requestError && !values.winterDuration}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Boxes
        handleStateFromBoxes={handleStateFromBoxes}
        devices={data === null ? [] : data.devices}
        location="windshield_washer_liquid_dispenser"
        requestError={requestError}
      />
    </div>
  );
};

WindshieldWasherLiquidDispenser.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    devices: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }),
};

export default connect()(WindshieldWasherLiquidDispenser);
