import React, { useContext, useCallback, useEffect } from 'react';
import { BusinessClientsContext } from './businessClients.context';
import { config } from '../../api/config';
import { actions } from '../../state';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './businessClients.module.scss';
import { moneyToCents } from '../../utils/index';

import {
  Header,
  Title,
  ButtonDownload,
  Button,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  RangeFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
} from '../../components';

import {
  formatFilename,
  formatPercent,
  formatDate,
  mapUserStatus,
  mapUserStatusColor,
  isFilterActive,
  generateHeader,
  formatMoney,
} from '../../utils';

const BusinessClients = ({
  dispatch,
  businessClients: { businessClients, total, header, footer, loading },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    statusFilter,
    setStatusFilter,
    companyFilter,
    setCompanyFilter,
    endDateLtFilter,
    setEndDateLtFilter,
    endDateGtFilter,
    setEndDateGtFilter,
    employeesFilter,
    setEmployeesFilter,
    discountFilter,
    setDiscountFilter,
    userFilter,
    setUserFilter,
    creditLimitLtFilter,
    setCreditLimitLtFilter,
    creditLimitGtFilter,
    setCreditLimitGtFilter,
    ongoingDebtLtFilter,
    setOngoingDebtLtFilter,
    ongoingDebtGtFilter,
    setOngoingDebtGtFilter,
    unpaidInvoicesLtFilter,
    setUnpaidInvoicesLtFilter,
    unpaidInvoicesGtFilter,
    setUnpaidInvoicesGtFilter,
    totalDebtLtFilter,
    setTotalDebtLtFilter,
    totalDebtGtFilter,
    setTotalDebtGtFilter,
    limitRemainderLtFilter,
    setLimitRemainderLtFilter,
    limitRemainderGtFilter,
    setLimitRemainderGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(BusinessClientsContext);

  const fetchBusinessClients = useCallback(
    data => {
      dispatch(actions.businessClients.getBusinessClientsAction(data));
    },
    [dispatch]
  );

  const clearBusinessClients = useCallback(() => {
    dispatch(actions.businessClients.clearBusinessClientsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchBusinessClients(filterBody);
    return () => clearBusinessClients();
  }, [fetchBusinessClients, clearBusinessClients, filterBody]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (companyFilter.length) {
      generatedFilter.push({ field: 'name', value: companyFilter });
    }
    if (employeesFilter.length) {
      generatedFilter.push({ field: 'employee_count', value: employeesFilter });
    }
    if (discountFilter.length) {
      generatedFilter.push({
        field: 'discount',
        value: discountFilter,
      });
    }
    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (userFilter.length) {
      generatedFilter.push({ field: 'email', value: userFilter });
    }
    if (endDateLtFilter) {
      generatedFilter.push({
        field: 'contract_end_date',
        expression: 'lte',
        value: endDateLtFilter,
        type: 'timestamp',
      });
    }
    if (endDateGtFilter) {
      generatedFilter.push({
        field: 'contract_end_date',
        expression: 'gte',
        value: endDateGtFilter,
        type: 'timestamp',
      });
    }

    if (creditLimitLtFilter) {
      generatedFilter.push({
        field: 'credit_limit',
        expression: 'lte',
        value: moneyToCents(creditLimitLtFilter),
        type: 'number',
      });
    }

    if (creditLimitGtFilter) {
      generatedFilter.push({
        field: 'credit_limit',
        expression: 'gte',
        value: moneyToCents(creditLimitGtFilter),
        type: 'number',
      });
    }

    if (unpaidInvoicesLtFilter) {
      generatedFilter.push({
        field: 'total_unpaid_invoice_cost',
        expression: 'lte',
        value: moneyToCents(unpaidInvoicesLtFilter),
        type: 'number',
      });
    }

    if (unpaidInvoicesGtFilter) {
      generatedFilter.push({
        field: 'total_unpaid_invoice_cost',
        expression: 'gte',
        value: moneyToCents(unpaidInvoicesGtFilter),
        type: 'number',
      });
    }

    if (totalDebtLtFilter) {
      generatedFilter.push({
        field: 'total_debt',
        expression: 'lte',
        value: moneyToCents(totalDebtLtFilter),
        type: 'number',
      });
    }

    if (totalDebtGtFilter) {
      generatedFilter.push({
        field: 'total_unpaid_invoice_cost',
        expression: 'gte',
        value: moneyToCents(totalDebtGtFilter),
        type: 'number',
      });
    }

    if (limitRemainderLtFilter) {
      generatedFilter.push({
        field: 'remaining_credit',
        expression: 'lte',
        value: moneyToCents(limitRemainderLtFilter),
        type: 'number',
      });
    }

    if (limitRemainderGtFilter) {
      generatedFilter.push({
        field: 'remaining_credit',
        expression: 'gte',
        value: moneyToCents(limitRemainderGtFilter),
        type: 'number',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapUserStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Įmonė"
      name="name"
      valueType="string"
      array={generateHeader(header.name)}
      filterState={companyFilter}
      setFilterState={setCompanyFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyFilter)}
    />,
    <DateFilter
      title="Pabaigos data"
      name="contract_end_date"
      lt={endDateLtFilter}
      setLt={setEndDateLtFilter}
      gt={endDateGtFilter}
      setGt={setEndDateGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', endDateLtFilter, endDateGtFilter)}
    />,
    <SelectAsyncFilter
      title="Darbuotojai"
      name="employee_count"
      valueType="number"
      params={{ model: 'business_employees', field: 'employee_count' }}
      filterState={employeesFilter}
      setFilterState={setEmployeesFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', employeesFilter)}
    />,
    <SelectAsyncFilter
      title="Nuolaida"
      name="discount"
      valueType="number"
      params={{ model: 'business_employees', field: 'discount' }}
      filterState={discountFilter}
      setFilterState={setDiscountFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', discountFilter)}
    />,
    <SelectFilter
      title="Vartotojas"
      name="email"
      valueType="string"
      array={generateHeader(header.email)}
      filterState={userFilter}
      setFilterState={setUserFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', userFilter)}
    />,
    <RangeFilter
      title="Limitas"
      name="credit_limit"
      lt={creditLimitLtFilter}
      setLt={setCreditLimitLtFilter}
      gt={creditLimitGtFilter}
      setGt={setCreditLimitGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        creditLimitLtFilter,
        creditLimitGtFilter
      )}
    />,
    <RangeFilter
      title="Einamojo mėn. skola su PVM"
      name="ongoingDebt"
      lt={ongoingDebtLtFilter}
      setLt={setOngoingDebtLtFilter}
      gt={ongoingDebtGtFilter}
      setGt={setOngoingDebtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        ongoingDebtLtFilter,
        ongoingDebtGtFilter
      )}
    />,
    <RangeFilter
      title="Neapmokėtos sąs. su PVM"
      name="total_unpaid_invoice_cost"
      lt={unpaidInvoicesLtFilter}
      setLt={setUnpaidInvoicesLtFilter}
      gt={unpaidInvoicesGtFilter}
      setGt={setUnpaidInvoicesGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        unpaidInvoicesLtFilter,
        unpaidInvoicesGtFilter
      )}
    />,
    <RangeFilter
      title="Bendra skola su PVM"
      name="total_debt"
      lt={totalDebtLtFilter}
      setLt={setTotalDebtLtFilter}
      gt={totalDebtGtFilter}
      setGt={setTotalDebtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', totalDebtLtFilter, totalDebtGtFilter)}
    />,
    <RangeFilter
      title="Limito likutis su PVM"
      name="remaining_credit"
      lt={limitRemainderLtFilter}
      setLt={setLimitRemainderLtFilter}
      gt={limitRemainderGtFilter}
      setGt={setLimitRemainderGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        limitRemainderLtFilter,
        limitRemainderGtFilter
      )}
    />,
  ];

  const tableFooter = [
    '',
    '',
    '',
    '',
    '',
    '',
    formatMoney(footer.credit_limit),
    formatMoney(footer.used_credit),
    formatMoney(footer.total_unpaid_invoice_cost),
    formatMoney(footer.total_debt),
    formatMoney(footer.remaining_credit),
  ];

  const clearFilters = () => {
    setStatusFilter([]);
    setCompanyFilter([]);

    setEndDateLtFilter();
    setEndDateGtFilter();

    setEmployeesFilter([]);
    setDiscountFilter([]);
    setUserFilter([]);

    // Range filters
    setCreditLimitLtFilter();
    setCreditLimitGtFilter();
    setOngoingDebtLtFilter();
    setOngoingDebtGtFilter();
    setUnpaidInvoicesLtFilter();
    setUnpaidInvoicesGtFilter();
    setTotalDebtLtFilter();
    setTotalDebtGtFilter();
    setLimitRemainderLtFilter();
    setLimitRemainderGtFilter();

    handleSubmit();
  };

  const renderTable = () =>
    businessClients.map(
      ({
        id,
        status,
        name,
        contract_end_date,
        employee_count,
        discount,
        email,
        credit_limit,
        used_credit,
        total_unpaid_invoice_cost,
        total_debt,
        remaining_credit,
      }) => (
        <TableRow url={config.APP_PATHS.editBusinessClient(id)} key={id}>
          <TableCell>
            <span style={{ color: mapUserStatusColor(status) }}>
              {mapUserStatus(status)}
            </span>
          </TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>
            {contract_end_date && formatDate(contract_end_date)}
          </TableCell>
          <TableCell>{employee_count}</TableCell>
          <TableCell>{discount ? discount : 0} %</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>{formatMoney(credit_limit)}</TableCell>
          <TableCell>{formatMoney(used_credit)}</TableCell>
          <TableCell>{formatMoney(total_unpaid_invoice_cost)}</TableCell>
          <TableCell>{formatMoney(total_debt)}</TableCell>
          <TableCell>{formatMoney(remaining_credit)}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.businessClients);
    dispatch(
      actions.businessClients.exportBusinessClientsAction(filename, filterBody)
    );
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.businessClients}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          {
            <Button color="primary" url={config.APP_PATHS.addBusinessClient}>
              Pridėti
            </Button>
          }
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        footer={tableFooter}
        isEmpty={!businessClients.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  businessClients: state.businessClients,
  xlsLoading: state.download.xlsLoading,
});

BusinessClients.propTypes = {
  dispatch: PropTypes.func.isRequired,
  businessClients: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    businessClients: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      name: PropTypes.arrayOf(PropTypes.string),
      email: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(BusinessClients);
