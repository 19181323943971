import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { config } from '../../api/config';

import {
  formatMoney,
  generateHeader,
  isEmpty,
  mapServiceTypes,
  mapBoxStatus,
  checkBoxColor,
  isFilterActive,
} from '../../utils';
import {
  WithSpinner,
  SelectFilter,
  RangeFilter,
  Table,
  TableRow,
  TableCell,
} from '../../components';
import { BoxesContext } from './boxes.context';
import { socketsClient } from '../../api/sockets';

const BoxesTable = ({
  dispatch,
  loading,
  boxes: { boxes, header, footer },
  timeRangeFilter,
  getFilteredBody,
  clearAllFilters,
  setClearToFalse,
  socket,
}) => {
  const {
    typeFilter,
    setTypeFilter,
    locationFilter,
    setLocationFilter,
    statusFilter,
    setStatusFilter,
    titleFilter,
    setTitleFilter,
    costLtFilter,
    setCostLtFilter,
    costGtFilter,
    setCostGtFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
  } = useContext(BoxesContext);

  useEffect(() => {
    if (socketsClient) {
      const channel = socketsClient.subscribe('device_update');
      channel.watch(message => {
        dispatch(actions.boxes.updateBoxesViaSocketAction(message));
      });
    }

    return () => {
      socketsClient.unsubscribe('device_update');
    };
  }, [dispatch]);

  const fetchBoxes = useCallback(
    data => {
      dispatch(actions.boxes.getBoxesTableDataAction(data));
    },
    [dispatch]
  );

  const clearBoxes = useCallback(() => {
    dispatch(actions.boxes.clearBoxesAction());
  }, [dispatch]);

  const clearSelectedLocation = useCallback(() => {
    dispatch(actions.locations.setSelectedLocationAction({}));
  }, [dispatch]);

  useEffect(() => {
    let filterToSubmit;

    if (filterBody) {
      if (clearAllFilters) {
        setLocationFilter([]);
        setStatusFilter([]);
        setCostLtFilter('');
        setCostGtFilter('');
        setTitleFilter([]);
        setFilterBody();
        setTypeFilter([]);
        setSortOrder('asc');
        setClearToFalse(false);
      } else if (timeRangeFilter) {
        const filterSum = { ...filterBody, ...timeRangeFilter };
        filterToSubmit = filterSum;
      } else {
        filterToSubmit = filterBody;
      }
    } else if (timeRangeFilter) {
      filterToSubmit = timeRangeFilter;
    }
    getFilteredBody(filterToSubmit);
    fetchBoxes(filterToSubmit);
    return () => clearBoxes(filterToSubmit);
  }, [
    fetchBoxes,
    clearBoxes,
    filterBody,
    getFilteredBody,
    timeRangeFilter,
    clearAllFilters,
    setClearToFalse,
  ]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (locationFilter.length) {
      generatedFilter.push({ field: 'full_address', value: locationFilter });
    }
    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }

    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }

    if (titleFilter.length) {
      generatedFilter.push({ field: 'title', value: titleFilter });
    }

    if (costLtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'lte',
        value: costLtFilter * 100,
      });
    }

    if (costGtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'gte',
        value: costGtFilter * 100,
      });
    }

    setFilterBody({
      filter: {
        and: generatedFilter,
      },
      order: [
        {
          by: name,
          order: sortOrder,
        },
      ],
    });
  };

  const tableHeader = [
    <SelectFilter
      title="Lokacija"
      name="full_address"
      valueType="string"
      array={generateHeader(header.full_address)}
      filterState={locationFilter}
      setFilterState={setLocationFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', locationFilter)}
    />,
    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapServiceTypes)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    <SelectFilter
      title="Boksas"
      name="title"
      valueType="string"
      array={generateHeader(header.title).sort((a, b) =>
        a.name.localeCompare(b.name)
      )}
      filterState={titleFilter}
      setFilterState={setTitleFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', titleFilter)}
      sortArray={true}
    />,
    <SelectFilter
      title="Būsena"
      name="type"
      valueType="number"
      array={generateHeader(header.status, mapBoxStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <RangeFilter
      title="Apyvarta su PVM"
      name="income"
      lt={costLtFilter}
      setLt={setCostLtFilter}
      gt={costGtFilter}
      setGt={setCostGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('select', costGtFilter, costGtFilter)}
    />,
  ];
  const tableFooter = ['', '', '', '', formatMoney(footer.income)];

  const renderTable = () =>
    boxes.map(
      ({ id, full_address, status, type, income, title, location_id }) => (
        <TableRow
          onClick={clearSelectedLocation}
          url={config.APP_PATHS.editLocation(location_id)}
          key={id}
        >
          <TableCell>{full_address}</TableCell>
          <TableCell>{mapServiceTypes(type)}</TableCell>
          <TableCell>{title}</TableCell>
          <TableCell>
            <span style={{ color: checkBoxColor(status) }}>
              {mapBoxStatus(status)}
            </span>
          </TableCell>
          <TableCell align="right">{formatMoney(income)}</TableCell>
        </TableRow>
      )
    );

  return (
    <WithSpinner loading={loading}>
      {!isEmpty(header) && (
        <Table
          header={tableHeader}
          footer={tableFooter}
          filter={true}
          isEmpty={!boxes.length}
          width="xs"
          rightAlignedFooterCells={[4]}
        >
          {renderTable()}
        </Table>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = state => ({
  boxes: state.boxes,
  loading: state.locations.loading,
  socket: state.download.socket,
});

BoxesTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  boxes: PropTypes.shape({
    boxes: PropTypes.array.isRequired,
    header: PropTypes.object.isRequired,
    footer: PropTypes.object.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  timeRangeFilter: PropTypes.object,
  getFilteredBody: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.bool.isRequired,
  setClearToFalse: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BoxesTable);
