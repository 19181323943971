import { authConstants } from './auth/auth.constants';
import { errorConstants } from './error/error.constants';
import { modalsConstants } from './modals/modals.constants';
import { searchConstants } from './search/search.constants';
import { adminsConstants } from './admins/admins.constants';
import { clientsConstants } from './clients/clients.constants';
import { locationsConstants } from './locations/locations.constants';
import { discountsConstants } from './discounts/discounts.constants';
import { membershipsConstants } from './memberships/memberships.constants';
import { membershipOrdersConstants } from './membershipOrders/membershipOrders.constants';
import { ordersConstants } from './orders/orders.constants';
import { boxesConstants } from './boxes/boxes.constants';
import { servicesConstants } from './services/services.constants';
import { addEditServices } from './addEditServices/addEditServices.constants';
import { invoicesConstants } from './invoices/invoices.constants';
import { downloadConstants } from './download/download.constants';
import { receiptsConstants } from './receipts/receipts.constants';
import { employeesConstants } from './businessAdmin/employees/employees.constants';
import { orderHistoryConstants } from './businessAdmin/orderHistory/orderHistory.constants';
import { businessInvoicesConstants } from './businessAdmin/businessInvoices/businessInvoices.constants';
import { businessClientsConstants } from './businessClients/businessClients.constants';
import { textileConstants } from './textile/textile.constants';
import { couponsConstants } from './coupons/coupons.constants';
import { dropdownsConstants } from './dropdowns/dropdowns.constants';

export const constants = {
  auth: authConstants,
  error: errorConstants,
  modals: modalsConstants,
  search: searchConstants,
  admins: adminsConstants,
  businessClients: businessClientsConstants,
  clients: clientsConstants,
  locations: locationsConstants,
  discounts: discountsConstants,
  memberships: membershipsConstants,
  membershipOrders: membershipOrdersConstants,
  orders: ordersConstants,
  services: servicesConstants,
  boxes: boxesConstants,
  addEditServices: addEditServices,
  receipts: receiptsConstants,
  invoices: invoicesConstants,
  download: downloadConstants,
  employees: employeesConstants,
  orderHistory: orderHistoryConstants,
  businessInvoices: businessInvoicesConstants,
  textile: textileConstants,
  coupons: couponsConstants,
  dropdowns: dropdownsConstants,
};
