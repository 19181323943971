import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './memberships.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import {
  Header,
  Breadcrumb,
  Paper,
  PaperHeader,
  TitleSecondary,
  Input,
  SubmitActions,
  Select,
  Title,
} from '../../components';
import History from './memberships.history.component';
import {
  MembershipTypes,
  MembershipTypesTitles,
} from '../../utils/global/enums';
import LabelElement from '../../components/form/_elements/labelElement/labelElement';

const membershipTypes = [
  {
    name: MembershipTypesTitles.MAIN,
    value: MembershipTypes.MAIN,
  },
  {
    name: MembershipTypesTitles.SHADOW,
    value: MembershipTypes.SHADOW,
  },
];

const titleLimit = 30;
const descriptionLimit = 255;
const colorCodeLimit = 30;

const CreateMembership = ({ dispatch, history, memberships: { loading } }) => {
  const [values, setValues] = useState({
    title_lt: '',
    title_en: '',
    title_ru: '',
    description_lt: '',
    description_en: '',
    description_ru: '',
    type: MembershipTypes.MAIN,
    color: '',
  });

  const [initialHistory, setInitialHistory] = useState(null);

  const fetchServices = useCallback(
    data => {
      dispatch(actions.services.getServicesAction(data));
    },
    [dispatch]
  );

  const clearServices = useCallback(() => {
    dispatch(actions.services.clearServicesAction());
  }, [dispatch]);

  useEffect(() => {
    fetchServices();
    return () => {
      clearServices();
    };
  }, [fetchServices, clearServices]);

  const handleSubmit = e => {
    e.preventDefault();
    const body = {
      ...values,
      initial_history: { ...initialHistory },
    };

    dispatch(actions.memberships.createMembershipAction(body, history));
  };

  const handleInputChange = ({ target: { name, value } }, limits) => {
    const canBeSaved =
      !limits || Number.isNaN(+limits) || value.toString()?.length <= limits;
    if (canBeSaved) {
      const modifiedValue = name === 'type' ? Number(value) : value;
      setValues({ ...values, [name]: modifiedValue });
    }
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.memberships}
          subtitle="Sukurti nuolaidų planą"
          baseUrl={config.APP_PATHS.memberships}
        />
      </Header>

      <Paper>
        <PaperHeader>
          <Title total="">Nuolaidų plano informacija</Title>
        </PaperHeader>
        <form onSubmit={handleSubmit} method="POST">
          <div className={styles.contentSection}>
            <TitleSecondary>Tipas</TitleSecondary>
            <div className={styles.membershipTypeSelect}>
              <div className={styles.labelContainer}>
                <LabelElement label="Pasirinkite tipą" htmlFor="type" />
              </div>
              <Select
                name="type"
                options={membershipTypes}
                selectedValue={values.type}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.contentSection}>
            <TitleSecondary>Bendra informacija</TitleSecondary>

            <div className={styles.formdataVertical}>
              <Input
                name="title_lt"
                label="Pavadinimas"
                placeholder="Pavadinimas (LT)"
                icon="flag-lt"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_lt}
                required
              />
              <Input
                name="description_lt"
                label="Aprašymas"
                placeholder="Aprašymas (LT)"
                icon="flag-lt"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_lt}
                required
              />
              <Input
                name="title_en"
                label="Pavadinimas"
                placeholder="Pavadinimas (EN)"
                icon="flag-en"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_en}
                required
              />
              <Input
                name="description_en"
                label="Aprašymas"
                placeholder="Aprašymas (EN)"
                icon="flag-en"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_en}
                required
              />
              <Input
                name="title_ru"
                label="Pavadinimas"
                placeholder="Pavadinimas (RU)"
                icon="flag-ru"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_ru}
                required
              />
              <Input
                name="description_ru"
                label="Aprašymas"
                placeholder="Aprašymas (RU)"
                icon="flag-ru"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_ru}
                required
              />
              <Input
                name="color"
                label="Spalvos kodas"
                placeholder="Spalvos kodas"
                type="text"
                onChange={e => handleInputChange(e, colorCodeLimit)}
                value={values.color}
                required
              />
              <div />
            </div>
          </div>

          <div className={styles.actionHelperContainer}>
            {!initialHistory && (
              <p className={styles.actionHelperText}>
                Norėdami sukurti, toliau esančioje kortelėje turite pridėti
                pradinę istoriją
              </p>
            )}
          </div>
          <SubmitActions
            back={config.APP_PATHS.memberships}
            loading={loading}
            disabled={!initialHistory}
          />
        </form>
      </Paper>

      <History
        history={initialHistory ? [initialHistory] : []}
        membershipType={values.type}
        onSaveHandler={setInitialHistory}
      />
    </section>
  );
};

const mapStateToProps = state => ({
  memberships: state.memberships,
});

CreateMembership.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  memberships: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(CreateMembership);
