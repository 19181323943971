import { constants } from '..';

const setBusinessClientsLoadingAction = flag => ({
  type: constants.businessClients.SET_CLIENTS_LOADING,
  flag,
});

const setContractUploadingAction = flag => ({
  type: constants.businessClients.SET_CONTRACT_LOADING,
  flag,
});

const getBusinessClientsAction = body => ({
  type: constants.businessClients.GET_CLIENTS,
  body,
});

const setBusinessClientsAction = (clients, count, header, footer) => ({
  type: constants.businessClients.SET_CLIENTS,
  clients,
  count,
  header,
  footer,
});

const getBusinessClientAction = id => ({
  type: constants.businessClients.GET_CLIENT,
  id,
});

const setBusinessClientAction = client => ({
  type: constants.businessClients.SET_CLIENT,
  client,
});

const clearBusinessClientsAction = () => ({
  type: constants.businessClients.CLEAR_CLIENTS,
});

const updateBusinessClientAction = (id, body, history) => ({
  type: constants.businessClients.UPDATE_CLIENT,
  id,
  body,
  history,
});

const addBusinessClientAction = (body, history) => ({
  type: constants.businessClients.ADD_BUSINESS_CLIENT,
  body,
  history,
});

const uploadContractAction = file => ({
  type: constants.businessClients.UPLOAD_CONTRACT,
  file,
});

const uploadLogotypeAction = file => ({
  type: constants.businessClients.UPLOAD_LOGOTYPE,
  file,
});

const setUploadedContractAction = file => ({
  type: constants.businessClients.SET_UPLOADED_CONTRACT,
  file,
});

const setUploadedLogotypeAction = file => ({
  type: constants.businessClients.SET_UPLOADED_LOGOTYPE,
  file,
});

const updateBusinessClientStatusAction = (id, status) => ({
  type: constants.businessClients.UPDATE_STATUS,
  id,
  status,
});

const getSelectedClientMembershipsAction = business_client_id => ({
  type: constants.businessClients.GET_CLIENTS_MEMBERSHIPS,
  business_client_id,
});

const setSelectedClientMembershipsAction = memberships => ({
  type: constants.businessClients.SET_CLIENTS_MEMBERSHIPS,
  memberships,
});

const addMembershipBusinessClientAction = (businessClientId, body) => ({
  type: constants.businessClients.ADD_CLIENTS_MEMBERSHIP,
  businessClientId,
  body,
});

const updateMembershipBusinessClientAction = (
  businessClientId,
  selectedMembershipId,
  body
) => ({
  type: constants.businessClients.UPDATE_CLIENTS_MEMBERSHIP,
  businessClientId,
  selectedMembershipId,
  body,
});

const updateBusinessClientsMembershipsAction = membership => ({
  type: constants.businessClients.UPDATE_CLIENTS_MEMBERSHIPS_LIST,
  membership,
});

const getManagerAdminsAction = body => ({
  type: constants.businessClients.GET_MANAGER_ADMINS,
  body,
});

const searchCompanyDetailsAction = query => ({
  type: constants.businessClients.SEARCH_COMPANY_DETAILS,
  query,
});

const searchCompanyDetailsByCodeAction = code => ({
  type: constants.businessClients.SEARCH_COMPANY_DETAILS_BY_CODE,
  code,
});

const setManagerAdminsAction = admins => ({
  type: constants.businessClients.SET_MANAGER_ADMINS,
  admins,
});

const setCompanyDetailsAction = details => ({
  type: constants.businessClients.SET_COMPANY_DETAILS,
  details,
});

const setCompanyDetailsByCodeAction = details => ({
  type: constants.businessClients.SET_COMPANY_DETAILS_BY_CODE,
  details,
});

const setCompanyDetailsLoadingAction = flag => ({
  type: constants.businessClients.SET_COMPANY_DETAILS_LOADING,
  flag,
});

const setCompanyDetailsErrorAction = error => ({
  type: constants.businessClients.SET_COMPANY_DETAILS_ERROR,
  error,
});

const setCompanyDetailsByCodeErrorAction = error => ({
  type: constants.businessClients.SET_COMPANY_DETAILS_BY_CODE_ERROR,
  error,
});

const getSingleCompanyDetailsAction = code => ({
  type: constants.businessClients.GET_SINGLE_COMPANY_DETAILS_ACTION,
  code,
});

const setSingleCompanyDetailsAction = code => ({
  type: constants.businessClients.SET_SINGLE_COMPANY_DETAILS_ACTION,
  code,
});

const exportBusinessClientsAction = (filename, body) => ({
  type: constants.businessClients.EXPORT_BUSINESS_CLIENTS,
  filename,
  body,
});

export const businessClientsActions = {
  setBusinessClientsLoadingAction,
  getBusinessClientsAction,
  setBusinessClientsAction,
  clearBusinessClientsAction,
  getBusinessClientAction,
  setBusinessClientAction,
  updateBusinessClientAction,
  addBusinessClientAction,
  setContractUploadingAction,
  setUploadedContractAction,
  uploadContractAction,
  updateBusinessClientStatusAction,
  getSelectedClientMembershipsAction,
  setSelectedClientMembershipsAction,
  updateMembershipBusinessClientAction,
  addMembershipBusinessClientAction,
  updateBusinessClientsMembershipsAction,
  uploadLogotypeAction,
  setUploadedLogotypeAction,
  getManagerAdminsAction,
  setManagerAdminsAction,
  searchCompanyDetailsAction,
  searchCompanyDetailsByCodeAction,
  setCompanyDetailsAction,
  setCompanyDetailsByCodeAction,
  setCompanyDetailsLoadingAction,
  setCompanyDetailsErrorAction,
  setCompanyDetailsByCodeErrorAction,
  getSingleCompanyDetailsAction,
  setSingleCompanyDetailsAction,
  exportBusinessClientsAction,
};
