import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { availablePermissions } from './admins.data';
import Form from './admins.form';
import { Paper, PaperHeader, TitleSecondary, Checkbox } from '../../components';

const AddAdmin = ({ dispatch, history, admins: { loading } }) => {
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    business_role: 0,
  });
  const [permissions, setPermissions] = useState([]);

  const handleInputChange = ({ target: { name, value } }) => {
    const updatedValue = name === 'business_role' ? Number(value) : value;
    setValues({ ...values, [name]: updatedValue });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const body = {
      ...values,
      business_role: values.business_role || undefined,
      permissions,
    };
    dispatch(actions.admins.addAdminAction(body, history));
  };

  const isChecked = value => {
    const values = value.split(',').map(item => +item);
    const checked = permissions.some(per => values.includes(per));
    return checked;
  };

  const handleCheckboxChange = ({ target: { value, checked } }) => {
    let newPermisions = permissions;
    const values = value.split(',').map(item => +item);

    if (checked) {
      newPermisions = [...permissions, ...values];
    } else {
      newPermisions = permissions.filter(
        permission => !values.includes(permission)
      );
    }

    setPermissions([...newPermisions]);
  };

  const renderPermissions = () =>
    availablePermissions.map(({ name, value }) => (
      <Checkbox
        key={name}
        label={name}
        name={name}
        checked={isChecked(value)}
        value={value}
        onChange={handleCheckboxChange}
      />
    ));

  return (
    <Paper size="lg">
      <PaperHeader>
        <TitleSecondary>Nauja administratoriaus paskyra</TitleSecondary>
      </PaperHeader>

      <Form
        type="add"
        values={values}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        loading={loading}
      >
        {renderPermissions()}
      </Form>
    </Paper>
  );
};

const mapStateToProps = state => ({
  admins: state.admins,
});

AddAdmin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(AddAdmin);
