import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';

export function* getCountriesSaga() {
  try {
    yield put(actions.dropdowns.setDropdownsLoadingAction(true));

    const response = yield call(Api.getCountries);

    yield put(actions.dropdowns.setCountriesAction(response));
  } catch (e) {
    yield put(
      actions.dropdowns.setDropdownsErrorAction(
        e.error ? e.error.error : e.message
      )
    );
  } finally {
    yield put(actions.dropdowns.setDropdownsLoadingAction(false));
  }
}
