import { constants } from '..';

const getServicesAction = body => ({
  type: constants.services.GET_SERVICES,
  body,
});

const setServicesAction = (services, count, header, footer) => ({
  type: constants.services.SET_SERVICES,
  services,
  count,
  header,
  footer,
});

const clearServicesAction = () => ({
  type: constants.services.CLEAR_SERVICES,
});

export const servicesActions = {
  getServicesAction,
  setServicesAction,
  clearServicesAction,
};
