export const couponsConstants = {
  SET_COUPONS_LOADING: 'coupons/SET_COUPONS_LOADING',
  SET_COUPONS: 'coupons/SET_COUPONS',
  GET_COUPONS: 'coupons/GET_COUPONS',
  EXPORT_COUPONS: 'coupons/EXPORT_COUPONS',
  EXPORT_COUPONS_CODE_BY_CODE: 'coupons/EXPORT_COUPONS_CODE_BY_CODE',
  CLEAR_COUPONS: 'coupons/CLEAR_COUPONS',
  GET_COUPON: 'coupons/GET_COUPON',
  SET_COUPON: 'coupons/SET_COUPON',
};
