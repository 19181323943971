import { constants } from '..';

const setXlsLoadingAction = flag => ({
  type: constants.download.SET_XLS_LOADING,
  flag,
});

const setXmlLoadingAction = flag => ({
  type: constants.download.SET_XML_LOADING,
  flag,
});

const setAsyncExportResult = result => ({
  type: constants.download.SET_ASYNC_EXPORT_RESULT,
  result,
});

const fetchExportedFile = (id, url) => ({
  type: constants.download.FETCH_EXPORTED_FILE,
  id,
  url,
});

const setDownloadedFile = fileContent => ({
  type: constants.download.SET_DOWNLOADED_FILE,
  fileContent,
});

const setQrLoadingAction = flag => ({
  type: constants.download.SET_QR_LOADING,
  flag,
});

const connectSocketAction = flag => ({
  type: constants.download.CONNECT_SOCKET,
  flag,
});

const cleanDownload = () => ({
  type: constants.download.CLEAN_DOWNLOAD,
});

export const downloadActions = {
  setXlsLoadingAction,
  setQrLoadingAction,
  setAsyncExportResult,
  fetchExportedFile,
  setDownloadedFile,
  cleanDownload,
  connectSocketAction,
  setXmlLoadingAction,
};
