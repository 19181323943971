export const errorMapper = {
  100: 'Blogai suvesti duomenys',
  101: 'Nerasta',
  102: 'Vidinė serverio klaida',
  103: 'Dublikatas',
  104: 'Bendrinė klaida',
  105: 'Autorizavimo klaida',
  106: 'Nėra leidimo',
  107: 'Konfliktas',
  108: 'Adyen klaida',

  200: 'OK',
  201: 'Objektas sukurtas',
  202: 'Užklausa priimta, bet atsake gali nebūti resurso',
  203: 'Nepatikima informacija',
  204: 'Nepateikta reikalauta informacija',
  205: 'Reikia atnaujinti informacijos vaizdavimo langą',
  206: 'Pateikta tik dalis reikalaujamos informacijos',

  301: 'Resursas yra kitame URL',
  302: 'Resursas laikinai perkeltas į kitą vietą',
  303: 'Resursas laikinai yra kitame URL',
  304: 'Nepasikeitė',
  305: 'Privalote naudoti proxy serverį',

  400: 'Neteisinga užklausa',
  401: 'Būtina autorizacija',
  402: 'Būtinas apmokėjimas',
  403: 'Priėjimas prie reikalaujamo resurso yra draudžiamas',
  404: 'Nerasta',
  407: 'Yra būtina užsiregistruoti proxy serveryje',
  408: 'Per numatytą laiko tarpą negautas atsakymas į užklausą',
  410: 'Resurso nebėra',
  412: 'Užklausai nepatenkintos išankstinės sąlygos',
  413: 'Resursas per didelis',
  414: 'Per ilgas URL',
  415: 'Neperduodamas (nežinomas) resurso tipas',

  500: 'Vidinė serverio klaida',
  501: 'Serveris nepalaiko užklausos',
  502: 'Klaida tinklų sąsajos programinėje įrangoje',
  503: 'Serveris neprieinamas',
  504: 'Informacija per ilgai nepraeina pro tinklų sąsają.',
  505: 'HTTP protokolo versija nėra priimama',

  1001: 'Prietaisas užimtas',
  1002: 'Prietaisas arba serveris neaktyvus',
  1003: 'Prietaisas nerastas',
  1004: 'Nėra ryšio',

  1101: 'Negaliojantis tokenas',
  1102: 'Pasibaigė sesija',
  1103: 'Blogas IP adresas',
  1104: 'Neteisingas el. paštas arba slaptažodis',
  1105: 'Paskyra deaktyvuota',
  1106: 'Sesija pasibaigusi',
  1107: 'Neatpažintas tokenas',
  1108: 'Tokenas nerastas',
  1109: 'Klientas deaktyvuotas',
  1110: 'Neteisingas patvirtinimo telefonu kodas',
  1111: 'Pasibaigęs patvirtinimo telefonu kodas',

  1201: 'Toks el. paštas jau egzistuoja',
  1202: 'El. paštas nerastas',
  1203: 'Slaptažodžio atstatymo el. laiškas nėra išsiųstas',
  1204: 'Negaliojantis slaptažodžio atstatymo tokenas',
  1205: 'Pasibaigęs slaptažodžio atstatymo tokeno galiojimas',
  1206: 'Administratorius nerastas',
  1207: 'Priėjimas neleidžiamas',
  1208: 'Nesutampa slaptažodžiai',
  1209: 'Savęs atnaujinimas nėra leidžiamas',
  1210: 'Užklausos pažeidimas',

  1301: 'Klientas nerastas',
  1302: 'Mokėjimo kortelės dublikatas',
  1303: 'Mokėjimo kortelės duomenys nerasti',
  1304: 'Užklausos pažeidimas',
  1305: 'Mokėjimo kortelė nerasta',
  1306: 'Nežinoma mokėjimo kortelės užklausa',
  1307: 'Mokėjimas nepavyko',
  1308: 'Pavogtas mokėjimas',
  1309: 'Kortelė atmesta',
  1310: 'Laukiama kortelės',
  1311: 'Negalima pašalinti kortelės kol yra laukiamų apmokėjimų',
  1312: 'Neatpažinta mokėjimo kortelė',
  1313: 'Mokėjimo kortelės galiojimas pasibaigęs',
  1314: 'Prekė priklauso ne jums',

  1401: 'Lokacija jau egzistuoja',
  1402: 'Nepridėta paslauga',
  1403: 'Nepridėta savitarnos paslauga',
  1404: 'Nepridėta automatinio plovimo paslauga',
  1405: 'Nepridėta langų plovimo paslauga',
  1406: 'Pasikartojantis IPS',
  1407: 'Lokacija nerasta',
  1408: 'Atnaujinimo konfliktas',

  1501: 'Nerasta autorizacija',
  1502: 'Autorizavimas nepavyko',
  1503: 'Parašas nerastas',
  1504: 'Parašo neatitikimas',

  1601: 'Nuolaidos dublikatas',
  1602: 'Lokacijų nerasta',
  1603: 'Nuolaida nerasta',
  1604: 'Negalima redaguoti deaktyvuotos nuolaidos',
  1605: 'Negalima redaguoti aktyvios nuolaidos',
  1606: 'Nuolaida jau deaktyvuota',
  1607: 'Nuolaidos kodas nebegalioja',
  1608: 'Toks kodas jau egzistuoja',

  1701: 'Pasas nerastas',
  1702: 'Pasas neturi istorijos',
  1703: 'Galiojimo datos pradžia negali būti praėjusi diena',
  1704: 'Nuolaida šią dieną jau egzistuoja',
  1705: 'Negalima redaguoti galiojančios istorijos',
  1706: 'Šis pasas jau įsigytas',
  1707: 'Pasas jau nupirktas ir laukiamas apmokėjimas',
  1708: 'Užsakymo apmokėjimas nepavyko',
  1709: 'Pasas neturi aktyvaus paso užsakymo',
  1710: 'Paso užsakymas nerastas',
  1711: 'Negalima deaktyvuoti neaktyvaus paso užsakymo',
  1712: 'Negalima įsigyti neaktyvaus paso',

  1801: 'Aktyvavimas negalimas',
  1802: 'Užsakymas negalimas kol yra neapmokėtų užsakymų',
  1803: 'Užsakymas nerastas',
  1804: 'Negalima uždaryti užsakymo kol nėra apmokėtos visos paslaugos',
  1805: 'Aktyvavimas nepavyko',
  1806: 'Nėra aktyvių užsakymų',
  1807: 'Prietaiso deaktyvuoti nėgalima',
  1808: 'Negalima deaktyvuoti ne savitarnos prietaiso',
  1809: 'Deaktyvavimas nepavyko',
  1814: 'Netinkamas užsakymo statusas',

  3305: 'Verslo klientas dar neturi prieigos prie administratorinės panelės.',
  3306: 'Praeitas laikotarpis nesuderinamas su kitu įrašu',
  3307: 'Narystės kaina tai datai nenustatyta',
  3310: 'Nepakankamas kiekis licencijų!',
  3503: 'Mėnesio limitas viršija kredito limitą!',
  3604: 'Kortelė su šiuo numeriu neegzistuoja.',

  4501: 'Prie šio kanalo prisijungti negalima',
  4502: 'Šio kanalo skelbti negalima',
};
