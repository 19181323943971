import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './updatePass.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import {
  Input,
  SubmitActions,
  Paper,
  PaperHeader,
  TitleSecondary,
} from '../../../components';

const UpdatePass = ({ dispatch, admins: { loading }, alert }) => {
  const INITIAL_VALUES = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };

  const [values, setValues] = useState(INITIAL_VALUES);

  useEffect(() => {
    if (alert && alert.category === 'success') {
      setValues(INITIAL_VALUES);
    }
  }, [alert, INITIAL_VALUES]);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { old_password, new_password, confirm_password } = values;

    try {
      if (!old_password) {
        throw new Error('Įveskite dabartinį slaptažodį');
      }
      if (!new_password) {
        throw new Error('Įveskite naują slaptažodį');
      }
      if (!confirm_password) {
        throw new Error('Patvirtinkite slaptažodį');
      }
      if (new_password !== confirm_password) {
        throw new Error('Slaptažodžiai nesutampa');
      }

      const body = { old_password, new_password };

      dispatch(actions.auth.updatePassAction(body));
    } catch ({ message }) {
      dispatch(actions.error.setAlertAction(message, 'danger'));
    }
  };

  return (
    <Paper>
      <PaperHeader>
        <TitleSecondary>Keisti slaptažodį</TitleSecondary>
      </PaperHeader>
      <form onSubmit={handleSubmit} method="POST">
        <div className={styles.inputs}>
          <Input
            name="old_password"
            label="Dabartinis slaptažodis"
            type="password"
            onChange={handleInputChange}
            value={values.old_password}
          />
          <Input
            name="new_password"
            label="Naujas slaptažodis"
            type="password"
            onChange={handleInputChange}
            value={values.new_password}
          />
          <Input
            name="confirm_password"
            label="Patvirtinti slaptažodį"
            type="password"
            onChange={handleInputChange}
            value={values.confirm_password}
          />
        </div>
        <SubmitActions back={config.APP_PATHS.home} loading={loading} />
      </form>
    </Paper>
  );
};

const mapStateToProps = state => ({
  admins: state.admins,
  alert: state.error.alert,
});

UpdatePass.defaultProps = {
  alert: null,
};

UpdatePass.propTypes = {
  dispatch: PropTypes.func.isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  alert: PropTypes.shape({
    msg: PropTypes.string,
    category: PropTypes.oneOf(['danger', 'success']),
  }),
};

export default connect(mapStateToProps)(UpdatePass);
