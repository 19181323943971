import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './tableSimple.module.scss';

const TableSimple = ({ children, header, footer, rightHeaderCells }) => {
  const renderHeader = () =>
    header.map((col, i) => (
      <span
        className={cn(
          styles.headerCell,
          rightHeaderCells.includes(i) ? styles.right : styles.left
        )}
        key={i}
        role="columnheader"
      >
        {col}
      </span>
    ));

  return (
    <div className={styles.main}>
      <div className={styles.table} role="grid">
        <div className={styles.header} role="presentation">
          <div className={styles.headerRow} role="row">
            {renderHeader()}
          </div>
        </div>
        <div className={styles.body} role="presentation">
          {children}
        </div>
      </div>
      {footer && <div className={styles.footerRow}>{footer}</div>}
    </div>
  );
};

export const TableRow = ({ children, onClick, noBorder }) => (
  <div
    className={noBorder ? styles.bodyRow : styles.bodyRowWithBorder}
    onClick={onClick}
    role="row"
  >
    {children}
  </div>
);

export const TableCell = ({ children, align }) => (
  <span className={cn(styles.bodyCell, styles[align])} role="gridcell">
    {children}
  </span>
);

TableSimple.defaultProps = {
  footer: null,
  rightHeaderCells: [],
};

TableSimple.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.array.isRequired,
  footer: PropTypes.node,
  headerAlign: PropTypes.string,
  rightHeaderCells: PropTypes.arrayOf(PropTypes.number),
};

TableRow.defaultProps = {
  onClick: () => {},
};

TableRow.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

TableCell.defaultProps = {
  children: null,
  align: 'left',
};

TableCell.propTypes = {
  children: PropTypes.node,
  align: PropTypes.string,
};

export default TableSimple;
