import React from 'react';
import PropTypes from 'prop-types';

import styles from './titleSecondary.module.scss';

const TitleSecondary = ({ children }) => (
  <h2 className={styles.title}>{children}</h2>
);

TitleSecondary.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TitleSecondary;
