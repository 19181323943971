import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { generatePhoneQuery } from '../../utils';
import { actions } from '..';

export function* getClientsSearchSaga({ query }) {
  try {
    const queryFromUser = query.trim();

    if (queryFromUser.length < config.DEFAULTS.symbolsToSearch) {
      throw new Error(`Įrašykite ${config.DEFAULTS.symbolsToSearch} simbolius`);
    }

    const queryToSubmit = generatePhoneQuery(queryFromUser);

    yield put(actions.search.setClientsSearchLoadingAction(true));

    const response = yield call(Api.searchClients, queryToSubmit);

    yield put(actions.search.setClientsSearchAction(response));
  } catch (e) {
    yield put(
      actions.search.setClientsSearchErrorAction(
        e.error ? e.error.error : e.message
      )
    );
  } finally {
    yield put(actions.search.setClientsSearchLoadingAction(false));
  }
}

export function* getH2CardsSearchSaga({ query }) {
  try {
    const queryFromUser = query.trim();

    if (queryFromUser.length < config.DEFAULTS.symbolsToSearch) {
      throw new Error(`Įrašykite ${config.DEFAULTS.symbolsToSearch} simbolius`);
    }

    const queryToSubmit = queryFromUser;

    yield put(actions.search.setClientsSearchLoadingAction(true));

    const response = yield call(Api.searchH2Cards, queryToSubmit);

    yield put(actions.search.setH2CardsSearchAction(response));
  } catch (e) {
    yield put(
      actions.search.setClientsSearchErrorAction(
        e.error ? e.error.error : e.message
      )
    );
  } finally {
    yield put(actions.search.setClientsSearchLoadingAction(false));
  }
}
