import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './buttonSimple.module.scss';
import { Icon } from '../..';

const ButtonSimple = ({
  children,
  type,
  onClick,
  classes,
  bigText,
  ...rest
}) => (
  <button
    className={cn(
      styles.button,
      styles[type],
      bigText ? styles['text-big'] : '',
      classes.button
    )}
    type="button"
    onClick={onClick}
    {...rest}
  >
    <Icon
      name={type}
      classes={{ icon: styles.icon }}
      styles={children ? {} : { margin: 0 }}
    />
    {children}
  </button>
);

ButtonSimple.defaultProps = {
  classes: {},
  bigText: false,
};

ButtonSimple.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'add',
    'remove',
    'filter',
    'success',
    'remove-red',
    'edit',
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
  }),
  bigText: PropTypes.bool,
};

export default ButtonSimple;
