import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { config } from '../../api/config';
import {
  formatMoney,
  generateHeader,
  isEmpty,
  mapServicesStatus,
  mapServiceTypes,
  checkStatusColor,
  isFilterActive,
} from '../../utils';
import {
  SelectFilter,
  RangeFilter,
  WithSpinner,
  Table,
  TableRow,
  TableCell,
} from '../../components';
import { AddressesContext } from './addresses.context copy';

const LocationsTable = ({
  dispatch,
  locations: { locations, header, footer, loading },
  timeRangeFilter,
  getFilteredBody,
  clearAllFilters,
  setClearToFalse,
}) => {
  const {
    locationFilter,
    setLocationFilter,
    statusFilter,
    setStatusFilter,
    servicesFilter,
    setServicesFilter,
    costLtFilter,
    setCostLtFilter,
    costGtFilter,
    setCostGtFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
  } = useContext(AddressesContext);

  const fetchLocations = useCallback(
    data => {
      dispatch(actions.locations.getLocationsAction(data));
    },
    [dispatch]
  );

  const clearLocations = useCallback(() => {
    dispatch(actions.locations.clearLocationsAction());
  }, [dispatch]);

  const clearSelectedLocation = useCallback(() => {
    dispatch(actions.locations.setSelectedLocationAction({}));
  }, [dispatch]);

  useEffect(() => {
    let filterToSubmit;

    if (filterBody) {
      if (clearAllFilters) {
        setLocationFilter([]);
        setStatusFilter([]);
        setServicesFilter([]);
        setCostLtFilter('');
        setCostGtFilter('');
        setFilterBody();
        setSortOrder('asc');
        setClearToFalse(false);
      } else if (timeRangeFilter) {
        const filterSum = { ...filterBody, ...timeRangeFilter };
        filterToSubmit = filterSum;
      } else {
        filterToSubmit = filterBody;
      }
    } else if (timeRangeFilter) {
      filterToSubmit = timeRangeFilter;
    }
    getFilteredBody(filterToSubmit);
    fetchLocations(filterToSubmit);
    return () => clearLocations();
  }, [
    fetchLocations,
    clearLocations,
    filterBody,
    timeRangeFilter,
    getFilteredBody,
    clearAllFilters,
    setClearToFalse,
  ]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (locationFilter.length) {
      generatedFilter.push({ field: 'full_address', value: locationFilter });
    }
    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (servicesFilter.length) {
      generatedFilter.push({
        field: 'enabled_services',
        value: servicesFilter,
        convert: 'smallint',
        expression: 'overlap',
      });
    }

    if (costLtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'lte',
        value: costLtFilter * 100,
      });
    }

    if (costGtFilter > 0) {
      generatedFilter.push({
        field: 'income',
        expression: 'gte',
        value: costGtFilter * 100,
      });
    }
    if (timeRangeFilter) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        ...timeRangeFilter,
      });
    } else {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    }
  };

  const tableHeader = [
    <SelectFilter
      title="Būsena"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapServicesStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Lokacija"
      name="full_address"
      valueType="string"
      array={generateHeader(header.full_address)}
      filterState={locationFilter}
      setFilterState={setLocationFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', locationFilter)}
    />,
    <SelectFilter
      title="Įjungtos paslaugos"
      name="enabled_services"
      valueType="number"
      array={generateHeader(header.enabled_services, mapServiceTypes)}
      filterState={servicesFilter}
      setFilterState={setServicesFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', servicesFilter)}
    />,
    <RangeFilter
      title="Apyvarta su PVM"
      name="income"
      lt={costLtFilter}
      setLt={setCostLtFilter}
      gt={costGtFilter}
      setGt={setCostGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', costLtFilter, costGtFilter)}
    />,
  ];

  const tableFooter = ['', '', '', formatMoney(footer.income)];

  const renderTable = () =>
    locations.map(({ id, full_address, status, enabled_services, income }) => (
      <TableRow
        onClick={clearSelectedLocation}
        url={config.APP_PATHS.editLocation(id)}
        key={id}
      >
        <TableCell>
          <span style={{ color: checkStatusColor(status) }}>
            {mapServicesStatus(status)}
          </span>
        </TableCell>
        <TableCell>{full_address}</TableCell>

        <TableCell>
          {enabled_services.map(service => (
            <p key={service}>{mapServiceTypes(service)}</p>
          ))}
        </TableCell>

        <TableCell align="right">{formatMoney(income)}</TableCell>
      </TableRow>
    ));

  return (
    <WithSpinner loading={loading}>
      {!isEmpty(header) && (
        <Table
          header={tableHeader}
          footer={tableFooter}
          filter={true}
          isEmpty={!locations.length}
          width="xs"
          rightAlignedFooterCells={[3]}
        >
          {renderTable()}
        </Table>
      )}
    </WithSpinner>
  );
};

const mapStateToProps = state => ({
  locations: state.locations,
});

LocationsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    locations: PropTypes.array.isRequired,
    header: PropTypes.object.isRequired,
    footer: PropTypes.object.isRequired,
  }).isRequired,
  timeRangeFilter: PropTypes.object,
  getFilteredBody: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.bool.isRequired,
  setClearToFalse: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(LocationsTable);
