import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  formatFilename,
  mapServiceTypes,
  formatMoney,
  mapBenefitResetPeriods,
  mapDiscountPeriods,
  formatDate,
} from '../../../utils';
import { Header, Title, Table, TableRow, TableCell } from '../../../components';
import { actions } from '../../../state';
import { config } from '../../../api/config';

const BusinessClientDiscounts = ({
  dispatch,
  memberships: { memberships, total, loading },
}) => {
  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMemberships();
    return () => {
      clearMemberships();
    };
  }, [fetchMemberships, clearMemberships]);

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.discounts);
    dispatch(actions.discounts.exportDiscountsAction(filename));
  };

  const tableHeader = [
    'Nuolaidos planas',
    'Nuolaidos',
    'Priskirtų licencijų',
    'Nepriskirtų licencijų',
    'Tipas ',
    'Kaina/lic',
    'Kaina (bendra)',
    'Pradžios data',
    'Pabaigos data',
  ];

  const renderTable = () => {
    const getDiscountsInfo = benefits => {
      return benefits.map(benefit => (
        <div key={benefit.id}>
          {`${benefit.amount}${
            !benefit.reset_period
              ? '%'
              : ` (${mapBenefitResetPeriods(benefit.reset_period)})`
          } ${mapServiceTypes(benefit.service_type)}`}
        </div>
      ));
    };
    return memberships.map(membership => {
      const {
        id,
        title_lt,
        assigned_license_count,
        license_count,
        period,
        price,
        total_price,
        benefits,
        valid_from,
        valid_until,
      } = membership;
      return (
        <TableRow
          url={config.APP_PATHS.baDiscountPlan(id)}
          state={JSON.stringify(membership)}
          key={id}
        >
          <TableCell>{title_lt}</TableCell>
          <TableCell>{getDiscountsInfo(benefits)}</TableCell>
          <TableCell>{assigned_license_count ?? 0}</TableCell>
          <TableCell>{license_count - assigned_license_count ?? 0}</TableCell>
          <TableCell>{mapDiscountPeriods(period)}</TableCell>
          <TableCell>{formatMoney(price)}</TableCell>
          <TableCell>{formatMoney(total_price)}</TableCell>
          <TableCell>{valid_from ? formatDate(valid_from) : '-'}</TableCell>
          <TableCell>{valid_until ? formatDate(valid_until) : '-'}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <section>
      <Header>
        <Title total="">{config.PAGES.baDiscountPlans}</Title>
      </Header>

      <Table
        header={tableHeader}
        isEmpty={!memberships.length}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  memberships: state.memberships,
  xlsLoading: state.download.xlsLoading,
});

BusinessClientDiscounts.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BusinessClientDiscounts);
