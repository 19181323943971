import React from 'react';
import cn from 'classnames';
import {
  ButtonSimple,
  Paper,
  Table,
  TableCell,
  TableRow,
} from '../../components';
import styles from './invoices.module.scss';
import { formatMoney } from '../../utils';

const InvoicesEntries = ({ entries, openModal, isSynced }) => {
  const tableHeader = ['Prekė', 'Kiekis', 'Mat. vnt.', 'Suma, eur (su PVM)'];

  const renderTablePart = entries =>
    entries.map((entry, index) => {
      const { item, quantity, unit, cost } = entry;

      return (
        <TableRow
          key={`${index}-${item}`}
          onClick={() => {
            if (!isSynced) openModal(entry);
          }}
        >
          <TableCell>{item || '-'}</TableCell>
          <TableCell>{quantity || '-'}</TableCell>
          <TableCell>{unit || '-'}</TableCell>
          <TableCell>{formatMoney(cost)}</TableCell>
        </TableRow>
      );
    });

  return (
    <Paper>
      <section className={cn(styles.document, styles.entriesContent)}>
        <div className={styles.entriesEditHeader}>
          <p className={styles.entriesEditTitle}>Prekės</p>
          {!isSynced && (
            <ButtonSimple
              type="add"
              onClick={() => openModal()}
              disabled={isSynced}
            >
              Pridėti
            </ButtonSimple>
          )}
        </div>
        <Table header={tableHeader} isEmpty={!entries.length}>
          {renderTablePart(entries)}
        </Table>
      </section>
    </Paper>
  );
};

export default InvoicesEntries;
