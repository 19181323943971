import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './memberships.module.scss';
import { actions } from '../../state';
import { formatDateTime, formatMoneyToCents, getTimestamp } from '../../utils';
import { Button } from '../../components';
import Form from './memberships.history.form';
import { createDefaultBenefit } from './memberships.history.component';
import { format } from 'date-fns';

const AddHistory = ({
  dispatch,
  id,
  services,
  membershipType,
  onSubmitHandler,
  prefill,
}) => {
  const [values, setValues] = useState({
    dateFrom: prefill ? prefill?.valid_from : '',
    discount: prefill ? prefill?.discount : '',
    price: prefill ? prefill.price / 100 : '',
    yearlyPrice: prefill ? prefill?.yearly_price / 100 : '',
    trialPeriodPrice: prefill ? prefill?.paid_trial_price / 100 : '',
    trialPeriod: prefill ? prefill?.trial_period : '',
    warranty: prefill ? prefill?.warranty : '',
    checkYearlyPlan: Boolean(prefill?.yearly_plan),
    checkTrialPeriod: Boolean(prefill?.trial_available),
    checkPaidTrialPeriod: Boolean(prefill?.paid_trial),
    benefits: prefill?.benefits.length
      ? [...prefill.benefits]
      : [{ ...createDefaultBenefit() }],
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setValues(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleDateChange = date => {
    setValues({
      ...values,
      dateFrom: date,
    });
  };

  const handleCheckboxChange = ({ currentTarget: { id } }) => {
    setValues(prev => {
      return { ...prev, [id]: !prev[id] };
    });
  };

  const handleBenefitChange = (e, benefitId) => {
    const {
      target: { name, value, id },
    } = e;

    setValues(prev => {
      const arr = [...prev?.benefits]?.map(benefit => {
        if (benefitId === benefit.id) {
          const key = name || id;
          benefit[key] = name ? Number(value) : !benefit[id];
        }
        return benefit;
      });

      return { ...prev, benefits: arr };
    });
  };

  const handleAddNewBenefit = () => {
    setValues(prev => {
      prev.benefits.push(createDefaultBenefit());

      return { ...prev };
    });
  };

  const handleRemoveBenefit = id => {
    setValues(prev => {
      prev.benefits.splice(id, 1);

      return { ...prev };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const benefits = values.benefits.filter(
      benefit =>
        JSON.stringify(benefit) !== JSON.stringify(createDefaultBenefit())
    );
    const body = {
      price: formatMoneyToCents(values.price),
      valid_from: formatDateTime(values.dateFrom),
      yearly_plan: values.checkYearlyPlan,
      yearly_price: values.checkYearlyPlan
        ? formatMoneyToCents(values.yearlyPrice)
        : undefined,
      warranty: Number(values.warranty),
      trial_available: values.checkTrialPeriod,
      trial_period: values.checkTrialPeriod ? values.trialPeriod : undefined,
      paid_trial: values.checkPaidTrialPeriod,
      paid_trial_price: values.checkPaidTrialPeriod
        ? formatMoneyToCents(values.trialPeriodPrice)
        : undefined,
      benefits: benefits.length ? benefits : undefined,
    };

    if (id && !onSubmitHandler) {
      dispatch(actions.memberships.addMembershipHistoryAction(id, body));
    }
    if (!id && onSubmitHandler) {
      onSubmitHandler(body);
      dispatch(actions.modals.clearModals());
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onChange={handleInputChange}
      onDateChange={handleDateChange}
      values={values}
      services={services}
      onBenefitChange={handleBenefitChange}
      onCheckboxChange={handleCheckboxChange}
      addNewBenefitClickHandler={handleAddNewBenefit}
      removeBenefitClickHandler={handleRemoveBenefit}
      membershipType={membershipType}
    >
      <div className={styles.addActions}>
        <Button color="primary" type="submit">
          Išsaugoti
        </Button>
      </div>
    </Form>
  );
};

AddHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func,
  id: PropTypes.number,
  membershipType: PropTypes.number.isRequired,
  prefill: PropTypes.shape({
    valid_from: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    discount: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    price: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    yearly_price: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    paid_trial_price: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    warranty: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    trial_period: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    yearly_plan: PropTypes.bool,
    paid_trial: PropTypes.bool,
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number.isRequired,
        service_type: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]).isRequired,
        amount: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.number.isRequired,
        ]).isRequired,
        reset_period: PropTypes.number,
      }).isRequired
    ),
  }),
};

export default connect()(AddHistory);
