import React, { useState } from 'react';
import { connect } from 'react-redux';
import { addMinutes, format } from 'date-fns';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import { discountGroups } from '../../utils';
import {
  Header,
  Breadcrumb,
  Paper,
  PaperHeader,
  TitleSecondary,
} from '../../components';
import Form from './discounts.form';
import { validationErrors } from './discounts.validation';

const BANNER_ADVERTISEMENT_TYPE = 651;
const BANNER_SIMPLE_TYPE = 652;

const AddDiscount = ({ dispatch, history, discounts: { loading } }) => {
  const [values, setValues] = useState({
    code: '',
    discount: '',
    description_lt: '',
    description_en: '',
    description_ru: '',
    dateFrom: format(Date.now(), 'yyyy-MM-dd'),
    dateTo: format(Date.now(), 'yyyy-MM-dd'),
    timeFrom: '',
    timeTo: '',
    createDiscountBanner: false,
    discountBannerIsAdvertisement: false,
    discountBannerFile: {},
    announcement: {
      type: '',
      sendDate: '',
      sendTime: '',
      title_lt: '',
      title_en: '',
      title_ru: '',
      description_lt: '',
      description_en: '',
      description_ru: '',
      app_notification: false,
      banner_id: 0,
    },
  });
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [groups, setGroups] = useState([]);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name.includes('.')) {
      const splitName = name.split('.');
      setValues({
        ...values,
        announcement: { ...values.announcement, [splitName[1]]: value },
      });
    } else setValues({ ...values, [name]: value });
  };

  const setCreateDiscountBanner = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;
    setValues({
      ...values,
      createDiscountBanner: newValue,
      announcement: {
        type: values.discountBannerIsAdvertisement
          ? BANNER_ADVERTISEMENT_TYPE
          : BANNER_SIMPLE_TYPE,
        app_notification: newValue
          ? values.announcement.app_notification
          : false,
        title_lt: newValue ? values.announcement.title_lt : '',
        title_en: newValue ? values.announcement.title_en : '',
        title_ru: newValue ? values.announcement.title_ru : '',
        description_lt: newValue ? values.announcement.description_lt : '',
        description_en: newValue ? values.announcement.description_en : '',
        description_ru: newValue ? values.announcement.description_ru : '',
        banner_id: newValue ? values.announcement.banner_id : 0,
        sendDate: newValue ? values.announcement.sendDate : '',
        sendTime: newValue ? values.announcement.sendTime : '',
      },
    });
  };

  const setDiscountBannerIsAdvertisement = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;

    setValues({
      ...values,
      discountBannerIsAdvertisement: newValue,
      announcement: {
        ...values.announcement,
        type: newValue ? BANNER_ADVERTISEMENT_TYPE : BANNER_SIMPLE_TYPE,
      },
    });
  };

  const setSendPushNotifications = e => {
    setValues({
      ...values,
      announcement: {
        ...values.announcement,
        app_notification: e.currentTarget.value === 'true' ? false : true,
      },
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values);

    if (error) {
      dispatch(actions.error.setErrorAction({ msg: error }));
    } else {
      const codeToSubmit = values.code;
      const descriptionLtToSubmit = values.description_lt;
      const descriptionEnToSubmit = values.description_en;
      const descriptionRuToSubmit = values.description_ru;
      const discountToSubmit = values.discount ? parseInt(values.discount) : 0;
      const dateFrom = new Date(values.dateFrom).toISOString();
      const dateTo = new Date(values.dateTo).toISOString();
      const dateIntervalToSubmit = [dateFrom, dateTo];
      const locationsToSubmit = locations.length ? locations : null;
      const servicesToSubmit = services.length ? services : null;
      const weekdaysToSubmit = weekdays.length ? weekdays : null;
      const groupsToSubmit =
        groups.length && groups.length !== discountGroups.length
          ? groups
          : [181];
      const timeToSubmit =
        values.timeFrom === values.timeTo
          ? null
          : [values.timeFrom, values.timeTo];

      const annAppNotificationToSubmit = values.announcement.app_notification;
      const annTitleLtToSubmit = values.announcement.title_lt;
      const annTitleEnToSubmit = values.announcement.title_en;
      const annTitleRuToSubmit = values.announcement.title_ru;
      const annDescriptionLtToSubmit = values.announcement.description_lt;
      const annDescriptionEnToSubmit = values.announcement.description_en;
      const annDescriptionRuToSubmit = values.announcement.description_ru;
      const annSendAtToSubmit =
        values.createDiscountBanner && values.announcement.app_notification
          ? new Date(
              values.announcement.sendDate + ' ' + values.announcement.sendTime
            ).toISOString()
          : null;
      const annTypeToSubmit = values.announcement.type;

      const body = {
        code: codeToSubmit,
        discount: discountToSubmit,
        description_lt: descriptionLtToSubmit,
        description_en: descriptionEnToSubmit,
        description_ru: descriptionRuToSubmit,
        date_interval: dateIntervalToSubmit,
        service_types: servicesToSubmit,
        client_groups: groupsToSubmit,
        locations: locationsToSubmit,
        time_interval: timeToSubmit,
        week_days: weekdaysToSubmit,
        createDiscountBanner: values.createDiscountBanner,
        announcement: values.createDiscountBanner
          ? {
              type: annTypeToSubmit,
              title_lt: annTitleLtToSubmit,
              title_en: annTitleEnToSubmit,
              title_ru: annTitleRuToSubmit,
              description_lt: annDescriptionLtToSubmit,
              description_en: annDescriptionEnToSubmit,
              description_ru: annDescriptionRuToSubmit,
              app_notification: annAppNotificationToSubmit,
              app_notification_send_at: annSendAtToSubmit,
            }
          : null,
      };

      dispatch(actions.discounts.addDiscountAction(body, history));
    }
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.discounts}
          baseUrl={config.APP_PATHS.discounts}
        />
      </Header>

      <Paper size="sm">
        <PaperHeader>
          <TitleSecondary>Pridėti nuolaidą</TitleSecondary>
        </PaperHeader>
        <Form
          values={values}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          loading={loading}
          weekdays={weekdays}
          setWeekdays={setWeekdays}
          services={services}
          setServices={setServices}
          locations={locations}
          setLocations={setLocations}
          groups={groups}
          setGroups={setGroups}
          setCreateDiscountBanner={setCreateDiscountBanner}
          setDiscountBannerIsAdvertisement={setDiscountBannerIsAdvertisement}
          setSendPushNotifications={setSendPushNotifications}
        />
      </Paper>
    </section>
  );
};

const mapStateToProps = state => ({
  discounts: state.discounts,
});

AddDiscount.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  discounts: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(AddDiscount);
