import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './resetPassRequest.module.scss';
import { actions } from '../../../state';
import { Button, Input } from '../../../components';

const ResetPassRequest = ({ dispatch }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = e => {
    e.preventDefault();

    if (email) {
      dispatch(actions.auth.resetPassRequestAction(email));
      dispatch(actions.modals.clearModals());
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.container} method="POST">
      <p className={styles.info}>
        Jūsų el. pašto adresu bus išsiųstas laiškas su nuoroda, kurią paspaudus
        gausite naują slaptažodį.
      </p>
      <Input
        type="email"
        name="email"
        label="El. paštas"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button color="primary" type="submit" classes={{ button: styles.button }}>
        Siųsti
      </Button>
    </form>
  );
};

ResetPassRequest.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ResetPassRequest);
