import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import styles from './orders.module.scss';
import { actions } from '../../state';
import Label from '../../components/form/_elements/labelElement/labelElement';
import { Button, Select, WithSpinner } from '../../components';

const RetryPayment = ({
  dispatch,
  orderId,
  clientId,
  clients: { loading, clientCards },
}) => {
  const [card, setCard] = useState();

  const fetchClientCards = useCallback(
    id => {
      dispatch(actions.clients.getClientCards(id));
    },
    [dispatch]
  );

  const clearClients = useCallback(() => {
    dispatch(actions.clients.clearClientsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchClientCards(clientId);
    return () => clearClients();
  }, [fetchClientCards, clearClients, clientId]);

  const loadValues = useCallback(() => {
    if (clientCards.length > 0) {
      const currentCard = clientCards[0].id;
      setCard(currentCard);
    }
  }, [clientCards]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const openModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Kartoti mokėjimą',
      desc: 'Ar tikrai norite kartoti mokėjimą?',
      onConfirm: () =>
        dispatch(actions.orders.retryPaymentAction(orderId, card)),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <>
      <div className={styles.labelContainer}>
        <Label label="Pasirinkite kortelę" htmlFor="payment_cards" />
      </div>
      <WithSpinner loading={loading}>
        <Select
          name="payment_cards"
          options={clientCards.map(({ id, number }) => ({
            name: number,
            value: id,
          }))}
          onChange={e => setCard(+e.target.value)}
        />
      </WithSpinner>
      <div className={styles.submit}>
        <Button color="primary" type="button" onClick={openModal}>
          Patvirtinti
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  clients: state.clients,
});

export default connect(mapStateToProps)(RetryPayment);
