import { constants } from '..';

const setModalVisible = payload => ({
  type: constants.modals.SET_MODAL_VISIBLE,
  payload,
});

const clearModals = () => ({
  type: constants.modals.CLEAR_MODALS,
});

export const modalsActions = {
  setModalVisible,
  clearModals,
};
