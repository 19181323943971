import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import styles from './orders.module.scss';
import { actions } from '../../state';
import Label from '../../components/form/_elements/labelElement/labelElement';
import { Button, Select, WithSpinner } from '../../components';
import { ordersActions } from '../../state/orders/orders.actions';

const ChangeOrderStatus = ({ dispatch, orderId }) => {
  const openModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Pakeisti užsakymo statusą',
      desc: 'Ar tikrai norite pakeisti užsakymo statusą į Įvykdytas?',
      onConfirm: () => dispatch(ordersActions.changeOrderStatusToPaid(orderId)),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <>
      <div className={styles.labelContainer}>
        <Label label="Pasirinkite statusą" />
      </div>
      <Select name="payment_cards" options={[{ name: 'Įvykdytas' }]} />
      <div className={styles.submit}>
        <Button color="primary" type="button" onClick={openModal}>
          Patvirtinti
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  clients: state.clients,
});

export default connect(mapStateToProps)(ChangeOrderStatus);
