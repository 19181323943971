import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';
import { errorMessage, downloadFile, convertPricesAndTime } from '../../utils';

export function* getLocationsSaga({ body }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));
    const { locations, count, header, footer } = yield call(
      Api.getLocations,
      body
    );

    yield put(
      actions.locations.setLocationsAction(locations, count, header, footer)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}

export function* getSelectedLocationSaga({ id }) {
  try {
    yield put(actions.locations.setLocationsLoadingAction(true));

    const location = yield call(Api.getLocation, id);

    const locationsWithConvertedPrices = convertPricesAndTime(location, 'from');

    yield put(
      actions.locations.setSelectedLocationAction(locationsWithConvertedPrices)
    );
    yield put(
      actions.addEditServices.replaceAddEditServicesData(
        locationsWithConvertedPrices
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.locations.setLocationsLoadingAction(false));
  }
}

export function* exportLocationsSaga({ filename, tab, filterBody }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));

    let response = undefined;

    switch (tab) {
      case 0:
        response = yield call(Api.exportLocations, filterBody);
        break;
      case 1:
        response = yield call(Api.exportServices, filterBody);
        break;
      case 2:
        response = yield call(Api.exportBoxes, filterBody);
        break;
      default:
        response = undefined;
    }

    if (response) {
      downloadFile(response, filename);
    } else {
      throw new Error('Nepasirinkta paslauga');
    }
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* downloadQrSaga({ id, filename }) {
  try {
    yield put(actions.download.setQrLoadingAction(true));

    const response = yield call(Api.downloadLocationsQr, id);
    downloadFile(response, filename, 'pdf');
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setQrLoadingAction(false));
  }
}
