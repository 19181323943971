import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  memberships: [],
  selectedMembership: {},
  count: 0,
  header: {},
};

export const membershipsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.memberships.SET_MEMBERSHIPS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.memberships.SET_MEMBERSHIPS: {
      const payloadMemberships =
        action?.memberships?.memberships || action.memberships;
      return {
        ...state,
        memberships: payloadMemberships,
        count: action.memberships.count,
        header: action.memberships.header,
      };
    }
    case constants.memberships.CLEAR_MEMBERSHIPS:
      return {
        ...INITIAL_STATE,
      };
    case constants.memberships.SET_MEMBERSHIP:
      return {
        ...state,
        selectedMembership: action.membership,
      };
    default:
      return state;
  }
};
