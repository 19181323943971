import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { config } from '../../api/config';

import styles from './authLayout.module.scss';
import { actions } from '../../state';

import Header from './header/header';
import Sidebar from './sidebar/sidebar';
import { socketsClient } from '../../api/sockets';
import { Button } from '../../components';

const AuthLayout = ({
  children,
  dispatch,
  auth: { token, firstItem, isImpersonating, mounting },
}) => {
  const history = useHistory();
  const [impersonationInProgress, setImpersonationInProgress] = useState(false);

  useEffect(() => {
    socketsClient.on('connect', () => {
      socketsClient.emit('verification', { token }, err => {
        if (err) {
          dispatch(actions.download.connectSocketAction(false));
        } else {
          dispatch(actions.download.connectSocketAction(true));
        }
      });
    });

    socketsClient.on('error', () => {
      dispatch(actions.download.connectSocketAction(false));
    });
  }, [token, dispatch]);

  useEffect(() => {
    //if (history && firstItem) history.push(firstItem.url);
  }, [firstItem]);

  const handleEndImpersonation = () => {
    history.push(config.APP_PATHS.home);
    dispatch(actions.auth.endImpersonationAction());
  };

  useEffect(() => {
    const isImpersonatingKey = config.LOCAL_STORAGE.isImpersonating;

    if (localStorage.hasOwnProperty(isImpersonatingKey)) {
      const impersonate = localStorage.getItem(isImpersonatingKey);
      setImpersonationInProgress(impersonate);
    } else setImpersonationInProgress(false);
  }, [isImpersonating, mounting]);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.contentContainer}>
        <Sidebar />
        <main className={styles.main}>{children}</main>

        {impersonationInProgress ? (
          <div className={styles.impersonationContainer}>
            <Button color="primary" onClick={handleEndImpersonation}>
              Atsijungti
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  isImpersonating: state.auth.isImpersonating,
  mounting: state.auth.mounting,
});

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func,
  auth: PropTypes.shape({
    token: PropTypes.string,
    firstItem: PropTypes.object,
    isImpersonating: PropTypes.bool,
    mounting: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(AuthLayout);
