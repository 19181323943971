import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './clients.module.scss';
import { Toggle, TitleSecondary, ButtonSimple, Input } from '../../components';
import { formatDate, formatMoney } from '../../utils';

const ClientInfo = ({
  client: { phone, virtual_balance },
  promotions,
  togglePromotions,
  deactivateMembershipOrder,
  topUpAmount,
  onTopUpAmountChanged,
  topUpClientWallet,
  openEditPlan,
  clientMemberships,
}) => {
  const info = [
    {
      title: 'Vartotojas',
      data: phone,
    },
    {
      title: 'Marketingas',
      data: (
        <Toggle
          onChange={togglePromotions}
          checked={promotions}
          size="sm"
          showMeta={true}
          onText="Sutinka"
          offText="Nesutinka"
        />
      ),
    },
    {
      title: 'Virtualios piniginės balansas',
      data: virtual_balance ? (
        <div>
          <span>{formatMoney(virtual_balance)}</span>
        </div>
      ) : (
        <div>
          <span>{formatMoney(0)}</span>
        </div>
      ),
    },
    {
      title: 'Papildyti piniginę (EUR)',
      data: (
        <div className={styles.topUpRow}>
          <Input
            name="topUpAmount"
            label="Papildyti pinigine"
            type="number"
            min="0.01"
            step="0.01"
            placeholder="0.00"
            onChange={e => onTopUpAmountChanged(e)}
            value={topUpAmount || ''}
            withLabel={false}
          />
          <ButtonSimple type="add" onClick={topUpClientWallet}>
            Pridėti
          </ButtonSimple>
        </div>
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <article className={styles.document}>
      <TitleSecondary>Kliento informacija</TitleSecondary>

      <div className={styles.info}>
        {renderInfo()}
        <div className={styles.membershipRow}>
          <span className={styles.membershipRowTitle}>Nuolaidų planai</span>
          <div>
            {
              <div>
                {clientMemberships?.map(
                  ({
                    id,
                    membership_title_lt,
                    valid_from,
                    valid_until,
                    status,
                  }) => {
                    const title = `${membership_title_lt} (${formatDate(
                      valid_from
                    )}-${formatDate(valid_until)})`;

                    const type = status === 552 ? 'remove' : 'edit';

                    const actionTitle =
                      type === 'remove' ? 'Atšaukti' : 'Redaguoti';

                    const action = () => {
                      if (type === 'remove')
                        return deactivateMembershipOrder(id);
                      return openEditPlan(id);
                    };

                    return (
                      <div className={styles.membershipItem} key={id}>
                        <span className={styles.membershipTitle}>{title}</span>
                        <ButtonSimple type={type} onClick={() => action()}>
                          {actionTitle}
                        </ButtonSimple>
                      </div>
                    );
                  }
                )}
                <div className={styles.addMembershipContainer}>
                  <ButtonSimple type="add" onClick={() => openEditPlan()}>
                    Pridėkite naują planą
                  </ButtonSimple>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </article>
  );
};

ClientInfo.defaultProps = {
  membership_title_lt: null,
  membership_order_id: null,
};

ClientInfo.propTypes = {
  client: PropTypes.shape({
    phone: PropTypes.string.isRequired,
    membership_title_lt: PropTypes.string,
    membership_order_id: PropTypes.number,
  }).isRequired,
  promotions: PropTypes.bool.isRequired,
  togglePromotions: PropTypes.func.isRequired,
  deactivateMembershipOrder: PropTypes.func.isRequired,
};

export default ClientInfo;
