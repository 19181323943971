import React, { useContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions } from '../../../state';
import { config } from '../../../api/config';

import { EmployeesContext } from './employees.context';
import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
  RangeFilter,
  Button,
} from '../../../components';

import {
  formatFilename,
  formatDate,
  formatMoney,
  mapUserStatus,
  mapUserStatusColor,
  isFilterActive,
  generateHeader,
  mapClientPromotions,
  mapClientCities,
  moneyToCents,
  mapEmployeeType,
  employeeIsCard,
} from '../../../utils';

const Employees = ({
  dispatch,
  employees: { employees, total, header, loading },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    typeFilter,
    setTypeFilter,
    phoneNumberFilter,
    setPhoneNumberFilter,
    employeeIdFilter,
    setEmployeeIdFilter,
    fullNameFilter,
    setFullNameFilter,
    monthlyLimitLtFilter,
    setMonthlyLimitLtFilter,
    monthlyLimitGtFilter,
    setMonthlyLimitGtFilter,
    statusFilter,
    setStatusFilter,
    remainderLtFilter,
    setRemainderLtFilter,
    remainderGtFilter,
    setRemainderGtFilter,
    sortOrder,
    setSortOrder,
    membershipFilter,
    setMembershipFilter,
  } = useContext(EmployeesContext);

  const fetchEmployees = useCallback(
    data => {
      dispatch(actions.employees.getEmployeesAction(data));
    },
    [dispatch]
  );

  const clearEmployees = useCallback(() => {
    dispatch(actions.employees.clearEmployeesAction());
  }, [dispatch]);

  useEffect(() => {
    fetchEmployees(filterBody);
    return () => clearEmployees();
  }, [fetchEmployees, clearEmployees, filterBody]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }

    if (phoneNumberFilter.length) {
      generatedFilter.push({ field: 'phone', value: phoneNumberFilter });
    }

    if (employeeIdFilter.length) {
      generatedFilter.push({ field: 'employee_id', value: employeeIdFilter });
    }

    if (membershipFilter.length) {
      generatedFilter.push({
        field: 'membership_title_lt',
        value: membershipFilter,
      });
    }

    if (fullNameFilter.length) {
      generatedFilter.push({
        field: 'full_name',
        value: fullNameFilter,
      });
    }

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }

    if (monthlyLimitLtFilter) {
      generatedFilter.push({
        field: 'credit_limit',
        expression: 'lte',
        value: moneyToCents(monthlyLimitLtFilter),
      });
    }

    if (monthlyLimitGtFilter) {
      generatedFilter.push({
        field: 'credit_limit',
        expression: 'gte',
        value: moneyToCents(monthlyLimitGtFilter),
      });
    }

    if (remainderLtFilter) {
      generatedFilter.push({
        field: 'remaining_credit',
        expression: 'lte',
        value: moneyToCents(remainderLtFilter),
      });
    }

    if (remainderGtFilter) {
      generatedFilter.push({
        field: 'remaining_credit',
        expression: 'gte',
        value: moneyToCents(remainderGtFilter),
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setTypeFilter([]);
    setPhoneNumberFilter([]);
    setEmployeeIdFilter([]);
    setFullNameFilter([]);
    setMonthlyLimitLtFilter();
    setMonthlyLimitGtFilter();
    setStatusFilter([]);
    setRemainderLtFilter();
    setRemainderGtFilter();

    handleSubmit();
  };

  const getMembershipPlanHeaderFilterArr = () =>
    generateHeader([
      ...new Set(
        employees
          .map(employee => employee?.membership_title_lt)
          ?.filter(Boolean)
      ),
    ]);

  const tableHeader = [
    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapEmployeeType)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    <SelectFilter
      title="Numeris"
      name="phone"
      valueType="string"
      array={generateHeader(header.phone)}
      filterState={phoneNumberFilter}
      setFilterState={setPhoneNumberFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', phoneNumberFilter)}
    />,
    <SelectFilter
      title="Darbuotojas"
      name="full_name"
      valueType="string"
      array={generateHeader(header.full_name)}
      filterState={fullNameFilter}
      setFilterState={setFullNameFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', fullNameFilter)}
    />,
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapUserStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <RangeFilter
      title="Mėnesio Limitas"
      name="credit_limit"
      lt={monthlyLimitLtFilter}
      setLt={setMonthlyLimitLtFilter}
      gt={monthlyLimitGtFilter}
      setGt={setMonthlyLimitGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive(
        'range',
        monthlyLimitLtFilter,
        monthlyLimitGtFilter
      )}
    />,
    <RangeFilter
      title="Likutis"
      name="remaining_credit"
      lt={remainderLtFilter}
      setLt={setRemainderLtFilter}
      gt={remainderGtFilter}
      setGt={setRemainderGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', remainderLtFilter, remainderGtFilter)}
    />,
    <SelectFilter
      title="Narystės planas"
      name="membership_title_lt"
      valueType="string"
      array={getMembershipPlanHeaderFilterArr()}
      filterState={membershipFilter}
      setFilterState={setMembershipFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', membershipFilter)}
    />,
  ];

  const renderTable = () =>
    employees.map(
      ({
        id,
        type,
        phone,
        employee_id,
        full_name,
        status,
        credit_limit,
        remaining_credit,
        membership_title_lt,
      }) => (
        <TableRow
          url={
            employeeIsCard({ type })
              ? config.APP_PATHS.editH2Card(id)
              : config.APP_PATHS.editEmployee(id)
          }
          key={id}
        >
          <TableCell>{mapEmployeeType(type)}</TableCell>
          <TableCell>{phone}</TableCell>
          <TableCell>{full_name}</TableCell>
          <TableCell>
            <span style={{ color: mapUserStatusColor(status) }}>
              {mapUserStatus(status)}
            </span>
          </TableCell>
          <TableCell>{formatMoney(credit_limit)}</TableCell>
          <TableCell>{formatMoney(remaining_credit)}</TableCell>
          <TableCell>{membership_title_lt}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.baEmployees);
    dispatch(actions.employees.exportEmployeesAction(filename, filterBody));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.baEmployees}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}

          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
          <Button color="primary" url={config.APP_PATHS.addEmployee}>
            Pridėti
          </Button>
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        isEmpty={!employees.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  employees: state.employees,
  xlsLoading: state.download.xlsLoading,
});

Employees.propTypes = {
  dispatch: PropTypes.func.isRequired,
  employees: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    employees: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      full_name: PropTypes.arrayOf(PropTypes.string),
      phone: PropTypes.arrayOf(PropTypes.string),
      employee_id: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Employees);
