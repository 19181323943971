import { constants } from '..';

const setInvoicesLoadingAction = flag => ({
  type: constants.invoices.SET_INVOICES_LOADING,
  flag,
});

const getInvoicesAction = body => ({
  type: constants.invoices.GET_INVOICES,
  body,
});

const setInvoicesAction = (invoices, count, header, footer) => ({
  type: constants.invoices.SET_INVOICES,
  invoices,
  count,
  header,
  footer,
});

const clearInvoicesAction = () => ({
  type: constants.invoices.CLEAR_INVOICES,
});

const getInvoiceAction = id => ({
  type: constants.invoices.GET_INVOICE,
  id,
});

const setInvoiceAction = invoice => ({
  type: constants.invoices.SET_INVOICE,
  invoice,
});

const setExportIdentifierAction = identifier => ({
  type: constants.invoices.SET_INVOICE_EXPORT_IDENTIFIER,
  identifier,
});

const exportInvoicesAction = (filename, body) => ({
  type: constants.invoices.EXPORT_INVOICES,
  filename,
  body,
});

const exportInvoicesAsyncAction = filterBody => ({
  type: constants.invoices.EXPORT_INVOICES_ASYNC,
  filter: filterBody,
});

const exportInvoicesHeadsAction = (filename, body) => ({
  type: constants.invoices.EXPORT_INVOICES_HEADS,
  filename,
  body,
});

const exportInvoicesBodiesAction = (filename, body) => ({
  type: constants.invoices.EXPORT_INVOICES_BODIES,
  filename,
  body,
});

const exportInvoicesPartnersAction = (filename, body) => ({
  type: constants.invoices.EXPORT_INVOICES_PARTNERS,
  filename,
  body,
});

const exportInvoicesXmlAction = (filename, body) => ({
  type: constants.invoices.EXPORT_INVOICES_XML,
  filename,
  body,
});

const generateMonthlyInvoicesAction = () => ({
  type: constants.invoices.GENERATE_MONTHLY_INVOICES,
});

const setMonthlyGeneratingAction = flag => ({
  type: constants.invoices.SET_MONTHLY_GENERATING,
  flag,
});

const updateInvoicePaymentStatusAction = (id, newStatus) => ({
  type: constants.invoices.UPDATE_PAYMENT_STATUS,
  id,
  newStatus,
});

const updateInvoiceAction = (id, body) => ({
  type: constants.invoices.UPDATE_INVOICE,
  id,
  body,
});

const downloadInvoicePdf = (id, invoiceNumber) => ({
  type: constants.invoices.DOWNLOAD_PDF,
  id,
  invoiceNumber,
});

export const invoicesActions = {
  setInvoicesLoadingAction,
  getInvoicesAction,
  setInvoicesAction,
  clearInvoicesAction,
  getInvoiceAction,
  setInvoiceAction,
  exportInvoicesAction,
  exportInvoicesAsyncAction,
  exportInvoicesHeadsAction,
  exportInvoicesBodiesAction,
  exportInvoicesPartnersAction,
  exportInvoicesXmlAction,
  generateMonthlyInvoicesAction,
  setMonthlyGeneratingAction,
  setExportIdentifierAction,
  updateInvoicePaymentStatusAction,
  downloadInvoicePdf,
  updateInvoiceAction,
};
