import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import print from 'print-js';

import styles from './receipts.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import {
  WithSpinner,
  Spinner,
  Header,
  Breadcrumb,
  Paper,
  PaperNav,
  PaperContainer,
  Button,
  ButtonLink,
} from '../../components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ReceiptDetails = ({
  dispatch,
  match: { params },
  receipts: { selectedReceipt, loading },
}) => {
  const fetchReceipt = useCallback(
    id => {
      dispatch(actions.receipts.getReceiptAction(id));
    },
    [dispatch]
  );

  const clearReceipts = useCallback(() => {
    dispatch(actions.receipts.clearReceiptsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchReceipt(params.id);
    return () => clearReceipts();
  }, [fetchReceipt, clearReceipts, params.id]);

  const handlePdfDownload = () => {
    dispatch(
      actions.receipts.downloadReceiptPdf(
        selectedReceipt.id,
        selectedReceipt.invoice_number
      )
    );
  };

  return (
    <section>
      <Header>
        <Breadcrumb
          title={config.PAGES.receipts}
          subtitle="Kvito informacija"
          baseUrl={config.APP_PATHS.receipts}
        />
        <Button color="outline" icon="download" onClick={handlePdfDownload}>
          Atsisiųsti
        </Button>
      </Header>
      <WithSpinner loading={loading}>
        <PaperContainer>
          <Paper>
            <div className={styles.pdfContainer}>
              {selectedReceipt.pdf && (
                <Document
                  file={`data:application/pdf;base64,${selectedReceipt.pdf}`}
                  error="Klaida! Nepavyko užkrauti .pdf dokumento"
                  noData="Dokumentas nerastas"
                  loading={<Spinner />}
                >
                  <Page pageNumber={1} />
                </Document>
              )}
            </div>
          </Paper>
          {!isEmpty(selectedReceipt) && (
            <PaperNav>
              <ButtonLink
                icon="nav-orders"
                url={
                  !!selectedReceipt.order_id
                    ? config.APP_PATHS.orderDetails(selectedReceipt.order_id)
                    : config.APP_PATHS.membershipOrderDetails(
                        selectedReceipt.membership_order_id
                      )
                }
              >
                {!!selectedReceipt.order_id ? 'Užsakymas' : 'Paso užsakymas'}
              </ButtonLink>
              <ButtonLink
                icon="nav-clients"
                url={config.APP_PATHS.editClient(selectedReceipt.client.id)}
              >
                {selectedReceipt.client.phone}
              </ButtonLink>
            </PaperNav>
          )}
        </PaperContainer>
      </WithSpinner>
    </section>
  );
};

const mapStateToProps = state => ({
  receipts: state.receipts,
});

ReceiptDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  receipts: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedReceipt: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(ReceiptDetails);
