import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  invoices: [],
  total: 0,
  header: {},
  footer: {},
  selectedInvoice: {},
  generatingMonthly: false,
  invoiceExport: {},
};

export const invoicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.invoices.SET_INVOICES_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.invoices.SET_INVOICES:
      return {
        ...state,
        invoices: action.invoices,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.invoices.SET_INVOICE:
      return {
        ...state,
        selectedInvoice: action.invoice,
      };
    case constants.invoices.CLEAR_INVOICES:
      return {
        ...INITIAL_STATE,
      };
    case constants.invoices.SET_MONTHLY_GENERATING:
      return {
        ...state,
        generatingMonthly: action.flag,
      };
    case constants.invoices.SET_INVOICE_EXPORT_IDENTIFIER:
      return {
        ...state,
        invoiceExport: action.identifier,
      };
    default:
      return state;
  }
};
