import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './memberships.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import {
  Header,
  Breadcrumb,
  Paper,
  PaperHeader,
  TitleSecondary,
  Input,
  SubmitActions,
  Toggle,
  Select,
  Title,
} from '../../components';
import History from './memberships.history.component';
import {
  MembershipTypes,
  MembershipTypesTitles,
} from '../../utils/global/enums';
import LabelElement from '../../components/form/_elements/labelElement/labelElement';

const membershipTypes = [
  {
    name: MembershipTypesTitles.MAIN,
    value: MembershipTypes.MAIN,
  },
  {
    name: MembershipTypesTitles.SHADOW,
    value: MembershipTypes.SHADOW,
  },
];

const titleLimit = 30;
const descriptionLimit = 255;
const colorCodeLimit = 30;

const EditMembership = ({
  dispatch,
  history,
  match: { params },
  memberships: { selectedMembership, loading },
}) => {
  const [values, setValues] = useState({
    title_lt: '',
    title_en: '',
    title_ru: '',
    description_lt: '',
    description_en: '',
    description_ru: '',
    type: MembershipTypes.MAIN,
    color: '',
  });
  const [status, setStatus] = useState(false);

  const fetchMembership = useCallback(
    id => {
      dispatch(actions.memberships.getMembershipAction(id));
    },
    [dispatch]
  );

  const fetchServices = useCallback(
    data => {
      dispatch(actions.services.getServicesAction(data));
    },
    [dispatch]
  );

  const clearServices = useCallback(() => {
    dispatch(actions.services.clearServicesAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMembership(params.id);
    fetchServices();
    return () => {
      clearMemberships();
      clearServices();
    };
  }, [
    fetchMembership,
    params.id,
    clearMemberships,
    fetchServices,
    clearServices,
  ]);

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedMembership)) {
      const {
        title_lt,
        title_en,
        title_ru,
        status,
        description_lt,
        description_en,
        description_ru,
        color,
        type,
      } = selectedMembership;

      setValues({
        title_lt,
        title_en,
        title_ru,
        description_lt: description_lt ?? '',
        description_en: description_en ?? '',
        description_ru: description_ru ?? '',
        color: color ?? '',
        type,
      });
      setStatus(status === 1 ? true : false);
    }
  }, [selectedMembership]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const handleSubmit = e => {
    e.preventDefault();
    const body = {
      ...values,
    };

    dispatch(
      actions.memberships.editMembershipAction(params.id, body, history)
    );
  };

  const handleInputChange = ({ target: { name, value } }, limits) => {
    const canBeSaved =
      !limits || Number.isNaN(+limits) || value.toString()?.length <= limits;
    if (canBeSaved) {
      const modifiedValue = name === 'type' ? Number(value) : value;
      setValues({ ...values, [name]: modifiedValue });
    }
  };

  const toggleStatus = () => {
    const newStatus = status ? 0 : 1;
    setStatus(status => !status);

    dispatch(
      actions.memberships.deactivateMembershipAction(params.id, newStatus)
    );
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.memberships}
          subtitle="Redaguoti nuolaidų planą"
          baseUrl={config.APP_PATHS.memberships}
        />
        <Toggle
          onChange={toggleStatus}
          checked={status}
          showMeta={true}
          onText="Įjungtas"
          offText="Išjungtas"
        />
      </Header>

      <Paper>
        <PaperHeader>
          <Title total="">Nuolaidų plano informacija</Title>
        </PaperHeader>
        <form onSubmit={handleSubmit} method="POST">
          <div className={styles.contentSection}>
            <TitleSecondary>Tipas</TitleSecondary>
            <div className={styles.membershipTypeSelect}>
              <div className={styles.labelContainer}>
                <LabelElement label="Pasirinkite tipą" htmlFor="type" />
              </div>
              <Select
                name="type"
                disabled
                options={membershipTypes}
                selectedValue={values.type}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.contentSection}>
            <TitleSecondary>Bendra informacija</TitleSecondary>

            <div className={styles.formdataVertical}>
              <Input
                name="title_lt"
                label="Pavadinimas"
                placeholder="Pavadinimas (LT)"
                icon="flag-lt"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_lt}
                required
              />
              <Input
                name="description_lt"
                label="Aprašymas"
                placeholder="Aprašymas (LT)"
                icon="flag-lt"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_lt}
                required
              />
              <Input
                name="title_en"
                label="Pavadinimas"
                placeholder="Pavadinimas (EN)"
                icon="flag-en"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_en}
                required
              />
              <Input
                name="description_en"
                label="Aprašymas"
                placeholder="Aprašymas (EN)"
                icon="flag-en"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_en}
                required
              />
              <Input
                name="title_ru"
                label="Pavadinimas"
                placeholder="Pavadinimas (RU)"
                icon="flag-ru"
                type="text"
                onChange={e => handleInputChange(e, titleLimit)}
                value={values.title_ru}
                required
              />
              <Input
                name="description_ru"
                label="Aprašymas"
                placeholder="Aprašymas (RU)"
                icon="flag-ru"
                type="text"
                onChange={e => handleInputChange(e, descriptionLimit)}
                value={values.description_ru}
                required
              />
              <Input
                name="color"
                label="Spalvos kodas"
                placeholder="Spalvos kodas"
                type="text"
                onChange={e => handleInputChange(e, colorCodeLimit)}
                value={values.color}
                required
              />
              <div />
            </div>
          </div>

          <SubmitActions
            back={config.APP_PATHS.memberships}
            loading={loading}
          />
        </form>
      </Paper>

      {!isEmpty(selectedMembership) && (
        <History
          id={selectedMembership.id}
          history={selectedMembership.history}
          membershipType={selectedMembership.type}
        />
      )}
    </section>
  );
};

const mapStateToProps = state => ({
  memberships: state.memberships,
});

EditMembership.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  memberships: PropTypes.shape({
    selectedMembership: PropTypes.shape({
      id: PropTypes.number,
      title_en: PropTypes.string,
      title_lt: PropTypes.string,
      title_ru: PropTypes.string,
      created_at: PropTypes.number,
      status: PropTypes.number,
      type: PropTypes.number,
      history: PropTypes.array,
      current_price: PropTypes.number,
      current_discount: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditMembership);
