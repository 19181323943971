import React, { useState, useMemo, useEffect } from 'react';
import { format } from 'date-fns';
import { config } from '../../api/config';
import { Button, Input, Select } from '../../components';
import { actions } from '../../state';
import styles from './businessClients.module.scss';
import { formatDate, getTimestamp } from '../../utils';
import inputStyles from '../../components/form/_elements/inputElement/inputElement.module.scss';
import DatePicker from 'react-datepicker';
import { getTime } from 'date-fns';

const invoicingPeriod = [
  { name: 'Kas mėnesį', value: '561' },
  { name: 'Kasmet', value: '562' },
];

const EditMembership = props => {
  const {
    businessClientId,
    memberships,
    dispatch,
    selectedMembership = null,
  } = props;

  const membershipsOptions = useMemo(
    () =>
      memberships.map(membership => ({
        name: membership.title_lt,
        value: membership.id,
      })),
    [memberships]
  );

  const [values, setValues] = useState({
    valid_from: new Date(selectedMembership?.valid_from || Date.now()),
    valid_until:
      selectedMembership && selectedMembership.valid_until
        ? new Date(selectedMembership.valid_until)
        : '',
    membership_id:
      selectedMembership?.membership_id || membershipsOptions[0].value,
    license_count: selectedMembership?.license_count || 1,
    invoicing_period: selectedMembership?.invoicing_period || '561',
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setValues(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleDatePickerChange = (date, name) => {
    const timestamp = getTime(date);
    setValues(prev => {
      return { ...prev, [name]: timestamp };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const body = {
      membership_id: selectedMembership
        ? undefined
        : Number(values.membership_id),
      invoicing_period: selectedMembership
        ? undefined
        : Number(values.invoicing_period),
      license_count: selectedMembership
        ? undefined
        : Number(values.license_count),
      valid_from: selectedMembership ? undefined : values.valid_from,
      valid_until: values.valid_until ? values.valid_until : undefined,
    };

    if (selectedMembership) {
      dispatch(
        actions.businessClients.updateMembershipBusinessClientAction(
          businessClientId,
          selectedMembership.id,
          body
        )
      );
      return;
    }

    dispatch(
      actions.businessClients.addMembershipBusinessClientAction(
        businessClientId,
        body
      )
    );
  };

  const info = [
    {
      title: 'Nuolaidos planas',
      data: (
        <Select
          name="membership_id"
          options={membershipsOptions}
          selectedValue={values.membership_id}
          onChange={handleInputChange}
          disabled={
            selectedMembership !== null && selectedMembership !== undefined
          }
        />
      ),
    },
    {
      title: 'Sąskaitų faktūrų išrašymo laikotarpis',
      data: (
        <Select
          name="invoicing_period"
          options={invoicingPeriod}
          selectedValue={values.invoicing_period}
          onChange={handleInputChange}
          disabled={
            selectedMembership !== null && selectedMembership !== undefined
          }
        />
      ),
    },
    {
      title: 'Licencijų skaičius (vnt.)',
      data: (
        <Input
          name="license_count"
          withLabel={false}
          placeholder="1"
          type="number"
          min="1"
          step="1"
          onChange={handleInputChange}
          value={values.license_count}
          disabled={
            selectedMembership !== null && selectedMembership !== undefined
          }
        />
      ),
    },
    {
      title: 'Galioja nuo',
      data: (
        <DatePicker
          className={inputStyles.input}
          selected={values.valid_from}
          onChange={date => handleDatePickerChange(date, 'valid_from')}
          minDate={Date.now()}
          maxDate={new Date('2999-12-31')}
          showYearDropdown
          placeholderText="Nuo"
          yearDropdownItemNumber={2}
          popperPlacement="top-start"
          dateFormat={config.DEFAULTS.dateFormat}
          disabled={
            selectedMembership !== null && selectedMembership !== undefined
          }
        />
      ),
    },
    {
      title: 'Galioja iki',
      data: (
        <DatePicker
          className={inputStyles.input}
          selected={values.valid_until}
          minDate={values.valid_from ? new Date(values.valid_from) : undefined}
          maxDate={new Date('2999-12-31')}
          onChange={date => handleDatePickerChange(date, 'valid_until')}
          showYearDropdown
          placeholderText="Iki"
          yearDropdownItemNumber={2}
          popperPlacement="top-start"
          dateFormat={config.DEFAULTS.dateFormat}
        />
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, hide, data }) =>
      hide ? (
        <></>
      ) : (
        <div className={styles.infoRow} key={title}>
          <span className="title">{title}</span>
          <span className="height free-width">{data}</span>
        </div>
      )
    );
  return (
    <form onSubmit={handleSubmit} method="POST">
      <div className={styles.info}>{renderInfo()}</div>

      <Button
        color="primary"
        type="submit"
        classes={{ button: styles.saveMembership }}
      >
        Išsaugoti
      </Button>
    </form>
  );
};

export default EditMembership;
