import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './breadcrumb.module.scss';
import Icon from '../icon/icon';

const Breadcrumb = ({ title, subtitle, baseUrl }) => {
  return (
    <ul className={styles.list}>
      <li>
        <Link to={baseUrl} className={styles.title}>
          {title}
        </Link>
      </li>
      {subtitle && (
        <li className={styles.subtitle}>
          <Icon name="chevron-right" classes={{ icon: styles.icon }} />
          {subtitle}
        </li>
      )}
    </ul>
  );
};

Breadcrumb.defaultProps = {
  subtitle: null,
};

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default Breadcrumb;
