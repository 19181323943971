import React from 'react';
import { connect } from 'react-redux';

import { Title, TitleSecondary } from '../../components';

const Welcome = ({ user: { first_name, last_name, email } }) => {
  return (
    <div>
      <TitleSecondary>Prisijungėte kaip:</TitleSecondary>
      <Title total={email}>
        {first_name} {last_name}
      </Title>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Welcome);
