import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import { isEmpty, discountGroups } from '../../utils';
import {
  Header,
  Breadcrumb,
  ButtonSimple,
  Paper,
  PaperHeader,
  TitleSecondary,
} from '../../components';
import Form from './discounts.form';
import { validationErrors } from './discounts.validation';

const BANNER_ADVERTISEMENT_TYPE = 651;
const BANNER_SIMPLE_TYPE = 652;

const EditDiscount = ({
  dispatch,
  history,
  match: { params },
  discounts: { selectedDiscount, loading },
}) => {
  const [values, setValues] = useState({
    code: '',
    discount: '',
    description_lt: '',
    description_en: '',
    description_ru: '',
    dateFrom: format(Date.now(), 'yyyy-MM-dd'),
    dateTo: format(Date.now(), 'yyyy-MM-dd'),
    timeFrom: '',
    timeTo: '',
    createDiscountBanner: false,
    discountBannerIsAdvertisement: false,
    announcement: {
      type: '',
      sendDate: '',
      sendTime: '',
      title_lt: '',
      title_en: '',
      title_ru: '',
      description_lt: '',
      description_en: '',
      description_ru: '',
      app_notification: false,
      banner_id: 0,
    },
  });
  const [locations, setLocations] = useState([]);
  const [services, setServices] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [groups, setGroups] = useState([]);

  const fetchDiscount = useCallback(
    id => {
      dispatch(actions.discounts.getDiscountAction(id));
    },
    [dispatch]
  );

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedDiscount)) {
      const {
        code,
        discount,
        description_lt,
        description_en,
        description_ru,
        date_interval,
        time_interval,
        service_types,
        client_groups,
        week_days,
        announcement,
        locations: loadedLocations,
      } = selectedDiscount;

      const groupIsAll =
        client_groups.length === 1 && client_groups.includes(181);

      const sendDate =
        announcement && announcement.app_notification
          ? announcement.app_notification_send_at.substring(0, 10)
          : null;
      const sendTime =
        announcement && announcement.app_notification
          ? new Date(announcement.app_notification_send_at)
              .toLocaleTimeString(config.DEFAULTS.locale)
              .substring(0, 5)
          : '';
      const bannerType =
        announcement && announcement.type
          ? announcement.type
          : BANNER_SIMPLE_TYPE;
      if (announcement) {
        dispatch(
          actions.discounts.setDiscountBannerAction(announcement.banner)
        );
      }

      setValues({
        code: code,
        discount: discount,
        description_lt: description_lt,
        description_en: description_en,
        description_ru: description_ru,
        dateFrom: format(date_interval[0], 'yyyy-MM-dd'),
        dateTo: format(date_interval[1], 'yyyy-MM-dd'),
        timeFrom: time_interval ? time_interval[0].split(' ')[0] : '',
        timeTo: time_interval ? time_interval[1].split(' ')[0] : '',
        createDiscountBanner: announcement !== null,
        discountBannerIsAdvertisement:
          announcement && announcement.type == BANNER_ADVERTISEMENT_TYPE,
        announcement:
          announcement !== null
            ? {
                type: bannerType,
                sendDate: sendDate,
                sendTime: sendTime,
                title_lt: announcement ? announcement.title_lt : '',
                title_en: announcement ? announcement.title_en : '',
                title_ru: announcement ? announcement.title_ru : '',
                description_lt: announcement ? announcement.description_lt : '',
                description_en: announcement ? announcement.description_en : '',
                description_ru: announcement ? announcement.description_ru : '',
                app_notification: announcement
                  ? announcement.app_notification
                  : false,
                banner: announcement ? announcement.banner : null,
              }
            : null,
      });
      setLocations(loadedLocations ? loadedLocations : []);
      setServices(service_types);
      setWeekdays(week_days);
      setGroups(groupIsAll ? [] : client_groups);
    }
  }, [selectedDiscount]);

  const clearDiscounts = useCallback(() => {
    dispatch(actions.discounts.clearDiscountsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchDiscount(params.id);
    return () => clearDiscounts();
  }, [fetchDiscount, clearDiscounts, params.id]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const handleInputChange = ({ target: { name, value } }) => {
    if (name.includes('.')) {
      const splitName = name.split('.');
      setValues({
        ...values,
        announcement: { ...values.announcement, [splitName[1]]: value },
      });
    } else setValues({ ...values, [name]: value });
  };

  const openModal = modalName => {
    const payload = {
      modalName,
      title: 'Deaktyvuoti',
      desc: 'Ar tikrai norite deaktyvuoti šį kodą?',
      onConfirm: () =>
        dispatch(
          actions.discounts.deactivateDiscountAction(params.id, history)
        ),
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const error = validationErrors(values);

    if (error) {
      dispatch(actions.error.setErrorAction({ msg: error }));
    } else {
      const codeToSubmit = values.code;
      const descriptionLtToSubmit = values.description_lt;
      const descriptionEnToSubmit = values.description_en;
      const descriptionRuToSubmit = values.description_ru;
      const discountToSubmit = parseInt(values.discount);
      const dateFrom = new Date(values.dateFrom).toISOString();
      const dateTo = new Date(values.dateTo).toISOString();
      const dateIntervalToSubmit = [dateFrom, dateTo];
      const locationsToSubmit = locations.length ? locations : null;
      const servicesToSubmit = services.length ? services : null;
      const weekdaysToSubmit = weekdays.length ? weekdays : null;
      const groupsToSubmit =
        groups.length && groups.length !== discountGroups.length
          ? groups
          : [181];
      const timeToSubmit =
        values.timeFrom === values.timeTo
          ? null
          : [values.timeFrom, values.timeTo];

      const createDiscountBannerToSubmit = values.createDiscountBanner;

      let announcementToSubmit = {};
      if (createDiscountBannerToSubmit) {
        announcementToSubmit.app_notification =
          values.announcement.app_notification;
        announcementToSubmit.title_lt = values.announcement.title_lt;
        announcementToSubmit.title_en = values.announcement.title_en;
        announcementToSubmit.title_ru = values.announcement.title_ru;
        announcementToSubmit.description_lt =
          values.announcement.description_lt;
        announcementToSubmit.description_en =
          values.announcement.description_en;
        announcementToSubmit.description_ru =
          values.announcement.description_ru;
        announcementToSubmit.app_notification_send_at =
          values.createDiscountBanner && values.announcement.app_notification
            ? new Date(
                values.announcement.sendDate +
                  ' ' +
                  values.announcement.sendTime
              ).toISOString()
            : null;

        announcementToSubmit.type = values.announcement.type;
      }

      const body = {
        code: codeToSubmit,
        discount: discountToSubmit,
        description_lt: descriptionLtToSubmit,
        description_en: descriptionEnToSubmit,
        description_ru: descriptionRuToSubmit,
        date_interval: dateIntervalToSubmit,
        service_types: servicesToSubmit,
        client_groups: groupsToSubmit,
        locations: locationsToSubmit,
        time_interval: timeToSubmit,
        week_days: weekdaysToSubmit,
        createDiscountBanner: createDiscountBannerToSubmit,
        announcement: createDiscountBannerToSubmit
          ? announcementToSubmit
          : null,
      };

      dispatch(actions.discounts.editDiscountAction(params.id, body, history));
    }
  };

  const setCreateDiscountBanner = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;
    setValues({
      ...values,
      createDiscountBanner: newValue,
      announcement: values.announcement
        ? {
            app_notification: newValue
              ? values.announcement.app_notification
              : false,
            title_lt: newValue ? values.announcement.title_lt : '',
            title_en: newValue ? values.announcement.title_en : '',
            title_ru: newValue ? values.announcement.title_ru : '',
            description_lt: newValue ? values.announcement.description_lt : '',
            description_en: newValue ? values.announcement.description_en : '',
            description_ru: newValue ? values.announcement.description_ru : '',
            banner_id: newValue ? values.announcement.banner_id : 0,
            sendDate: newValue ? values.announcement.sendDate : '',
            sendTime: newValue ? values.announcement.sendTime : '',
          }
        : {
            app_notification: newValue,
            title_lt: '',
            title_en: '',
            title_ru: '',
            description_lt: '',
            description_en: '',
            description_ru: '',
            banner_id: 0,
            sendDate: '',
            sendTime: '',
          },
    });
  };

  const setDiscountBannerIsAdvertisement = e => {
    const newValue = e.currentTarget.value === 'true' ? false : true;

    setValues({
      ...values,
      discountBannerIsAdvertisement: newValue,
      announcement: {
        ...values.announcement,
        type: newValue ? BANNER_ADVERTISEMENT_TYPE : BANNER_SIMPLE_TYPE,
      },
    });
  };

  const setSendPushNotifications = e => {
    setValues({
      ...values,
      announcement: {
        ...values.announcement,
        app_notification: e.currentTarget.value === 'true' ? false : true,
      },
    });
  };

  return (
    <section>
      <Header size="sm">
        <Breadcrumb
          title={config.PAGES.discounts}
          subtitle={selectedDiscount.code}
          baseUrl={config.APP_PATHS.discounts}
        />
        {!loading && selectedDiscount.status !== 174 && (
          <ButtonSimple type="remove" onClick={() => openModal('confirmModal')}>
            Deaktyvuoti
          </ButtonSimple>
        )}
      </Header>

      <Paper size="sm">
        <PaperHeader>
          <TitleSecondary>Redaguoti nuolaidą</TitleSecondary>
        </PaperHeader>
        <Form
          values={values}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          loading={loading}
          weekdays={weekdays}
          setWeekdays={setWeekdays}
          services={services}
          setServices={setServices}
          locations={locations}
          setLocations={setLocations}
          groups={groups}
          setGroups={setGroups}
          setCreateDiscountBanner={setCreateDiscountBanner}
          setDiscountBannerIsAdvertisement={setDiscountBannerIsAdvertisement}
          setSendPushNotifications={setSendPushNotifications}
        />
      </Paper>
    </section>
  );
};

const mapStateToProps = state => ({
  discounts: state.discounts,
});

EditDiscount.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  discounts: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedDiscount: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      discount: PropTypes.number,
      deactivated_at: PropTypes.number,
      service_types: PropTypes.arrayOf(PropTypes.number),
      admin_id: PropTypes.number,
      locations: PropTypes.array,
      time_interval: PropTypes.arrayOf(PropTypes.string),
      date_interval: PropTypes.arrayOf(PropTypes.number),
      status: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditDiscount);
