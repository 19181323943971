import { config } from '../../api/config';

export const availablePermissions = [
  { name: config.PAGES.admins, value: '1001,1002' },
  { name: config.PAGES.locations, value: '1015,1016' },
  { name: config.PAGES.orders, value: '1013,1014' },
  { name: config.PAGES.clients, value: '1003,1004' },
  { name: config.PAGES.invoices, value: '1005,1006' },
  { name: config.PAGES.receipts, value: '1017,1018' },
  { name: config.PAGES.membershipOrders, value: '1007,1008' },
  { name: config.PAGES.memberships, value: '1009,1010' },
  { name: config.PAGES.discounts, value: '1011,1012' },
  { name: config.PAGES.businessClients, value: '1019,1020' },
  { name: config.PAGES.textile, value: '1021,1022' },
  { name: config.PAGES.coupons, value: '1023,1024' },
];

export const businessRoles = [
  {
    name: 'Nepasirinkta',
    value: 0,
  },
  {
    name: config.BUSINESS_ROLES.MANAGER.name,
    value: config.BUSINESS_ROLES.MANAGER.value,
  },
];
