import { constants } from '..';

const INITIAL_STATE = {
  services: [],
  total: 0,
  header: {},
  footer: {},
};

export const servicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.services.SET_SERVICES:
      return {
        ...state,
        services: action.services,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.services.CLEAR_SERVICES:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
