import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './paper.module.scss';

const Paper = ({ children, size }) => {
  return <div className={cn(styles.paper, styles[size])}>{children}</div>;
};

export const PaperContainer = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export const PaperHeader = ({ children }) => (
  <div className={styles.header}>{children}</div>
);

export const PaperNav = ({ children }) => (
  <nav className={styles.nav}>{children}</nav>
);

export const PaperNote = ({ children }) => (
  <div className={styles.note}>{children}</div>
);

Paper.defaultProps = {
  size: 'sm',
};

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'lg']),
};

PaperContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

PaperHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

PaperNav.propTypes = {
  children: PropTypes.node.isRequired,
};

PaperNote.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Paper;
