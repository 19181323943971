import React, { createContext, useState } from 'react';

export const ServicesContext = createContext();

export const ServicesProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [typeFilter, setTypeFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [costLtFilter, setCostLtFilter] = useState('');
  const [costGtFilter, setCostGtFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <ServicesContext.Provider
      value={{
        filterBody,
        setFilterBody,
        typeFilter,
        setTypeFilter,
        locationFilter,
        setLocationFilter,
        statusFilter,
        setStatusFilter,
        costLtFilter,
        setCostLtFilter,
        costGtFilter,
        setCostGtFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
