import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './memberships.module.scss';
import { Button, Checkbox, Input, Select } from '../../components';
import LabelElement from '../../components/form/_elements/labelElement/labelElement';

const resetPeriodOptions = [
  { name: 'Kasdien', value: '51' },
  { name: 'Kas savaitę', value: '52' },
  { name: 'Kas mėnesį', value: '53' },
];

const typeOptions = [
  { name: '%', value: '41' },
  { name: 'Suma', value: '42' },
];

const Benefit = ({
  benefit,
  services,
  onChange,
  addNewClickHandler,
  removeClickHandler,
}) => (
  <div>
    <div className={cn(styles.flexWrapper, 'top')}>
      <div className={styles.flexItem}>
        <div>
          <div className={styles.labelContainer}>
            <LabelElement label="Paslaugos tipas" htmlFor="service_type" />
          </div>
          <Select
            name="service_type"
            options={services}
            selectedValue={benefit.service_type}
            onChange={onChange}
          />
        </div>
      </div>
      <div className={styles.flexItem}>
        <div className={styles.labelContainer}>
          <LabelElement label="Nuolaidos tipas" htmlFor="type" />
        </div>
        <Select
          name="type"
          options={typeOptions}
          selectedValue={benefit.type}
          onChange={onChange}
        />

        <div>
          <Input
            name="amount"
            label={Number(benefit.type) === 41 ? 'Nuolaida (%)' : 'Suma (€)'}
            placeholder="0"
            type="number"
            min="0"
            max="100"
            step="1"
            onChange={onChange}
            value={benefit.amount}
          />
        </div>
        {Number(benefit.type) === 42 && (
          <div>
            <div className={styles.labelContainer}>
              <LabelElement
                label="Iš naujo nustatyti laikotarpį"
                htmlFor="reset_period"
              />
            </div>
            <Select
              name="reset_period"
              options={resetPeriodOptions}
              selectedValue={benefit.reset_period ?? 51}
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </div>
    <div className={styles.formCheckbox}>
      <Checkbox
        id="hidden"
        name="hidden"
        label="paslėpti tekstą programoje mobiliesiems"
        value={benefit.hidden || false}
        onChange={onChange}
        checked={benefit.hidden || false}
      />
    </div>
    <div className={styles.actionsWrapper}>
      <Button
        color="light"
        type="button"
        onClick={removeClickHandler}
        disabled={!removeClickHandler}
      >
        Pašalinti
      </Button>
      {addNewClickHandler && (
        <Button
          color="primary"
          type="button"
          disabled={!benefit.amount}
          onClick={addNewClickHandler}
        >
          Pridėkite dar vieną
        </Button>
      )}
    </div>
  </div>
);

Benefit.propTypes = {
  benefit: PropTypes.shape({
    type: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    service_type: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
    amount: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Benefit;
