import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { config } from './api/config';

import './style/index.scss';
import { actions } from './state';
import Routes from './routes';

const App = ({ dispatch, mounting, impersonationToken }) => {
  useEffect(() => {
    // eslint-disable-next-line
    const { isImpersonating } = config.LOCAL_STORAGE;
    if (!mounting) {
      if (localStorage.hasOwnProperty(isImpersonating)) {
        const impersonate = localStorage.getItem(isImpersonating);

        if (impersonate) {
          dispatch(actions.auth.mountUserOnImpersonationAction());
        }
      } else {
        dispatch(actions.auth.mountUserAction());
      }
    }
  }, [impersonationToken]);

  return <Routes />;
};

const mapStateToProps = state => ({
  mounting: state.auth.mounting,
  impersonationToken: state.auth.impersonationToken,
});

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(App);
