import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './memberships.module.scss';
import { actions } from '../../state';
import {
  isEmpty,
  formatMoney,
  formatDate,
  mapServiceTypes,
  mapBenefitResetPeriods,
} from '../../utils';
import AddHistory from './memberships.history.add';
import EditHistory from './memberships.history.edit';
import TableSimple, {
  TableRow,
  TableCell,
} from '../../components/tableSimple/tableSimple';
import {
  Paper,
  PaperHeader,
  TitleSecondary,
  ButtonSimple,
} from '../../components';
import { useMemo } from 'react';

export const createDefaultBenefit = () => ({
  id: Date.now().toString(),
  type: 41,
  service_type: '',
  amount: '',
  reset_period: 51,
  hidden: false,
});

const History = ({
  dispatch,
  history,
  id,
  defaultModal,
  servicesTable,
  membershipType,
  onSaveHandler,
}) => {
  const [selectedHistory, setSelectedHistory] = useState({});

  useEffect(() => {
    if (!isEmpty(selectedHistory)) {
      openModal('edit');
    }
    // eslint-disable-next-line
  }, [selectedHistory]);

  useEffect(() => {
    if (!defaultModal) {
      setSelectedHistory({});
    }
  }, [defaultModal]);

  const selectHistory = historyId => {
    const [currentHistory] = history.filter(item => item.id === historyId);
    if (currentHistory.valid_from <= Date.now()) {
      dispatch(
        actions.error.setErrorAction({
          msg: 'Negalima redaguoti galiojančios nuolaidos',
        })
      );
    } else {
      setSelectedHistory(currentHistory);
    }
  };

  const onRowClick = historyItem => {
    if (id) {
      selectHistory(historyItem.id);
      return;
    }
    setSelectedHistory(historyItem);
  };

  const services = useMemo(() => {
    const result = servicesTable?.services
      ?.filter(
        (service, index, self) =>
          index === self.findIndex(o => o.type === service.type)
      )
      .map(service => ({
        name: mapServiceTypes(service.type),
        value: service.type,
      }));
    result.unshift({ name: '', value: '' });
    return result;
  }, [servicesTable]);

  const openModal = action => {
    const payload = {
      modalName: 'defaultModal',
      title: 'Keisti paso parametrus',
      withScroll: true,
      children:
        action === 'add' ? (
          <AddHistory
            id={id}
            services={services}
            membershipType={membershipType}
            onSubmitHandler={onSaveHandler}
            prefill={history?.[0]}
          />
        ) : (
          <EditHistory
            id={id}
            history={selectedHistory}
            services={services}
            membershipType={membershipType}
            onSubmitHandler={onSaveHandler}
          />
        ),
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  const tableHeader = [
    'Galioja nuo',
    'Kaina su PVM',
    'Paslaugos',
    'Nuolaida',
    'Garantija (mėn.)',
  ];

  const renderTable = () => {
    const getServicesNames = benefits =>
      benefits.map(benefit => {
        return (
          <div key={benefit.service_type}>
            {mapServiceTypes(benefit.service_type)}
          </div>
        );
      });

    const getServicesDiscounts = benefits => {
      return benefits.map(benefit => (
        <div key={benefit.id}>{`${benefit.amount}${
          !benefit.reset_period
            ? '%'
            : ` (${mapBenefitResetPeriods(benefit.reset_period)})`
        }`}</div>
      ));
    };
    return history.map(historyItem => {
      const {
        id,
        valid_from,
        discount,
        price,
        yearly_price,
        benefits,
        warranty,
      } = historyItem;
      return (
        <TableRow
          key={`${id}__${valid_from}`}
          onClick={() => onRowClick(historyItem)}
        >
          <TableCell>{formatDate(valid_from)}</TableCell>
          <TableCell>
            <div>Kas mėnesį: {formatMoney(price)}</div>
            {yearly_price && <div>Kasmet: {formatMoney(yearly_price)}</div>}
          </TableCell>
          <TableCell>
            {!benefits.length ? (
              <div>Savitarna</div>
            ) : (
              getServicesNames(benefits)
            )}
          </TableCell>
          <TableCell>
            {!benefits.length ? `${discount}%` : getServicesDiscounts(benefits)}
          </TableCell>
          <TableCell>{warranty}</TableCell>
        </TableRow>
      );
    });
  };

  const showAddButton = id ? true : !history.length;

  return (
    <Paper>
      <PaperHeader>
        <TitleSecondary>{id ? 'Istorija' : 'Pradinė istorija'}</TitleSecondary>
        {showAddButton && (
          <ButtonSimple type="add" onClick={() => openModal('add')}>
            Pridėti
          </ButtonSimple>
        )}
      </PaperHeader>
      <div className={styles.table}>
        <TableSimple header={tableHeader}>{renderTable()}</TableSimple>
      </div>
    </Paper>
  );
};

const mapStateToProps = state => ({
  defaultModal: state.modals.defaultModal,
  servicesTable: state.servicesTable,
});

History.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSaveHandler: PropTypes.func,
  id: PropTypes.number,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      discount: PropTypes.number,
      price: PropTypes.number,
      valid_from: PropTypes.number,
      valid_until: PropTypes.number,
    })
  ).isRequired,
  defaultModal: PropTypes.bool.isRequired,
  membershipType: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(History);
