import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import {
  getLocalStorageItemIfNotExpired,
  resetLocalStorageItem,
  removeLocalStorageItem,
  errorMessage,
  resetLocalStorageImpersonationToken,
  getLocalStorageItem,
  removeLocalStorageItemIfExists,
  removeImpersonationItemsFromLocalStorage,
} from '../../utils';

export function* loginUserSaga({ credentials, history }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const { adminToken, adminTokenSetupTime } = config.LOCAL_STORAGE;

    const { token, two_fa } = yield call(Api.getToken, credentials);

    if (two_fa) {
      yield put(actions.auth.confirmationRequiredAction(true));

      history.push(config.APP_PATHS.twoFactorAuthConfirmation);
    } else {
      const userDetails = yield call(Api.getCurrentAdmin, token);

      // TODO: remove
      const updatedDetails = {
        ...userDetails,
        permissions: [...userDetails.permissions, 1021],
      };

      resetLocalStorageItem(adminToken, adminTokenSetupTime, token);

      yield put(actions.auth.setUserAction(updatedDetails, token));
    }
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* mountUserSaga() {
  const {
    adminToken,
    adminTokenSetupTime,
    adminTokenExpiryHours,
  } = config.LOCAL_STORAGE;

  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const token = getLocalStorageItemIfNotExpired(
      adminToken,
      adminTokenSetupTime,
      adminTokenExpiryHours
    );

    if (token) {
      const userDetails = yield call(Api.getCurrentAdmin, token);

      // TODO: remove
      const updatedDetails = {
        ...userDetails,
        permissions: [...userDetails.permissions, 1021],
      };

      yield put(actions.auth.setUserAction(updatedDetails, token));
    }

    yield put(actions.auth.setAuthLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));

    removeLocalStorageItem(adminToken, adminTokenSetupTime);
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* clearUserSaga() {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const {
      adminToken,
      adminTokenSetupTime,
      impersonationToken,
      isImpersonating,
    } = config.LOCAL_STORAGE;

    removeLocalStorageItem(adminToken, adminTokenSetupTime);
    removeImpersonationItemsFromLocalStorage(
      impersonationToken,
      isImpersonating
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* updatePassSaga({ body }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    yield call(Api.updatePass, body);

    const msg = 'Slaptažodis sėkmingai atnaujintas';
    yield put(actions.error.setAlertAction(msg, 'success'));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* resetPassRequestSaga({ email }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    const body = {
      email,
    };

    yield call(Api.resetPassRequest, body);

    const msg =
      'Jūsų el. pašto adresu išsiųstas laiškas su nuoroda, kurią paspaudus gausite naują slaptažodį.';
    yield put(actions.error.setAlertAction(msg, 'success'));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* resetPassFromTokenSaga({ token, password, history }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    const body = {
      token,
      new_password: password,
    };

    yield call(Api.resetPassFromToken, body);

    const msg = 'Slaptažodis sėkmingai atnaujintas';
    yield put(actions.error.setAlertAction(msg, 'success'));

    history.push(config.APP_PATHS.home);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* impersonateBusinessClientSaga({ id }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));
    yield put(actions.auth.setImpersonationMountingAction(true));

    const response = yield call(Api.impersonateBusinessClient, id);

    yield put(actions.auth.setAuthLoadingAction(false));
    yield put(actions.auth.setImpersonation(response.token));

    resetLocalStorageImpersonationToken(
      config.LOCAL_STORAGE.impersonationToken,
      response.token,
      config.LOCAL_STORAGE.isImpersonating
    );
    yield put(actions.auth.setAuthLoadingAction(true));

    yield call(mountUserOnImpersonationSaga);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
    yield put(actions.auth.setAuthLoadingAction(false));
    yield put(actions.auth.setImpersonationMountingAction(false));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* mountUserOnImpersonationSaga() {
  const { impersonationToken } = config.LOCAL_STORAGE;

  try {
    const token = getLocalStorageItem(impersonationToken);
    if (token) {
      const userDetails = yield call(Api.getCurrentAdmin, token);

      yield put(actions.auth.setUserAction(userDetails, token));
    }

    yield put(actions.auth.setAuthLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));

    removeLocalStorageItemIfExists(impersonationToken);
    put(actions.auth.setImpersonationMountingAction(false));
  } finally {
    yield put(actions.auth.setImpersonationMountingAction(false));
  }
}

export function* endImpersonationSaga() {
  const {
    adminToken,
    adminTokenSetupTime,
    adminTokenExpiryHours,
    impersonationToken,
    isImpersonating,
  } = config.LOCAL_STORAGE;

  try {
    yield put(actions.auth.setImpersonationMountingAction(true));
    removeImpersonationItemsFromLocalStorage(
      impersonationToken,
      isImpersonating
    );

    yield put(actions.auth.setAuthLoadingAction(true));
    yield put(actions.auth.clearImpersonation());

    const token = getLocalStorageItemIfNotExpired(
      adminToken,
      adminTokenSetupTime,
      adminTokenExpiryHours
    );

    if (token) {
      const userDetails = yield call(Api.getCurrentAdmin, token);

      yield put(actions.auth.setUserAction(userDetails, token));
    }

    yield put(actions.auth.setAuthLoadingAction(false));
    yield put(actions.auth.setImpersonationMountingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* verify2faCodeSaga({ code, history }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    const { adminToken, adminTokenSetupTime } = config.LOCAL_STORAGE;

    const { token } = yield call(Api.verify2faCode, { code });

    const userDetails = yield call(Api.getCurrentAdmin, token);

    resetLocalStorageItem(adminToken, adminTokenSetupTime, token);

    yield put(actions.auth.setUserAction(userDetails, token));

    history.push(config.APP_PATHS.home);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}

export function* resend2faCode({ credentials }) {
  try {
    yield put(actions.auth.setAuthLoadingAction(true));

    yield call(Api.getToken, credentials);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.auth.setAuthLoadingAction(false));
  }
}
