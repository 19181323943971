import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import servicesBoxStyles from './servicesBoxStyles.module.scss';
import { actions } from './../../../state';
import Boxes from './../boxes/boxes';
import { InputElement } from './../../../components';
import { formattedDevices } from '../locations.utils';

const AutoTextile = ({ dispatch, data, requestError }) => {
  const [values, setValues] = useState({
    mini:
      data !== null
        ? data.options.find(obj => obj.type === 201).price / 100
        : '',
    midi:
      data !== null
        ? data.options.find(obj => obj.type === 202).price / 100
        : '',
    maxi:
      data !== null
        ? data.options.find(obj => obj.type === 203).price / 100
        : '',
    premium:
      data !== null
        ? data.options.find(obj => obj.type === 204).price / 100
        : '',
  });

  const [devices, setBoxesValues] = useState(data === null ? [] : data.devices);
  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    const options = [
      {
        type: 201,
        price: values.mini * 100,
      },
      {
        type: 202,
        price: values.midi * 100,
      },
      {
        type: 203,
        price: values.maxi * 100,
      },
      {
        type: 204,
        price: values.premium * 100,
      },
    ];
    const data = {
      options,
      devices: formattedDevices(devices),
    };
    dispatch(actions.addEditServices.pushAutoTextileAction(data));
  });

  const handleStateFromBoxes = x => {
    setBoxesValues(x);
  };
  return (
    <div className={servicesBoxStyles.mainContainer}>
      <table className={servicesBoxStyles.container}>
        <thead>
          <tr>
            <th>Paslaugos</th>
            <th>Kaina su PVM (EUR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={servicesBoxStyles.name}>ORANGE</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="mini"
                type="number"
                onChange={handleInputChange}
                value={values.mini}
                invalid={requestError && values.mini}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
          </tr>
          <tr>
            <td>PRO</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="midi"
                type="number"
                onChange={handleInputChange}
                value={values.midi}
                invalid={requestError && values.midi}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
          </tr>
          <tr>
            <td>VIP</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="maxi"
                type="number"
                onChange={handleInputChange}
                value={values.maxi}
                invalid={requestError && values.maxi}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
          </tr>
          <tr>
            <td>ELITE</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="premium"
                type="number"
                onChange={handleInputChange}
                value={values.premium}
                invalid={requestError && values.premium}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Boxes
        handleStateFromBoxes={handleStateFromBoxes}
        devices={data === null ? [] : data.devices}
        location="automatic_textile_washing"
        requestError={requestError}
      />
    </div>
  );
};

AutoTextile.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.shape({
    devices: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }),
};

export default connect()(AutoTextile);
