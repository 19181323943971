import React, { createContext, useState } from 'react';

export const ReceiptsContext = createContext();

export const ReceiptsProvider = ({ children }) => {
  const [paymentFilter, setPaymentFilter] = useState([]);
  const [receiptFilter, setReceiptFilter] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [companyCodeFilter, setCompanyCodeFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBody, setFilterBody] = useState();
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState();
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState();
  return (
    <ReceiptsContext.Provider
      value={{
        paymentFilter,
        setPaymentFilter,
        receiptFilter,
        setReceiptFilter,
        phoneFilter,
        setPhoneFilter,
        companyFilter,
        setCompanyFilter,
        companyCodeFilter,
        setCompanyCodeFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
      }}
    >
      {children}
    </ReceiptsContext.Provider>
  );
};
