import React from 'react';

import styles from './defaultModal.module.scss';
import ModalLayout from '../_modalLayout/modalLayout';

const DefaultModal = ({ children, title, onCancel, withScroll = false }) => {
  return (
    <ModalLayout title={title} onCancel={onCancel} withScroll={withScroll}>
      <div className={styles.desc} id="modal__desc">
        {children}
      </div>
    </ModalLayout>
  );
};

export default DefaultModal;
