import { constants } from '..';

const getBoxesTableDataAction = body => ({
  type: constants.boxes.GET_BOXES_TABLE_DATA,
  body,
});

const setBoxesAction = (devices, count, header, footer) => ({
  type: constants.boxes.SET_BOXES_TABLE_DATA,
  devices,
  count,
  header,
  footer,
});

const clearBoxesAction = () => ({
  type: constants.boxes.CLEAR_BOXES,
});

const updateBoxesViaSocketAction = message => ({
  type: constants.boxes.UPDATE_BOXES_VIA_SOCKET,
  message,
});

export const boxesActions = {
  getBoxesTableDataAction,
  setBoxesAction,
  clearBoxesAction,
  updateBoxesViaSocketAction,
};
