import React from 'react';
import PropTypes from 'prop-types';

// import styles from './multiselect.module.scss';
import Checkbox from '../checkbox/checkbox';

const Multiselect = ({
  type,
  array,
  name,
  renderName,
  value,
  valueType,
  state,
  setState,
}) => {
  const isChecked = (value, values) => values.includes(value);

  const handleCheckboxChange = (
    { target: { value, checked } },
    values,
    callback
  ) => {
    let newValues;
    switch (valueType) {
      case 'number':
        newValues = checked
          ? [...values, +value]
          : values.filter(v => v !== +value);
        break;
      case 'string':
        newValues = checked
          ? [...values, value]
          : values.filter(v => v !== value);
        break;
      case 'boolean':
        newValues = checked
          ? [...values, JSON.parse(value)]
          : values.filter(v => v !== JSON.parse(value));
        break;
      default:
        newValues = [...values];
    }

    return callback([...newValues]);
  };

  if (type === 'object') {
    return array.map(el => (
      <Checkbox
        label={el[name]}
        name={el[name]}
        key={el[value]}
        value={el[value]}
        onChange={e => handleCheckboxChange(e, state, setState)}
        checked={isChecked(el[value], state)}
      />
    ));
  }

  if (type === 'array') {
    return array.map(el => (
      <Checkbox
        label={renderName ? renderName(el) : el}
        name={renderName ? renderName(el) : el}
        key={el}
        value={el}
        onChange={e => handleCheckboxChange(e, state, setState)}
        checked={isChecked(el, state)}
      />
    ));
  }
};

Multiselect.defaultProps = {
  renderName: undefined,
};

Multiselect.propTypes = {
  type: PropTypes.string.isRequired,
  array: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  renderName: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  valueType: PropTypes.oneOf(['string', 'number', 'boolean']).isRequired,
  state: PropTypes.array,
  setState: PropTypes.func.isRequired,
};

export default Multiselect;
