import React, { createContext, useState } from 'react';

export const BusinessInvoicesContext = createContext();

export const BusinessInvoicesProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [invoiceNumberFilter, setInvoiceNumberFilter] = useState([]);
  const [dateLtFilter, setDateLtFilter] = useState();
  const [dateGtFilter, setDateGtFilter] = useState();
  const [amountLtFilter, setAmountLtFilter] = useState();
  const [amountGtFilter, setAmountGtFilter] = useState();
  const [wholeAmountLtFilter, setWholeAmountLtFilter] = useState();
  const [wholeAmountGtFilter, setWholeAmountGtFilter] = useState();
  const [debtLtFilter, setDebtLtFilter] = useState();
  const [debtGtFilter, setDebtGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <BusinessInvoicesContext.Provider
      value={{
        filterBody,
        setFilterBody,
        statusFilter,
        setStatusFilter,
        invoiceNumberFilter,
        setInvoiceNumberFilter,
        dateLtFilter,
        setDateLtFilter,
        dateGtFilter,
        setDateGtFilter,
        amountLtFilter,
        setAmountLtFilter,
        amountGtFilter,
        setAmountGtFilter,
        wholeAmountLtFilter,
        setWholeAmountLtFilter,
        wholeAmountGtFilter,
        setWholeAmountGtFilter,
        debtLtFilter,
        setDebtLtFilter,
        debtGtFilter,
        setDebtGtFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </BusinessInvoicesContext.Provider>
  );
};
