import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  receipts: [],
  total: 0,
  header: {},
  footer: {},
  selectedReceipt: {},
  receiptExport: {},
};

export const receiptsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.receipts.SET_RECEIPTS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.receipts.SET_RECEIPTS:
      return {
        ...state,
        receipts: action.receipts,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.receipts.SET_RECEIPT:
      return {
        ...state,
        selectedReceipt: action.receipt,
      };
    case constants.receipts.SET_RECEIPTS_EXPORT_IDENTIFIER:
      return {
        ...state,
        receiptExport: action.identifier,
      };
    case constants.receipts.CLEAR_RECEIPTS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
