export const ordersConstants = {
  SET_ORDERS_LOADING: 'orders/SET_ORDERS_LOADING',
  GET_ORDERS: 'orders/GET_ORDERS',
  SET_ORDERS: 'orders/SET_ORDERS',
  CLEAR_ORDERS: 'orders/CLEAR_ORDERS',
  GET_ORDER: 'orders/GET_ORDER',
  SET_ORDER: 'orders/SET_ORDER',
  RETRY_PAYMENT: 'orders/RETRY_PAYMENT',
  EXPORT_ORDERS: 'orders/EXPORT_ORDERS',
  EXPORT_ORDERS_ASYNC: 'orders/EXPORT_ORDERS_ASYNC',
  SET_ORDERS_EXPORT_IDENTIFIER: 'orders/SET_ORDERS_EXPORT_IDENTIFIER',
  CHANGE_ORDER_STATUS_TO_PAID: 'orders/CHANGE_ORDER_STATUS_TO_PAID',
};
