import React, { createContext, useState } from 'react';

export const MembershipOrdersContext = createContext();

export const MembershipOrdersProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState([]);
  const [costLtFilter, setCostLtFilter] = useState('');
  const [costGtFilter, setCostGtFilter] = useState('');
  const [discountLtFilter, setDiscountLtFilter] = useState('');
  const [discountGtFilter, setDiscountGtFilter] = useState('');
  const [validFromLtFilter, setValidFromLtFilter] = useState();
  const [validFromGtFilter, setValidFromGtFilter] = useState();
  const [validUntilLtFilter, setValidUntilLtFilter] = useState();
  const [validUntilGtFilter, setValidUntilGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <MembershipOrdersContext.Provider
      value={{
        filterBody,
        setFilterBody,
        statusFilter,
        setStatusFilter,
        titleFilter,
        setTitleFilter,
        clientFilter,
        setClientFilter,
        costLtFilter,
        setCostLtFilter,
        costGtFilter,
        setCostGtFilter,
        discountLtFilter,
        setDiscountLtFilter,
        discountGtFilter,
        setDiscountGtFilter,
        validFromLtFilter,
        setValidFromLtFilter,
        validFromGtFilter,
        setValidFromGtFilter,
        validUntilLtFilter,
        setValidUntilLtFilter,
        validUntilGtFilter,
        setValidUntilGtFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </MembershipOrdersContext.Provider>
  );
};
