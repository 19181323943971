import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './buttonLink.module.scss';
import { Icon } from '../..';

const ButtonLink = ({ children, url, icon, ...rest }) =>
  url ? (
    <Link className={styles.button} to={url} {...rest}>
      <span className={styles.label}>
        {icon && <Icon name={icon} classes={{ icon: styles.icon }} />}
        {children}
      </span>
      <Icon name="chevron-right" classes={{ icon: styles.chevron }} />
    </Link>
  ) : (
    <button className={styles.button} to={url} {...rest}>
      <span className={styles.label}>
        {icon && <Icon name={icon} classes={{ icon: styles.icon }} />}
        {children}
      </span>
      <Icon name="chevron-right" classes={{ icon: styles.chevron }} />
    </button>
  );

ButtonLink.defaultProps = {
  icon: null,
  url: null,
};

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string,
  icon: PropTypes.string,
};

export default ButtonLink;
