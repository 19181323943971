import React, { useRef } from 'react';
import { connect } from 'react-redux';

import styles from './qrCodesAllModal.module.scss';
import { actions } from '../../../state';
import { mapServiceTypesNames, serviceTypesNames } from './../../../utils';
import QrCodesItem from './qrCodesItem';
import { Backdrop, Icon, Button, WithSpinner } from '../../../components';

const QrCodesAllModal = ({ dispatch, title, data, loading }) => {
  const componentRef = useRef();

  const handleCancel = () => {
    dispatch(actions.modals.clearModals());
  };

  const handleQrCodeClick = (device, service) => {
    const getServiceName = () => {
      if (service === 'self_service_washing') {
        if (device.type === 201) {
          return 'Boksas';
        }
        return 'Atviras boksas';
      }
      return mapServiceTypesNames(service);
    };

    const payload = {
      modalName: 'qrCodeModal',
      children: device.qr,
      desc: `${data.address}, ${data.city}, ${getServiceName()}, ${
        device.title
      }`,
      title: 'QR kodas',
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  const locationTtitle = `${data.address}, ${data.city}`;

  return (
    <>
      <div
        className={styles.modal}
        id="modal"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal__title"
        aria-describedby="modal__desc"
      >
        <button className={styles.close} onClick={handleCancel}>
          <Icon
            name="close"
            classes={{
              icon: styles.icon,
            }}
          />
        </button>

        <h3 className={styles.title} id="modal__title">
          {title}. {locationTtitle}
        </h3>

        <div className={styles.desc} id="modal__desc" ref={componentRef}>
          {serviceTypesNames.map(service => {
            return (
              <QrCodesItem
                key={service}
                service={service}
                data={data}
                locationTitle={locationTtitle}
                handleQrCodeClick={handleQrCodeClick}
              />
            );
          })}
        </div>
        <div className={styles.actions}>
          <Button
            color="primary"
            onClick={() =>
              dispatch(
                actions.locations.downloadLocationsQrAction(
                  data.id,
                  locationTtitle
                )
              )
            }
          >
            Parsisiųsti
          </Button>
          <WithSpinner loading={loading}> </WithSpinner>
        </div>
      </div>
      <Backdrop onCancel={handleCancel} />
    </>
  );
};

const mapStateToProps = state => ({
  data: state.locations.selectedLocation,
  loading: state.download.qrLoading,
});

export default connect(mapStateToProps)(QrCodesAllModal);
