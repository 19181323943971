import { constants } from '..';

const setLocationsLoadingAction = flag => ({
  type: constants.locations.SET_LOCATIONS_LOADING,
  flag,
});

const getLocationsAction = body => ({
  type: constants.locations.GET_LOCATIONS,
  body,
});

const setLocationsAction = (locations, count, header, footer) => ({
  type: constants.locations.SET_LOCATIONS,
  locations,
  count,
  header,
  footer,
});

const addLocationsAction = history => ({
  type: constants.locations.ADD_LOCATIONS,
  history,
});

const getServicesTableDataAction = body => ({
  type: constants.locations.GET_SERVICES_TABLE_DATA,
  body,
});

const getBoxesTableAction = body => ({
  type: constants.locations.GET_BOXES_TABLE_DATA,
  body,
});

const getSelectedLocationAction = (id, history) => ({
  type: constants.locations.GET_SELECTED_LOCATION,
  id,
});

const setSelectedLocationAction = payload => ({
  type: constants.locations.SET_SELECTED_LOCATION,
  payload,
});

const clearLocationsAction = () => ({
  type: constants.locations.CLEAR_LOCATIONS,
});

const exportLocationsAction = (filename, tab, filterBody) => ({
  type: constants.locations.EXPORT_LOCATIONS,
  filename,
  tab,
  filterBody,
});

const downloadLocationsQrAction = (id, filename) => ({
  type: constants.locations.DOWNLOAD_LOCATIONS_QR,
  id,
  filename,
});

export const locationsActions = {
  setLocationsLoadingAction,
  getLocationsAction,
  setLocationsAction,
  addLocationsAction,
  getServicesTableDataAction,
  getBoxesTableAction,
  getSelectedLocationAction,
  setSelectedLocationAction,
  clearLocationsAction,
  exportLocationsAction,
  downloadLocationsQrAction,
};
