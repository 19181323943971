import { constants } from '..';

const setAuthLoadingAction = flag => ({
  type: constants.auth.SET_AUTH_LOADING,
  flag,
});

const loginUserAction = (credentials, history) => ({
  type: constants.auth.LOGIN_USER,
  credentials,
  history,
});

const setUserAction = (userDetails, token) => ({
  type: constants.auth.SET_USER,
  userDetails,
  token,
});

const mountUserAction = () => ({
  type: constants.auth.MOUNT_USER,
});

const clearUserAction = () => ({
  type: constants.auth.CLEAR_USER,
});

const updatePassAction = body => ({
  type: constants.auth.UPDATE_PASS,
  body,
});

const resetPassRequestAction = email => ({
  type: constants.auth.RESET_PASS_REQUEST,
  email,
});

const resetPassFromTokenAction = (token, password, history) => ({
  type: constants.auth.RESET_PASS_FROM_TOKEN,
  token,
  password,
  history,
});

const setFirstItem = sidebarItem => ({
  type: constants.auth.SET_FIRST_ITEM,
  sidebarItem,
});

const setImpersonation = token => ({
  type: constants.auth.SET_IMPERSONATION,
  token,
});

const impersonateBusinessClient = (id, history) => ({
  type: constants.auth.IMPERSONATE_BUSINESS_CLIENT,
  id,
  history,
});

const mountUserOnImpersonationAction = () => ({
  type: constants.auth.MOUNT_ON_IMPERSONATION,
});

const endImpersonationAction = () => ({
  type: constants.auth.END_IMPERSONATION,
});

const setImpersonationMountingAction = value => ({
  type: constants.auth.IMPERSONATION_MOUNTING,
  mounting: value,
});

const clearImpersonation = () => ({
  type: constants.auth.CLEAR_IMPERSONATION,
});

const confirmationRequiredAction = required => ({
  type: constants.auth.CONFIRMATION_REQUIRED,
  required,
});

const verify2faCode = (code, history) => ({
  type: constants.auth.VERIFY_2FA_CODE,
  code,
  history,
});

const resend2faCode = credentials => ({
  type: constants.auth.RESEND_2FA_CODE,
  credentials,
});

export const authActions = {
  setAuthLoadingAction,
  loginUserAction,
  setUserAction,
  mountUserAction,
  clearUserAction,
  updatePassAction,
  resetPassRequestAction,
  resetPassFromTokenAction,
  setFirstItem,
  impersonateBusinessClient,
  setImpersonation,
  mountUserOnImpersonationAction,
  endImpersonationAction,
  setImpersonationMountingAction,
  clearImpersonation,
  confirmationRequiredAction,
  verify2faCode,
  resend2faCode,
};
