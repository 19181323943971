import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from '../../api/config';
import { actions } from '../../state';
import {
  formatMoney,
  formatFilename,
  formatDate,
  generateHeader,
  mapMembershipOrderStatus,
  mapMembershipOrderStatusColor,
  isFilterActive,
} from '../../utils';
import {
  Title,
  Header,
  HeaderActions,
  ButtonDownload,
  SelectFilter,
  SelectAsyncFilter,
  RangeFilter,
  DateFilter,
  ButtonSimple,
  Table,
  TableRow,
  TableCell,
} from '../../components';
import { MembershipOrdersContext } from './membershipOrders.context';

const MembershipOrders = ({
  dispatch,
  membershipOrders: { membershipOrders, total, header, footer, loading },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    statusFilter,
    setStatusFilter,
    titleFilter,
    setTitleFilter,
    clientFilter,
    setClientFilter,
    costLtFilter,
    setCostLtFilter,
    costGtFilter,
    setCostGtFilter,
    discountLtFilter,
    setDiscountLtFilter,
    discountGtFilter,
    setDiscountGtFilter,
    validFromLtFilter,
    setValidFromLtFilter,
    validFromGtFilter,
    setValidFromGtFilter,
    validUntilLtFilter,
    setValidUntilLtFilter,
    validUntilGtFilter,
    setValidUntilGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(MembershipOrdersContext);

  const fetchMembershipOrders = useCallback(
    data => {
      dispatch(actions.membershipOrders.getMembershipOrdersAction(data));
    },
    [dispatch]
  );

  const clearMembershipOrders = useCallback(() => {
    dispatch(actions.membershipOrders.clearMembershipOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMembershipOrders(filterBody);
    return () => clearMembershipOrders();
  }, [fetchMembershipOrders, clearMembershipOrders, filterBody]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (titleFilter.length) {
      generatedFilter.push({
        field: 'membership_title_lt',
        value: titleFilter,
      });
    }
    if (clientFilter.length) {
      generatedFilter.push({ field: 'client', value: clientFilter });
    }
    if (costLtFilter > 0) {
      generatedFilter.push({
        field: 'cost',
        expression: 'lte',
        value: costLtFilter * 100,
      });
    }
    if (costGtFilter > 0) {
      generatedFilter.push({
        field: 'cost',
        expression: 'gte',
        value: costGtFilter * 100,
      });
    }
    if (discountLtFilter > 0) {
      generatedFilter.push({
        field: 'discount',
        expression: 'lte',
        value: discountLtFilter,
      });
    }
    if (discountGtFilter > 0) {
      generatedFilter.push({
        field: 'discount',
        expression: 'gte',
        value: discountGtFilter,
      });
    }
    if (validFromGtFilter) {
      generatedFilter.push({
        field: 'valid_from',
        expression: 'gte',
        value: validFromGtFilter,
        type: 'timestamp',
      });
    }
    if (validFromLtFilter) {
      generatedFilter.push({
        field: 'valid_from',
        expression: 'lte',
        value: validFromLtFilter,
        type: 'timestamp',
      });
    }
    if (validUntilGtFilter) {
      generatedFilter.push({
        field: 'valid_until',
        expression: 'gte',
        value: validUntilGtFilter,
        type: 'timestamp',
      });
    }
    if (validUntilLtFilter) {
      generatedFilter.push({
        field: 'valid_until',
        expression: 'lte',
        value: validUntilLtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setTitleFilter([]);
    setClientFilter([]);

    setCostLtFilter('');
    setCostGtFilter('');
    setDiscountLtFilter('');
    setDiscountGtFilter('');

    setValidFromLtFilter();
    setValidFromGtFilter();
    setValidUntilLtFilter();
    setValidUntilGtFilter();

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapMembershipOrderStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Pavadinimas"
      name="membership_title_lt"
      valueType="string"
      array={generateHeader(header.membership_title_lt)}
      filterState={titleFilter}
      setFilterState={setTitleFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', titleFilter)}
    />,
    <DateFilter
      title="Galioja nuo"
      name="valid_from"
      lt={validFromLtFilter}
      setLt={setValidFromLtFilter}
      gt={validFromGtFilter}
      setGt={setValidFromGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', validFromLtFilter, validFromGtFilter)}
    />,
    <DateFilter
      title="Galioja iki"
      name="valid_until"
      lt={validUntilLtFilter}
      setLt={setValidUntilLtFilter}
      gt={validUntilGtFilter}
      setGt={setValidUntilGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', validUntilLtFilter, validUntilGtFilter)}
    />,
    <SelectAsyncFilter
      title="Vartotojas"
      name="client"
      valueType="string"
      params={{ model: 'clients', field: 'phone' }}
      filterState={clientFilter}
      setFilterState={setClientFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', clientFilter)}
    />,
    <RangeFilter
      title="Suma su PVM"
      name="cost"
      lt={costLtFilter}
      setLt={setCostLtFilter}
      gt={costGtFilter}
      setGt={setCostGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('range', costLtFilter, costGtFilter)}
    />,
    <RangeFilter
      title="Nuolaida"
      name="discount"
      lt={discountLtFilter}
      setLt={setDiscountLtFilter}
      gt={discountGtFilter}
      setGt={setDiscountGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('range', discountLtFilter, discountGtFilter)}
    />,
  ];

  const tableFooter = ['', '', '', '', '', formatMoney(footer.cost), ''];

  const renderTable = () =>
    membershipOrders.map(
      ({
        id,
        status,
        membership_title_lt,
        valid_from,
        valid_until,
        client,
        cost,
        discount,
      }) => (
        <TableRow url={config.APP_PATHS.membershipOrderDetails(id)} key={id}>
          <TableCell>
            <span style={{ color: mapMembershipOrderStatusColor(status) }}>
              {mapMembershipOrderStatus(status)}
            </span>
          </TableCell>
          <TableCell>{membership_title_lt}</TableCell>
          <TableCell>{formatDate(valid_from)}</TableCell>
          <TableCell>{formatDate(valid_until)}</TableCell>
          <TableCell>{client}</TableCell>
          <TableCell align="right">{formatMoney(cost)}</TableCell>
          <TableCell align="right">{discount} %</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.membershipOrders);
    dispatch(
      actions.membershipOrders.exportMembershipOrdersAction(
        filename,
        filterBody
      )
    );
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.membershipOrders}</Title>

        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        filter={true}
        loading={loading}
        isEmpty={!membershipOrders.length}
        rightAlignedFooterCells={[5]}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  membershipOrders: state.membershipOrders,
  xlsLoading: state.download.xlsLoading,
});

MembershipOrders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  membershipOrders: PropTypes.shape({
    membershipOrders: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      membership_title_lt: PropTypes.arrayOf(PropTypes.string),
      membership_title_en: PropTypes.arrayOf(PropTypes.string),
      membership_title_ru: PropTypes.arrayOf(PropTypes.string),
      client: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    footer: PropTypes.shape({
      cost: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MembershipOrders);
