import { constants } from '..';

const INITIAL_STATE = {
  boxes: [],
  total: 0,
  header: {},
  footer: {},
};

export const boxesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.boxes.SET_BOXES_TABLE_DATA:
      return {
        ...state,
        boxes: action.devices,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.boxes.CLEAR_BOXES:
      return {
        ...INITIAL_STATE,
      };
    case constants.boxes.UPDATE_BOXES_VIA_SOCKET:
      const deviceToUpdate = state.boxes.find(
        device => device.id === action.message.device_id
      );

      if (!deviceToUpdate) {
        return {
          ...state,
        };
      } else {
        const newDevice = {
          ...deviceToUpdate,
          status: action.message.status,
        };

        return {
          ...state,
          boxes: state.boxes.map(device => {
            if (device.id === newDevice.id) {
              return newDevice;
            } else {
              return device;
            }
          }),
        };
      }

    default:
      return state;
  }
};
