import React from 'react';
import PropTypes from 'prop-types';

import styles from './checkbox.module.scss';

const Checkbox = ({
  label,
  name,
  checked,
  value,
  fullLine,
  onChange,
  success,
  ...rest
}) => (
  <div className={styles.checkbox}>
    <input
      type="checkbox"
      checked={checked}
      id={name}
      value={value}
      onChange={onChange}
      {...rest}
    />
    <label
      className={`${fullLine ? styles.labelFullLine : ''} ${
        success ? styles.success : ''
      }`}
      htmlFor={name}
    >
      {label}
    </label>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  success: PropTypes.bool,
};

export default Checkbox;
