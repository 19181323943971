import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { downloadFile, errorMessage } from '../../utils';
import { actions } from '../actions';

export function* downloadExportedFileSaga({ id, url }) {
  try {
    const options = {
      method: 'GET',
      encoding: null,
      url: url,
    };

    const response = yield call(Api.withAuth, options);

    yield put(actions.download.setDownloadedFile(response));

    //downloadFile(response, id);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
  }
}
