import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { config } from '../../api/config';
import {
  formatTime,
  formatFilename,
  formatDate,
  mapServiceTypes,
  mapDiscountStatus,
  mapDiscountStatusColor,
} from '../../utils';
import {
  Header,
  HeaderActions,
  Title,
  Button,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
} from '../../components';

const Discounts = ({
  dispatch,
  discounts: { discounts, total, loading },
  xlsLoading,
}) => {
  const fetchDiscounts = useCallback(() => {
    dispatch(actions.discounts.getDiscountsAction());
  }, [dispatch]);

  const clearDiscounts = useCallback(() => {
    dispatch(actions.discounts.clearDiscountsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchDiscounts();
    return () => clearDiscounts();
  }, [fetchDiscounts, clearDiscounts]);

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.discounts);
    dispatch(actions.discounts.exportDiscountsAction(filename));
  };

  const renderTypes = types =>
    types.map(type => <p key={type}>{mapServiceTypes(type)}</p>);

  const renderLocations = locations =>
    locations ? (
      locations.map(location => <p key={location}>{location}</p>)
    ) : (
      <p>Visos lokacijos</p>
    );

  const renderTime = interval =>
    interval && (
      <p>
        {formatTime(interval[0])} - {formatTime(interval[1])}
      </p>
    );

  const tableHeader = [
    'Statusas',
    'Kodas',
    'Galioja nuo',
    'Galioja iki',
    'Laikas',
    'Lokacija',
    'Paslauga',
    'Panaudota',
    'Nuolaida',
  ];

  const renderTable = () =>
    discounts.map(
      ({
        id,
        status,
        code,
        start_date,
        end_date,
        time_interval,
        locations,
        service_types,
        use_count,
        discount,
      }) => (
        <TableRow url={config.APP_PATHS.editDiscount(id)} key={id}>
          <TableCell>
            <span style={{ color: mapDiscountStatusColor(status) }}>
              {mapDiscountStatus(status)}
            </span>
          </TableCell>
          <TableCell>{code}</TableCell>
          <TableCell>{formatDate(start_date)}</TableCell>
          <TableCell>{formatDate(end_date)}</TableCell>
          <TableCell>{renderTime(time_interval)}</TableCell>
          <TableCell>{renderLocations(locations)}</TableCell>
          <TableCell>{renderTypes(service_types)}</TableCell>
          <TableCell align="right">{use_count}</TableCell>
          <TableCell align="right">{discount}%</TableCell>
        </TableRow>
      )
    );

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.discounts}</Title>
        <HeaderActions>
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
          <Button color="primary" url={config.APP_PATHS.addDiscount}>
            Sukurti
          </Button>
        </HeaderActions>
      </Header>

      <Table header={tableHeader} isEmpty={!discounts.length} loading={loading}>
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  discounts: state.discounts,
  xlsLoading: state.download.xlsLoading,
});

Discounts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  discounts: PropTypes.shape({
    discounts: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Discounts);
