import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../../api/api';
import { config } from '../../../api/config';
import { actions } from '../../actions';

import { errorMessage, downloadFile } from '../../../utils';

export function* getEmployeesSaga({ body: initialBody }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { count, business_client_employees, header, footer } = yield call(
      Api.getEmployees,
      body
    );

    const newHeader = {
      ...header,
      type: [1401, 1402],
    };

    yield put(
      actions.employees.setEmployeesAction(
        business_client_employees,
        count,
        newHeader,
        footer
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* getEmployeeSaga({ id }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const employee = yield call(Api.getEmployee, id);

    yield put(actions.employees.setEmployeeAction(employee));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* getH2CardSaga({ id }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const card = yield call(Api.getH2Card, id);

    yield put(actions.employees.setEmployeeAction(card));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* addEmployeeSaga({ body, history }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const employee = yield call(Api.addEmployee, body);

    yield put(actions.employees.setEmployeesLoadingAction(false));
    history.push(config.APP_PATHS.baEmployees);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* addH2CardSaga({ body, history }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const card = yield call(Api.addH2Card, body);

    yield put(actions.employees.setEmployeesLoadingAction(false));
    history.push(config.APP_PATHS.baEmployees);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* updateEmployeeSaga({ id, body, history }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const employee = yield call(Api.updateEmployee, id, body);

    yield put(actions.employees.setEmployeesLoadingAction(false));
    history.push(config.APP_PATHS.baEmployees);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* updateH2CardSaga({ id, body, history }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const card = yield call(Api.updateH2Card, id, body);

    yield put(actions.employees.setEmployeesLoadingAction(false));
    history.push(config.APP_PATHS.baEmployees);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* changeEmployeeStatusSaga({ id, newStatus }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const body = { status: newStatus ? 1 : 0 };

    yield call(Api.updateEmployee, id, body);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* changeH2CardStatusSaga({ id, newStatus }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    const body = { status: newStatus ? 1 : 0 };

    yield call(Api.updateH2Card, id, body);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* bulkMembershipAssignSaga({ body, history }) {
  try {
    yield put(actions.employees.setEmployeesLoadingAction(true));

    yield call(Api.employeesBulkMembershipsAssign, body);

    history.push(config.APP_PATHS.baDiscountPlans);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.employees.setEmployeesLoadingAction(false));
  }
}

export function* exportEmployeesSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportEmployees, body);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
