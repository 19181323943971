import React from 'react';
import PropTypes from 'prop-types';

import { formatMoney, mapAdyenStatus } from '../../utils';
import TableSimple, {
  TableRow,
  TableCell,
} from '../../components/tableSimple/tableSimple';

const OrderPaymentsTable = ({ payments }) => {
  const tableHeader = ['PSP reference', 'Kiekis', 'Statusas', 'Priežastis'];

  const renderTable = () =>
    payments.map(
      ({ id, status, psp_reference, amount, failure_reason }, index) => {
        return (
          <React.Fragment key={id}>
            <TableRow noBorder={index === payments.length - 1}>
              <TableCell>{psp_reference}</TableCell>
              <TableCell>{formatMoney(amount)}</TableCell>
              <TableCell>{mapAdyenStatus(status)}</TableCell>
              <TableCell>{failure_reason}</TableCell>
            </TableRow>
          </React.Fragment>
        );
      }
    );

  return <TableSimple header={tableHeader}>{renderTable()}</TableSimple>;
};

OrderPaymentsTable.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OrderPaymentsTable;
