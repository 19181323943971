import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './clients.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { formatDate, isEmpty } from '../../utils';
import ClientInfo from './clients.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Toggle,
  Paper,
  PaperNav,
  PaperContainer,
  ButtonLink,
  Button,
} from '../../components';
import { clientsActions } from '../../state/clients/clients.actions';
import ClientMembership from './clients.membership.edit';
import { format } from 'date-fns';

const EditClient = ({
  dispatch,
  match: { params },
  clients: { loading, selectedClient },
  memberships: { memberships },
}) => {
  const [status, setStatus] = useState(false);
  const [promotions, setPromotions] = useState(false);
  const [topUpAmount, setTopUpAmount] = useState(null);

  const fetchClient = useCallback(
    id => {
      dispatch(actions.clients.getClientAction(id));
    },
    [dispatch]
  );

  const clearClient = useCallback(() => {
    dispatch(actions.clients.clearClientsAction());
  }, [dispatch]);

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedClient)) {
      const { status, promotions } = selectedClient;

      setStatus(status === 1 ? true : false);
      setPromotions(promotions);
      setTopUpAmount(null);
    }
  }, [selectedClient]);

  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchClient(params.id);
    fetchMemberships();
    return () => {
      clearClient();
      clearMemberships();
    };
  }, [fetchClient, clearClient, params.id, fetchMemberships, clearMemberships]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const deactivateMembershipOrder = id => {
    dispatch(
      actions.clients.deactivateClientsMembershipOrderAction(
        id,
        selectedClient.id
      )
    );
  };

  const toggleStatus = () => {
    const newStatus = status ? 0 : 1;
    setStatus(status => !status);

    dispatch(actions.clients.updateClient(params.id, { status: newStatus }));
  };

  const togglePromotions = () => {
    const newPromotions = !promotions;
    setPromotions(newPromotions);

    dispatch(
      actions.clients.updateClient(params.id, {
        promotions: newPromotions,
      })
    );
  };

  const openDeleteClientModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Ištrinti kliento duomenis',
      desc: 'Ar tikrai norite ištrinti kliento duomenis?',
      onConfirm: () => dispatch(clientsActions.deleteClientData(params.id)),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  const handleTopUpAmountChange = ({ target: { name, value } }) => {
    setTopUpAmount(+value);
  };

  const openTopUpClientWalletModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Papildyti vartotojo e-piniginę',
      desc: 'Ar tikrai norite papildyti e-piniginę nurodyta suma?',
      onConfirm: () =>
        dispatch(
          clientsActions.topUpWallet(+params.id, Math.round(topUpAmount * 100))
        ),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  const handleSubmit = data => {
    const body = {
      ...data,
      membership_id: Number(data.membership_id),
      client_id: Number(params.id),
    };
    dispatch(actions.memberships.addMembershipOrderToClient(body));
  };

  const openDiscountPlansModal = orderId => {
    const membershipOptions = memberships.map(({ title_lt, id }) => ({
      name: title_lt,
      value: id,
    }));
    membershipOptions.unshift({ name: '', value: 0 });

    const defaultValues = orderId
      ? selectedClient.memberships.reduce(
          (acc, { id, membership_id, valid_from, valid_until }) => {
            if (id !== orderId) return acc;
            return {
              membership_id,
              valid_from: format(valid_from, 'yyyy-MM-dd'),
              valid_until: format(valid_until, 'yyyy-MM-dd'),
            };
          },
          {}
        )
      : {
          membership_id: 0,
          valid_from: '',
          valid_until: '',
        };

    const payload = {
      modalName: 'defaultModal',
      title: 'Nuolaidų planai',
      children: (
        <ClientMembership
          defaultValues={defaultValues}
          membershipOptions={membershipOptions}
          onSubmit={handleSubmit}
        />
      ),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.clients}
            subtitle={selectedClient.phone}
            baseUrl={config.APP_PATHS.clients}
          />
          <Button color="dark" onClick={openDeleteClientModal}>
            Ištrinti duomenis
          </Button>
        </div>

        <Toggle
          onChange={toggleStatus}
          checked={status}
          showMeta={true}
          onText="Aktyvus"
          offText="Deaktyvuotas"
        />
      </Header>

      <PaperContainer>
        <Paper>
          {!isEmpty(selectedClient) && (
            <ClientInfo
              client={selectedClient}
              promotions={promotions}
              togglePromotions={togglePromotions}
              deactivateMembershipOrder={deactivateMembershipOrder}
              topUpAmount={topUpAmount}
              onTopUpAmountChanged={handleTopUpAmountChange}
              topUpClientWallet={openTopUpClientWalletModal}
              openEditPlan={openDiscountPlansModal}
              clientMemberships={selectedClient.memberships}
            />
          )}
          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>
        <PaperNav>
          {selectedClient.order_count > 0 && (
            <ButtonLink
              icon="nav-orders"
              url={config.APP_PATHS.ordersByClient(selectedClient.phone)}
            >
              {config.PAGES.orders} ({selectedClient.order_count})
            </ButtonLink>
          )}
        </PaperNav>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  clients: state.clients,
  memberships: state.memberships,
});

EditClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  clients: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedClient: PropTypes.object.isRequired,
  }).isRequired,
  memberships: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    memberships: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditClient);
