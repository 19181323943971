import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './invoices.module.scss';
import { Input, Button, Select } from '../../components';

const InvoiceRecipient = ({ defaultValues, onSubmit, countries, isSynced }) => {
  const [values, setValues] = useState(defaultValues);
  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const adminCountries = useMemo(
    () =>
      countries?.length
        ? countries.map(country => {
            return {
              name: country.name_lt,
              value: country.code,
            };
          })
        : [],
    [countries]
  );

  const countriesList = [
    {
      name: 'Nepasirinkta',
      value: '',
    },
    ...(adminCountries?.length ? adminCountries : []),
  ];

  const info = [
    {
      title: 'Įmonės kodas',
      data: (
        <Input
          name="company_code"
          label=""
          type="text"
          value={values.company_code}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Įmonė',
      data: (
        <Input
          name="company_name"
          label=""
          type="text"
          value={values.company_name}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Vardas, Pavardė',
      data: (
        <Input
          name="billing_name"
          label=""
          type="text"
          value={values.billing_name}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'PVM kodas',
      data: (
        <Input
          name="tax_code"
          label=""
          type="text"
          value={values.tax_code}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Adresas',
      data: (
        <Input
          name="address"
          label=""
          type="text"
          value={values.address}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Miestas',
      data: (
        <Input
          name="city"
          label=""
          type="text"
          value={values.city}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Pašto kodas',
      data: (
        <Input
          name="postal_code"
          label=""
          type="text"
          value={values.postal_code}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
    {
      title: 'Šalis',
      data: (
        <div className={styles.selectInput}>
          <Select
            name="country_code"
            options={countriesList}
            selectedValue={values.country_code}
            onChange={handleInputChange}
            disabled={isSynced}
          />
        </div>
      ),
    },
    {
      title: 'Telefono nr.',
      data: (
        <Input
          name="client_phone"
          label=""
          type="text"
          value={values.client_phone}
          onChange={handleInputChange}
          disabled={isSynced}
        />
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div
        className={cn(styles.infoRow, styles.editInvoiceInfoRow)}
        key={title}
      >
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <>
      <article className={styles.document}>
        <div className={styles.info}>{renderInfo()}</div>
      </article>
      <div className={styles.submitContainer}>
        <Button
          color="primary"
          onClick={() => onSubmit(values)}
          disabled={isSynced}
        >
          Išsaugoti
        </Button>
      </div>
    </>
  );
};

InvoiceRecipient.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InvoiceRecipient;
