import React, { useState } from 'react';
import { Input } from '../../../components';
import PropTypes from 'prop-types';
import styles from './orderHistory.module.scss';
import { mapServiceTypes } from '../../../utils';

import { formatMoney } from '../../../utils';

const HistoricalOrderInfo = ({
  order: {
    employee_phone,
    employee_name,
    services,
    total_cost,
    total_discount,
  },
}) => {
  const info = [
    {
      title: 'Tel. numeris',
      data: <span>{employee_phone}</span>,
    },
    {
      title: 'Vardas, Pavardė',
      data: <span>{employee_name}</span>,
    },
  ];

  const totals = [
    {
      title: 'Suma',
      data: <span>{formatMoney(total_cost)}</span>,
    },
    {
      title: 'Nuolaida',
      data: <span>{formatMoney(total_discount)}</span>,
    },
  ];

  const renderInfo = () => {
    let infoMap = info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

    let servicesMap = [];
    if (services)
      servicesMap = services.map(({ service_type, cost }) => (
        <div className={styles.infoRow} key={service_type}>
          <span>{mapServiceTypes(service_type)}</span>
          <span>{formatMoney(cost)}</span>
        </div>
      ));

    let totalsMap = [];
    if (totals)
      totalsMap = totals.map(({ title, data }) => (
        <div className={styles.infoRow} key={title}>
          <span>{title}</span>
          <span>{data}</span>
        </div>
      ));

    return infoMap.concat(servicesMap.concat(totalsMap));
  };

  return (
    <article className={styles.document}>
      <div className={styles.info}>{renderInfo()}</div>
    </article>
  );
};

HistoricalOrderInfo.defaultProps = {
  order: {},
};

HistoricalOrderInfo.propTypes = {
  order: PropTypes.shape({
    employee_phone: PropTypes.string,
    employee_name: PropTypes.string,
    services: PropTypes.array,
    total_cost: PropTypes.number,
    total_discount: PropTypes.number,
  }).isRequired,
};

export default HistoricalOrderInfo;
