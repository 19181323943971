import { authActions } from './auth/auth.actions';
import { errorActions } from './error/error.actions';
import { modalsActions } from './modals/modals.actions';
import { searchActions } from './search/search.actions';
import { adminsActions } from './admins/admins.actions';
import { locationsActions } from './locations/locations.actions';
import { clientsActions } from './clients/clients.actions';
import { discountsActions } from './discounts/discounts.actions';
import { membershipsActions } from './memberships/memberships.actions';
import { membershipOrdersActions } from './membershipOrders/membershipOrders.actions';
import { ordersActions } from './orders/orders.actions';
import { servicesActions } from './services/services.actions';
import { boxesActions } from './boxes/boxes.actions';
import { addEditServicesActions } from './addEditServices/addEditServices.actions';
import { invoicesActions } from './invoices/invoices.actions';
import { downloadActions } from './download/download.actions';
import { receiptsActions } from './receipts/receipts.actions';
import { employeesActions } from './businessAdmin/employees/employees.actions';
import { orderHistoryActions } from './businessAdmin/orderHistory/orderHistory.actions';
import { businessInvoicesActions } from './businessAdmin/businessInvoices/businessInvoices.actions';
import { businessClientsActions } from './businessClients/businessClients.actions';
import { textileActions } from './textile/textile.actions';
import { couponsActions } from './coupons/coupons.actions';
import { dropdownsActions } from './dropdowns/dropdowns.actions';

export const actions = {
  auth: authActions,
  error: errorActions,
  modals: modalsActions,
  search: searchActions,
  admins: adminsActions,
  locations: locationsActions,
  clients: clientsActions,
  businessClients: businessClientsActions,
  discounts: discountsActions,
  memberships: membershipsActions,
  membershipOrders: membershipOrdersActions,
  orders: ordersActions,
  services: servicesActions,
  boxes: boxesActions,
  addEditServices: addEditServicesActions,
  receipts: receiptsActions,
  invoices: invoicesActions,
  download: downloadActions,
  employees: employeesActions,
  orderHistory: orderHistoryActions,
  businessInvoices: businessInvoicesActions,
  textile: textileActions,
  coupons: couponsActions,
  dropdowns: dropdownsActions,
};
