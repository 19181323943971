import React, { useState } from 'react';
import { Input, Checkbox, Select } from '../../../components';
import PropTypes from 'prop-types';
import styles from './employees.module.scss';

import { formatMoney, formatDate } from '../../../utils';

const EmployeeInfo = ({
  employee: {
    full_name,
    phone,
    credit_limit,
    remaining_credit,
    immediate_top_up,
    active_top_up_sum,
    active_top_up_count,
    last_system_top_up_date,
    created_at,
    add_card_fee = true,
    membership_id,
  },
  showImmediateTopUp,
  onInputChanged,
  onCheckboxChanged,
  isPhysicalCard,
  isCreateMode,
  membershipOptions,
}) => {
  const info = [
    {
      title: isPhysicalCard ? 'Kortelės numeris' : 'Tel. numeris',
      data:
        isPhysicalCard && !isCreateMode ? (
          <div>{phone}</div>
        ) : (
          <Input
            name="phone"
            label="Tel. numeris"
            onChange={e => onInputChanged(e)}
            value={phone}
            withLabel={false}
          />
        ),
    },
    {
      title: 'Vardas, Pavardė',
      data: (
        <Input
          name="full_name"
          label="Vardas, Pavardė"
          onChange={e => onInputChanged(e)}
          value={full_name}
          withLabel={false}
        />
      ),
    },
    {
      title: 'Mėnesio limitas (EUR)',
      data: (
        <Input
          name="credit_limit"
          type="number"
          min="0.01"
          step="0.01"
          placeholder="0.00"
          label="Mėnesio limitas (EUR)"
          onChange={e => onInputChanged(e)}
          value={credit_limit}
          withLabel={false}
        />
      ),
    },
    {
      title: 'Nuolaidos planas',
      hidden: !membershipOptions.length || isPhysicalCard,
      data: (
        <Select
          name="membership_id"
          options={membershipOptions}
          selectedValue={membership_id ?? ''}
          onChange={onInputChanged}
        />
      ),
    },
    {
      title: 'Likutis (EUR)',
      data: remaining_credit ? (
        <div>
          <span>{formatMoney(remaining_credit)}</span>
        </div>
      ) : (
        <div>
          <span>{formatMoney(0)}</span>
        </div>
      ),
    },
    {
      title: 'Aktyvių papildymų suma',
      hidden: !isPhysicalCard,
      data: active_top_up_sum ? (
        <div>
          <span>{formatMoney(active_top_up_sum)}</span>
        </div>
      ) : (
        <div>
          <span>{formatMoney(0)}</span>
        </div>
      ),
    },
    {
      title: 'Aktyvių papildymų skaičius',
      hidden: !isPhysicalCard,
      data: active_top_up_count ? (
        <div>
          <span>{active_top_up_count}</span>
        </div>
      ) : (
        <div>
          <span>0</span>
        </div>
      ),
    },
    {
      title: 'Paskutinio papildymo data',
      hidden: !isPhysicalCard,
      data: last_system_top_up_date ? (
        <div>
          <span>{formatDate(last_system_top_up_date)}</span>
        </div>
      ) : (
        <div>
          <span>-</span>
        </div>
      ),
    },
    {
      title: 'Papildyti mėnesio limito suma',
      hidden: !showImmediateTopUp,
      data: (
        <Checkbox
          name="immediate_top_up"
          label="Taip"
          checked={immediate_top_up}
          value={immediate_top_up}
          onChange={onCheckboxChanged}
        />
      ),
    },
    {
      title: isCreateMode ? 'Pridėti kortelės mokestį' : 'Kortelė sukurta',
      hidden: !isPhysicalCard,
      data:
        isPhysicalCard && isCreateMode ? (
          <Checkbox
            name="add_card_fee"
            label="Taip"
            checked={add_card_fee}
            value={add_card_fee}
            onChange={onCheckboxChanged}
          />
        ) : isPhysicalCard ? (
          <div>
            <span>{created_at ? formatDate(created_at) : '-'}</span>
          </div>
        ) : (
          <></>
        ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data, hidden }) =>
      !hidden ? (
        <div className={styles.infoRow} key={title}>
          <span>{title}</span>
          <span>{data}</span>
        </div>
      ) : null
    );

  return (
    <article className={styles.document}>
      <div className={styles.info}>{renderInfo()}</div>
    </article>
  );
};

EmployeeInfo.defaultProps = {
  isPhysicalCard: false,
  isCreateMode: false,
  membershipOptions: [],
};

EmployeeInfo.propTypes = {
  employee: PropTypes.shape({
    phone: PropTypes.string,
    full_name: PropTypes.string,
    employee_id: PropTypes.string,
    credit_limit: PropTypes.number,
    remaining_credit: PropTypes.number,
    immediate_top_up: PropTypes.bool,
    add_card_fee: PropTypes.bool,
  }).isRequired,
  onInputChanged: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func,
  isPhysicalCard: PropTypes.bool,
  isCreateMode: PropTypes.bool,
  membershipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

export default EmployeeInfo;
