import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { downloadFile, errorMessage } from '../../utils';

export function* getMembershipOrdersSaga({ body: initialBody }) {
  try {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { membership_orders, count, header, footer } = yield call(
      Api.getMembershipOrders,
      body
    );

    yield put(
      actions.membershipOrders.setMembershipOrdersAction(
        membership_orders,
        count,
        header,
        footer
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(false));
  }
}

export function* getMembershipOrderSaga({ id }) {
  try {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(true));

    const membershipOrder = yield call(Api.getMembershipOrder, id);

    yield put(
      actions.membershipOrders.setMembershipOrderAction(membershipOrder)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(false));
  }
}

export function* deactivateMembershipOrderSaga({ id }) {
  try {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(true));

    const membershipOrder = yield call(Api.deactivateMembershipOrder, id);

    yield put(
      actions.membershipOrders.setMembershipOrderAction(membershipOrder)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.membershipOrders.setMembershipOrdersLoadingAction(false));
  }
}

export function* exportMembershipOrdersSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportMembershipOrders, body);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
