import React from 'react';
import cn from 'classnames';
import {
  ButtonSimple,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableSeparator,
} from '../../components';
import { formatDate } from '../../utils';
import styles from './businessClients.module.scss';

const MembershipsTabContent = ({
  selectedClientMemberships,
  loading,
  openModal,
}) => {
  const tableHeader = [
    'Planas',
    'Licencijų skaičius',
    'Priskirta naudotojams',
    'Galioja nuo',
    'Galioja iki',
  ];

  const renderTablePart = memberships =>
    memberships.map(membership => {
      const {
        id,
        valid_from,
        valid_until,
        license_count,
        assigned_license_count,
        membership_title_lt,
      } = membership;

      return (
        <TableRow key={id} onClick={() => openModal(membership)}>
          <TableCell>{membership_title_lt}</TableCell>
          <TableCell>{license_count}</TableCell>
          <TableCell>{assigned_license_count || '-'}</TableCell>
          <TableCell>{formatDate(valid_from)}</TableCell>
          <TableCell>{valid_until ? formatDate(valid_until) : '-'}</TableCell>
        </TableRow>
      );
    });

  const needSeparator = memberships => {
    return (
      selectedClientMemberships.filter(m => m.valid_until <= Date.now())
        ?.length > 0 &&
      selectedClientMemberships.filter(m => m.valid_until >= Date.now())
        ?.length > 0
    );
  };

  return (
    <Paper>
      <section className={cn(styles.document, styles.membershipsContent)}>
        <div className={styles.membershipEditHeader}>
          <p className={styles.membershipEditTitle}>Aktyvūs planai</p>
          <ButtonSimple type="add" onClick={() => openModal()}>
            Pridėti
          </ButtonSimple>
        </div>
        <Table
          header={tableHeader}
          isEmpty={!selectedClientMemberships.length}
          loading={loading}
        >
          {renderTablePart(
            selectedClientMemberships.filter(m => m.valid_until >= Date.now())
          )}
          {needSeparator(selectedClientMemberships) ? (
            <TableSeparator columns={[1, 2, 3, 4, 5]}></TableSeparator>
          ) : (
            <></>
          )}
          {renderTablePart(
            selectedClientMemberships.filter(m => m.valid_until <= Date.now())
          )}
        </Table>
      </section>
    </Paper>
  );
};

export default MembershipsTabContent;
