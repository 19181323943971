import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './alert.module.scss';
import { Icon } from '..';

const Alert = ({ alert }) =>
  alert && (
    <p className={cn(styles.alert, styles[alert.category])}>
      <Icon name={alert.category} classes={{ icon: styles.icon }} />
      {alert.msg}
    </p>
  );

const mapStateToProps = state => ({
  alert: state.error.alert,
});

Alert.propTypes = {
  alert: PropTypes.shape({
    msg: PropTypes.string.isRequired,
    category: PropTypes.oneOf(['danger', 'success']).isRequired,
  }),
};

export default connect(mapStateToProps)(Alert);
