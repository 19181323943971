import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { config } from './api/config';
import { PrivateRoute } from './utils';

import Layout from './layout/layout';
import NotFound from './pages/notFound/notFound.page';
import Login from './pages/login/login.page';
import Welcome from './pages/welcome/welcome.page';
import Memberships from './pages/memberships/memberships.page';
import CreateMembership from './pages/memberships/memberships.add';
import EditMembership from './pages/memberships/memberships.edit';
import Discounts from './pages/discounts/discounts.page';
import AddDiscounts from './pages/discounts/discounts.add';
import EditDiscount from './pages/discounts/discounts.edit';
import Receipts from './pages/receipts/receipts.page';
import ReceiptDetails from './pages/receipts/receipts.details';
import Invoices from './pages/invoices/invoices.page';
import InvoiceDetails from './pages/invoices/invoices.details';
import Locations from './pages/locations/locations';
import AddWasher from './pages/locations/addLocation/addLocation';
import EditLocation from './pages/locations/editLocation/editLocation';
import Orders from './pages/orders/orders.page';
import OrderDetails from './pages/orders/orders.details';
import MembershipOrders from './pages/membershipOrders/membershipOrders.page';
import MembershipOrderDetails from './pages/membershipOrders/membershipOrders.details';
import Clients from './pages/clients/clients.page';
import Textile from './pages/textile/textile.page';
import BusinessClients from './pages/businessClients/businessClients.page';
import EditBusinessClient from './pages/businessClients/businessClients.edit';
import AddBusinessClient from './pages/businessClients/businessClients.add';
import EditClient from './pages/clients/clients.edit';
import Admins from './pages/admins/admins.page';
import AddAdmin from './pages/admins/admins.add';
import EditAdmin from './pages/admins/admins.edit';
import Employees from './pages/businessAdmin/employees/employees.page';
import OrderHistory from './pages/businessAdmin/orderHistory/orderHistory.page';
import BusinessInvoices from './pages/businessAdmin/businessInvoices/businessInvoices.page';
import UpdatePass from './pages/login/updatePass/updatePass';
import ResetPassFromToken from './pages/login/resetPassFromToken/resetPassFromToken';
import EditEmployee from './pages/businessAdmin/employees/employees.edit';
import AddEmployee from './pages/businessAdmin/employees/employees.add';
import HistoricalOrderDetails from './pages/businessAdmin/orderHistory/orderHistory.details';
import BusinessInvoiceDetails from './pages/businessAdmin/businessInvoices/businessInvoices.details';
import withFiltersContext from './components/filtersHoc/filters.hoc';
import TwoFactorLogin from './pages/login/2fa/twoFactorLogin';
import Coupons from './pages/coupons/coupons.page';
import CouponDetails from './pages/coupons/coupons.details';
import BusinessClientDiscounts from './pages/businessAdmin/discounts/discounts.page';
import BaDiscountBulkAssigning from './pages/businessAdmin/discounts/discounts.bulkAssigning';

const Routes = ({ auth: { loggedIn, loading, permissions } }) => {
  const index = {
    Auth: Welcome,
    Guest: Login,
  };

  const commonOptions = {
    exact: true,
    restricted: false,
    userPermissions: permissions,
    loggedIn,
    loading,
  };

  return (
    <Router>
      <Layout loggedIn={loggedIn}>
        <Switch>
          <Route
            path={config.APP_PATHS.home}
            exact
            component={loggedIn ? index.Auth : index.Guest}
          />
          <Route
            path={config.APP_PATHS.twoFactorAuthConfirmation}
            exact
            component={TwoFactorLogin}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.resetPassword}
            component={ResetPassFromToken}
            restricted={true}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.locations}
            component={Locations}
            permission={config.PERMISSIONS.locations.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addWasher}
            component={AddWasher}
            permission={config.PERMISSIONS.locations.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.ordersByClient()}
            component={Orders}
            permission={config.PERMISSIONS.orders.view}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.ordersByBusinessClient()}
            component={Orders}
            permission={config.PERMISSIONS.orders.view}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.ordersByLocation()}
            component={Orders}
            permission={config.PERMISSIONS.orders.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.orderDetails()}
            component={OrderDetails}
            permission={config.PERMISSIONS.orders.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.orders}
            component={Orders}
            permission={config.PERMISSIONS.orders.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.clients}
            component={Clients}
            permission={config.PERMISSIONS.clients.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.clientsByBusinessClient()}
            component={Clients}
            permission={config.PERMISSIONS.clients.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editClient()}
            component={EditClient}
            permission={config.PERMISSIONS.clients.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.receipts}
            component={Receipts}
            permission={config.PERMISSIONS.invoices.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.receiptDetails()}
            component={ReceiptDetails}
            permission={config.PERMISSIONS.invoices.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.invoices}
            component={Invoices}
            permission={config.PERMISSIONS.invoices.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.invoicesByBusinessClient()}
            component={Invoices}
            permission={config.PERMISSIONS.invoices.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.invoiceDetails()}
            component={InvoiceDetails}
            permission={config.PERMISSIONS.invoices.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.membershipOrders}
            component={MembershipOrders}
            permission={config.PERMISSIONS.membershipOrders.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.membershipOrderDetails()}
            component={MembershipOrderDetails}
            permission={config.PERMISSIONS.membershipOrders.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.memberships}
            component={Memberships}
            permission={config.PERMISSIONS.memberships.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addMembership}
            component={CreateMembership}
            permission={config.PERMISSIONS.memberships.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editMembership()}
            component={EditMembership}
            permission={config.PERMISSIONS.memberships.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.discounts}
            component={Discounts}
            permission={config.PERMISSIONS.discounts.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addDiscount}
            component={AddDiscounts}
            permission={config.PERMISSIONS.discounts.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editDiscount()}
            component={EditDiscount}
            permission={config.PERMISSIONS.discounts.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.admins}
            component={Admins}
            permission={config.PERMISSIONS.admins.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addAdmin}
            component={AddAdmin}
            permission={config.PERMISSIONS.admins.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editAdmin()}
            component={EditAdmin}
            permission={config.PERMISSIONS.admins.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.updatePassword}
            component={UpdatePass}
            permission={null}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editLocation()}
            component={EditLocation}
            permission={config.PERMISSIONS.locations.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.textile}
            component={Textile}
            permission={config.PERMISSIONS.textile.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.coupons}
            component={Coupons}
            permission={config.PERMISSIONS.coupons.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.couponDetails()}
            component={CouponDetails}
            permission={config.PERMISSIONS.coupons.view}
          />

          {/* Business Admin */}
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baEmployees}
            component={Employees}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baDiscountPlans}
            component={BusinessClientDiscounts}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baDiscountPlan()}
            component={BaDiscountBulkAssigning}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editEmployee()}
            component={EditEmployee}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editH2Card()}
            component={EditEmployee}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addEmployee}
            component={AddEmployee}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baOrderHistory}
            component={OrderHistory}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baOrderHistoryByClient()}
            component={OrderHistory}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.historicalOrderDetails()}
            component={HistoricalOrderDetails}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baInvoices}
            component={BusinessInvoices}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.baInvoiceDetails()}
            component={BusinessInvoiceDetails}
          />

          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.businessClients}
            component={BusinessClients}
            permission={config.PERMISSIONS.businessClients.view}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.addBusinessClient}
            component={AddBusinessClient}
            permission={config.PERMISSIONS.businessClients.edit}
          />
          <PrivateRoute
            {...commonOptions}
            path={config.APP_PATHS.editBusinessClient()}
            component={EditBusinessClient}
            permission={config.PERMISSIONS.businessClients.edit}
          />
          <PrivateRoute
            {...commonOptions}
            exact={false}
            component={NotFound}
            permission={null}
          />
        </Switch>
      </Layout>
    </Router>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});

Routes.propTypes = {
  auth: PropTypes.shape({
    loggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(withFiltersContext(Routes));
