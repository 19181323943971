export const clientsConstants = {
  SET_CLIENTS_LOADING: 'clients/SET_CLIENTS_LOADING',
  GET_CLIENTS: 'clients/GET_CLIENTS',
  SET_CLIENTS: 'clients/SET_CLIENTS',
  GET_CLIENT: 'clients/GET_CLIENT',
  SET_CLIENT: 'clients/SET_CLIENT',
  UPDATE_CLIENT: 'clients/UPDATE_CLIENT',
  CLEAR_CLIENTS: 'clients/CLEAR_CLIENTS',
  EXPORT_CLIENTS: 'clients/EXPORT_CLIENTS',
  EXPORT_CLIENTS_ASYNC: 'clients/EXPORT_CLIENTS_ASYNC',
  SET_CLIENTS_EXPORT_IDENTIFIER: 'clients/SET_CLIENTS_EXPORT_IDENTIFIER',
  DEACTIVATE_CLIENTS_MEMBERSHIP_ORDER:
    'clients/DEACTIVATE_CLIENTS_MEMBERSHIP_ORDER',
  GET_CLIENT_CARDS: 'clients/GET_CLIENT_CARDS',
  SET_CLIENT_CARDS: 'clients/SET_CLIENT_CARDS',
  DELETE_CLIENT_DATA: 'clients/DELETE_CLIENT_DATA',
  TOP_UP_WALLET: 'clients/TOP_UP_WALLET',
  SET_SELECTED_CLIENT_MEMBERSHIP: 'clients/SET_SELECTED_CLIENT_MEMBERSHIP',
  DELETE_CLIENTS_MEMBERSHIP_ORDER: 'clients/DELETE_CLIENTS_MEMBERSHIP_ORDER',
};
