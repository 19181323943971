import { constants } from '..';

const INITIAL_STATE = {
  confirmModal: false,
  defaultModal: false,
  qrCodesAllModal: false,
  qrCodeModal: false,
  exportModal: false,
  children: null,
  title: '',
  desc: '',
  withScroll: false,
  onConfirm: () => {},
};

export const modalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.modals.SET_MODAL_VISIBLE:
      const { modalName, ...rest } = action.payload;
      return {
        ...state,
        [modalName]: true,
        ...rest,
      };
    case constants.modals.CLEAR_MODALS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
