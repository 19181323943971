import React, { useCallback, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { OrderHistoryContext } from './orderHistory.context';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { mapPaymentMethod, moneyToCents } from '../../../utils';
import PropTypes from 'prop-types';
import { socketsClient } from '../../../api/sockets';

import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  DateFilter,
  HeaderActions,
  ButtonSimple,
  RangeFilter,
  TimeFilter,
} from '../../../components';

import {
  formatFilename,
  formatDate,
  formatMoney,
  mapUserStatus,
  mapUserStatusColor,
  isFilterActive,
  generateHeader,
  mapClientPromotions,
  mapClientCities,
} from '../../../utils';

const OrderHistory = ({
  dispatch,
  user,
  match: { params, url },
  history,
  orders: { orders, total, header, loading, orderHistoryExport },
  xlsLoading,
}) => {
  const {
    filterBody,
    setFilterBody,
    locationFilter,
    setLocationFilter,
    paymentMethodFilter,
    setPaymentMethodFilter,
    dateLtFilter,
    setDateLtFilter,
    dateGtFilter,
    setDateGtFilter,
    timeLtFilter,
    setTimeLtFilter,
    timeGtFilter,
    setTimeGtFilter,
    fullNameFilter,
    setFullNameFilter,
    clientPhoneFilter,
    setClientPhoneFilter,
    amountLtFilter,
    setAmountLtFilter,
    amountGtFilter,
    setAmountGtFilter,
    sortOrder,
    setSortOrder,
  } = useContext(OrderHistoryContext);

  const fetchOrderHistory = useCallback(
    data => {
      dispatch(actions.orderHistory.getOrderHistoryAction(data));
    },
    [dispatch]
  );

  useEffect(() => {
    const channel = socketsClient.subscribe(`admin/${user.id}`);
    if (orderHistoryExport && orderHistoryExport.id) {
      const payload = {
        modalName: 'exportModal',
        title: 'Failo eksportavimas',
        fileName: orderHistoryExport.filename,
        onCancel: () => {
          socketsClient.unsubscribe(`admin/${user.id}`);
          dispatch(actions.download.cleanDownload());
        },
      };
      dispatch(actions.modals.setModalVisible(payload));

      channel.watch(message => {
        dispatch(actions.download.setAsyncExportResult(message));
      });
    }
    return () => {
      socketsClient.unsubscribe(`admin/${user.id}`);
    };
  }, [orderHistoryExport]);

  const clearOrderHsitory = useCallback(() => {
    dispatch(actions.orderHistory.clearOrderHistoryAction());
  }, [dispatch]);

  useEffect(() => {
    fetchOrderHistory(filterBody);
    return () => clearOrderHsitory();
  }, [fetchOrderHistory, clearOrderHsitory, filterBody]);

  const handleSubmit = name => {
    if (params.phone) {
      history.push(config.APP_PATHS.baOrderHistory);
    }

    const generatedFilter = [];

    if (locationFilter.length) {
      generatedFilter.push({ field: 'location', value: locationFilter });
    }
    if (clientPhoneFilter.length) {
      generatedFilter.push({ field: 'client_phone', value: clientPhoneFilter });
    }
    if (paymentMethodFilter.length) {
      generatedFilter.push({
        field: 'payment_method',
        value: paymentMethodFilter,
      });
    }
    if (dateLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: dateLtFilter,
        type: 'timestamp',
      });
    }
    if (dateGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: dateGtFilter,
        type: 'timestamp',
      });
    }
    if (timeLtFilter) {
      generatedFilter.push({
        field: 'time',
        expression: 'lte',
        value: timeLtFilter,
        type: 'time',
      });
    }
    if (timeGtFilter) {
      generatedFilter.push({
        field: 'time',
        expression: 'gte',
        value: timeGtFilter,
        type: 'time',
      });
    }
    if (fullNameFilter.length) {
      generatedFilter.push({
        field: 'business_employee_full_name',
        value: fullNameFilter,
      });
    }
    if (amountLtFilter) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'lte',
        value: moneyToCents(amountLtFilter),
        type: 'number',
      });
    }
    if (amountGtFilter) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'gte',
        value: moneyToCents(amountGtFilter),
        type: 'number',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setLocationFilter([]);
    setPaymentMethodFilter([]);
    setDateLtFilter();
    setDateGtFilter();
    setTimeLtFilter();
    setTimeGtFilter();
    setFullNameFilter([]);
    setAmountLtFilter();
    setAmountGtFilter();
    setClientPhoneFilter([]);

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Lokacija"
      name="location"
      valueType="string"
      array={generateHeader(header.location)}
      filterState={locationFilter}
      setFilterState={setLocationFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', locationFilter)}
    />,
    <SelectFilter
      title="Mokėjimo metodas"
      name="payment_method"
      valueType="number"
      array={generateHeader([371, 372, 373], mapPaymentMethod)}
      filterState={paymentMethodFilter}
      setFilterState={setPaymentMethodFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', paymentMethodFilter)}
    />,
    <DateFilter
      title="Data"
      name="created_at"
      lt={dateLtFilter}
      setLt={setDateLtFilter}
      gt={dateGtFilter}
      setGt={setDateGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('range', dateLtFilter, dateGtFilter)}
    />,
    <TimeFilter
      title="Laikas"
      name="time"
      lt={timeLtFilter}
      setLt={setTimeLtFilter}
      gt={timeGtFilter}
      setGt={setTimeGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('range', timeLtFilter, timeGtFilter)}
    />,
    <SelectAsyncFilter
      title="Vardas"
      name="business_employee_full_name"
      valueType="string"
      params={{ model: 'business_employees', field: 'full_name' }}
      filterState={fullNameFilter}
      setFilterState={setFullNameFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', fullNameFilter)}
    />,
    <SelectAsyncFilter
      title="Numeris"
      name="client_phone"
      valueType="string"
      params={{ model: 'business_employees', field: 'phone' }}
      filterState={clientPhoneFilter}
      setFilterState={setClientPhoneFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', clientPhoneFilter)}
    />,
    <RangeFilter
      title="Suma"
      name="total_cost"
      lt={amountLtFilter}
      setLt={setAmountLtFilter}
      gt={amountGtFilter}
      setGt={setAmountGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', amountLtFilter, amountGtFilter)}
    />,
  ];

  const renderTable = () =>
    orders.map(
      ({
        id,
        location,
        payment_method,
        created_at,
        time,
        business_employee_full_name,
        client_phone,
        total_cost,
      }) => (
        // url={config.APP_PATHS.historicalOrderDetails(id)}
        <TableRow key={id}>
          <TableCell>{location}</TableCell>
          <TableCell>{mapPaymentMethod(payment_method)}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell>{time}</TableCell>
          <TableCell>{business_employee_full_name}</TableCell>
          <TableCell>{client_phone}</TableCell>
          <TableCell>{formatMoney(total_cost)}</TableCell>
        </TableRow>
      )
    );

  // H2-1732 hide orders export
  // const xlsExport = () => {
  //   const filename = formatFilename(config.PAGES.baHistory);
  //   dispatch(actions.orderHistory.exportBusinessClientOrdersAction(filename));
  // };

  const xlsCardAsyncExport = () => {
    dispatch(actions.orderHistory.exportH2CardAsyncAction(filterBody));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.baHistory}</Title>
        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}

          {/* <ButtonDownload onClick={xlsExport} loading={xlsLoading} /> */}
          <ButtonDownload onClick={xlsCardAsyncExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>
      <Table
        header={tableHeader}
        isEmpty={!orders.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  orders: state.orderHistory,
  xlsLoading: state.download.xlsLoading,
  user: state.auth.user,
});

OrderHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  orders: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    orders: PropTypes.array.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      location: PropTypes.arrayOf(PropTypes.string),
      payment_type: PropTypes.arrayOf(PropTypes.number),
      discounts: PropTypes.arrayOf(PropTypes.string),
      service_types: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(OrderHistory);
