import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './receipts.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import {
  formatMoney,
  formatFilename,
  formatDate,
  mapPaymentType,
  generateHeader,
  isFilterActive,
} from '../../utils';
import {
  Header,
  Title,
  ButtonDownload,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  HeaderActions,
  ButtonSimple,
  DateFilter,
} from '../../components';
import { ReceiptsContext } from './receipts.context';
import { socketsClient } from '../../api/sockets';

const Receipts = ({
  dispatch,
  user,
  receipts: { receipts, total, footer, header, loading, receiptExport },
  xlsLoading,
  xmlLoading,
}) => {
  const {
    paymentFilter,
    setPaymentFilter,
    receiptFilter,
    setReceiptFilter,
    phoneFilter,
    setPhoneFilter,
    companyFilter,
    setCompanyFilter,
    companyCodeFilter,
    setCompanyCodeFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
  } = useContext(ReceiptsContext);

  useEffect(() => {
    const channel = socketsClient.subscribe(`admin/${user.id}`);
    if (receiptExport && receiptExport.id) {
      const payload = {
        modalName: 'exportModal',
        title: 'Failo eksportavimas',
        fileName: receiptExport.filename,
        onCancel: () => {
          socketsClient.unsubscribe(`admin/${user.id}`);
          dispatch(actions.download.cleanDownload());
        },
      };
      dispatch(actions.modals.setModalVisible(payload));

      channel.watch(message => {
        dispatch(actions.download.setAsyncExportResult(message));
      });
    }

    return () => {
      socketsClient.unsubscribe(`admin/${user.id}`);
    };
  }, [receiptExport]);

  const fetchReceipts = useCallback(
    data => {
      dispatch(actions.receipts.getReceiptsAction(data));
    },
    [dispatch]
  );

  const clearReceipts = useCallback(() => {
    dispatch(actions.receipts.clearReceiptsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchReceipts(filterBody);
    return () => clearReceipts();
  }, [fetchReceipts, clearReceipts, filterBody]);

  const handleSubmit = name => {
    const generatedFilter = [];

    if (paymentFilter.length) {
      generatedFilter.push({ field: 'payment_type', value: paymentFilter });
    }
    if (receiptFilter.length) {
      generatedFilter.push({ field: 'number', value: receiptFilter });
    }
    if (phoneFilter.length) {
      generatedFilter.push({ field: 'client_phone', value: phoneFilter });
    }
    if (companyFilter.length) {
      generatedFilter.push({ field: 'company_name', value: companyFilter });
    }
    if (companyCodeFilter.length) {
      generatedFilter.push({ field: 'company_code', value: companyCodeFilter });
    }
    if (createdAtGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: createdAtGtFilter,
        type: 'timestamp',
      });
    }
    if (createdAtLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: createdAtLtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setPaymentFilter([]);
    setReceiptFilter([]);
    setPhoneFilter([]);
    setCompanyFilter([]);
    setCompanyCodeFilter([]);
    setCreatedAtLtFilter();
    setCreatedAtGtFilter();

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Tipas"
      name="payment_type"
      valueType="number"
      array={generateHeader(header.payment_type, mapPaymentType)}
      filterState={paymentFilter}
      setFilterState={setPaymentFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', paymentFilter)}
    />,
    <SelectAsyncFilter
      title="Kvito nr."
      name="number"
      valueType="string"
      params={{ model: 'receipts', field: 'number' }}
      filterState={receiptFilter}
      setFilterState={setReceiptFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', receiptFilter)}
    />,
    <SelectAsyncFilter
      title="Numeris"
      name="client_phone"
      valueType="string"
      params={{ model: 'clients', field: 'phone' }}
      filterState={phoneFilter}
      setFilterState={setPhoneFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', phoneFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonė"
      name="company_name"
      valueType="string"
      params={{ model: 'companies', field: 'company_name' }}
      filterState={companyFilter}
      setFilterState={setCompanyFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonės kodas"
      name="company_code"
      valueType="string"
      params={{ model: 'companies', field: 'company_code' }}
      filterState={companyCodeFilter}
      setFilterState={setCompanyCodeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyCodeFilter)}
    />,
    <div className={styles.tableHeader}>PVM kodas</div>,
    <DateFilter
      title="Data"
      name="created_at"
      lt={createdAtLtFilter}
      setLt={setCreatedAtLtFilter}
      gt={createdAtGtFilter}
      setGt={setCreatedAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', createdAtLtFilter, createdAtGtFilter)}
    />,
    <div className={styles.tableHeader}>Be PVM</div>,
    <div className={styles.tableHeader}>Su PVM</div>,
  ];

  const tableFooter = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    formatMoney(footer.total_cost_without_tax),
    formatMoney(footer.total_cost),
  ];

  const renderTable = () =>
    receipts.map(
      ({
        id,
        number,
        client_phone,
        company_name,
        company_code,
        payment_type,
        created_at,
        total_cost_without_tax,
        total_cost,
        tax_code,
      }) => (
        <TableRow url={config.APP_PATHS.receiptDetails(id)} key={id}>
          <TableCell>{mapPaymentType(payment_type)}</TableCell>
          <TableCell>{number}</TableCell>
          <TableCell>{client_phone}</TableCell>
          <TableCell>{company_name || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{company_code || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{tax_code || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell align="right">
            {formatMoney(total_cost_without_tax)}
          </TableCell>
          <TableCell align="right">{formatMoney(total_cost)}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.receipts);
    //dispatch(actions.receipts.exportReceiptsAction(filename, filterBody));
    dispatch(actions.receipts.exportReceiptsAsyncAction(filterBody));
  };

  const xmlExport = () => {
    const filename = formatFilename(config.PAGES.receipts);
    dispatch(actions.receipts.exportReceiptsXmlAction(filename, filterBody));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.receipts}</Title>

        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
          <ButtonDownload type="xml" onClick={xmlExport} loading={xmlLoading} />
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        isEmpty={receipts && !receipts.length}
        filter={true}
        loading={loading}
        rightAlignedFooterCells={[6, 7]}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  receipts: state.receipts,
  user: state.auth.user,
  xlsLoading: state.download.xlsLoading,
  xmlLoading: state.download.xmlLoading,
});

Receipts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  receipts: PropTypes.shape({
    receipts: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    header: PropTypes.shape({
      invoice_number: PropTypes.arrayOf(PropTypes.string),
      client_phone: PropTypes.arrayOf(PropTypes.string),
      company_name: PropTypes.arrayOf(PropTypes.string),
      company_code: PropTypes.arrayOf(PropTypes.string),
      payment_type: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    footer: PropTypes.shape({
      total_cost: PropTypes.number,
      total_cost_without_tax: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
  xmlLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Receipts);
