import { call, put, select } from 'redux-saga/effects';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import { actions } from '..';
import { downloadFile, errorMessage } from '../../utils';
import { selectDiscountBanner } from '../selectors';

export function* getDiscountsSaga() {
  try {
    yield put(actions.discounts.setDiscountsLoadingAction(true));

    const body = {
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { discounts, count } = yield call(Api.getDiscounts, body);

    yield put(actions.discounts.setDiscountsAction(discounts, count));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* getDiscountSaga({ id }) {
  try {
    yield put(actions.discounts.setDiscountsLoadingAction(true));

    const discount = yield call(Api.getDiscount, id);

    yield put(actions.discounts.setDiscountAction(discount));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* addDiscountSaga({ body, history }) {
  try {
    yield put(actions.discounts.setDiscountsLoadingAction(true));

    if (body.createDiscountBanner) {
      const banner = yield select(selectDiscountBanner);

      const withBanner = {
        ...body,
        announcement: {
          ...body.announcement,
          banner_id: banner.id,
        },
      };

      yield call(Api.addDiscount, withBanner);
    } else yield call(Api.addDiscount, body);

    history.push(config.APP_PATHS.discounts);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* editDiscountSaga({ id, body, history }) {
  try {
    yield put(actions.discounts.setDiscountsLoadingAction(true));

    const banner = yield select(selectDiscountBanner);

    if (body.createDiscountBanner) {
      const withBanner = {
        ...body,
        announcement: {
          ...body.announcement,
          banner_id: banner.id,
        },
      };

      yield call(Api.editDiscount, id, withBanner);
    } else yield call(Api.editDiscount, id, body);
    history.push(config.APP_PATHS.discounts);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* deactivateDiscountSaga({ id, history }) {
  try {
    yield put(actions.discounts.setDiscountsLoadingAction(true));

    yield call(Api.deactivateDiscount, id);

    history.push(config.APP_PATHS.discounts);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountsLoadingAction(false));
  }
}

export function* exportDiscountsSaga({ filename }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportDiscounts);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* uploadDiscountBannerSaga({ file }) {
  try {
    yield put(actions.discounts.setDiscountBannerUploadingAction(true));

    const data = yield call(Api.uploadDiscountBanner, file);

    yield put(actions.discounts.setDiscountBannerAction(data));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.discounts.setDiscountBannerUploadingAction(false));
  }
}
