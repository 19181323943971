import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { errorMessage, downloadFile } from '../../utils';
import {
  selectBusinessClientContract,
  selectBusinessClientLogotype,
} from '../selectors';

export function* getBusinessClientsSaga({ body: initialBody }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { business_clients, count, header, footer } = yield call(
      Api.getBusinessClients,
      body
    );

    yield put(
      actions.businessClients.setBusinessClientsAction(
        business_clients,
        count,
        header,
        footer
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* getBusinessClientSaga({ id }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const business_client = yield call(Api.getBusinessClient, id);

    yield put(actions.businessClients.setBusinessClientAction(business_client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* updateBusinessClientSaga({ id, body, history }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const contract = yield select(selectBusinessClientContract);
    const logotype = yield select(selectBusinessClientLogotype);

    const client = yield call(Api.updateBusinessClient, id, {
      ...body,
      contract_id: contract?.id,
      logo_id: logotype?.id,
    });

    yield put(actions.businessClients.setBusinessClientsLoadingAction(client));

    history.push(config.APP_PATHS.businessClients);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* updateBusinessClientStatusSaga({ id, status }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const body = { status: status };
    const client = yield call(Api.updateBusinessClient, id, body);

    yield put(actions.businessClients.setBusinessClientsLoadingAction(client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* addBusinessClientSaga({ body, history }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const contract = yield select(selectBusinessClientContract);
    const logotype = yield select(selectBusinessClientLogotype);

    const client = yield call(Api.addBusinessClient, {
      ...body,
      contract_id: contract.id,
      logo_id: logotype.id,
    });

    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
    history.push(config.APP_PATHS.businessClients);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* uploadContractSaga({ file }) {
  try {
    yield put(actions.businessClients.setContractUploadingAction(true));

    const data = yield call(Api.uploadBusinessClientFile, file);

    yield put(actions.businessClients.setUploadedContractAction(data));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setContractUploadingAction(false));
  }
}

export function* uploadLogotypeSaga({ file }) {
  try {
    yield put(actions.businessClients.setContractUploadingAction(true));

    const data = yield call(Api.uploadBusinessClientImage, file);

    yield put(actions.businessClients.setUploadedLogotypeAction(data));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setContractUploadingAction(false));
  }
}

export function* getBusinessClientsMembershipsSaga({ business_client_id }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const business_clients_memberships = yield call(
      Api.getBusinessClientsMemberships,
      business_client_id
    );

    yield put(
      actions.businessClients.setSelectedClientMembershipsAction(
        business_clients_memberships
      )
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* addBusinessClientsMembershipSaga({ businessClientId, body }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const business_clients_membership = yield call(
      Api.addBusinessClientsMembership,
      businessClientId,
      body
    );

    yield put(
      actions.businessClients.updateBusinessClientsMembershipsAction(
        business_clients_membership
      )
    );

    yield put(actions.modals.clearModals());
  } catch (e) {
    const msg = errorMessage(e);
    // yield put(actions.modals.clearModals());
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* updateBusinessClientsMembershipSaga({
  businessClientId,
  selectedMembershipId,
  body,
}) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const business_clients_membership = yield call(
      Api.updateBusinessClientsMembership,
      businessClientId,
      selectedMembershipId,
      body
    );

    yield put(
      actions.businessClients.updateBusinessClientsMembershipsAction(
        business_clients_membership
      )
    );

    yield put(actions.modals.clearModals());
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* getManagerAdminsSaga({ body }) {
  try {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(true));

    const { admins } = yield call(Api.getAdminsManagers, body);

    yield put(actions.businessClients.setManagerAdminsAction(admins));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.businessClients.setBusinessClientsLoadingAction(false));
  }
}

export function* searchCompanyDetailsSaga({ query }) {
  try {
    const queryFromUser = query.trim();

    if (queryFromUser.length < config.DEFAULTS.symbolsToSearch) {
      throw new Error(`Įrašykite ${config.DEFAULTS.symbolsToSearch} simbolius`);
    }

    const queryToSubmit = queryFromUser;

    yield put(actions.businessClients.setCompanyDetailsLoadingAction(true));

    const response = yield call(Api.searchCompanyDetails, queryToSubmit);

    yield put(actions.businessClients.setCompanyDetailsAction(response));
  } catch (e) {
    yield put(
      actions.businessClients.setCompanyDetailsErrorAction(
        e.error ? e.error.error : e.message
      )
    );
  } finally {
    yield put(actions.businessClients.setCompanyDetailsLoadingAction(false));
  }
}

export function* searchCompanyDetailsByCodeSaga({ code }) {
  try {
    const queryFromUser = code.trim();

    if (queryFromUser.length < config.DEFAULTS.companyCodeSymbolsToSearch) {
      throw new Error(
        `Įrašykite ${config.DEFAULTS.companyCodeSymbolsToSearch} simbolius`
      );
    }

    yield put(actions.businessClients.setCompanyDetailsLoadingAction(true));

    const response = yield call(Api.getSingleCompanyDetails, code);

    yield put(
      actions.businessClients.setCompanyDetailsByCodeAction([response])
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(
      actions.businessClients.setCompanyDetailsByCodeErrorAction(
        msg?.code ? msg.msg : e.error ? e.error.error : e.message
      )
    );
  } finally {
    yield put(actions.businessClients.setCompanyDetailsLoadingAction(false));
  }
}

export function* getSingleCompanyDetailsSaga({ code }) {
  try {
    const response = yield call(Api.getSingleCompanyDetails, code);

    yield put(actions.businessClients.setSingleCompanyDetailsAction(response));
  } catch (e) {
    yield put(
      actions.businessClients.setCompanyDetailsErrorAction(
        e.error ? e.error.error : e.message
      )
    );
  }
}

export function* exportBusinessClientsSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportBusinessClients, body);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
