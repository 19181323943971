import { constants } from '..';

const setMembershipOrdersLoadingAction = flag => ({
  type: constants.membershipOrders.SET_MEMBERSHIP_ORDERS_LOADING,
  flag,
});

const getMembershipOrdersAction = body => ({
  type: constants.membershipOrders.GET_MEMBERSHIP_ORDERS,
  body,
});

const setMembershipOrdersAction = (
  membership_orders,
  count,
  header,
  footer
) => ({
  type: constants.membershipOrders.SET_MEMBERSHIP_ORDERS,
  membership_orders,
  count,
  header,
  footer,
});

const getMembershipOrderAction = id => ({
  type: constants.membershipOrders.GET_MEMBERSHIP_ORDER,
  id,
});

const setMembershipOrderAction = membershipOrder => ({
  type: constants.membershipOrders.SET_MEMBERSHIP_ORDER,
  membershipOrder,
});

const clearMembershipOrdersAction = () => ({
  type: constants.membershipOrders.CLEAR_MEMBERSHIP_ORDERS,
});

const deactivateMembershipOrderAction = id => ({
  type: constants.membershipOrders.DEACTIVATE_MEMBERSHIP_ORDER,
  id,
});

const exportMembershipOrdersAction = (filename, body) => ({
  type: constants.membershipOrders.EXPORT_MEMBERSHIP_ORDERS,
  filename,
  body,
});

export const membershipOrdersActions = {
  setMembershipOrdersLoadingAction,
  getMembershipOrdersAction,
  setMembershipOrdersAction,
  getMembershipOrderAction,
  setMembershipOrderAction,
  clearMembershipOrdersAction,
  deactivateMembershipOrderAction,
  exportMembershipOrdersAction,
};
