import React from 'react';
import { Button, Paper, WithSpinner } from '../../components';
import { isEmpty } from '../../utils';
import BusinessClientInfo from './businessClients.info.component';
import styles from './businessClients.module.scss';

const GeneralInfoTabContent = ({
  loading = false,
  values,
  sendPassword,
  setSendPassword,
  contractValidFrom,
  setContractValidFrom,
  contractValidTo,
  setContractValidTo,
  handleContractValidFrom,
  handleContractValidTo,
  handleImpersonate,
  handleSubmit,
  handleInputChange,
  handleCheckboxChange,
  handleFileUpload,
  handleLogotypeUpload,
  managerAdmins,
  countries,
  companyDetails,
  companyDetailsByCode,
  searchCompanyDetails,
  searchCompanyDetailsByCode,
  companyDetailsLoading,
  companyDetailsError,
  companyDetailsByCodeError,
  getSingleCompanyDetails,
}) => (
  <Paper>
    {!isEmpty(values) && (
      <BusinessClientInfo
        businessClient={values}
        sendPassword={sendPassword}
        setSendPassword={setSendPassword}
        newContractValidFrom={contractValidFrom}
        setNewContractValidFrom={setContractValidFrom}
        newContractValidTo={contractValidTo}
        setNewContractValidTo={setContractValidTo}
        onInputChange={handleInputChange}
        onCheckboxChange={handleCheckboxChange}
        setContractValidFrom={handleContractValidFrom}
        setContractValidTo={handleContractValidTo}
        onFileUpload={handleFileUpload}
        onLogotypeUpload={handleLogotypeUpload}
        managerAdmins={managerAdmins}
        countries={countries}
        companyDetails={companyDetails}
        companyDetailsByCode={companyDetailsByCode}
        searchCompanyDetails={searchCompanyDetails}
        searchCompanyDetailsByCode={searchCompanyDetailsByCode}
        companyDetailsLoading={companyDetailsLoading}
        companyDetailsError={companyDetailsError}
        companyDetailsByCodeError={companyDetailsByCodeError}
        getSingleCompanyDetails={getSingleCompanyDetails}
      />
    )}
    {
      <div className={styles.submitContainer}>
        <Button color="primary" onClick={handleImpersonate}>
          Prisijungti
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          Išsaugoti
        </Button>
      </div>
    }
    <WithSpinner loading={loading}> </WithSpinner>
  </Paper>
);
export default GeneralInfoTabContent;
