import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './search.module.scss';
import { actions } from '../../../../state';
import { config } from '../../../../api/config';
import {
  useClickOutside,
  isBusinessAdmin as isBusinessAdminFunc,
} from '../../../../utils';
import { Spinner, Icon } from '../../../../components';
import { useDebouncedCallback } from 'use-debounce';

const Search = ({
  dispatch,
  search: { loading, error, results, h2CardsResults },
  user,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isBusinessAdmin, setIsBusinessAdmin] = useState(false);

  const componentRef = useRef();

  const [isOpen, setIsOpen] = useClickOutside(componentRef);

  const handleSearch = useDebouncedCallback(value => {
    dispatch(actions.search.getClientsSearchAction(value));
    dispatch(actions.search.getH2CardsSearchAction(value));
  }, 800);

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    handleSearch(value);
  };

  const renderResultsList = useMemo(() => {
    const allResults = [...results, ...h2CardsResults];

    return allResults.map(({ id, business_client_id, name, number, phone }) => {
      const isH2Card = !!number;
      const resultKey = `${id}-${isH2Card ? 'card' : 'client'}`;
      const resultTitle = isH2Card ? `${name} (${number})` : phone;
      const resultLink = isH2Card
        ? config.APP_PATHS.editBusinessClient(business_client_id)
        : config.APP_PATHS.editClient(id);

      return (
        <li
          className={styles.item}
          onClick={() => setIsOpen(false)}
          key={resultKey}
        >
          <Link to={resultLink} className={styles.link}>
            {resultTitle}
          </Link>
        </li>
      );
    });
  }, [results, h2CardsResults]);

  const renderResults = useCallback(() => {
    if (loading) {
      return (
        <li className={styles.item}>
          <Spinner />
        </li>
      );
    }

    if (error) {
      return (
        <li className={styles.item}>
          <h6 className={styles.link}>{error}</h6>
        </li>
      );
    }

    if (results.length || h2CardsResults.length) {
      return renderResultsList;
    } else {
      return (
        <li className={styles.item}>
          <h6 className={styles.link}>Nieko nerasta</h6>
        </li>
      );
    }
  }, [loading, styles, error, results, h2CardsResults, renderResultsList]);

  useEffect(() => {
    setIsBusinessAdmin(user && isBusinessAdminFunc(user.role));
  }, [user]);

  return isBusinessAdmin ? (
    <></>
  ) : (
    <div className={styles.container} ref={componentRef}>
      <Icon name="search" classes={{ icon: styles.icon }} />
      <input
        id="search"
        className={styles.input}
        type="search"
        onFocus={() => setIsOpen(true)}
        onChange={handleInputChange}
        value={inputValue}
        autoComplete="off"
        placeholder="Paieška"
      />
      {isOpen && <ul className={styles.list}>{renderResults()}</ul>}
    </div>
  );
};

const mapStateToProps = state => ({
  search: state.search,
  user: state.auth.user,
});

Search.propTypes = {
  dispatch: PropTypes.func.isRequired,
  search: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        phone: PropTypes.string.isRequired,
      })
    ).isRequired,
    h2CardsResults: PropTypes.arrayOf(
      PropTypes.shape({
        business_client_id: PropTypes.number.isRequired,
        business_client_name: PropTypes.string.isRequired,
        current_balance: PropTypes.number.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
  user: PropTypes.object,
};

export default connect(mapStateToProps)(Search);
