import React, { createContext, useState } from 'react';

export const TextileContext = createContext();

export const TextileProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState('');
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState('');
  const [timeLtFilter, setTimeLtFilter] = useState('00:00');
  const [timeGtFilter, setTimeGtFilter] = useState('00:00');
  const [programFilter, setProgramFilter] = useState([]);
  const [codeFilter, setCodeFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');

  return (
    <TextileContext.Provider
      value={{
        filterBody,
        setFilterBody,
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
        timeLtFilter,
        setTimeLtFilter,
        timeGtFilter,
        setTimeGtFilter,
        programFilter,
        setProgramFilter,
        codeFilter,
        setCodeFilter,
        statusFilter,
        setStatusFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </TextileContext.Provider>
  );
};
