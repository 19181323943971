import React from 'react';
import PropTypes from 'prop-types';

import styles from './membershipOrders.module.scss';
import {
  formatDate,
  mapMembershipType,
  mapPaymentType,
  mapMembershipOrderStatus,
  formatMoney,
} from '../../utils';
import { TitleSecondary } from '../../components';

const OrderInfo = ({ order }) => {
  const {
    client_phone,
    membership_type,
    payment_type,
    valid_from,
    status,
    cost,
    valid_until,
  } = order;
  const info = [
    {
      title: 'Vartotojas',
      data: client_phone,
    },
    {
      title: 'Paso tipas',
      data: mapMembershipType(membership_type),
    },
    {
      title: 'Mokėjimo tipas',
      data: mapPaymentType(payment_type),
    },
    {
      title: 'Užsakymo suma su PVM',
      data: formatMoney(cost),
    },
    {
      title: 'Galioja nuo',
      data: formatDate(valid_from),
    },
    {
      title: 'Galioja iki',
      data: formatDate(valid_until),
    },
    {
      title: 'Statusas',
      data: mapMembershipOrderStatus(status),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <article className={styles.document}>
      <TitleSecondary>Užsakymo informacija</TitleSecondary>

      <div className={styles.info}>{renderInfo()}</div>
    </article>
  );
};

OrderInfo.propTypes = {
  order: PropTypes.shape({
    client_phone: PropTypes.string.isRequired,
    membership_type: PropTypes.number.isRequired,
    payment_type: PropTypes.number.isRequired,
    valid_from: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
  }).isRequired,
};

export default OrderInfo;
