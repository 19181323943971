import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../../api/api';
import { config } from '../../../api/config';
import { actions } from '../../actions';

import { errorMessage, downloadFile } from '../../../utils';

export function* getOrderHistorySaga({ body: initialBody }) {
  try {
    yield put(actions.orderHistory.setOrderHistoryLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { orders, count, header, footer } = yield call(
      Api.getBusinessClientOrders,
      body
    );

    yield put(
      actions.orderHistory.setOrderHistoryAction(orders, count, header, footer)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orderHistory.setOrderHistoryLoadingAction(false));
  }
}

export function* getHistoricalOrderSaga({ id }) {
  try {
    yield put(actions.orderHistory.setOrderHistoryLoadingAction(true));

    const order = yield call(Api.getBusinessClientOrder, id);

    yield put(actions.orderHistory.setOrderAction(order));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orderHistory.setOrderHistoryLoadingAction(false));
  }
}

export function* exportOrderHistorySaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportOrders, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportH2CardAsyncSaga({ filter }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportH2CardAsync, filter);

    yield put(actions.orderHistory.setExportIdentifierAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
