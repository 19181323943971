import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { selectClient } from '../selectors';
import { downloadFile, errorMessage } from '../../utils';

export function* getClientsSaga({ body: initialBody }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { clients, count, header } = yield call(Api.getClients, body);

    yield put(actions.clients.setClientsAction(clients, count, header));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* getClientSaga({ id }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const client = yield call(Api.getClient, id);

    yield put(actions.clients.setClientAction(client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* updateClientSaga({ id, body }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const client = yield call(Api.updateClient, id, body);

    yield put(actions.clients.setClientAction(client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* deactivateClientsMembershipOrderSaga({ orderId }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    yield call(Api.deactivateMembershipOrder, orderId);

    yield put(actions.clients.deleteMembershipOrderAction(orderId));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* exportClientsSaga({ filename }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportClients);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* getClientCardsSaga({ clientId }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const cards = yield call(Api.getClientCards, clientId);

    yield put(actions.clients.setClientCards(cards));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* deleteClientDataSaga({ id }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const client = yield call(Api.deleteClientData, id);

    yield put(actions.clients.setClientAction(client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* topUpWalletSaga({ clientId, topUpAmount }) {
  try {
    yield put(actions.clients.setClientsLoadingAction(true));

    const topUp = yield call(Api.topUpWallet, clientId, topUpAmount);
    const client = yield call(Api.getClient, topUp.client_id);

    yield put(actions.clients.setClientAction(client));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.clients.setClientsLoadingAction(false));
  }
}

export function* exportClientsAsyncSaga({ filter }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportClientsAsync, filter);

    yield put(actions.clients.setExportIdentifierAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
