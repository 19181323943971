export const invoicesConstants = {
  SET_INVOICES_LOADING: 'invoices/SET_INVOICES_LOADING',
  GET_INVOICES: 'invoices/GET_INVOICES',
  SET_INVOICES: 'invoices/SET_INVOICES',
  CLEAR_INVOICES: 'invoices/CLEAR_INVOICES',
  GET_INVOICE: 'invoices/GET_INVOICE',
  SET_INVOICE: 'invoices/SET_INVOICE',
  EXPORT_INVOICES: 'invoices/EXPORT_INVOICES',
  EXPORT_INVOICES_ASYNC: 'invoices/EXPORT_INVOICES_ASYNC',
  EXPORT_INVOICES_HEADS: 'invoices/EXPORT_INVOICES_HEADS',
  EXPORT_INVOICES_BODIES: 'invoices/EXPORT_INVOICES_BODIES',
  EXPORT_INVOICES_PARTNERS: 'invoices/EXPORT_INVOICES_PARTNERS',
  EXPORT_INVOICES_XML: 'invoices/EXPORT_INVOICES_XML',
  GENERATE_MONTHLY_INVOICES: 'invoices/GENERATE_MONTHLY_INVOICES',
  SET_MONTHLY_GENERATING: 'invoices/SET_MONTHLY_GENERATING',
  SET_INVOICE_EXPORT_IDENTIFIER: 'invoices/SET_INVOICE_EXPORT_IDENTIFIER',
  UPDATE_PAYMENT_STATUS: 'invoices/UPDATE_PAYMENT_STATUS',
  DOWNLOAD_PDF: 'invoices/DOWNLOAD_PDF',
  UPDATE_INVOICE: 'invoices/UPDATE_INVOICE',
};
