import { format } from 'date-fns';
import { config } from '../../api/config';

export const formatDate = date =>
  format(new Date(date), config.DEFAULTS.dateFormat);

export const formatMoney = ammount => {
  let money = 0;

  if (ammount) {
    money = ammount / 100;
  }
  money = money.toLocaleString(config.DEFAULTS.locale, {
    style: 'currency',
    maximumFractionDigits: 3,
    currency: 'EUR',
  });

  return money;
};

export const formatTime = string => {
  return string;
};

export const formatSecondsToMinutes = seconds => {
  const minutes = new Date(seconds * 1000).toISOString().substr(14, 5);
  return minutes;
};

export const formatMoneyToCents = money => {
  let cents = +money;
  cents = cents * 100;
  cents = parseInt(cents);

  return cents;
};

export const formatFilename = base =>
  `${base} ${format(new Date(), 'yyyy-MM-dd')}`;

export const formatPercent = percent => {
  return `${percent * 100} %`;
};

export const getTimestamp = (date, time) => {
  const currentTimestamp = Date.now();

  if (!date) return currentTimestamp;

  const preparedTime = time ?? format(currentTimestamp, 'HH:mm');
  return new Date(`${date}T${preparedTime}`).getTime();
};

//create timestamp from Date & time
export const formatDateTime = (payload, time) => {
  const date = new Date(payload);

  if (time) {
    const [hours, minutes] = time.split(':');

    date.setHours(hours);
    date.setMinutes(minutes);
  }

  return date.getTime();
};
