import React from 'react';
import PropTypes from 'prop-types';

import styles from './guestLayout.module.scss';

const GuestLayout = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestLayout;
