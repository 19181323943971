import { constants } from '../constants';

const setCouponsLoadingAction = flag => ({
  type: constants.coupons.SET_COUPONS_LOADING,
  flag,
});

const setCouponsAction = (coupons, header, footer, count) => ({
  type: constants.coupons.SET_COUPONS,
  coupons,
  header,
  footer,
  count,
});

const exportCouponsAction = (filename, filter) => ({
  type: constants.coupons.EXPORT_COUPONS,
  filename,
  filter,
});

const exportCouponsCodeByCodeAction = (filename, couponId) => ({
  type: constants.coupons.EXPORT_COUPONS_CODE_BY_CODE,
  filename,
  couponId,
});

const getCouponsAction = filter => ({
  type: constants.coupons.GET_COUPONS,
  filter,
});

const clearCouponsAction = () => ({
  type: constants.coupons.CLEAR_COUPONS,
});

const getCouponAction = id => ({
  type: constants.coupons.GET_COUPON,
  id,
});

const setCouponAction = coupon => ({
  type: constants.coupons.SET_COUPON,
  coupon,
});

export const couponsActions = {
  setCouponsLoadingAction,
  setCouponsAction,
  exportCouponsAction,
  getCouponsAction,
  clearCouponsAction,
  getCouponAction,
  setCouponAction,
  exportCouponsCodeByCodeAction,
};
