import { constants } from '..';

const setDiscountsLoadingAction = flag => ({
  type: constants.discounts.SET_DISCOUNTS_LOADING,
  flag,
});

const setDiscountBannerUploadingAction = flag => ({
  type: constants.discounts.SET_BANNER_UPLOADING,
  flag,
});

const setDiscountBannerAction = banner => ({
  type: constants.discounts.SET_BANNER,
  banner,
});

const getDiscountsAction = () => ({
  type: constants.discounts.GET_DISCOUNTS,
});

const setDiscountsAction = (discounts, count) => ({
  type: constants.discounts.SET_DISCOUNTS,
  discounts,
  count,
});

const clearDiscountsAction = () => ({
  type: constants.discounts.CLEAR_DISCOUNTS,
});

const addDiscountAction = (body, history) => ({
  type: constants.discounts.ADD_DISCOUNT,
  body,
  history,
});

const getDiscountAction = id => ({
  type: constants.discounts.GET_DISCOUNT,
  id,
});

const setDiscountAction = discount => ({
  type: constants.discounts.SET_DISCOUNT,
  discount,
});

const editDiscountAction = (id, body, history) => ({
  type: constants.discounts.EDIT_DISCOUNT,
  id,
  body,
  history,
});

const exportDiscountsAction = filename => ({
  type: constants.discounts.EXPORT_DISCOUNTS,
  filename,
});

const deactivateDiscountAction = (id, history) => ({
  type: constants.discounts.DEACTIVATE_DISCOUNT,
  id,
  history,
});

const uploadDiscountBanner = file => ({
  type: constants.discounts.UPLOAD_IMAGE,
  file,
});

export const discountsActions = {
  setDiscountsLoadingAction,
  setDiscountBannerUploadingAction,
  setDiscountBannerAction,
  getDiscountsAction,
  setDiscountsAction,
  addDiscountAction,
  getDiscountAction,
  setDiscountAction,
  editDiscountAction,
  exportDiscountsAction,
  clearDiscountsAction,
  deactivateDiscountAction,
  uploadDiscountBanner,
};
