import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './employees.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import {
  isEmpty,
  centsToMoney,
  moneyToCents,
  employeeIsCard,
} from '../../../utils';
import EmployeeInfo from './employees.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Toggle,
  Paper,
  PaperNav,
  PaperContainer,
  ButtonLink,
  Button,
  Checkbox,
} from '../../../components';
import { employeesActions } from '../../../state/businessAdmin/employees/employees.actions';
import { useMemo } from 'react';

const EditEmployee = ({
  dispatch,
  history,
  match: { params },
  employees: { loading, selectedEmployee },
  memberships: { memberships },
}) => {
  const [isPhysicalCard, setIsPhysicalCard] = useState(false);
  const [values, setValues] = useState({
    ...selectedEmployee,
    credit_limit: centsToMoney(selectedEmployee.credit_limit),
  });
  const [impersonationInProgress, setImpersonationInProgress] = useState(false);

  useEffect(() => {}, history);

  const fetchEmployee = useCallback(
    id => {
      if (history.location.pathname.includes(config.APP_PATHS.editH2Card(id))) {
        dispatch(actions.employees.getH2CardAction(id));
        setIsPhysicalCard(true);
      } else {
        dispatch(actions.employees.getEmployeeAction(id));
        setIsPhysicalCard(false);
      }
    },
    [dispatch]
  );

  const clearEmployee = useCallback(() => {
    dispatch(actions.employees.clearEmployeesAction());
  }, [dispatch]);

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedEmployee)) {
      if (isPhysicalCard) {
        setValues({
          id: selectedEmployee.id,
          full_name: selectedEmployee.name,
          phone: selectedEmployee.number,
          credit_limit: centsToMoney(selectedEmployee.top_up_limit),
          remaining_credit: selectedEmployee.current_actual_balance,
          status: selectedEmployee.status,
          active_top_up_sum: selectedEmployee.active_top_up_sum,
          active_top_up_count: selectedEmployee.active_top_up_count,
          last_system_top_up_date: selectedEmployee.last_system_top_up_date,
          created_at: selectedEmployee.created_at,
        });
      } else {
        setValues({
          ...selectedEmployee,
          credit_limit: centsToMoney(selectedEmployee.credit_limit),
        });
      }
    }
  }, [selectedEmployee]);

  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMemberships();
    return () => {
      clearMemberships();
    };
  }, [fetchMemberships, clearMemberships]);

  const toggleStatus = () => {
    if (isPhysicalCard)
      dispatch(actions.employees.changeH2CardStatus(values.id, !values.status));
    else
      dispatch(
        actions.employees.changeEmployeeStatus(values.id, !values.status)
      );

    setValues({
      ...values,
      status: !values.status,
    });
  };

  useEffect(() => {
    fetchEmployee(params.id);
    return () => clearEmployee();
  }, [fetchEmployee, clearEmployee, params.id]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  useEffect(() => {
    const isImpersonatingKey = config.LOCAL_STORAGE.isImpersonating;

    if (localStorage.hasOwnProperty(isImpersonatingKey)) {
      const impersonate = localStorage.getItem(isImpersonatingKey);
      setImpersonationInProgress(impersonate);
    } else setImpersonationInProgress(false);
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (isPhysicalCard) {
      dispatch(
        actions.employees.updateH2Card(
          params.id,
          {
            number: values.phone,
            top_up_limit: moneyToCents(values.credit_limit),
            name: values.full_name,
          },
          history
        )
      );
    } else {
      dispatch(
        actions.employees.updateEmployee(
          values.id,
          {
            full_name: values.full_name,
            phone: values.phone,
            credit_limit: moneyToCents(values.credit_limit),
            membership_id: values.membership_id
              ? Number(values.membership_id)
              : null,
          },
          history
        )
      );
    }
  };

  const membershipOptions = useMemo(() => {
    let options = [];
    if (memberships) {
      options = memberships.map(
        ({ id, title_lt, license_count, assigned_license_count }) => ({
          name: `${title_lt} (${license_count -
            (assigned_license_count || 0)})`,
          value: id,
        })
      );
    }
    options.unshift({ name: 'Planas nepasirinktas', value: '' });
    return options;
  }, [memberships]);

  const disableToggle = useMemo(
    () => !impersonationInProgress && isPhysicalCard && !values.status,
    [isPhysicalCard, values.status, impersonationInProgress]
  );

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.baEmployees}
            baseUrl={config.APP_PATHS.baEmployees}
          />
        </div>
        <Toggle
          onChange={toggleStatus}
          checked={!!values.status}
          showMeta={true}
          onText="Aktyvus"
          offText="Deaktyvuotas"
          disabled={disableToggle}
        />
      </Header>

      <PaperContainer>
        <Paper>
          {!isEmpty(selectedEmployee) && (
            <EmployeeInfo
              employee={values}
              onInputChanged={handleInputChange}
              isPhysicalCard={isPhysicalCard}
              membershipOptions={membershipOptions}
            />
          )}
          {
            <div className={styles.submitContainer}>
              <Button color="primary" onClick={handleSubmit} disabled={loading}>
                Išsaugoti
              </Button>
            </div>
          }
          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>

        <PaperNav>
          <ButtonLink
            icon="nav-orders"
            url={config.APP_PATHS.baOrderHistoryByClient(
              selectedEmployee.phone
            )}
          >
            Užsakymai ({selectedEmployee ? selectedEmployee.order_count : 0})
          </ButtonLink>

          <ButtonLink
            icon="nav-invoices"
            //url={config.APP_PATHS.invoice(selectedEmployee.phone)}
          >
            {config.PAGES.baInvoices} (0)
          </ButtonLink>
        </PaperNav>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  employees: state.employees,
  memberships: state.memberships,
});

EditEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  employees: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedEmployee: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditEmployee);
