import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './toggle.module.scss';

const Toggle = ({
  checked,
  onChange,
  showMeta,
  size,
  onText,
  offText,
  disabled,
}) => (
  <div
    className={cn(styles.container, styles[size], disabled && styles.disabled)}
  >
    {showMeta && (
      <span className={!checked ? styles.metaOff : ''}>{offText}</span>
    )}
    <div className={cn(styles.toggle, styles[size])}>
      <input
        type="checkbox"
        className={cn(styles.checkbox, disabled && styles.disabled)}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={cn(styles.switch, styles[size])} />
      <span className={cn(styles.layer, styles[size])} />
    </div>
    {showMeta && <span className={checked ? styles.metaOn : ''}>{onText}</span>}
  </div>
);

Toggle.defaultProps = {
  size: 'lg',
  showMeta: false,
  onText: 'Įjungta',
  offText: 'Išjungta',
  disabled: false,
};

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showMeta: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg']),
  onText: PropTypes.string,
  offText: PropTypes.string,
};

export default Toggle;
