import { constants } from '..';

const setAdminsLoadingAction = flag => ({
  type: constants.admins.SET_ADMINS_LOADING,
  flag,
});

const getAdminsAction = () => ({
  type: constants.admins.GET_ADMINS,
});

const clearAdminsAction = () => ({
  type: constants.admins.CLEAR_ADMINS,
});

const setAdminsAction = (admins, count) => ({
  type: constants.admins.SET_ADMINS,
  admins,
  count,
});

const addAdminAction = (body, history) => ({
  type: constants.admins.ADD_ADMIN,
  body,
  history,
});

const getAdminAction = id => ({
  type: constants.admins.GET_ADMIN,
  id,
});

const setAdminAction = admin => ({
  type: constants.admins.SET_ADMIN,
  admin,
});

const editAdminAction = (id, body, history) => ({
  type: constants.admins.EDIT_ADMIN,
  id,
  body,
  history,
});

const deactivateAdminAction = (id, newStatus) => ({
  type: constants.admins.DEACTIVATE_ADMIN,
  id,
  newStatus,
});

export const adminsActions = {
  setAdminsLoadingAction,
  getAdminsAction,
  clearAdminsAction,
  setAdminsAction,
  addAdminAction,
  getAdminAction,
  setAdminAction,
  editAdminAction,
  deactivateAdminAction,
};
