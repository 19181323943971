import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './../addLocation/addWasher.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { isEmpty } from '../../../utils';
import { deviceStatuses, checkForEnabled } from '../locations.utils';
import Maps from '../../../components/maps/googleMaps';
import ServicesBox from './../services/services';
import {
  Breadcrumb,
  Toggle,
  Paper,
  Header,
  TitleSecondary,
  SubmitActions,
  ButtonLink,
  PaperContainer,
  PaperNav,
  Input,
} from '../../../components';
import { socketsClient } from '../../../api/sockets';

const EditLocation = ({
  dispatch,
  history,
  match: { params },
  selectedLocation,
  loading,
  error,
}) => {
  const fetchSelectedLocation = useCallback(
    id => {
      dispatch(actions.locations.getSelectedLocationAction(id, history));
    },
    [dispatch, history]
  );
  const [requestError, setRequestError] = useState(false);

  // device_id: 279
  // location_id: 73
  // service_id: 160
  // status: 0
  // timestamp: 1611312187783

  useEffect(() => {
    if (socketsClient) {
      const channel = socketsClient.subscribe('device_update');

      channel.watch(({ device_id, location_id, status }) => {
        if (location_id === selectedLocation.id) {
          const deviceMapper = device => {
            if (device.id === device_id) {
              return { ...device, status };
            }
            return device;
          };

          const newSelectedLocation = {
            ...selectedLocation,
            automatic_contactless_washing: selectedLocation.automatic_contactless_washing
              ? {
                  ...selectedLocation.automatic_contactless_washing,
                  devices: selectedLocation.automatic_contactless_washing.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            automatic_textile_washing: selectedLocation.automatic_textile_washing
              ? {
                  ...selectedLocation.automatic_textile_washing,
                  devices: selectedLocation.automatic_textile_washing.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            car_mat_cleaner: selectedLocation.car_mat_cleaner
              ? {
                  ...selectedLocation.car_mat_cleaner,
                  devices: selectedLocation.car_mat_cleaner.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            paper_towel_dispenser: selectedLocation.paper_towel_dispenser
              ? {
                  ...selectedLocation.paper_towel_dispenser,
                  devices: selectedLocation.paper_towel_dispenser.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            self_service_washing: selectedLocation.self_service_washing
              ? {
                  ...selectedLocation.self_service_washing,
                  devices: selectedLocation.self_service_washing.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            smart_vacuum_cleaner: selectedLocation.smart_vacuum_cleaner
              ? {
                  ...selectedLocation.smart_vacuum_cleaner,
                  devices: selectedLocation.smart_vacuum_cleaner.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            vacuum_cleaner: selectedLocation.vacuum_cleaner
              ? {
                  ...selectedLocation.vacuum_cleaner,
                  devices: selectedLocation.vacuum_cleaner.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            windshield_washer_liquid_dispenser: selectedLocation.windshield_washer_liquid_dispenser
              ? {
                  ...selectedLocation.windshield_washer_liquid_dispenser,
                  devices: selectedLocation.windshield_washer_liquid_dispenser.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            tyre_blackener: selectedLocation.tyre_blackener
              ? {
                  ...selectedLocation.tyre_blackener,
                  devices: selectedLocation.tyre_blackener.devices.map(
                    deviceMapper
                  ),
                }
              : null,
            towel_dispenser: selectedLocation.towel_dispenser
              ? {
                  ...selectedLocation.towel_dispenser,
                  devices: selectedLocation.towel_dispenser.devices.map(
                    deviceMapper
                  ),
                }
              : null,
          };

          dispatch(
            actions.locations.setSelectedLocationAction(newSelectedLocation)
          );
        }
      });
    }

    return () => {
      socketsClient.unsubscribe('device_update');
    };
  }, []);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  useEffect(() => {
    fetchSelectedLocation(params.id);
  }, [fetchSelectedLocation, params.id]);

  const handleSave = e => {
    dispatch(actions.locations.addLocationsAction());
  };

  const activeServices = {
    automatic_contactless_washing:
      selectedLocation.automatic_contactless_washing,
    automatic_textile_washing: selectedLocation.automatic_textile_washing,
    car_mat_cleaner: selectedLocation.car_mat_cleaner,
    paper_towel_dispenser: selectedLocation.paper_towel_dispenser,
    self_service_washing: selectedLocation.self_service_washing,
    vacuum_cleaner: selectedLocation.vacuum_cleaner,
    smart_vacuum_cleaner: selectedLocation.smart_vacuum_cleaner,
    windshield_washer_liquid_dispenser:
      selectedLocation.windshield_washer_liquid_dispenser,
    tyre_blackener: selectedLocation.tyre_blackener,
    towel_dispenser: selectedLocation.towel_dispenser,
  };

  const address = {
    address: selectedLocation.address,
    city: selectedLocation.city,
    coordinates: selectedLocation.coordinates,
  };

  const changeLocationStatus = status => {
    const statuses = deviceStatuses(selectedLocation);
    const containsEnabled = checkForEnabled(statuses);

    if (!containsEnabled) {
      dispatch(
        actions.error.setErrorAction({
          msg:
            'Norėdami aktyvuoti plovyklą - įjunkite bent vieną paslaugą ir išsaugokite pakeitimus',
        })
      );
    } else {
      const payload = {
        modalName: 'confirmModal',
        title: status === 1 ? 'Deaktyvuoti lokaciją' : 'Aktyvuoti lokaciją',
        desc:
          status === 1
            ? 'Ar tikrai norite deaktyvuoti visą lokaciją?'
            : 'Ar tikrai norite įjungti lokaciją?',
        onConfirm: () =>
          dispatch(
            actions.addEditServices.handleLocationStatusChangeAction(
              'all',
              status
            )
          ),
      };
      dispatch(actions.modals.setModalVisible(payload));
    }
  };

  const openQrCodesAllModal = () => {
    const payload = {
      modalName: 'qrCodesAllModal',
      title: 'Aparatų QR kodai',
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <section>
      {!isEmpty(selectedLocation) && (
        <>
          <Header>
            <Breadcrumb
              title={config.PAGES.locations}
              subtitle={`${selectedLocation.address}, ${selectedLocation.city}`}
              baseUrl={config.APP_PATHS.locations}
            />

            <Toggle
              checked={selectedLocation.status === 1 ? true : false}
              onChange={() => changeLocationStatus(selectedLocation.status)}
              showMeta={true}
            />
          </Header>

          <PaperContainer>
            <Paper>
              <div className={styles.container}>
                <TitleSecondary>Adresas</TitleSecondary>
                <Maps
                  address={address}
                  invalidInput={
                    requestError &&
                    !(
                      selectedLocation.city &&
                      selectedLocation.coordinates &&
                      selectedLocation.address
                    )
                  }
                />
              </div>
            </Paper>

            <PaperNav>
              <ButtonLink icon="qr-code" onClick={() => openQrCodesAllModal()}>
                QR Kodai
              </ButtonLink>

              <ButtonLink
                icon="nav-orders"
                url={config.APP_PATHS.ordersByLocation(selectedLocation.id)}
              >
                {config.PAGES.orders} ({selectedLocation.order_count})
              </ButtonLink>
            </PaperNav>
          </PaperContainer>

          <Paper size="lg">
            <div className={styles.container}>
              <TitleSecondary>Paslaugos</TitleSecondary>

              <ServicesBox
                data={activeServices}
                requestError={requestError}
                fetchSelectedLocation={fetchSelectedLocation}
              />
            </div>

            <SubmitActions
              back={config.APP_PATHS.locations}
              loading={loading}
              onClick={handleSave}
            />
          </Paper>
        </>
      )}
    </section>
  );
};

const mapStateToProps = state => ({
  selectedLocation: state.locations.selectedLocation,
  loading: state.locations.loading,
  error: state.error.error,
});

EditLocation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(EditLocation);
