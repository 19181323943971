import React from 'react';
import PropTypes from 'prop-types';

import styles from './textareaElement.module.scss';

const TextareaElement = ({ name, onChange, value, ...rest }) => {
  return (
    <textarea
      className={styles.textarea}
      name={name}
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
};

TextareaElement.defaultProps = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default TextareaElement;
