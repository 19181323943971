import React from 'react';
import PropTypes from 'prop-types';

import styles from './submitActions.module.scss';
import { Button, Spinner, Alert } from '../../components';

const SubmitActions = ({
  confirm,
  cancel,
  back,
  loading,
  disabled,
  ...rest
}) => (
  <div className={styles.actions}>
    <Alert />
    {loading && <Spinner />}
    <Button color="dim" url={back}>
      {cancel}
    </Button>
    <Button
      color="primary"
      type="submit"
      disabled={disabled || loading}
      {...rest}
    >
      {confirm}
    </Button>
  </div>
);

SubmitActions.defaultProps = {
  confirm: 'Išsaugoti',
  cancel: 'Atšaukti',
  disabled: false,
};

SubmitActions.propTypes = {
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  back: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default SubmitActions;
