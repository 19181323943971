import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getTime, startOfDay, endOfDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import { formatMoney } from '../../utils';

import styles from './businessClients.module.scss';
import {
  Toggle,
  TitleSecondary,
  ButtonSimple,
  Input,
  Checkbox,
} from '../../components';

const BusinessClientFinanceInfo = ({
  businessClient: {
    ongoingDebt,
    total_unpaid_invoice_cost,
    total_debt,
    remaining_credit,
  },
}) => {
  const info = [
    {
      title: 'Einamojo mėn. skola',
      data: <span>{formatMoney(ongoingDebt)}</span>,
    },
    {
      title: 'Neapmokėta skola',
      data: <span>{formatMoney(total_unpaid_invoice_cost)}</span>,
    },
    {
      title: 'Bendra skola',
      data: <span>{formatMoney(total_debt)}</span>,
    },
    {
      title: 'Limito likutis',
      data: <span>{formatMoney(remaining_credit)}</span>,
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <div className={styles.document} style={{ marginTop: 49 }}>
      <div className={styles.financeInfo}>{renderInfo()}</div>
    </div>
  );
};

BusinessClientFinanceInfo.defaultProps = {
  ongoingDebt: 0,
  total_unpaid_invoice_cost: 0,
  total_debt: 0,
  remaining_credit: 0,
};

BusinessClientFinanceInfo.propTypes = {
  businessClient: PropTypes.shape({
    ongoingDebt: PropTypes.number,
    total_unpaid_invoice_cost: PropTypes.number,
    total_debt: PropTypes.number,
    remaining_credit: PropTypes.number,
  }).isRequired,
};

export default BusinessClientFinanceInfo;
