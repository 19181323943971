import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { isEmpty } from '../../utils';
import { availablePermissions } from './admins.data';
import Form from './admins.form';
import {
  Paper,
  PaperHeader,
  TitleSecondary,
  Checkbox,
  Toggle,
} from '../../components';

const EditAdmin = ({
  dispatch,
  history,
  match: { params },
  admins: { loading, selectedAdmin },
}) => {
  const [permissions, setPermissions] = useState([]);
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    business_role: 0,
  });
  const [status, setStatus] = useState(false);

  const fetchAdmin = useCallback(
    id => {
      dispatch(actions.admins.getAdminAction(id));
    },
    [dispatch]
  );

  const loadValues = useCallback(() => {
    if (!isEmpty(selectedAdmin)) {
      const {
        first_name,
        last_name,
        email,
        permissions,
        status,
        phone,
        business_role,
      } = selectedAdmin;
      setValues({
        first_name,
        last_name,
        email,
        password: '',
        phone,
        business_role,
      });
      setPermissions([...permissions]);
      setStatus(status === 1 ? true : false);
    }
  }, [selectedAdmin]);

  const clearAdmins = useCallback(() => {
    dispatch(actions.admins.clearAdminsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchAdmin(params.id);
    return () => clearAdmins();
  }, [fetchAdmin, params.id, clearAdmins]);

  useEffect(() => {
    loadValues();
  }, [loadValues]);

  const handleInputChange = ({ target: { name, value } }) => {
    const updatedValue = name === 'business_role' ? Number(value) : value;
    setValues({ ...values, [name]: updatedValue });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { first_name, last_name, password, phone, business_role } = values;
    const body = {
      first_name: first_name,
      last_name: last_name,
      password: password.length ? password : null,
      phone: phone,
      business_role: business_role || null,
      permissions,
    };
    dispatch(actions.admins.editAdminAction(params.id, body, history));
  };

  const isChecked = value => {
    const values = value.split(',').map(item => +item);
    const checked = permissions.some(per => values.includes(per));
    return checked;
  };

  const handleCheckboxChange = ({ target: { value, checked } }) => {
    let newPermisions = permissions;
    const values = value.split(',').map(item => +item);

    if (checked) {
      newPermisions = [...permissions, ...values];
    } else {
      newPermisions = permissions.filter(
        permission => !values.includes(permission)
      );
    }

    setPermissions([...newPermisions]);
  };

  const renderPermissions = () =>
    availablePermissions.map(({ name, value }) => (
      <Checkbox
        key={name}
        label={name}
        name={name}
        checked={isChecked(value)}
        value={value}
        onChange={handleCheckboxChange}
      />
    ));

  const toggleStatus = () => {
    const newStatus = status ? 0 : 1;
    setStatus(status => !status);

    dispatch(actions.admins.deactivateAdminAction(params.id, newStatus));
  };

  return (
    <Paper size="lg">
      <PaperHeader>
        <TitleSecondary>Redaguoti administratoriaus paskyrą</TitleSecondary>
        <Toggle
          onChange={toggleStatus}
          checked={status}
          showMeta={true}
          onText="Aktyvus"
          offText="Deaktyvuotas"
        />
      </PaperHeader>

      <Form
        type="edit"
        values={values}
        onChange={handleInputChange}
        onSubmit={handleSubmit}
        loading={loading}
      >
        {renderPermissions()}
      </Form>
    </Paper>
  );
};

const mapStateToProps = state => ({
  admins: state.admins,
});

EditAdmin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedAdmin: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EditAdmin);
