import { call, put, select } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '../actions';
import { config } from '../../api/config';
import { errorMessage, downloadFile } from '../../utils';

export function* getCouponsSaga({ filter: initialFilter }) {
  try {
    yield put(actions.coupons.setCouponsLoadingAction(true));

    const body = {
      ...initialFilter,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { coupons, header, footer, count } = yield call(Api.getCoupons, body);

    yield put(actions.coupons.setCouponsAction(coupons, header, footer, count));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.coupons.setCouponsLoadingAction(false));
  }
}

export function* exportCouponsSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportCoupons, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* getCouponSaga({ id }) {
  try {
    yield put(actions.coupons.setCouponsLoadingAction(true));

    const coupon = yield call(Api.getCoupon, id);

    yield put(actions.coupons.setCouponAction(coupon));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.coupons.setCouponsLoadingAction(false));
  }
}

export function* exportCouponsCodeByCodeSaga({ filename, couponId }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportCouponsCodeByCode, couponId);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
