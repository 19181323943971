export const selectUserToken = state => state.auth.token;

export const selectClient = state => state.clients.selectedClient;

export const selectAddEditServicesdata = state => state.addEditServices;
export const selectLocationId = state => state.locations.selectedLocation.id;
export const selectCurrentLocationStatus = state =>
  state.locations.selectedLocation.status;
export const selectDiscountBanner = state => state.discounts.discountBanner;
export const selectDiscountBannerLoading = state =>
  state.discounts.bannerUploading;
export const selectBusinessClientContract = state =>
  state.businessClients.contract;
export const selectBusinessClientLogotype = state =>
  state.businessClients.logotype;
export const selectIsImpersonating = state => state.auth.isImpersonating;
