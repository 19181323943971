import React from 'react';
import cn from 'classnames';

import styles from './tabs.module.scss';

const Tabs = props => {
  const { tabsContent, activeTab, setActiveTab, children } = props;

  return (
    <div>
      <div className={styles.tabGroup}>
        {tabsContent.map(({ title, tabIdx }) => {
          return (
            <button
              key={title}
              onClick={() => setActiveTab(tabIdx)}
              className={cn(styles.tab, activeTab === tabIdx && styles.active)}
            >
              <span className={cn(activeTab === tabIdx && styles.trapezoid)}>
                {title}
              </span>
            </button>
          );
        })}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Tabs;
