export const membershipOrdersConstants = {
  SET_MEMBERSHIP_ORDERS_LOADING:
    'membershipOrders/SET_MEMBERSHIP_ORDERS_LOADING',
  GET_MEMBERSHIP_ORDERS: 'membershipOrders/GET_MEMBERSHIP_ORDERS',
  SET_MEMBERSHIP_ORDERS: 'membershipOrders/SET_MEMBERSHIP_ORDERS',
  GET_MEMBERSHIP_ORDER: 'membershipOrders/GET_MEMBERSHIP_ORDER',
  SET_MEMBERSHIP_ORDER: 'membershipOrders/SET_MEMBERSHIP_ORDER',
  DEACTIVATE_MEMBERSHIP_ORDER: 'membershipOrders/DEACTIVATE_MEMBERSHIP_ORDER',
  CLEAR_MEMBERSHIP_ORDERS: 'membershipOrders/CLEAR_MEMBERSHIP_ORDERS',
  EXPORT_MEMBERSHIP_ORDERS: 'membershipOrders/EXPORT_MEMBERSHIP_ORDERS',
};
