import { constants } from '../constants';

const INITIAL_STATE = {
  loading: false,
  coupons: [],
  total: 0,
  selectedCoupon: {},
};

export const couponsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.coupons.SET_COUPONS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.coupons.SET_COUPONS:
      return {
        ...state,
        coupons: action.coupons,
        header: action.header,
        footer: action.footer,
        total: action.count,
      };
    case constants.coupons.SET_COUPON:
      return {
        ...state,
        selectedCoupon: action.coupon,
      };
    case constants.coupons.CLEAR_COUPONS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
