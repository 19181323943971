import React from 'react';
import PropTypes from 'prop-types';

import styles from './headerActions.module.scss';

const HeaderActions = ({ children }) => (
  <div className={styles.actions}>{children}</div>
);

HeaderActions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderActions;
