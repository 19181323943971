import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { actions } from '..';

export function* getAutomaticWashCodesTableSaga({ body }) {
  try {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(true));

    const { automatic_wash_codes, count, header, footer } = yield call(
      Api.getAutomaticWashCodesTable,
      body
    );

    yield put(
      actions.textile.setAutomaticWashCodesAction(
        automatic_wash_codes,
        count,
        header,
        footer
      )
    );
  } catch ({ error: { error } }) {
    yield put(actions.error.setErrorAction(error));
  } finally {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(false));
  }
}

export function* useWashCodeSaga({ id, body }) {
  try {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(true));

    const { resp } = yield call(Api.useAutomaticWashCode, id);

    const { automatic_wash_codes, count, header, footer } = yield call(
      Api.getAutomaticWashCodesTable,
      body
    );

    yield put(
      actions.textile.setAutomaticWashCodesAction(
        automatic_wash_codes,
        count,
        header,
        footer
      )
    );
  } catch ({ error: { error } }) {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(false));
  } finally {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(false));
  }
}

export function* removeWashCodeSaga({ id, body }) {
  try {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(true));

    const { resp } = yield call(Api.removeAutomaticWashCode, id);

    const { automatic_wash_codes, count, header, footer } = yield call(
      Api.getAutomaticWashCodesTable,
      body
    );

    yield put(
      actions.textile.setAutomaticWashCodesAction(
        automatic_wash_codes,
        count,
        header,
        footer
      )
    );
  } catch ({ error: { error } }) {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(false));
  } finally {
    yield put(actions.textile.setAutomaticWashCodesLoadingAction(false));
  }
}
