import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { config } from '../../api/config';
import { Spinner } from '../../components';

const PrivateRoute = ({
  component: Component,
  permission,
  userPermissions,
  loggedIn,
  loading,
  restricted,
  ...rest
}) => {
  if (loading) {
    return <Spinner />;
  }

  if (restricted) {
    if (!loggedIn) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
  } else {
    if (loggedIn && (userPermissions.includes(permission) || !permission)) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
  }

  return <Redirect to={config.APP_PATHS.home} />;
};

export default PrivateRoute;
