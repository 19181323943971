import React, { useEffect, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './invoices.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import store from '../../utils/redux/store';
import {
  formatMoney,
  formatFilename,
  formatDate,
  mapPaymentType,
  generateHeader,
  isFilterActive,
  mapInvoicePaymentStatus,
  mapInvoicePaymentStatusColor,
} from '../../utils';
import {
  Header,
  Title,
  ButtonDownload,
  Button,
  Table,
  TableRow,
  TableCell,
  SelectFilter,
  SelectAsyncFilter,
  HeaderActions,
  ButtonSimple,
  DateFilter,
} from '../../components';
import { InvoicesContext } from './invoices.context';
import { socketsClient } from '../../api/sockets';
import {
  INVOICE_STATUS_NOT_PAID,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PARTIALLY_PAID,
} from './constants';

const Invoices = ({
  dispatch,
  user,
  invoices: { invoices, total, footer, header, loading, invoiceExport },
  match: { params, url },
  history,
  xlsLoading,
  xmlLoading,
}) => {
  const [generatingMonthly, setGeneratingMonthly] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(
    window.location.hostname === 'h2temp.herokuapp.com' ||
      window.location.hostname === 'localhost'
  );

  const {
    paymentFilter,
    setPaymentFilter,
    paymentStatusFilter,
    setPaymentStatusFilter,
    contractFilter,
    setContractFilter,
    invoiceFilter,
    setInvoiceFilter,
    phoneFilter,
    setPhoneFilter,
    companyFilter,
    setCompanyFilter,
    companyCodeFilter,
    setCompanyCodeFilter,
    businessClientFilter,
    setBusinessClientFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
  } = useContext(InvoicesContext);

  const fetchInvoices = useCallback(
    data => {
      dispatch(actions.invoices.getInvoicesAction(data));
    },
    [dispatch]
  );

  useEffect(() => {
    const channel = socketsClient.subscribe(`admin/${user.id}`);
    if (invoiceExport && invoiceExport.id) {
      const payload = {
        modalName: 'exportModal',
        title: 'Failo eksportavimas',
        fileName: invoiceExport.filename,
        onCancel: () => {
          socketsClient.unsubscribe(`admin/${user.id}`);
          dispatch(actions.download.cleanDownload());
        },
      };
      dispatch(actions.modals.setModalVisible(payload));

      channel.watch(message => {
        dispatch(actions.download.setAsyncExportResult(message));
      });
    }

    return () => {
      socketsClient.unsubscribe(`admin/${user.id}`);
    };
  }, [invoiceExport]);

  const clearInvoices = useCallback(() => {
    dispatch(actions.invoices.clearInvoicesAction());
  }, [dispatch]);

  useEffect(() => {
    setGeneratingMonthly(store.getState().invoices.generatingMonthly);
  }, [store.getState().invoices.generatingMonthly]);

  useEffect(() => {
    let filterToSubmit;
    if (filterBody) {
      filterToSubmit = filterBody;
    } else if (params.business_client_id) {
      filterToSubmit = {
        filter: {
          business_client_id: [params.business_client_id],
        },
      };
      setBusinessClientFilter([params.business_client_id]);
    }
    fetchInvoices(filterToSubmit);
    return () => clearInvoices();
  }, [fetchInvoices, clearInvoices, filterBody, params.business_client_id]);

  const handleSubmit = name => {
    if (params.business_client_id) {
      history.push(config.APP_PATHS.invoices);
    }

    const generatedFilter = [];

    if (paymentFilter.length) {
      generatedFilter.push({ field: 'payment_type', value: paymentFilter });
    }
    if (paymentStatusFilter.length) {
      generatedFilter.push({ field: 'status', value: paymentStatusFilter });
    }
    if (invoiceFilter.length) {
      generatedFilter.push({ field: 'number', value: invoiceFilter });
    }
    if (phoneFilter.length) {
      generatedFilter.push({ field: 'client_phone', value: phoneFilter });
    }
    if (companyFilter.length) {
      generatedFilter.push({ field: 'company_name', value: companyFilter });
    }
    if (companyCodeFilter.length) {
      generatedFilter.push({ field: 'company_code', value: companyCodeFilter });
    }
    if (contractFilter.length) {
      if (contractFilter.length === 1 && contractFilter[0] === 'Taip') {
        generatedFilter.push({
          field: 'business_client_id',
          expression: 'gte',
          value: 0,
          type: 'number',
        });
      } else if (contractFilter.length === 1 && contractFilter[0] === 'Ne') {
        generatedFilter.push({
          field: 'business_client_id',
          expression: 'eq',
          value: null,
          type: 'number',
        });
      }
    }
    if (createdAtGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: createdAtGtFilter,
        type: 'timestamp',
      });
    }
    if (createdAtLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: createdAtLtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setPaymentFilter([]);
    setPaymentStatusFilter([]);
    setContractFilter([]);
    setInvoiceFilter([]);
    setPhoneFilter([]);
    setCompanyFilter([]);
    setCompanyCodeFilter([]);
    setCreatedAtLtFilter();
    setCreatedAtGtFilter();

    handleSubmit();
  };

  const tableHeader = [
    <SelectFilter
      title="Būsena"
      name="status"
      valueType="number"
      array={generateHeader(
        [
          INVOICE_STATUS_NOT_PAID,
          INVOICE_STATUS_PAID,
          INVOICE_STATUS_PARTIALLY_PAID,
        ],
        mapInvoicePaymentStatus
      )}
      filterState={paymentStatusFilter}
      setFilterState={setPaymentStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', paymentStatusFilter)}
    />,
    <SelectFilter
      title="Tipas"
      name="payment_type"
      valueType="number"
      array={generateHeader(header.payment_type, mapPaymentType)}
      filterState={paymentFilter}
      setFilterState={setPaymentFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', paymentFilter)}
    />,
    <SelectFilter
      title="Sutartis"
      name="business_client_id"
      valueType="string"
      array={['Taip', 'Ne'].map(v => ({ name: v, value: v }))}
      filterState={contractFilter}
      setFilterState={setContractFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', contractFilter)}
    />,
    <SelectAsyncFilter
      title="Sąskaitos nr."
      name="number"
      valueType="string"
      params={{ model: 'invoices', field: 'number' }}
      filterState={invoiceFilter}
      setFilterState={setInvoiceFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', invoiceFilter)}
    />,
    <SelectAsyncFilter
      title="Numeris"
      name="client_phone"
      valueType="string"
      params={{ model: 'clients', field: 'phone' }}
      filterState={phoneFilter}
      setFilterState={setPhoneFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', phoneFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonė"
      name="company_name"
      valueType="string"
      params={{ model: 'companies', field: 'company_name' }}
      filterState={companyFilter}
      setFilterState={setCompanyFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonės kodas"
      name="company_code"
      valueType="string"
      params={{ model: 'companies', field: 'company_code' }}
      filterState={companyCodeFilter}
      setFilterState={setCompanyCodeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyCodeFilter)}
    />,
    <div className={styles.tableHeader}>PVM kodas</div>,
    <DateFilter
      title="Data"
      name="created_at"
      lt={createdAtLtFilter}
      setLt={setCreatedAtLtFilter}
      gt={createdAtGtFilter}
      setGt={setCreatedAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', createdAtLtFilter, createdAtGtFilter)}
    />,
    <div className={styles.tableHeader}>Be PVM</div>,
    <div className={styles.tableHeader}>Su PVM</div>,
    <div className={styles.tableHeader}>Skola su PVM</div>,
  ];

  const tableFooter = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    formatMoney(footer.total_cost_without_tax),
    formatMoney(footer.total_cost),
    formatMoney(footer.total_debt),
  ];

  const renderTable = () =>
    invoices.map(
      ({
        id,
        status,
        business_client_id,
        number,
        client_phone,
        company_name,
        company_code,
        payment_type,
        created_at,
        total_cost_without_tax,
        total_cost,
        tax_code,
        total_debt,
      }) => (
        <TableRow url={config.APP_PATHS.invoiceDetails(id)} key={id}>
          <TableCell>
            <span style={{ color: mapInvoicePaymentStatusColor(status) }}>
              {mapInvoicePaymentStatus(status)}
            </span>
          </TableCell>
          <TableCell>{mapPaymentType(payment_type)}</TableCell>
          <TableCell>{business_client_id ? 'Taip' : 'Ne'}</TableCell>
          <TableCell>{number}</TableCell>
          <TableCell>{client_phone}</TableCell>
          <TableCell>{company_name || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{company_code || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{tax_code || 'Mobilus mokėtojas'}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell align="right">
            {formatMoney(total_cost_without_tax)}
          </TableCell>
          <TableCell align="right">{formatMoney(total_cost)}</TableCell>
          <TableCell align="right">{formatMoney(total_debt)}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.invoices);
    //dispatch(actions.invoices.exportInvoicesAction(filename, filterBody));
    dispatch(actions.invoices.exportInvoicesAsyncAction(filterBody));
  };

  const xlsExportHeads = () => {
    const filename = formatFilename(config.PAGES.invoices + 'heads');
    dispatch(actions.invoices.exportInvoicesHeadsAction(filename, filterBody));
  };

  const xlsExportBodies = () => {
    const filename = formatFilename(config.PAGES.invoices + 'bodies');
    dispatch(actions.invoices.exportInvoicesBodiesAction(filename, filterBody));
  };

  const xlsExportPartners = () => {
    const filename = formatFilename(config.PAGES.invoices + 'partners');
    dispatch(
      actions.invoices.exportInvoicesPartnersAction(filename, filterBody)
    );
  };

  const xmlExport = () => {
    const filename = formatFilename(config.PAGES.invoices);
    dispatch(actions.invoices.exportInvoicesXmlAction(filename, filterBody));
  };

  const generateMonthlyInvocies = () => {
    dispatch(actions.invoices.generateMonthlyInvoicesAction());
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.invoices}</Title>

        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          {showGenerateButton ? (
            <Button
              color="primary"
              onClick={generateMonthlyInvocies}
              loading={generatingMonthly}
            >
              Generuoti mėnesio sąskaitas
            </Button>
          ) : null}

          <Button
            color="primary"
            onClick={xlsExportHeads}
            loading={xlsLoading}
            transparent={true}
          >
            {' '}
            Galvos{' '}
          </Button>
          <Button
            color="primary"
            onClick={xlsExportBodies}
            loading={xlsLoading}
            transparent={true}
          >
            {' '}
            Kūneliai{' '}
          </Button>
          <Button
            color="primary"
            onClick={xlsExportPartners}
            loading={xlsLoading}
            transparent={true}
          >
            {' '}
            Partneriai{' '}
          </Button>
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
          <ButtonDownload type="xml" onClick={xmlExport} loading={xmlLoading} />
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        isEmpty={!invoices.length}
        filter={true}
        loading={loading}
        rightAlignedFooterCells={[6, 7]}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  invoices: state.invoices,
  user: state.auth.user,
  xlsLoading: state.download.xlsLoading,
  xmlLoading: state.download.xmlLoading,
});

Invoices.propTypes = {
  dispatch: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    invoices: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    header: PropTypes.shape({
      invoice_number: PropTypes.arrayOf(PropTypes.string),
      client_phone: PropTypes.arrayOf(PropTypes.string),
      company_name: PropTypes.arrayOf(PropTypes.string),
      company_code: PropTypes.arrayOf(PropTypes.string),
      payment_type: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    footer: PropTypes.shape({
      total_cost: PropTypes.number,
      total_cost_without_tax: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
  xmlLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Invoices);
