import React from 'react';

import styles from './header.module.scss';
import Logo from './logo/logo';
import Search from './search/search';
import Dropdown from './dropdown/dropdown';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.logo}>
      <Logo />
    </div>

    <nav className={styles.nav}>
      <Search />
      <Dropdown />
    </nav>
  </header>
);

export default Header;
