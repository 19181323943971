import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { downloadFile, errorMessage } from '../../utils';

export function* getOrdersSaga({ body: initialBody }) {
  try {
    yield put(actions.orders.setOrdersLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { orders, count, header, footer } = yield call(Api.getOrders, body);

    yield put(actions.orders.setOrdersAction(orders, count, header, footer));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orders.setOrdersLoadingAction(false));
  }
}

export function* getOrderSaga({ id }) {
  try {
    yield put(actions.orders.setOrdersLoadingAction(true));

    const order = yield call(Api.getOrder, id);

    yield put(actions.orders.setOrderAction(order));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orders.setOrdersLoadingAction(false));
  }
}

export function* exportOrdersSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportOrders, body);

    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* retryPaymentSaga({ orderId, paymentId }) {
  try {
    yield put(actions.orders.setOrdersLoadingAction(true));

    const order = yield call(Api.retryPayment, orderId, paymentId);

    if (order.status === 455) {
      yield put(actions.error.setErrorAction({ msg: 'Mokėjimas nepavyko' }));
    } else {
      yield put(actions.orders.setOrderAction(order));
    }
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orders.setOrdersLoadingAction(false));
  }
}

export function* changeOrderStatusToPaid({ id }) {
  try {
    yield put(actions.orders.setOrdersLoadingAction(true));
    const { orders, count, header, footer } = yield call(Api.markAsPaid, id);
    yield put(actions.orders.setOrdersAction(orders, count, header, footer));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.orders.setOrdersLoadingAction(false));
  }
}

export function* exportOrdersAsyncSaga({ filter }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportOrdersAsync, filter);

    yield put(actions.orders.setExportIdentifierAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}
