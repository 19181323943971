import React, { createContext, useState } from 'react';

export const OrderHistoryContext = createContext();

export const OrderHistoryProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [locationFilter, setLocationFilter] = useState([]);
  const [paymentMethodFilter, setPaymentMethodFilter] = useState([]);
  const [dateLtFilter, setDateLtFilter] = useState();
  const [dateGtFilter, setDateGtFilter] = useState();
  const [timeLtFilter, setTimeLtFilter] = useState();
  const [timeGtFilter, setTimeGtFilter] = useState();
  const [fullNameFilter, setFullNameFilter] = useState([]);
  const [clientPhoneFilter, setClientPhoneFilter] = useState([]);
  const [amountLtFilter, setAmountLtFilter] = useState();
  const [amountGtFilter, setAmountGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <OrderHistoryContext.Provider
      value={{
        filterBody,
        setFilterBody,
        locationFilter,
        setLocationFilter,
        paymentMethodFilter,
        setPaymentMethodFilter,
        dateLtFilter,
        setDateLtFilter,
        dateGtFilter,
        setDateGtFilter,
        fullNameFilter,
        setFullNameFilter,
        clientPhoneFilter,
        setClientPhoneFilter,
        amountLtFilter,
        setAmountLtFilter,
        amountGtFilter,
        setAmountGtFilter,
        timeLtFilter,
        setTimeLtFilter,
        timeGtFilter,
        setTimeGtFilter,
        sortOrder,
        setSortOrder,
      }}
    >
      {children}
    </OrderHistoryContext.Provider>
  );
};
