export const adminsConstants = {
  SET_ADMINS_LOADING: 'admins/SET_ADMINS_LOADING',
  GET_ADMINS: 'admins/GET_ADMINS',
  CLEAR_ADMINS: 'admins/CLEAR_ADMINS',
  GET_ADMIN: 'admins/GET_ADMIN',
  SET_ADMIN: 'admins/SET_ADMIN',
  SET_ADMINS: 'admins/SET_ADMINS',
  ADD_ADMIN: 'admins/ADD_ADMIN',
  EDIT_ADMIN: 'admins/EDIT_ADMIN',
  DEACTIVATE_ADMIN: 'admins/DEACTIVATE_ADMIN',
};
