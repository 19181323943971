import React, { createContext, useState } from 'react';

export const EmployeesContext = createContext();

export const EmployeesProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [typeFilter, setTypeFilter] = useState([]);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState([]);
  const [employeeIdFilter, setEmployeeIdFilter] = useState([]);
  const [fullNameFilter, setFullNameFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [monthlyLimitLtFilter, setMonthlyLimitLtFilter] = useState();
  const [monthlyLimitGtFilter, setMonthlyLimitGtFilter] = useState();
  const [remainderLtFilter, setRemainderLtFilter] = useState();
  const [remainderGtFilter, setRemainderGtFilter] = useState();
  const [membershipFilter, setMembershipFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <EmployeesContext.Provider
      value={{
        filterBody,
        setFilterBody,
        typeFilter,
        setTypeFilter,
        phoneNumberFilter,
        setPhoneNumberFilter,
        employeeIdFilter,
        setEmployeeIdFilter,
        fullNameFilter,
        setFullNameFilter,
        monthlyLimitLtFilter,
        setMonthlyLimitLtFilter,
        monthlyLimitGtFilter,
        setMonthlyLimitGtFilter,
        statusFilter,
        setStatusFilter,
        remainderLtFilter,
        setRemainderLtFilter,
        remainderGtFilter,
        setRemainderGtFilter,
        sortOrder,
        setSortOrder,
        membershipFilter,
        setMembershipFilter,
      }}
    >
      {children}
    </EmployeesContext.Provider>
  );
};
