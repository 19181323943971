export const employeesConstants = {
  SET_EMPLOYEES_LOADING: 'employees/SET_EMPLOYEES_LOADING',
  GET_EMPLOYEES: 'employees/GET_EMPLOYEES',
  SET_EMPLOYEES: 'employees/SET_EMPLOYEES',
  GET_EMPLOYEE: 'employees/GET_EMPLOYEE',
  GET_H2_CARD: 'employees/GET_H2_CARD',
  SET_EMPLOYEE: 'employees/SET_EMPLOYEE',
  UPDATE_EMPLOYEE: 'employees/UPDATE_EMPLOYEE',
  CLEAR_EMPLOYEES: 'employees/CLEAR_EMPLOYEES',
  EXPORT_EMPLOYEES: 'employees/EXPORT_EMPLOYEES',
  DELETE_EMPLOYEE_DATA: 'employees/DELETE_EMPLOYEE_DATA',
  ADD_EMPLOYEE: 'employees/ADD_EMPLOYEE',
  ADD_H2_CARD: 'employees/ADD_H2_CARD',
  UPDATE_H2_CARD: 'employees/UPDATE_H2_CARD',
  CHANGE_STATUS: 'employees/CHANGE_STATUS',
  CHANGE_H2_CARD_STATUS: 'employees/CHANGE_H2_CARD_STATUS',
  BULK_MEMBERSHIP_ASSIGN: 'employees/BULK_MEMBERSHIP_ASSIGN',
};
