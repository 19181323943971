import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import styles from './businessClients.module.scss';
import { actions } from '../../state';
import { config } from '../../api/config';
import { isEmpty } from '../../utils';
import BusinessClientInfo from './businessClients.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Paper,
  PaperContainer,
  Button,
} from '../../components';
import { businessClientsActions } from '../../state/businessClients/businessClients.actions';
import { validationErrors } from './businessClients.validations';

const AddBusinessClient = ({
  dispatch,
  history,
  match: { params },
  businessClients: {
    loading,
    managerAdmins,
    companyDetails,
    companyDetailsByCode,
    companyDetailsLoading,
    companyDetailsError,
    companyDetailsByCodeError,
    singleCompanyDetails,
  },
  dropdowns: { countries },
}) => {
  const [values, setValues] = useState({
    status: 1,
    company_code: '',
    name: '',
    tax_code: '',
    address: '',
    city: '',
    postal_code: '',
    country_code: 'LT',
    email: '',
    invoice_emails: '',
    owner_full_name: '',
    phone: '',
    credit_limit: '',
    discount: '',
    sendPassword: false,
    contract_start_date: null,
    contract_end_date: null,
    progressive_discount: true,
    payment_term: 10,
    manager_id: 0,
  });
  const [sendPassword, setSendPassword] = useState(false);
  const [contractValidFrom, setContractValidFrom] = useState(null);
  const [contractValidTo, setContractValidTo] = useState(null);

  const fetchManagerAdmins = useCallback(() => {
    const body = {
      filter: {
        business_role: config.BUSINESS_ROLES.MANAGER.value,
      },
    };
    dispatch(actions.businessClients.getManagerAdminsAction(body));
  }, [dispatch]);

  const fetchCountries = useCallback(() => {
    dispatch(actions.dropdowns.getCountriesAction());
  }, [dispatch]);

  const clearClient = useCallback(() => {
    dispatch(actions.businessClients.clearBusinessClientsAction());
  }, [dispatch]);

  const openDeleteClientModal = () => {
    const payload = {
      modalName: 'confirmModal',
      title: 'Ištrinti kliento duomenis',
      desc: 'Ar tikrai norite ištrinti kliento duomenis?',
      onConfirm: () =>
        dispatch(businessClientsActions.deleteClientData(params.id)),
    };
    dispatch(actions.modals.clearModals());
    dispatch(actions.modals.setModalVisible(payload));
  };

  const handleInputChange = ({ name, value }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCheckboxChange = ({ currentTarget: { id, value } }) => {
    const newValue = value === 'true' ? false : true;
    setValues({
      ...values,
      [id]: newValue,
    });
  };

  const handleContractValidFrom = timestamp => {
    setValues({
      ...values,
      contract_start_date: timestamp,
    });
  };

  const handleContractValidTo = timestamp => {
    setValues({
      ...values,
      contract_end_date: timestamp,
    });
  };

  const handleCompanyDataPrefill = () => {
    const {
      company_code,
      company_name,
      tax_code,
      address,
      city,
      post_code,
      country_code,
    } = singleCompanyDetails;

    setValues({
      ...values,
      name: company_name,
      company_code,
      tax_code,
      address,
      city,
      postal_code: post_code,
      country_code,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values);

    if (error) dispatch(actions.error.setErrorAction({ msg: error }));
    else {
      dispatch(
        actions.businessClients.addBusinessClientAction(
          {
            ...values,
            invoice_emails:
              values.invoice_emails && values.invoice_emails.length > 0
                ? values.invoice_emails.replaceAll(' ', '').split(',')
                : [],
            discount: values.discount ? values.discount : 0,
            credit_limit: +values.credit_limit * 100,
            send_invite: values.sendPassword,
            payment_term: +values.payment_term,
            manager_id: values.manager_id || undefined,
          },
          history
        )
      );
    }
  };

  const handleFileUpload = file => {
    dispatch(actions.businessClients.uploadContractAction(file));
  };

  const handleSearchCompanyDetails = useDebouncedCallback(value => {
    dispatch(actions.businessClients.searchCompanyDetailsAction(value));
  }, 800);

  const handleSearchCompanyDetailsByCode = useDebouncedCallback(value => {
    dispatch(actions.businessClients.searchCompanyDetailsByCodeAction(value));
  }, 800);

  const handleGetSingleCompanyDetails = value => {
    dispatch(actions.businessClients.getSingleCompanyDetailsAction(value));
  };

  const resetSingleCompanyDetails = () => {
    dispatch(actions.businessClients.setSingleCompanyDetailsAction(null));
  };

  useEffect(() => {
    fetchManagerAdmins();
  }, [fetchManagerAdmins]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    if (singleCompanyDetails) {
      handleCompanyDataPrefill();
    }
  }, [singleCompanyDetails]);

  useEffect(() => {
    return () => {
      resetSingleCompanyDetails();
    };
  }, []);

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.businessClients}
            baseUrl={config.APP_PATHS.businessClients}
          />
          {/* <Button color="dark" onClick={openDeleteClientModal}>
              Ištrinti duomenis
            </Button> */}
        </div>
      </Header>

      <PaperContainer>
        <Paper>
          {!isEmpty(values) && (
            <BusinessClientInfo
              businessClient={values}
              sendPassword={sendPassword}
              setSendPassword={setSendPassword}
              newContractValidFrom={contractValidFrom}
              setNewContractValidFrom={setContractValidFrom}
              newContractValidTo={contractValidTo}
              setNewContractValidTo={setContractValidTo}
              onInputChange={handleInputChange}
              onCheckboxChange={handleCheckboxChange}
              setContractValidFrom={handleContractValidFrom}
              setContractValidTo={handleContractValidTo}
              onFileUpload={handleFileUpload}
              managerAdmins={managerAdmins}
              countries={countries}
              companyDetails={companyDetails}
              companyDetailsByCode={companyDetailsByCode}
              searchCompanyDetails={handleSearchCompanyDetails}
              searchCompanyDetailsByCode={handleSearchCompanyDetailsByCode}
              companyDetailsLoading={companyDetailsLoading}
              companyDetailsError={companyDetailsError}
              companyDetailsByCodeError={companyDetailsByCodeError}
              getSingleCompanyDetails={handleGetSingleCompanyDetails}
            />
          )}
          {
            <div className={styles.submitContainer}>
              <Button color="primary" onClick={handleSubmit}>
                Išsaugoti
              </Button>
            </div>
          }
          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  businessClients: state.businessClients,
  dropdowns: state.dropdowns,
});

AddBusinessClient.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  businessClients: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedClient: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(AddBusinessClient);
