import { constants } from '..';

const INITIAL_STATE = {
  // addEditServices: {},
  address: '',
  city: '',
  status: 1,
  coordinates: [],
  self_service_washing: null,
  automatic_textile_washing: null,
  automatic_contactless_washing: null,
  vacuum_cleaner: null,
  smart_vacuum_cleaner: null,
  car_mat_cleaner: null,
  paper_towel_dispenser: null,
  windshield_washer_liquid_dispenser: null,
  tyre_blackener: null,
  towel_dispenser: null,
};

export const addEditServicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.addEditServices.PUSH_PLACES_DATA:
      return {
        ...state,
        address: action.data.address,
        city: action.data.city,
        coordinates: action.data.coordinates,
      };
    case constants.addEditServices.PUSH_NEW_COORD:
      return {
        ...state,
        coordinates: [action.coord.lat, action.coord.lng],
      };
    case constants.addEditServices.PUSH_NEW_STATUS:
      return {
        ...state,
        status: action.status,
      };

    case constants.addEditServices.ADD_EDIT_SELFSERVICES_DATA:
      return {
        ...state,
        self_service_washing: action.data
          ? {
              ...state.self_service_washing,
              ...action.data,
            }
          : null,
      };

    case constants.addEditServices.ADD_EDIT_AUTOTEXTILE_DATA:
      return {
        ...state,
        automatic_textile_washing: action.data
          ? {
              ...state.automatic_textile_washing,
              ...action.data,
            }
          : null,
      };

    case constants.addEditServices.ADD_EDIT_AUTOCONTACTLESS_DATA:
      return {
        ...state,
        automatic_contactless_washing: action.data
          ? {
              ...state.automatic_contactless_washing,
              ...action.data,
            }
          : null,
      };

    case constants.addEditServices.ADD_EDIT_VACUUM_CLEANER_DATA:
      return {
        ...state,
        vacuum_cleaner: action.data
          ? {
              ...state.vacuum_cleaner,
              ...action.data,
            }
          : null,
      };

    case constants.addEditServices.ADD_EDIT_SMART_VACUUM_CLEANER_DATA:
      return {
        ...state,
        smart_vacuum_cleaner: action.data
          ? {
              ...state.smart_vacuum_cleaner,
              ...action.data,
            }
          : null,
      };

    case constants.addEditServices.ADD_EDIT_CAR_MAT_CLEANER_DATA:
      return {
        ...state,
        car_mat_cleaner: action.data
          ? {
              ...state.car_mat_cleaner,
              ...action.data,
            }
          : null,
      };
    case constants.addEditServices.ADD_EDIT_PAPER_TOWEL_DISPENSER_DATA:
      return {
        ...state,
        paper_towel_dispenser: action.data
          ? {
              ...state.paper_towel_dispenser,
              ...action.data,
            }
          : null,
      };
    case constants.addEditServices.ADD_WIND_SHIELD_WASHER_LIQUID_DISPENSER_DATA:
      return {
        ...state,
        windshield_washer_liquid_dispenser: action.data
          ? {
              ...state.windshield_washer_liquid_dispenser,
              ...action.data,
            }
          : null,
      };
    case constants.addEditServices.ADD_TYRE_BLACKENER_DATA:
      return {
        ...state,
        tyre_blackener: action.data
          ? {
              ...state.tyre_blackener,
              ...action.data,
            }
          : null,
      };
    case constants.addEditServices.ADD_TOWEL_DISPENSER_DATA:
      return {
        ...state,
        towel_dispenser: action.data
          ? {
              ...state.towel_dispenser,
              ...action.data,
            }
          : null,
      };
    case constants.addEditServices.REPLACE_ADD_EDIT_SERVICES_DATA:
      return {
        ...action.payload,
      };

    case constants.addEditServices.CLEAR_ADD_EDIT_SERVICES_ACTION:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};
