import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './clients.module.scss';
import { Input, Select, Button } from '../../components';
import { config } from '../../api/config';

const ClientMembership = ({
  membershipOptions,
  onSubmit,
  defaultValues,
  updateHandler,
}) => {
  const [values, setValues] = useState(defaultValues);
  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const info = [
    {
      title: 'Nuolaidos planas',
      data: (
        <Select
          name="membership_id"
          options={membershipOptions}
          selectedValue={values.membership_id}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Galioja nuo',
      data: (
        <Input
          name="valid_from"
          withLabel={false}
          label=""
          type="date"
          max="2999-12-31"
          placeholder={config.DEFAULTS.dateFormat}
          onChange={handleInputChange}
          value={values.valid_from}
        />
      ),
    },
    {
      title: 'Galioja iki',
      data: (
        <Input
          name="valid_until"
          withLabel={false}
          label=""
          type="date"
          max="2999-12-31"
          placeholder={config.DEFAULTS.dateFormat}
          onChange={handleInputChange}
          value={values.valid_until}
        />
      ),
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={cn(styles.infoRow, styles.editMembershipRow)} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  const isActionDisabled =
    defaultValues.membership_id !== 0
      ? !updateHandler
      : !values.valid_from || !values.valid_until || !values.membership_id;

  return (
    <>
      <div>{renderInfo()}</div>
      <div className={styles.submitContainer}>
        <Button
          color="primary"
          onClick={() => onSubmit(values)}
          disabled={isActionDisabled}
        >
          Išsaugoti
        </Button>
      </div>
    </>
  );
};

ClientMembership.defaultProps = {
  membershipOptions: [],
};

ClientMembership.propTypes = {
  membershipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]).isRequired,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ClientMembership;
