import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import styles from './memberships.module.scss';
import { actions } from '../../state';
import { formatDateTime, formatMoneyToCents, getTimestamp } from '../../utils';
import Form from './memberships.history.form';
import { Button, ButtonSimple } from '../../components';
import { createDefaultBenefit } from './memberships.history.component';

const EditHistory = ({
  dispatch,
  id,
  history,
  services,
  membershipType,
  onSubmitHandler,
}) => {
  const [values, setValues] = useState({
    dateFrom: history.valid_from,
    discount: history.discount,
    price: history.price / 100,
    yearlyPrice: history.yearly_price / 100 ?? '',
    trialPeriodPrice: history.paid_trial_price / 100 ?? '',
    trialPeriod: history.trial_period ?? 1,
    warranty: history.warranty ?? 12,
    checkYearlyPlan: Boolean(history.yearly_plan),
    checkTrialPeriod: Boolean(history.trial_available),
    checkPaidTrialPeriod: Boolean(history.paid_trial),
    benefits: history.benefits.length
      ? [...history.benefits]
      : [{ ...createDefaultBenefit() }],
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setValues(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleDateChange = date => {
    setValues({
      ...values,
      dateFrom: date,
    });
  };

  const handleCheckboxChange = ({ currentTarget: { id } }) => {
    setValues(prev => {
      return { ...prev, [id]: !prev[id] };
    });
  };

  const handleBenefitChange = (e, benefitId) => {
    const {
      target: { name, value, id },
    } = e;

    setValues(prev => {
      const arr = [...prev?.benefits]?.map(benefit => {
        if (benefitId === benefit.id) {
          const key = name || id;
          benefit[key] = name ? Number(value) : !benefit[id];
        }
        return benefit;
      });

      return { ...prev, benefits: [...arr] };
    });
  };

  const handleAddNewBenefit = () => {
    setValues(prev => {
      prev.benefits.push(createDefaultBenefit());

      return { ...prev };
    });
  };

  const handleRemoveBenefit = id => {
    setValues(prev => {
      prev.benefits.splice(id, 1);

      return { ...prev };
    });
  };

  const closeModal = () => {
    dispatch(actions.modals.clearModals());
  };

  const handleSubmit = e => {
    e.preventDefault();

    const {
      dateFrom,
      warranty,
      price,
      benefits,
      checkYearlyPlan,
      checkPaidTrialPeriod,
      checkTrialPeriod,
      yearlyPrice,
      trialPeriod,
      trialPeriodPrice,
    } = values;

    const isBenefitsChanged =
      history.benefits.length !== benefits.length ||
      JSON.stringify(history.benefits) !== JSON.stringify(benefits);

    const valid_from = formatDateTime(dateFrom);

    const isChanged =
      valid_from !== history.valid_from ||
      history.price !== formatMoneyToCents(price) ||
      warranty !== history.guarantee_period ||
      history.yearly_plan !== checkYearlyPlan ||
      history.yearly_price !== formatMoneyToCents(yearlyPrice) ||
      history.paid_trial_price === formatMoneyToCents(trialPeriodPrice) ||
      history.paid_trial !== checkPaidTrialPeriod ||
      history.trial_period !== trialPeriod ||
      history.trial_available !== checkTrialPeriod ||
      isBenefitsChanged;

    if (!isChanged) {
      return dispatch(
        actions.error.setErrorAction({
          msg: 'No changes made',
        })
      );
    }

    const preparedBenefits = benefits
      .filter(
        benefit =>
          JSON.stringify(benefit) !== JSON.stringify(createDefaultBenefit())
      )
      .map(benefit => {
        if (!benefit.reset_period) {
          benefit.reset_period = 51;
        }
        return benefit;
      });

    const body = {
      price: formatMoneyToCents(price),
      valid_from,
      yearly_plan: checkYearlyPlan,
      yearly_price: checkYearlyPlan
        ? formatMoneyToCents(yearlyPrice)
        : undefined,
      warranty,
      trial_available: checkTrialPeriod,
      trial_period: checkTrialPeriod ? trialPeriod : undefined,
      paid_trial: checkPaidTrialPeriod,
      paid_trial_price: checkPaidTrialPeriod
        ? formatMoneyToCents(trialPeriodPrice)
        : undefined,
      benefits: preparedBenefits.length ? preparedBenefits : undefined,
    };

    if (id && !onSubmitHandler) {
      dispatch(
        actions.memberships.editMembershipHistoryAction(id, history.id, body)
      );
    }

    if (!id && onSubmitHandler) {
      onSubmitHandler(body);
    }
  };

  const deleteHistory = () => {
    if (id && history.id) {
      dispatch(
        actions.memberships.deleteMembershipHistoryAction(id, history.id)
      );
    }
    if (!id && onSubmitHandler) onSubmitHandler(null);
    closeModal();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      onChange={handleInputChange}
      onDateChange={handleDateChange}
      values={values}
      services={services}
      onBenefitChange={handleBenefitChange}
      onCheckboxChange={handleCheckboxChange}
      addNewBenefitClickHandler={handleAddNewBenefit}
      removeBenefitClickHandler={handleRemoveBenefit}
      membershipType={membershipType}
    >
      <div className={styles.actions}>
        <ButtonSimple type="remove" onClick={deleteHistory}>
          Trinti
        </ButtonSimple>
        <Button color="primary" type="submit">
          Išsaugoti
        </Button>
      </div>
    </Form>
  );
};

EditHistory.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func,
  id: PropTypes.number,
  membershipType: PropTypes.number.isRequired,
  history: PropTypes.shape({
    id: PropTypes.number,
    discount: PropTypes.number,
    price: PropTypes.number,
    valid_from: PropTypes.number,
    valid_until: PropTypes.number,
  }).isRequired,
};

export default connect()(EditHistory);
