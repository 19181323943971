import { constants } from '..';

const setClientsLoadingAction = flag => ({
  type: constants.clients.SET_CLIENTS_LOADING,
  flag,
});

const getClientsAction = body => ({
  type: constants.clients.GET_CLIENTS,
  body,
});

const setClientsAction = (clients, count, header) => ({
  type: constants.clients.SET_CLIENTS,
  clients,
  count,
  header,
});

const getClientAction = id => ({
  type: constants.clients.GET_CLIENT,
  id,
});

const setClientAction = client => ({
  type: constants.clients.SET_CLIENT,
  client,
});

const updateClient = (id, body) => ({
  type: constants.clients.UPDATE_CLIENT,
  id,
  body,
});

const clearClientsAction = () => ({
  type: constants.clients.CLEAR_CLIENTS,
});

const exportClientsAction = filename => ({
  type: constants.clients.EXPORT_CLIENTS,
  filename,
});

const exportClientsAsyncAction = filterBody => ({
  type: constants.clients.EXPORT_CLIENTS_ASYNC,
  filter: filterBody,
});

const setExportIdentifierAction = identifier => ({
  type: constants.clients.SET_CLIENTS_EXPORT_IDENTIFIER,
  identifier,
});

const deactivateClientsMembershipOrderAction = orderId => ({
  type: constants.clients.DEACTIVATE_CLIENTS_MEMBERSHIP_ORDER,
  orderId,
});

const getClientCards = clientId => ({
  type: constants.clients.GET_CLIENT_CARDS,
  clientId,
});

const setClientCards = clientCards => ({
  type: constants.clients.SET_CLIENT_CARDS,
  clientCards,
});

const deleteClientData = id => ({
  type: constants.clients.DELETE_CLIENT_DATA,
  id,
});

const topUpWallet = (clientId, topUpAmount) => ({
  type: constants.clients.TOP_UP_WALLET,
  clientId,
  topUpAmount,
});

const setSelectedClientMembership = membership => ({
  type: constants.clients.SET_SELECTED_CLIENT_MEMBERSHIP,
  membership,
});

const deleteMembershipOrderAction = orderId => ({
  type: constants.clients.DELETE_CLIENTS_MEMBERSHIP_ORDER,
  orderId,
});

export const clientsActions = {
  setClientsLoadingAction,
  getClientsAction,
  setClientsAction,
  getClientAction,
  setClientAction,
  updateClient,
  clearClientsAction,
  exportClientsAction,
  exportClientsAsyncAction,
  setExportIdentifierAction,
  deactivateClientsMembershipOrderAction,
  getClientCards,
  setClientCards,
  deleteClientData,
  topUpWallet,
  setSelectedClientMembership,
  deleteMembershipOrderAction,
};
