import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  admins: [],
  total: 0,
  selectedAdmin: {},
};

export const adminsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.admins.SET_ADMINS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.admins.SET_ADMINS:
      return {
        ...state,
        admins: action.admins,
        total: action.count,
      };
    case constants.admins.CLEAR_ADMINS:
      return {
        ...INITIAL_STATE,
      };
    case constants.admins.SET_ADMIN:
      return {
        ...state,
        selectedAdmin: action.admin,
      };
    default:
      return state;
  }
};
