import React from 'react';
import cn from 'classnames';
import styles from './modalLayout.module.scss';
import { Backdrop, Icon } from '../../../components';

const ModalLayout = ({ children, title, onCancel, withScroll }) => (
  <>
    <div
      className={cn(styles.modal, { [styles.withScroll]: withScroll })}
      id="modal"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal__title"
      aria-describedby="modal__desc"
    >
      <button className={styles.close} onClick={onCancel}>
        <Icon
          name="close"
          classes={{
            icon: styles.icon,
          }}
        />
      </button>

      <h3 className={styles.title} id="modal__title">
        {title}
      </h3>

      <div className={styles.content}>{children}</div>
    </div>
    <Backdrop onCancel={onCancel} />
  </>
);

export default ModalLayout;
