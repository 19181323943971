import React from 'react';
import PropTypes from 'prop-types';

import styles from './title.module.scss';

const Title = ({ children, total }) => {
  return (
    <h1 className={styles.title}>
      {children}
      {total ? <span className={styles.total}> ({total})</span> : null}
    </h1>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  total: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
};

export default Title;
