import { call, put } from 'redux-saga/effects';
import { Api } from '../../api/api';
import { config } from '../../api/config';
import { actions } from '..';
import { downloadFile, errorMessage } from '../../utils';

export function* getInvoicesSaga({ body: initialBody }) {
  try {
    yield put(actions.invoices.setInvoicesLoadingAction(true));

    const body = {
      ...initialBody,
      limit: config.DEFAULTS.recordsPerPage,
    };

    const { invoices, count, header, footer } = yield call(
      Api.getInvoices,
      body
    );

    yield put(
      actions.invoices.setInvoicesAction(invoices, count, header, footer)
    );
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setInvoicesLoadingAction(false));
  }
}

export function* getInvoiceSaga({ id }) {
  try {
    yield put(actions.invoices.setInvoicesLoadingAction(true));

    const invoice = yield call(Api.getInvoice, id);

    yield put(actions.invoices.setInvoiceAction(invoice));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setInvoicesLoadingAction(false));
  }
}

export function* exportInvoicesSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportInvoices, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportInvoicesAsyncSaga({ filter }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportInvoicesAsync, filter);

    yield put(actions.invoices.setExportIdentifierAction(response));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportInvoicesHeadsSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportInvoicesHeads, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportInvoicesBodiesSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportInvoicesBodies, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportInvoicesPartnersSaga({ filename, body }) {
  try {
    yield put(actions.download.setXlsLoadingAction(true));
    const response = yield call(Api.exportInvoicesPartners, body);
    downloadFile(response, filename);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXlsLoadingAction(false));
  }
}

export function* exportInvoicesXmlSaga({ filename, body }) {
  try {
    yield put(actions.download.setXmlLoadingAction(true));
    const response = yield call(Api.exportInvoicesXml, body);
    downloadFile(response, filename, 'xml');
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.download.setXmlLoadingAction(false));
  }
}

// TODO: need to do something with response?
export function* generateMonthlyInvoicesSaga() {
  try {
    yield put(actions.invoices.setMonthlyGeneratingAction(true));
    const response = yield call(Api.generateMonthlyInvoices);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setMonthlyGeneratingAction(false));
  }
}

export function* updateInvoicePaymentStatus({ id, newStatus }) {
  try {
    yield put(actions.invoices.setInvoicesLoadingAction(true));

    const body = { status: newStatus };

    const invoice = yield call(Api.updateInvoice, id, body);

    yield put(actions.invoices.setInvoicesLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setInvoicesLoadingAction(false));
  }
}

export function* downloadInvoicePdf({ id, invoiceNumber }) {
  try {
    yield put(actions.invoices.setInvoicesLoadingAction(true));

    const invoice = yield call(Api.downloadInvoicePdf, id);

    downloadFile(invoice, invoiceNumber, 'pdf');

    yield put(actions.invoices.setInvoicesLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setInvoicesLoadingAction(false));
  }
}

export function* updateInvoiceSaga({ id, body }) {
  try {
    yield put(actions.invoices.setInvoicesLoadingAction(true));

    const invoice = yield call(Api.updateInvoice, id, body);

    yield put(actions.invoices.setInvoicesLoadingAction(false));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.invoices.setInvoicesLoadingAction(false));
    yield put(actions.modals.clearModals());
    yield put(actions.invoices.getInvoiceAction(id));
  }
}
