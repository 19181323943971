import React from 'react';

import { OrdersProvider } from '../../pages/orders/orders.context';
import { ClientsProvider } from '../../pages/clients/clients.context';
import { BusinessClientsProvider } from '../../pages/businessClients/businessClients.context';
import { InvoicesProvider } from '../../pages/invoices/invoices.context';
import { ReceiptsProvider } from '../../pages/receipts/receipts.context';
import { MembershipOrdersProvider } from '../../pages/membershipOrders/membershipOrders.context';
import { LocationsProvider } from '../../pages/locations/locations.context';
import { AddressesProvider } from '../../pages/locations/addresses.context copy';
import { ServicesProvider } from '../../pages/locations/services.context';
import { BoxesProvider } from '../../pages/locations/boxes.context';
import { EmployeesProvider } from '../../pages/businessAdmin/employees/employees.context';
import { OrderHistoryProvider } from '../../pages/businessAdmin/orderHistory/orderHistory.context';
import { BusinessInvoicesProvider } from '../../pages/businessAdmin/businessInvoices/businessInvoices.context';
import { TextileProvider } from '../../pages/textile/textile.context';
import { CouponsProvider } from '../../pages/coupons/coupons.context';
import { MembershipsProvider } from '../../pages/memberships/memberships.context';

const withFiltersContext = Component => {
  const WrapperComponent = props => {
    return (
      <OrdersProvider>
        <ClientsProvider>
          <BusinessClientsProvider>
            <EmployeesProvider>
              <TextileProvider>
                <CouponsProvider>
                  <OrderHistoryProvider>
                    <BusinessInvoicesProvider>
                      <ReceiptsProvider>
                        <InvoicesProvider>
                          <MembershipOrdersProvider>
                            <MembershipsProvider>
                              <LocationsProvider>
                                <AddressesProvider>
                                  <ServicesProvider>
                                    <BoxesProvider>
                                      <Component {...props} />
                                    </BoxesProvider>
                                  </ServicesProvider>
                                </AddressesProvider>
                              </LocationsProvider>
                            </MembershipsProvider>
                          </MembershipOrdersProvider>
                        </InvoicesProvider>
                      </ReceiptsProvider>
                    </BusinessInvoicesProvider>
                  </OrderHistoryProvider>
                </CouponsProvider>
              </TextileProvider>
            </EmployeesProvider>
          </BusinessClientsProvider>
        </ClientsProvider>
      </OrdersProvider>
    );
  };

  return WrapperComponent;
};

export default withFiltersContext;
