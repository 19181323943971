import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './orders.module.scss';

import { config } from '../../api/config';
import { actions } from '../../state';
import {
  generateHeader,
  formatDate,
  formatMoney,
  formatTime,
  formatFilename,
  mapServiceTypes,
  mapOrderStatus,
  isFilterActive,
  mapOrderStatusColor,
  mapPaymentMethod,
} from '../../utils';
import {
  Header,
  HeaderActions,
  Title,
  ButtonDownload,
  SelectFilter,
  SelectAsyncFilter,
  RangeFilter,
  DateFilter,
  TimeFilter,
  ButtonSimple,
  Table,
  TableRow,
  TableCell,
} from '../../components';
import { withOrdersHoc, OrdersContext } from './orders.context';
import { socketsClient } from '../../api/sockets';

const Orders = ({
  dispatch,
  user,
  match: { params, url },
  history,
  orders: { orders, total, header, footer, loading, orderExport },
  xlsLoading,
  loaction,
}) => {
  const {
    filterBody,
    setFilterBody,
    statusFilter,
    setStatusFilter,
    paymentMethodFilter,
    setPaymentMethodFilter,
    locationFilter,
    setLocationFilter,
    discountFilter,
    setDiscountFilter,
    companyFilter,
    setCompanyFilter,
    companyCodeFilter,
    setCompanyCodeFilter,
    clientFilter,
    setClientFilter,
    businessClientFilter,
    setBusinessClientFilter,
    servicesFilter,
    setServicesFilter,
    costLtFilter,
    setCostLtFilter,
    costGtFilter,
    setCostGtFilter,
    costTaxLtFilter,
    setCostTaxLtFilter,
    costTaxGtFilter,
    setCostTaxGtFilter,
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
    timeLtFilter,
    setTimeLtFilter,
    timeGtFilter,
    setTimeGtFilter,
    sortOrder,
    setSortOrder,
    sourceFilter,
    setSourceFilter,
  } = useContext(OrdersContext);

  useEffect(() => {
    const channel = socketsClient.subscribe(`admin/${user.id}`);
    if (orderExport && orderExport.id) {
      const payload = {
        modalName: 'exportModal',
        title: 'Failo eksportavimas',
        fileName: orderExport.filename,
        onCancel: () => {
          socketsClient.unsubscribe(`admin/${user.id}`);
          dispatch(actions.download.cleanDownload());
        },
      };
      dispatch(actions.modals.setModalVisible(payload));

      channel.watch(message => {
        dispatch(actions.download.setAsyncExportResult(message));
      });
    }

    return () => {
      socketsClient.unsubscribe(`admin/${user.id}`);
    };
  }, [orderExport]);

  const fetchOrders = useCallback(
    data => {
      dispatch(actions.orders.getOrdersAction(data));
    },
    [dispatch]
  );

  const clearOrders = useCallback(() => {
    dispatch(actions.orders.clearOrdersAction());
  }, [dispatch]);

  useEffect(() => {
    let filterToSubmit;
    if (filterBody) {
      filterToSubmit = filterBody;
    } else if (params.location) {
      filterToSubmit = {
        filter: {
          location_id: params.location,
        },
      };
      const formattedAddress = `${loaction.address}, ${loaction.city}`;
      setLocationFilter([formattedAddress]);
    } else if (params.phone) {
      filterToSubmit = {
        filter: {
          client_phone: [params.phone],
        },
      };
      setClientFilter([params.phone]);
    } else if (params.business_client_id) {
      filterToSubmit = {
        filter: {
          business_client_id: [params.business_client_id],
        },
      };
      setBusinessClientFilter([params.business_client_id]);
    }
    fetchOrders(filterToSubmit);
    return () => clearOrders();
  }, [
    fetchOrders,
    clearOrders,
    filterBody,
    params.phone,
    params.location,
    params.business_client_id,
    loaction.address,
    loaction.city,
  ]);

  const handleSubmit = name => {
    if (params.location || params.phone || params.business_client_id) {
      history.push(config.APP_PATHS.orders);
    }

    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (paymentMethodFilter.length) {
      generatedFilter.push({
        field: 'payment_method',
        value: paymentMethodFilter,
      });
    }
    if (locationFilter.length) {
      generatedFilter.push({ field: 'location', value: locationFilter });
    }
    if (sourceFilter.length) {
      generatedFilter.push({ field: 'source', value: sourceFilter });
    }
    if (companyFilter.length) {
      generatedFilter.push({ field: 'company_name', value: companyFilter });
    }
    if (companyCodeFilter.length) {
      generatedFilter.push({ field: 'company_code', value: companyCodeFilter });
    }
    if (clientFilter.length) {
      generatedFilter.push({ field: 'client_phone', value: clientFilter });
    }
    if (discountFilter.length) {
      generatedFilter.push({
        field: 'discounts',
        value: discountFilter,
        expression: 'overlap',
        convert: 'text',
      });
    }

    if (costTaxLtFilter > 0) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'lte',
        value: costTaxLtFilter * 100,
      });
    }
    if (costTaxGtFilter > 0) {
      generatedFilter.push({
        field: 'total_cost',
        expression: 'gte',
        value: costTaxGtFilter * 100,
      });
    }
    if (createdAtGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: createdAtGtFilter,
        type: 'timestamp',
      });
    }
    if (createdAtLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: createdAtLtFilter,
        type: 'timestamp',
      });
    }
    if (timeLtFilter !== '00:00') {
      generatedFilter.push({
        field: 'time',
        expression: 'lte',
        value: timeLtFilter,
        type: 'time',
      });
    }
    if (timeGtFilter !== '00:00') {
      generatedFilter.push({
        field: 'time',
        expression: 'gte',
        value: timeGtFilter,
        type: 'time',
      });
    }
    if (servicesFilter.length) {
      generatedFilter.push({
        field: 'service_types',
        value: servicesFilter,
        convert: 'smallint',
        expression: 'overlap',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setStatusFilter([]);
    setPaymentMethodFilter([]);
    setLocationFilter([]);
    setDiscountFilter([]);
    setCompanyFilter([]);
    setCompanyCodeFilter([]);
    setClientFilter([]);
    setServicesFilter([]);
    setSourceFilter([]);

    setCostLtFilter('');
    setCostGtFilter('');
    setCostTaxLtFilter('');
    setCostTaxGtFilter('');

    setCreatedAtLtFilter();
    setCreatedAtGtFilter();

    setTimeLtFilter('00:00');
    setTimeGtFilter('00:00');

    handleSubmit();
    if (url !== config.APP_PATHS.orders) {
      history.push(config.APP_PATHS.orders);
    }
  };

  const tableHeader = [
    <div className={styles.tableHeader}>Užsakymo ID</div>,
    <SelectFilter
      title="Statusas"
      name="status"
      valueType="number"
      array={generateHeader(header.status, mapOrderStatus)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <SelectFilter
      title="Mokėjimo metodas"
      name="payment_method"
      valueType="number"
      array={generateHeader([371, 372, 373], mapPaymentMethod)}
      filterState={paymentMethodFilter}
      setFilterState={setPaymentMethodFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', paymentMethodFilter)}
    />,
    <SelectFilter
      title="Lokacija"
      name="location"
      valueType="string"
      array={generateHeader(header.location)}
      filterState={locationFilter}
      setFilterState={setLocationFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', locationFilter)}
    />,
    <DateFilter
      title="Data"
      name="created_at"
      lt={createdAtLtFilter}
      setLt={setCreatedAtLtFilter}
      gt={createdAtGtFilter}
      setGt={setCreatedAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', createdAtLtFilter, createdAtGtFilter)}
    />,
    <TimeFilter
      title="Laikas"
      name="time"
      lt={timeLtFilter}
      setLt={setTimeLtFilter}
      gt={timeGtFilter}
      setGt={setTimeGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('time', timeLtFilter, timeGtFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonė"
      name="company_name"
      valueType="string"
      params={{ model: 'companies', field: 'company_name' }}
      filterState={companyFilter}
      setFilterState={setCompanyFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyFilter)}
    />,
    <SelectAsyncFilter
      title="Įmonės kodas"
      name="company_code"
      valueType="string"
      params={{ model: 'companies', field: 'company_code' }}
      filterState={companyCodeFilter}
      setFilterState={setCompanyCodeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', companyCodeFilter)}
    />,
    <SelectFilter
      title="Nuolaida"
      name="discounts"
      valueType="string"
      array={generateHeader(header.discounts)}
      filterState={discountFilter}
      setFilterState={setDiscountFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', discountFilter)}
    />,
    <SelectAsyncFilter
      title="Numeris"
      name="client_phone"
      valueType="string"
      params={{ model: 'clients', field: 'phone' }}
      filterState={clientFilter}
      setFilterState={setClientFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', clientFilter)}
    />,
    <RangeFilter
      title="Suma su PVM"
      name="total_cost"
      lt={costTaxLtFilter}
      setLt={setCostTaxLtFilter}
      gt={costTaxGtFilter}
      setGt={setCostTaxGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('range', costTaxLtFilter, costTaxGtFilter)}
    />,
    <SelectFilter
      title="Paslaugos"
      name="service_types"
      valueType="number"
      array={generateHeader(header.service_types, mapServiceTypes)}
      filterState={servicesFilter}
      setFilterState={setServicesFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      position="right"
      isActive={isFilterActive('select', servicesFilter)}
    />,
    <SelectFilter
      title="Šaltinis"
      name="source"
      valueType="string"
      array={generateHeader(header.source)}
      filterState={sourceFilter}
      setFilterState={setSourceFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', sourceFilter)}
    />,
    <div className={styles.tableHeader}>Išorinis ID</div>,
  ];

  const tableFooter = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    formatMoney(footer.total_cost),
    '',
    '',
    '',
  ];

  const renderTypes = types =>
    types.map(type => <p key={type}>{mapServiceTypes(type)}</p>);

  const renderTable = () =>
    orders.map(
      ({
        id,
        status,
        payment_method,
        location,
        created_at,
        time,
        company_name,
        company_code,
        discounts,
        client_phone,
        total_cost,
        service_types,
        source,
        external_id,
      }) => (
        <TableRow url={config.APP_PATHS.orderDetails(id)} key={id}>
          <TableCell>{id}</TableCell>
          <TableCell>
            <span style={{ color: mapOrderStatusColor(status) }}>
              {mapOrderStatus(status)}
            </span>
          </TableCell>
          <TableCell>{mapPaymentMethod(payment_method)}</TableCell>
          <TableCell>{location}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell>{formatTime(time)}</TableCell>
          <TableCell>{company_name}</TableCell>
          <TableCell>{company_code}</TableCell>
          <TableCell>
            {discounts.map((discount, i) => (
              <p key={i}>{discount}</p>
            ))}
          </TableCell>
          <TableCell>{client_phone}</TableCell>
          <TableCell align="right">{formatMoney(total_cost)}</TableCell>
          <TableCell>{renderTypes(service_types)}</TableCell>
          <TableCell>{source}</TableCell>
          <TableCell>{external_id || '-'}</TableCell>
        </TableRow>
      )
    );

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.orders);
    //dispatch(actions.orders.exportOrdersAction(filename, filterBody));
    dispatch(actions.orders.exportOrdersAsyncAction(filterBody));
  };

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.orders}</Title>
        <HeaderActions>
          {(filterBody || params.location || params.phone) && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        filter={true}
        isEmpty={!orders.length}
        loading={loading}
        rightAlignedFooterCells={[8, 9]}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  orders: state.orders,
  user: state.auth.user,
  xlsLoading: state.download.xlsLoading,
  loaction: state.locations.selectedLocation,
});

Orders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  orders: PropTypes.shape({
    orders: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    header: PropTypes.shape({
      status: PropTypes.arrayOf(PropTypes.number),
      location: PropTypes.arrayOf(PropTypes.string),
      payment_type: PropTypes.arrayOf(PropTypes.number),
      company_name: PropTypes.arrayOf(PropTypes.string),
      company_code: PropTypes.arrayOf(PropTypes.string),
      discounts: PropTypes.arrayOf(PropTypes.string),
      client_phone: PropTypes.arrayOf(PropTypes.string),
      service_types: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    footer: PropTypes.shape({
      total_cost: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Orders);
