import { constants } from '../../constants';

const INITIAL_STATE = {
  loading: false,
  orders: [],
  total: 0,
  header: {},
  footer: {},
  selectedOrder: {},
  orderHistoryExport: {},
};

export const orderHistoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.orderHistory.SET_ORDER_HISTORY_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.orderHistory.SET_ORDER_HISTORY: {
      return {
        ...state,
        orders: action.orders,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    }
    case constants.orderHistory.SET_ORDER_HISTORY_RECORD:
      return {
        ...state,
        selectedOrder: action.order,
      };
    case constants.orderHistory.SET_ORDER_HISTORY_EXPORT_IDENTIFIER:
      return {
        ...state,
        orderHistoryExport: action.identifier,
      };
    case constants.orderHistory.CLEAR_ORDER_HISTORY:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
