import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from '../../../api/config';
import {
  Button,
  Checkbox,
  Header,
  Paper,
  PaperContainer,
  Title,
} from '../../../components';
import { useState } from 'react';
import { useEffect } from 'react';
import { actions } from '../../../state';
import { useCallback } from 'react';

import styles from './discounts.module.scss';

const BaDiscountBulkAssigning = ({
  dispatch,
  history,
  employees: { employees, loading },
}) => {
  const [plan, setPlan] = useState(null);
  const [checkedEmployees, setCheckedEmployees] = useState(null);

  useEffect(() => {
    const state = history?.location?.state;
    if (!state) {
      history.push(config.APP_PATHS.baDiscountPlans);
      return;
    }
    if (!plan) {
      setPlan(JSON.parse(state));
    }
  }, [history, plan]);

  useEffect(() => {
    if (employees.length && !checkedEmployees) {
      const data = employees.reduce((acc, { id, full_name, membership_id }) => {
        const key = `${id}_${full_name}`;
        return { ...acc, [key]: membership_id === plan.id };
      }, {});
      setCheckedEmployees(data);
    }
  }, [checkedEmployees, plan, employees]);

  const fetchEmployees = useCallback(
    data => {
      dispatch(actions.employees.getEmployeesAction(data));
    },
    [dispatch]
  );

  const clearEmployees = useCallback(() => {
    dispatch(actions.employees.clearEmployeesAction());
  }, [dispatch]);

  useEffect(() => {
    const filterBody = {
      filter: {
        and: [{ field: 'type', value: [1401] }],
      },
    };
    fetchEmployees(filterBody);
    return () => clearEmployees();
  }, [fetchEmployees, clearEmployees]);

  const getTotal = () =>
    plan
      ? `${plan.license_count - (plan.assigned_license_count || 0)}/${
          plan.license_count
        }`
      : '';

  const handleCheckboxChange = ({ currentTarget: { id } }) => {
    setCheckedEmployees({
      ...checkedEmployees,
      [id]: !checkedEmployees[id],
    });
  };

  const handleSubmit = () => {
    const selectedIds = Object.entries(checkedEmployees)
      .filter(([_key, value]) => value)
      .map(([key]) => +key.split('_')[0]);

    const body = {
      membership_id: plan.id,
      employee_ids: selectedIds,
    };
    dispatch(actions.employees.bulkMembershipAssign(body, history));
  };

  const renderInfo = () =>
    employees.map(({ full_name, id }) => {
      const key = `${id}_${full_name}`;
      return (
        checkedEmployees && (
          <div className={styles.infoRow} key={full_name}>
            <Checkbox
              name={key}
              label={full_name}
              checked={checkedEmployees[key]}
              value={checkedEmployees[key]}
              onChange={handleCheckboxChange}
            />
          </div>
        )
      );
    });

  return plan ? (
    <section>
      <Header>
        <Title total={getTotal()}>{plan?.title_lt}</Title>
      </Header>
      <PaperContainer>
        <Paper size="xs">
          <article className={styles.document}>
            <div className={styles.info}>{renderInfo()}</div>
            <div className={styles.submitContainer}>
              <Button color="primary" onClick={handleSubmit}>
                Išsaugoti
              </Button>
            </div>
          </article>
        </Paper>
      </PaperContainer>
    </section>
  ) : null;
};

const mapStateToProps = state => ({
  employees: state.employees,
});

BaDiscountBulkAssigning.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(BaDiscountBulkAssigning);
