import React from 'react';
import QRCode from 'qrcode.react';

import styles from './qrCodesAllModal.module.scss';
import { mapServiceTypesNames } from '../../../utils';

const OqCodesItem = ({ data, service, locationTitle, handleQrCodeClick }) => {
  const size = 128;

  const getServiceName = device => {
    if (service === 'self_service_washing') {
      if (device.type === 201) {
        return 'Boksas';
      }
      return 'Atviras boksas';
    }
    return mapServiceTypesNames(service);
  };

  return (
    data[service] && (
      <div className={styles.qrContainer}>
        {data[service].devices.map(x => (
          <div
            key={x.id}
            className={styles.qr}
            onClick={() => handleQrCodeClick(x, service)}
          >
            <QRCode value={x.qr} renderAs="svg" size={size} />
            <p>{locationTitle}</p>
            <p>{getServiceName(x)}</p>
            <p>{x.title}</p>
          </div>
        ))}
      </div>
    )
  );
};

export default OqCodesItem;
