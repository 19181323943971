import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './table.module.scss';
import { Spinner } from '..';

const Table = ({
  children,
  header,
  footer,
  filter,
  isEmpty,
  width,
  loading,
  rightAlignedFooterCells,
}) => {
  const row = Array.from({ length: header.length });

  const renderHeader = () =>
    header.map((col, i) => (
      <span className={styles.headerCell} key={i} role="columnheader">
        {filter ? col : <div className={styles.headerTitle}>{col}</div>}
      </span>
    ));

  const renderFooter = () =>
    footer
      ? footer.map((col, i) => (
          <span
            className={cn(
              styles.footerCell,
              rightAlignedFooterCells.includes(i) && styles.rightCells
            )}
            key={i}
            role="gridcell"
          >
            {col}
          </span>
        ))
      : row.map((_, i) => <TableCell key={i}> </TableCell>);

  const renderEmptyRow = () =>
    isEmpty && (
      <div className={styles.emptyRow}>
        {row.map((_, i) => (
          <TableCell key={i}> </TableCell>
        ))}
      </div>
    );

  return (
    <div className={styles.main}>
      <div className={cn(styles.table, styles[width])} role="grid">
        <div className={styles.header} role="presentation">
          <div className={styles.headerRow} role="row">
            {renderHeader()}
          </div>
        </div>
        <div className={styles.body} role="presentation">
          {children}
        </div>
        {renderEmptyRow()}
        <div className={styles.footerRow} role="row">
          {renderFooter()}
        </div>
      </div>
      {isEmpty && (
        <div className={styles.infoText}>
          {loading ? <Spinner /> : 'Rezultatų nėra'}
        </div>
      )}
    </div>
  );
};

export const TableRow = ({
  children,
  url,
  onClick,
  disabled,
  largeFont,
  state,
}) =>
  url ? (
    <Link
      onClick={onClick}
      className={cn(
        styles.bodyRow,
        disabled ? styles.disabled : null,
        largeFont ? styles['font-big'] : ''
      )}
      to={state ? { pathname: url, state } : url}
      role="row"
    >
      {children}
    </Link>
  ) : (
    <div
      className={cn(
        styles.bodyRow,
        disabled ? styles.disabled : null,
        largeFont ? styles['font-big'] : ''
      )}
      onClick={onClick}
      role="row"
    >
      {children}
    </div>
  );

export const TableSeparator = ({ columns }) => (
  <div className={styles.separatorRow}>
    {columns.map((_, i) => (
      <TableCell key={i}> </TableCell>
    ))}
  </div>
);

export const TableCell = ({ children, align, verticalCenter }) => (
  <span
    className={cn(
      styles.bodyCell,
      styles[align],
      verticalCenter ? styles.verticalCenter : null
    )}
    role="gridcell"
  >
    {children}
  </span>
);

Table.defaultProps = {
  isEmpty: false,
  footer: null,
  filter: false,
  loading: false,
  width: 'xs',
  rightAlignedFooterCells: [],
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.array.isRequired,
  footer: PropTypes.array,
  filter: PropTypes.bool,
  isEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  width: PropTypes.oneOf(['xs', 'sm', 'lg']),
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TableRow.defaultProps = {
  url: null,
  onClick: () => {},
  disabled: false,
  largeFont: false,
};

TableRow.propTypes = {
  url: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  largeFont: PropTypes.bool,
};

TableCell.defaultProps = {
  children: null,
  align: 'left',
  verticalCenter: false,
};

TableCell.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default Table;
