import { call, put } from 'redux-saga/effects';
import { config } from '../../api/config';
import { Api } from '../../api/api';
import { actions } from '..';
import { errorMessage } from '../../utils';

export function* getAdminsSaga() {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    const { admins, count } = yield call(Api.getAdmins);

    yield put(actions.admins.setAdminsAction(admins, count));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* getAdminSaga({ id }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    const admin = yield call(Api.getAdmin, id);

    yield put(actions.admins.setAdminAction(admin));
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* addAdminSaga({ body, history }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    yield call(Api.addAdmin, body);

    history.push(config.APP_PATHS.admins);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* editAdminSaga({ id, body, history }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    yield call(Api.editAdmin, id, body);

    history.push(config.APP_PATHS.admins);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}

export function* deactivateAdminAction({ id, newStatus }) {
  try {
    yield put(actions.admins.setAdminsLoadingAction(true));

    const body = { status: newStatus };

    yield call(Api.editAdmin, id, body);
  } catch (e) {
    const msg = errorMessage(e);
    yield put(actions.error.setErrorAction(msg));
  } finally {
    yield put(actions.admins.setAdminsLoadingAction(false));
  }
}
