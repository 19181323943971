import { constants } from '..';

const INITIAL_STATE = {
  xlsLoading: false,
  xmlLoading: false,
  qrLoading: false,
  exportResult: null,
  fileContent: null,
  socket: false,
};

export const downloadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.download.SET_XLS_LOADING:
      return {
        ...state,
        xlsLoading: action.flag,
      };
    case constants.download.CLEAN_DOWNLOAD:
      return {
        ...state,
        fileContent: null,
        exportResult: null,
      };
    case constants.download.SET_DOWNLOADED_FILE:
      return {
        ...state,
        fileContent: action.fileContent,
      };
    case constants.download.SET_XML_LOADING:
      return {
        ...state,
        xmlLoading: action.flag,
      };
    case constants.download.SET_QR_LOADING:
      return {
        ...state,
        qrLoading: action.flag,
      };
    case constants.download.SET_ASYNC_EXPORT_RESULT:
      return {
        ...state,
        exportResult: action.result,
      };
    case constants.download.CONNECT_SOCKET:
      return {
        ...state,
        socket: action.flag,
      };
    default:
      return state;
  }
};
