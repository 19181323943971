import React, { useRef } from 'react';
import QRCode from 'qrcode.react';

import styles from './qrCodeModal.module.scss';
import { formatAddress, htmlToPdf } from './../../../utils';
import { Backdrop, Icon, Button } from '../../../components';

const QrComponent = ({ children, location }) => {
  const componentRef = useRef();
  const size = 128;

  const handleClick = () => {
    if (componentRef.current) {
      htmlToPdf(componentRef.current, formatAddress(location), 'one');
    }
  };

  return (
    <>
      <div ref={componentRef} className={styles.oneQrContainer.qr}>
        <div className={styles.qrContainer}>
          <QRCode value={children} renderAs="svg" size={size} />
          <p>{location}</p>
        </div>
      </div>
      <div className={styles.actions}>
        <Button color="primary" onClick={handleClick}>
          Parsisiųsti
        </Button>
      </div>
    </>
  );
};

const QrCodeModal = ({ title, children, desc, onCancel }) => {
  return (
    <>
      <div
        className={styles.modal}
        id="modal"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal__title"
        aria-describedby="modal__desc"
      >
        <button className={styles.close} onClick={onCancel}>
          <Icon
            name="close"
            classes={{
              icon: styles.icon,
            }}
          />
        </button>

        <h3 className={styles.title} id="modal__title">
          {title}
        </h3>

        <div className={styles.desc} id="modal__desc">
          <QrComponent children={children} location={desc} />
        </div>
      </div>
      <Backdrop onCancel={onCancel} />
    </>
  );
};

export default QrCodeModal;
