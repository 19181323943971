import { constants } from '..';

const setReceiptsLoadingAction = flag => ({
  type: constants.receipts.SET_RECEIPTS_LOADING,
  flag,
});

const getReceiptsAction = body => ({
  type: constants.receipts.GET_RECEIPTS,
  body,
});

const setReceiptsAction = (receipts, count, header, footer) => ({
  type: constants.receipts.SET_RECEIPTS,
  receipts,
  count,
  header,
  footer,
});

const clearReceiptsAction = () => ({
  type: constants.receipts.CLEAR_RECEIPTS,
});

const getReceiptAction = id => ({
  type: constants.receipts.GET_RECEIPT,
  id,
});

const setReceiptAction = receipt => ({
  type: constants.receipts.SET_RECEIPT,
  receipt,
});

const exportReceiptsAction = (filename, body) => ({
  type: constants.receipts.EXPORT_RECEIPTS,
  filename,
  body,
});

const exportReceiptsXmlAction = (filename, body) => ({
  type: constants.receipts.EXPORT_RECEIPTS_XML,
  filename,
  body,
});

const downloadReceiptPdf = (id, invoiceNumber) => ({
  type: constants.receipts.DOWNLOAD_PDF,
  id,
  invoiceNumber,
});

const exportReceiptsAsyncAction = filterBody => ({
  type: constants.receipts.EXPORT_RECEIPTS_ASYNC,
  filter: filterBody,
});

const setExportIdentifierAction = identifier => ({
  type: constants.receipts.SET_RECEIPTS_EXPORT_IDENTIFIER,
  identifier,
});

export const receiptsActions = {
  setReceiptsLoadingAction,
  getReceiptsAction,
  setReceiptsAction,
  clearReceiptsAction,
  getReceiptAction,
  setReceiptAction,
  exportReceiptsAction,
  exportReceiptsAsyncAction,
  exportReceiptsXmlAction,
  setExportIdentifierAction,
  downloadReceiptPdf,
};
