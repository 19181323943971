import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './resetPassFromToken.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { getQueryParam } from '../../../utils';
import logo from '../logo.png';
import { Button, WithSpinner, Input, Paper, Alert } from '../../../components';

const ResetPassFromToken = ({
  dispatch,
  history,
  location: { search },
  admins: { loading },
}) => {
  const [values, setValues] = useState({
    new_password: '',
    confirm_password: '',
  });

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();

    const token = getQueryParam(search, 'token');

    try {
      if (!values.new_password) {
        throw new Error('Įveskite naują slaptažodį');
      }
      if (!values.confirm_password) {
        throw new Error('Patvirtinkite slaptažodį');
      }
      if (values.new_password !== values.confirm_password) {
        throw new Error('Slaptažodžiai nesutampa');
      }

      dispatch(
        actions.auth.resetPassFromTokenAction(
          token,
          values.new_password,
          history
        )
      );
    } catch ({ message }) {
      dispatch(actions.error.setAlertAction(message, 'danger'));
    }
  };

  return (
    <section className={styles.login}>
      <Paper size="xs">
        <div className={styles.container}>
          <Link to={config.APP_PATHS.home}>
            <img className={styles.logo} src={logo} alt="H2AUTO" />
          </Link>
          <form onSubmit={onSubmit} method="POST">
            <Alert />

            <Input
              name="new_password"
              label="Naujas slaptažodis"
              type="password"
              value={values.new_password}
              onChange={handleInputChange}
            />
            <Input
              name="confirm_password"
              label="Patvirtinti slaptažodį"
              type="password"
              value={values.confirm_password}
              onChange={handleInputChange}
            />

            <WithSpinner loading={loading}>
              <Button
                color="primary"
                classes={{ button: styles.button }}
                type="submit"
              >
                Atnaujinti
              </Button>
            </WithSpinner>
          </form>
        </div>
      </Paper>
    </section>
  );
};

const mapStateToProps = state => ({
  admins: state.admins,
});

ResetPassFromToken.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  admins: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(ResetPassFromToken);
