import { constants } from '..';

const setClientsSearchLoadingAction = flag => ({
  type: constants.search.SET_SEARCH_LOADING,
  flag,
});

const setClientsSearchErrorAction = error => ({
  type: constants.search.SET_SEARCH_ERROR,
  error,
});

const getClientsSearchAction = query => ({
  type: constants.search.GET_SEARCH,
  query,
});

const setClientsSearchAction = results => ({
  type: constants.search.SET_SEARCH,
  results,
});

const getH2CardsSearchAction = query => ({
  type: constants.search.GET_H2_CARD_SEARCH,
  query,
});

const setH2CardsSearchAction = results => ({
  type: constants.search.SET_H2_CARD_SEARCH,
  results,
});

export const searchActions = {
  setClientsSearchLoadingAction,
  setClientsSearchErrorAction,
  getClientsSearchAction,
  setClientsSearchAction,
  getH2CardsSearchAction,
  setH2CardsSearchAction,
};
