import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  orders: [],
  total: 0,
  header: {},
  footer: {},
  selectedOrder: {},
  orderExport: {},
};

export const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.orders.SET_ORDERS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.orders.SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.orders.CLEAR_ORDERS:
      return {
        ...INITIAL_STATE,
      };
    case constants.orders.SET_ORDER:
      return {
        ...state,
        selectedOrder: action.order,
      };
    case constants.orders.SET_ORDERS_EXPORT_IDENTIFIER:
      return {
        ...state,
        orderExport: action.identifier,
      };
    default:
      return state;
  }
};
