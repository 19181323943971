import React from 'react';

import PropTypes from 'prop-types';

import styles from './orders.module.scss';
import {
  formatTime,
  formatDate,
  mapPaymentType,
  mapPaymentMethod,
} from '../../utils';
import { TitleSecondary } from '../../components';
import ServicesTable from './orders.services.table';
import OrderPaymentsTable from './orders.payments.table';

const OrderDocument = ({
  order: {
    id,
    client,
    created_at,
    time,
    payment_type,
    payment_method,
    company_name,
    payment_card_number,
    items,
    discounts,
    total_tax,
    total_cost_with_discount,
    payments,
    source,
    external_id,
  },
}) => {
  const info = [
    {
      title: 'Užsakymo ID',
      data: id,
    },
    {
      title: 'Vartotojas',
      data: client.phone,
    },
    {
      title: 'Data',
      data: formatDate(created_at),
    },
    {
      title: 'Laikas',
      data: formatTime(time),
    },
    {
      title: 'Tipas',
      data: mapPaymentType(payment_type),
    },
    {
      title: 'Mokėta',
      data: mapPaymentMethod(payment_method),
    },
    {
      title: 'Įmonė',
      data: company_name || '-',
    },
    {
      title: 'Mokėjimo kortelė',
      data: payment_card_number,
    },
    {
      title: 'Šaltinis',
      data: source,
    },
    {
      title: 'Išorinis ID',
      data: external_id || '-',
    },
  ];

  const renderInfo = () =>
    info.map(({ title, data }) => (
      <div className={styles.infoRow} key={title}>
        <span>{title}</span>
        <span>{data}</span>
      </div>
    ));

  return (
    <article className={styles.document}>
      <TitleSecondary>Užsakymo informacija</TitleSecondary>

      <div className={styles.info}>{renderInfo()}</div>

      <ServicesTable
        items={items}
        discounts={discounts}
        total_tax={total_tax}
        total_cost_with_discount={total_cost_with_discount}
      />

      <div className={styles.paymentInfoTitle}>
        <TitleSecondary>Mokėjimo informacija</TitleSecondary>
      </div>

      <OrderPaymentsTable payments={payments} />
    </article>
  );
};

OrderDocument.defaultProps = {
  company_name: null,
};

OrderDocument.propTypes = {
  order: PropTypes.shape({
    client: PropTypes.shape({
      id: PropTypes.number,
      phone: PropTypes.string,
    }).isRequired,
    created_at: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
    payment_type: PropTypes.number.isRequired,
    company_name: PropTypes.string,
    payment_card_number: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    discounts: PropTypes.array.isRequired,
    total_cost_without_tax: PropTypes.number.isRequired,
    total_cost_with_discount: PropTypes.number.isRequired,
  }).isRequired,
};

export default OrderDocument;
