import React from 'react';
import { Link } from 'react-router-dom';

import styles from './logo.module.scss';
import { config } from '../../../../api/config';
import logo from './h2auto_logo.png';

const Logo = () => (
  <Link to={config.APP_PATHS.home}>
    <img className={styles.logo} src={logo} alt="H2AUTO" />
  </Link>
);

export default Logo;
