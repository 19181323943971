import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './locations.module.scss';
import { actions } from './../../state';
import { config } from './../../api/config';
import { formatFilename } from '../../utils';
import {
  Button,
  ButtonDownload,
  HeaderActions,
  DateFilter,
  ButtonSimple,
  Tabs,
} from '../../components';
import LocationsTable from './locationsTable';
import ServicesTable from './servicesTable';
import BoxesTable from './boxesTable';
import { LocationsContext } from './locations.context';

const tabsContent = [
  {
    title: 'Adresai',
    tabIdx: 0,
  },
  {
    title: 'Paslaugos',
    tabIdx: 1,
  },
  {
    title: 'Boksai',
    tabIdx: 2,
  },
];

const Locations = ({ dispatch, xlsLoading }) => {
  const {
    activeTab,
    setActiveTab,
    filteredBody,
    setFilteredBody,
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
    sortOrder,
    setSortOrder,
    timeRangeFilterValue,
    setTimeRangeFilterValue,
    clearAllFilters,
    setClearAllFilters,
  } = useContext(LocationsContext);

  const handleSubmit = name => {
    const generatedFilter = [];
    if (name && name === 'clear') {
      setFilteredBody();
      setTimeRangeFilterValue();
      setClearAllFilters(true);
    } else {
      setClearAllFilters(false);
      if (createdAtGtFilter) {
        generatedFilter.push({
          field: 'completed_at',
          expression: 'gte',
          value: createdAtGtFilter,
          type: 'timestamp',
        });
      }
      if (createdAtLtFilter) {
        generatedFilter.push({
          field: 'completed_at',
          expression: 'lte',
          value: createdAtLtFilter,
          type: 'timestamp',
        });
      }

      if (name) {
        const timeRangeFilter2 = {
          filter2: {
            and: generatedFilter,
          },
        };
        setTimeRangeFilterValue(timeRangeFilter2);
      }
    }
  };

  const setClearToFalse = useCallback(body => {
    setClearAllFilters(body);
  }, []);

  const getFilteredBody = useCallback(
    body => {
      if (clearAllFilters === true) {
        setFilteredBody();
        setTimeRangeFilterValue();
        setCreatedAtLtFilter(0);
        setCreatedAtGtFilter(0);
      } else {
        setFilteredBody(body);
      }
    },
    [clearAllFilters]
  );

  const renderTable = () => {
    const TableProps = {
      timeRangeFilter: timeRangeFilterValue,
      getFilteredBody: getFilteredBody,
      clearAllFilters: clearAllFilters,
      setClearToFalse: setClearToFalse,
    };

    switch (activeTab) {
      case 0:
        return <LocationsTable {...TableProps} />;
      case 1:
        return <ServicesTable {...TableProps} />;
      case 2:
        return <BoxesTable {...TableProps} />;
      default:
        return null;
    }
  };

  const clearState = () => {
    dispatch(actions.addEditServices.clearAddEditServicesAction());
  };

  const clearFilters = () => {
    setFilteredBody();
    setTimeRangeFilterValue();
    setCreatedAtLtFilter(0);
    setCreatedAtGtFilter(0);
    handleSubmit('clear');
    setClearAllFilters(true);
  };

  const exportXls = () => {
    const filename = formatFilename(config.PAGES.locations);
    dispatch(
      actions.locations.exportLocationsAction(filename, activeTab, filteredBody)
    );
  };

  return (
    <section>
      <div className={styles.buttonContainer}>
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabsContent={tabsContent}
        />
        <HeaderActions>
          {filteredBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <div className={styles.timeRangeButton}>
            <DateFilter
              title="Pasirinkite laikotarpį"
              name="completed_at"
              lt={createdAtLtFilter}
              setLt={setCreatedAtLtFilter}
              gt={createdAtGtFilter}
              setGt={setCreatedAtGtFilter}
              onSubmit={handleSubmit}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              inLocations={true}
              isActive={false}
            />
          </div>
          <ButtonDownload onClick={exportXls} loading={xlsLoading} />
          <Button
            color="primary"
            url={config.APP_PATHS.addWasher}
            onClick={clearState}
          >
            Pridėti plovyklą
          </Button>
        </HeaderActions>
      </div>
      {renderTable()}
    </section>
  );
};

const mapStateToProps = state => ({
  xlsLoading: state.download.xlsLoading,
});

Locations.propTypes = {
  dispatch: PropTypes.func.isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Locations);
