import React from 'react';
import styles from './monthSelection.module.scss';
import PropTypes from 'prop-types';

const MonthSelection = ({ name, value, onChange }) => {
  const months = [
    { title: 'Sausis', value: 1 },
    { title: 'Vasaris', value: 2 },
    { title: 'Kovas', value: 3 },
    { title: 'Balandis', value: 4 },
    { title: 'Gegužė', value: 5 },
    { title: 'Birželis', value: 6 },
    { title: 'Liepa', value: 7 },
    { title: 'Rugpjūtis', value: 8 },
    { title: 'Rugsėjis', value: 9 },
    { title: 'Spalis', value: 10 },
    { title: 'Lapkritis', value: 11 },
    { title: 'Gruodis', value: 12 },
  ];

  const renderOptions = () =>
    months.map(({ title, value }) => (
      <option key={value} value={value}>
        {title}
      </option>
    ));

  return (
    <select
      name={name}
      value={value}
      onChange={onChange}
      className={styles.container}
    >
      {renderOptions()}
    </select>
  );
};

MonthSelection.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MonthSelection;
