import { TextileContext } from './textile.context';

import React, { useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../state';
import { config } from '../../api/config';

import {
  TableCell,
  TableRow,
  Header,
  Title,
  ButtonSimple,
  HeaderActions,
  Table,
  DateFilter,
  SelectFilter,
  TimeFilter,
  Button,
} from '../../components';
import { formatDate, formatTime } from '../../utils';
import {
  mapAutomaticWashCodeStatuses,
  mapServiceOptionType,
  mapTextileCodeColor,
} from '../../utils';
import styles from './textile.module.scss';
import { isFilterActive, generateHeader } from '../../utils';

const Textile = ({
  dispatch,
  automatic_wash_codes: { automatic_wash_codes, total, header, loading },
}) => {
  const {
    filterBody,
    setFilterBody,
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
    timeLtFilter,
    setTimeLtFilter,
    timeGtFilter,
    setTimeGtFilter,
    programFilter,
    setProgramFilter,
    codeFilter,
    setCodeFilter,
    statusFilter,
    setStatusFilter,
    sortOrder,
    setSortOrder,
  } = useContext(TextileContext);
  const FIFTEEN_MINUTES = 900000; // milliseconds

  const fetchAutomaticWashCodes = useCallback(
    data => {
      dispatch(actions.textile.getAutomaticWashCodesAction(data));
    },
    [dispatch]
  );

  const clearAutomaticWashCodes = useCallback(() => {
    dispatch(actions.textile.clearAutomaticWashCodesAction());
  }, [dispatch]);

  const refreshTable = () => {
    dispatch(actions.textile.getAutomaticWashCodesAction(filterBody));
  };

  useEffect(() => {
    if (filterBody)
      fetchAutomaticWashCodes({
        ...filterBody,
        order: [{ by: 'created_at', order: 'desc' }, ...filterBody.order],
      });
    else fetchAutomaticWashCodes(filterBody);

    return () => clearAutomaticWashCodes();
  }, [fetchAutomaticWashCodes, clearAutomaticWashCodes, filterBody]);

  const clearFilters = () => {
    setStatusFilter([]);
    setCreatedAtLtFilter();
    setCreatedAtGtFilter();
    setTimeLtFilter('00:00');
    setTimeGtFilter('00:00');
    setCodeFilter([]);
    setProgramFilter([]);

    handleSubmit();
  };

  const handleSubmit = name => {
    const generatedFilter = [];

    if (statusFilter.length) {
      generatedFilter.push({ field: 'status', value: statusFilter });
    }
    if (codeFilter.length) {
      generatedFilter.push({ field: 'code', value: codeFilter });
    }
    if (programFilter.length) {
      generatedFilter.push({ field: 'program', value: programFilter });
    }
    if (timeLtFilter !== '00:00') {
      generatedFilter.push({
        field: 'time',
        expression: 'lte',
        value: timeLtFilter,
        type: 'time',
      });
    }
    if (timeGtFilter !== '00:00') {
      generatedFilter.push({
        field: 'time',
        expression: 'gte',
        value: timeGtFilter,
        type: 'time',
      });
    }
    if (createdAtGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: createdAtGtFilter,
        type: 'timestamp',
      });
    }
    if (createdAtLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: createdAtLtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const handleOnUseCodeClicked = id => {
    dispatch(actions.textile.useWashCodeAction(id, filterBody));
  };

  const handleOnRemoveCodeClicked = id => {
    dispatch(actions.textile.removeWashCodeAction(id, filterBody));
  };

  const tableHeader = [
    <DateFilter
      title="Data"
      name="created_at"
      lt={createdAtLtFilter}
      setLt={setCreatedAtLtFilter}
      gt={createdAtGtFilter}
      setGt={setCreatedAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', createdAtLtFilter, createdAtGtFilter)}
    />,
    <TimeFilter
      title="Laikas"
      name="time"
      lt={timeLtFilter}
      setLt={setTimeLtFilter}
      gt={timeGtFilter}
      setGt={setTimeGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('time', timeLtFilter, timeGtFilter)}
    />,
    <SelectFilter
      title="Programa"
      name="program"
      valueType="number"
      array={generateHeader(header.program, mapServiceOptionType)}
      filterState={programFilter}
      setFilterState={setProgramFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', programFilter)}
    />,
    <SelectFilter
      title="PIN Kodas"
      name="code"
      valueType="string"
      array={generateHeader(header.code, x => `${x}`)}
      filterState={codeFilter}
      setFilterState={setCodeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', codeFilter)}
    />,
    <SelectFilter
      title="Būsena"
      name="status"
      valueType="number"
      array={generateHeader([1601, 1602, 1603], mapAutomaticWashCodeStatuses)}
      filterState={statusFilter}
      setFilterState={setStatusFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', statusFilter)}
    />,
    <div className={styles.actionsHeader}>Veiksmai</div>,
  ];
  const tableFooter = [];

  const usedAtTresholdPassed = used_at => {
    return used_at ? new Date().getTime() - used_at > FIFTEEN_MINUTES : null;
  };

  const renderTable = () =>
    automatic_wash_codes.map(
      ({ id, created_at, time, program, code, status, used_at }) => (
        <TableRow key={id} disabled={status === 1602} largeFont={true}>
          <TableCell verticalCenter={true}>{formatDate(created_at)}</TableCell>
          <TableCell verticalCenter={true}>{formatTime(time)}</TableCell>
          <TableCell verticalCenter={true}>
            {mapServiceOptionType(program)}
          </TableCell>
          <TableCell verticalCenter={true}>
            <span
              style={{ color: mapTextileCodeColor(status), fontWeight: 'bold' }}
            >
              {code}
            </span>
          </TableCell>
          <TableCell verticalCenter={true}>
            {mapAutomaticWashCodeStatuses(status)}
          </TableCell>
          <TableCell verticalCenter={true}>
            <div className={styles.actionButtons}>
              {status === 1601 ? (
                <div>
                  <ButtonSimple
                    type="success"
                    onClick={() => handleOnUseCodeClicked(id)}
                    bigText={true}
                  >
                    Panaudoti
                  </ButtonSimple>
                </div>
              ) : (
                <></>
              )}
              {status === 1602 && !usedAtTresholdPassed(used_at) ? (
                <div>
                  <ButtonSimple
                    type="remove-red"
                    onClick={() => handleOnRemoveCodeClicked(id)}
                    bigText={true}
                  >
                    Atšaukti
                  </ButtonSimple>
                </div>
              ) : (
                <></>
              )}
            </div>
          </TableCell>
        </TableRow>
      )
    );

  return (
    <section>
      <Header size="custom">
        <Title total={total}>{config.PAGES.textile}</Title>

        <HeaderActions>
          <Button color="primary" onClick={refreshTable}>
            Atnaujinti
          </Button>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        isEmpty={!automatic_wash_codes.length}
        filter={true}
        loading={loading}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  automatic_wash_codes: state.textile,
});

Textile.propTypes = {
  dispatch: PropTypes.func.isRequired,
  automatic_wash_codes: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    total: PropTypes.number.isRequired,
    automatic_wash_codes: PropTypes.array.isRequired,
    header: PropTypes.shape({
      code: PropTypes.arrayOf(PropTypes.string),
      program: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(Textile);
