import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  countries: [],
};

export const dropdownsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.dropdowns.SET_DROPDOWNS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.dropdowns.SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
      };
    default:
      return state;
  }
};
