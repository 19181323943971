import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const htmlToPdf = async (container, name) => {
  const canvas = await html2canvas(container);

  const pdf = new jsPDF('p', 'pt', 'letter');

  for (let i = 0; i <= container.clientHeight / 980; i++) {
    const srcImg = canvas;
    const sX = 0;
    const sY = 980 * i; // start 980 pixels down for every new page
    const sWidth = 900;
    const sHeight = 980;
    const dX = 0;
    const dY = 0;
    const dWidth = 900;
    const dHeight = 980;

    const onePageCanvas = document.createElement('canvas');
    onePageCanvas.setAttribute('width', 700);
    onePageCanvas.setAttribute('height', 984.3);
    const ctx = onePageCanvas.getContext('2d');
    ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

    const canvasDataURL = onePageCanvas.toDataURL('image/png');
    const width = onePageCanvas.width;
    const height = onePageCanvas.clientHeight;

    if (i > 0) {
      pdf.addPage(612, 791);
    }
    pdf.setPage(i + 1);
    pdf.addImage(canvasDataURL, 'PNG', 20, 15, width * 0.8, height * 0.8);
  }

  pdf.save(`${name}.pdf`);
};
