import React, { createContext, useState } from 'react';

export const BoxesContext = createContext();

export const BoxesProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [typeFilter, setTypeFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [titleFilter, setTitleFilter] = useState([]);
  const [costLtFilter, setCostLtFilter] = useState('');
  const [costGtFilter, setCostGtFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  return (
    <BoxesContext.Provider
      value={{
        typeFilter,
        setTypeFilter,
        locationFilter,
        setLocationFilter,
        statusFilter,
        setStatusFilter,
        titleFilter,
        setTitleFilter,
        costLtFilter,
        setCostLtFilter,
        costGtFilter,
        setCostGtFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
      }}
    >
      {children}
    </BoxesContext.Provider>
  );
};
