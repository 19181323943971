import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './addWasher.module.scss';
import Maps from '../../../components/maps/googleMaps';
import ServicesBox from './../services/services';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import {
  Breadcrumb,
  Toggle,
  Paper,
  Header,
  TitleSecondary,
  SubmitActions,
} from '../../../components';

const Services = ({ dispatch, history, loading, selectedLocation, error }) => {
  const [requestError, setRequestError] = useState(false);

  useEffect(() => {
    if (error) {
      setRequestError(true);
    }
  }, [error]);

  const handleSave = () => {
    dispatch(actions.locations.addLocationsAction(history));
  };

  const activeServices = {
    automatic_contactless_washing: null,
    automatic_textile_washing: null,
    car_mat_cleaner: null,
    paper_towel_dispenser: null,
    self_service_washing: null,
    vacuum_cleaner: null,
    smart_vacuum_cleaner: null,
    windshield_washer_liquid_dispenser: null,
    tyre_blackener: null,
    towel_dispenser: null,
  };

  const changeLocationStatus = () => {
    const newStatus = selectedLocation.status === 1 ? 0 : 1;
    dispatch(actions.addEditServices.pushNewStatusAction(newStatus));
  };

  return (
    <section>
      <Header>
        <Breadcrumb
          title={config.PAGES.locations}
          subtitle="Pridėti lokaciją"
          baseUrl={config.APP_PATHS.locations}
        />
        <Toggle
          checked={selectedLocation.status === 1 ? true : false}
          onChange={() => changeLocationStatus()}
          showMeta={true}
        />
      </Header>

      <Paper size="lg">
        <div className={styles.container}>
          <TitleSecondary>Adresas</TitleSecondary>
          <Maps
            invalidInput={
              requestError &&
              !(
                selectedLocation.city &&
                selectedLocation.coordinates &&
                selectedLocation.address
              )
            }
          />
        </div>

        <div className={styles.container}>
          <TitleSecondary>Paslaugos</TitleSecondary>
          <ServicesBox data={activeServices} requestError={requestError} />
        </div>

        <SubmitActions
          back={config.APP_PATHS.locations}
          loading={loading}
          onClick={handleSave}
        />
      </Paper>
    </section>
  );
};

const mapStateToProps = state => ({
  loading: state.locations.loading,
  selectedLocation: state.addEditServices,
  error: state.error.error,
});

Services.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedLocation: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    coordinates: PropTypes.array.isRequired,
    self_service_washing: PropTypes.object,
    automatic_textile_washing: PropTypes.object,
    automatic_contactless_washing: PropTypes.object,
    vacuum_cleaner: PropTypes.object,
    smart_vacuum_cleaner: PropTypes.object,
    car_mat_cleaner: PropTypes.object,
    paper_towel_dispenser: PropTypes.object,
    windshield_washer_liquid_dispenser: PropTypes.object,
    tyre_blackener: PropTypes.object,
    towel_dispenser: PropTypes.object,
  }).isRequired,
};

export default connect(mapStateToProps)(Services);
