import React, { createContext, useState } from 'react';

export const AddressesContext = createContext();

export const AddressesProvider = ({ children }) => {
  const [locationFilter, setLocationFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [servicesFilter, setServicesFilter] = useState([]);
  const [costLtFilter, setCostLtFilter] = useState('');
  const [costGtFilter, setCostGtFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterBody, setFilterBody] = useState();

  return (
    <AddressesContext.Provider
      value={{
        locationFilter,
        setLocationFilter,
        statusFilter,
        setStatusFilter,
        servicesFilter,
        setServicesFilter,
        costLtFilter,
        setCostLtFilter,
        costGtFilter,
        setCostGtFilter,
        sortOrder,
        setSortOrder,
        filterBody,
        setFilterBody,
      }}
    >
      {children}
    </AddressesContext.Provider>
  );
};
