import { constants } from '..';

const INITIAL_STATE = {
  loading: false,
  membershipOrders: [],
  total: 0,
  header: {},
  footer: {},
  selectedMembershipOrder: {},
};

export const membershipOrdersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.membershipOrders.SET_MEMBERSHIP_ORDERS_LOADING:
      return {
        ...state,
        loading: action.flag,
      };
    case constants.membershipOrders.SET_MEMBERSHIP_ORDERS:
      return {
        ...state,
        membershipOrders: action.membership_orders,
        total: action.count,
        header: action.header,
        footer: action.footer,
      };
    case constants.membershipOrders.SET_MEMBERSHIP_ORDER:
      return {
        ...state,
        selectedMembershipOrder: action.membershipOrder,
      };
    case constants.membershipOrders.CLEAR_MEMBERSHIP_ORDERS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
