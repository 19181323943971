import { constants } from '../../constants';

const setOrderHistoryLoadingAction = flag => ({
  type: constants.orderHistory.SET_ORDER_HISTORY_LOADING,
  flag,
});

const getOrderHistoryAction = body => ({
  type: constants.orderHistory.GET_ORDER_HISTORY,
  body,
});

const setOrderHistoryAction = (orders, count, header, footer) => ({
  type: constants.orderHistory.SET_ORDER_HISTORY,
  orders,
  count,
  header,
  footer,
});

const getOrderAction = id => ({
  type: constants.orderHistory.GET_ORDER_HISTORY_RECORD,
  id,
});

const setOrderAction = order => ({
  type: constants.orderHistory.SET_ORDER_HISTORY_RECORD,
  order,
});

const clearOrderHistoryAction = () => ({
  type: constants.orderHistory.CLEAR_ORDER_HISTORY,
});

const exportBusinessClientOrdersAction = (filename, filterBody) => ({
  type: constants.orderHistory.EXPORT_ORDER_HISTORY,
  filename,
  filterBody,
});

const exportH2CardAsyncAction = filterBody => ({
  type: constants.orderHistory.EXPORT_H2_CARD_ASYNC,
  filter: filterBody,
});

const setExportIdentifierAction = identifier => ({
  type: constants.orderHistory.SET_ORDER_HISTORY_EXPORT_IDENTIFIER,
  identifier,
});

export const orderHistoryActions = {
  setOrderHistoryLoadingAction,
  getOrderHistoryAction,
  setOrderHistoryAction,
  getOrderAction,
  setOrderAction,
  clearOrderHistoryAction,
  exportBusinessClientOrdersAction,
  exportH2CardAsyncAction,
  setExportIdentifierAction,
};
