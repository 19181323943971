import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { errorReducer } from './error/error.reducer';
import { modalsReducer } from './modals/modals.reducer';
import { searchReducer } from './search/search.reducer';
import { adminsReducer } from './admins/admins.reducer';
import { locationsReducer } from './locations/locations.reducer';
import { clientsReducer } from './clients/clients.reducer';
import { discountsReducer } from './discounts/discounts.reducer';
import { membershipsReducer } from './memberships/memberships.reducer';
import { membershipOrdersReducer } from './membershipOrders/membershipOrders.reducer';
import { ordersReducer } from './orders/orders.reducer';
import { servicesReducer } from './services/services.reducer';
import { boxesReducer } from './boxes/boxes.reducer';
import { addEditServicesReducer } from './addEditServices/addEditServices.reducer';
import { invoicesReducer } from './invoices/invoices.reducer';
import { receiptsReducer } from './receipts/receipts.reducer';
import { downloadReducer } from './download/download.reducer';
import { employeesReducer } from './businessAdmin/employees/employees.reducer';
import { orderHistoryReducer } from './businessAdmin/orderHistory/orderHistory.reducer';
import { businessInvoicesReducer } from './businessAdmin/businessInvoices/businessInvoices.reducer';
import { businessClientsReducer } from './businessClients/businessClients.reducer';
import { textileReducer } from './textile/textile.reducer';
import { couponsReducer } from './coupons/coupons.reducer';
import { dropdownsReducer } from './dropdowns/dropdowns.reducer';

export const reducers = combineReducers({
  auth: authReducer,
  error: errorReducer,
  modals: modalsReducer,
  search: searchReducer,
  admins: adminsReducer,
  locations: locationsReducer,
  clients: clientsReducer,
  businessClients: businessClientsReducer,
  discounts: discountsReducer,
  memberships: membershipsReducer,
  membershipOrders: membershipOrdersReducer,
  orders: ordersReducer,
  servicesTable: servicesReducer,
  boxes: boxesReducer,
  addEditServices: addEditServicesReducer,
  receipts: receiptsReducer,
  invoices: invoicesReducer,
  download: downloadReducer,
  employees: employeesReducer,
  orderHistory: orderHistoryReducer,
  businessInvoices: businessInvoicesReducer,
  textile: textileReducer,
  coupons: couponsReducer,
  dropdowns: dropdownsReducer,
});
