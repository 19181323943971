import { constants } from '..';

const pushSelfServicesAction = data => ({
  type: constants.addEditServices.ADD_EDIT_SELFSERVICES_DATA,
  data,
});

const pushAutoTextileAction = data => ({
  type: constants.addEditServices.ADD_EDIT_AUTOTEXTILE_DATA,
  data,
});

const pushAutoContactlessAction = data => ({
  type: constants.addEditServices.ADD_EDIT_AUTOCONTACTLESS_DATA,
  data,
});

const pushVacuumCleanerAction = data => ({
  type: constants.addEditServices.ADD_EDIT_VACUUM_CLEANER_DATA,
  data,
});

const pushSmartVacuumCleanerAction = data => ({
  type: constants.addEditServices.ADD_EDIT_SMART_VACUUM_CLEANER_DATA,
  data,
});

const pushCarMatCleanerAction = data => ({
  type: constants.addEditServices.ADD_EDIT_CAR_MAT_CLEANER_DATA,
  data,
});

const pushPaperTowelDispenserAction = data => ({
  type: constants.addEditServices.ADD_EDIT_PAPER_TOWEL_DISPENSER_DATA,
  data,
});

const pushWindshieldWasherLiquidDispenserAction = data => ({
  type: constants.addEditServices.ADD_WIND_SHIELD_WASHER_LIQUID_DISPENSER_DATA,
  data,
});

const pushTyreBlackenerAction = data => ({
  type: constants.addEditServices.ADD_TYRE_BLACKENER_DATA,
  data,
});

const pushTowelDispenserAction = data => ({
  type: constants.addEditServices.ADD_TOWEL_DISPENSER_DATA,
  data,
});

const pushPlaceAction = data => ({
  type: constants.addEditServices.PUSH_PLACES_DATA,
  data,
});

const pushNewCoordAction = coord => ({
  type: constants.addEditServices.PUSH_NEW_COORD,
  coord,
});

const pushNewStatusAction = status => ({
  type: constants.addEditServices.PUSH_NEW_STATUS,
  status,
});

const editLocationAction = (id, body, history) => ({
  type: constants.addEditServices.EDIT_LOCATION,
  id,
  body,
  history,
});

const replaceAddEditServicesData = payload => ({
  type: constants.addEditServices.REPLACE_ADD_EDIT_SERVICES_DATA,
  payload,
});

const clearAddEditServicesAction = () => ({
  type: constants.addEditServices.CLEAR_ADD_EDIT_SERVICES_ACTION,
});

const handleLocationStatusChangeAction = (source, status) => ({
  type: constants.addEditServices.HANDLE_LOCATION_STATUS_CHANGE,
  source,
  status,
});

const regenerateDeviceKeyAction = id => {
  return {
    type: constants.addEditServices.REGENERATE_DEVICE_KEY,
    id,
  };
};

export const addEditServicesActions = {
  pushSelfServicesAction,
  pushPlaceAction,
  pushAutoTextileAction,
  regenerateDeviceKeyAction,
  pushAutoContactlessAction,
  pushVacuumCleanerAction,
  pushSmartVacuumCleanerAction,
  pushCarMatCleanerAction,
  pushPaperTowelDispenserAction,
  pushWindshieldWasherLiquidDispenserAction,
  pushTyreBlackenerAction,
  pushTowelDispenserAction,
  editLocationAction,
  replaceAddEditServicesData,
  clearAddEditServicesAction,
  pushNewCoordAction,
  handleLocationStatusChangeAction,
  pushNewStatusAction,
};
