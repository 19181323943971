import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './orders.module.scss';
import {
  formatMoney,
  formatSecondsToMinutes,
  mapServiceTypes,
  mapServiceOptionType,
  mapSelfServiceTypes,
} from '../../utils';
import TableSimple, {
  TableRow,
  TableCell,
} from '../../components/tableSimple/tableSimple';

const ServicesTable = ({ items, total_tax, total_cost_with_discount }) => {
  const generateQuantity = (type, option, quantity) => {
    switch (type) {
      case 22:
      case 23:
        return mapServiceOptionType(option);
      case 21:
      case 24:
      case 25:
      case 27:
      case 28:
        return `${formatSecondsToMinutes(quantity)} min`;
      case 26:
        return `${quantity} l`;
      default:
        return '-';
    }
  };

  const tableHeader = ['Paslauga', 'Kiekis', 'Kaina su PVM'];

  const renderTable = () =>
    items.map(
      (
        {
          id,
          service_type,
          service_option,
          quantity,
          cost,
          detailed,
          discount_info,
        },
        index
      ) => (
        <React.Fragment key={id}>
          <TableRow noBorder={true}>
            <TableCell>{mapServiceTypes(service_type)}</TableCell>
            <TableCell>
              {generateQuantity(service_type, service_option, quantity)}
            </TableCell>
            <TableCell align="right">{formatMoney(cost)}</TableCell>
          </TableRow>
          {detailed &&
            detailed.map(({ option_type, cost, quantity }, innerIndex) =>
              quantity ? (
                <TableRow
                  key={option_type}
                  noBorder={
                    discount_info ||
                    index === items.length - 1 ||
                    innerIndex !== detailed.length - 1
                  }
                >
                  <TableCell>
                    <span className={styles.colorGrey}>
                      {mapSelfServiceTypes(option_type)}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={styles.colorGrey}>
                      {generateQuantity(service_type, service_option, quantity)}
                    </span>
                  </TableCell>
                  <TableCell align="right">
                    <span className={styles.colorGrey}>
                      {formatMoney(cost)}
                    </span>
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )
            )}
          {discount_info ? (
            <TableRow noBorder={index === items.length - 1}>
              <TableCell />
              <TableCell />
              <TableCell align="right">
                <div className={styles.discountSummary}>
                  <span>
                    Nuolaida (
                    {discount_info.title_lt
                      ? discount_info.title_lt
                      : discount_info.code}
                    -{discount_info.discount}%)
                  </span>
                  <span>{formatMoney(discount_info.total_discount)}</span>
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
        </React.Fragment>
      )
    );

  const renderFooter = () => (
    <div className={styles.tableFooter}>
      <div className={styles.summary}>
        <span>Suma (be PVM)</span>
        <span>{formatMoney(total_cost_with_discount - total_tax)}</span>
      </div>
      <div className={styles.summary}>
        <span>PVM (21%)</span>
        <span>{formatMoney(total_tax)}</span>
      </div>

      <div className={cn(styles.summary, styles.bold)}>
        <span>Bendra suma su PVM</span>
        <span>{formatMoney(total_cost_with_discount)}</span>
      </div>
    </div>
  );
  return (
    <TableSimple
      header={tableHeader}
      footer={renderFooter()}
      rightHeaderCells={[2]}
    >
      {renderTable()}
    </TableSimple>
  );
};

ServicesTable.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ServicesTable;
