import React from 'react';
import PropTypes from 'prop-types';

import styles from './inputElement.module.scss';

const InputElement = ({
  name,
  type,
  onChange,
  value,
  invalid,
  style,
  ...rest
}) => {
  return (
    <input
      className={styles.input}
      style={{
        borderWidth: 1,
        borderColor: invalid ? 'red' : 'transparent',
        ...style,
      }}
      name={name}
      type={type}
      onChange={onChange}
      value={value || ''}
      {...rest}
    />
  );
};

InputElement.defaultProps = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
};

export default InputElement;
