import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './employees.module.scss';
import { actions } from '../../../state';
import { config } from '../../../api/config';
import { isEmpty, moneyToCents } from '../../../utils';
import EmployeeInfo from './employees.info.component';
import {
  WithSpinner,
  Header,
  Breadcrumb,
  Toggle,
  Paper,
  PaperNav,
  PaperContainer,
  ButtonLink,
  Button,
} from '../../../components';
import { employeesActions } from '../../../state/businessAdmin/employees/employees.actions';
import { validationErrors } from './employees.validation';
import { useMemo } from 'react';

const AddEmployee = ({
  dispatch,
  history,
  match: { params },
  employees: { loading, selectedEmployee },
  memberships: { memberships },
}) => {
  const [values, setValues] = useState({
    status: 1,
    full_name: '',
    phone: '',
    employee_id: '',
    credit_limit: 0,
    immediate_top_up: false,
    add_card_fee: true,
  });
  const [isPhysicalCard, setIsPhysicalCard] = useState(false);

  const fetchMemberships = useCallback(() => {
    dispatch(actions.memberships.getMembershipsAction());
  }, [dispatch]);

  const clearMemberships = useCallback(() => {
    dispatch(actions.memberships.clearMembershipsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchMemberships();
    return () => {
      clearMemberships();
    };
  }, [fetchMemberships, clearMemberships]);

  const toggleIsPhysicalCard = () => {
    setIsPhysicalCard(!isPhysicalCard);
  };

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const error = validationErrors(values, memberships);

    if (error) {
      dispatch(actions.error.setErrorAction({ msg: error }));
    } else {
      if (isPhysicalCard) {
        dispatch(
          actions.employees.addH2Card(
            {
              number: values.phone,
              top_up_limit: moneyToCents(values.credit_limit),
              name: values.full_name,
              immediate_top_up: values.immediate_top_up,
              add_card_fee: values.add_card_fee,
            },
            history
          )
        );
      } else {
        dispatch(
          actions.employees.addEmployee(
            {
              ...values,
              credit_limit: moneyToCents(values.credit_limit),
              membership_id: values.membership_id
                ? Number(values.membership_id)
                : null,
            },
            history
          )
        );
      }
    }
  };

  const handleCheckboxChange = ({ currentTarget: { id, value } }) => {
    setValues({
      ...values,
      [id]: !values[id],
    });
  };

  const membershipOptions = useMemo(() => {
    const options =
      memberships?.map(
        ({ id, title_lt, license_count, assigned_license_count }) => ({
          name: `${title_lt} (${license_count -
            (assigned_license_count || 0)})`,
          value: id,
        })
      ) || [];
    options.unshift({ name: 'Planas nepasirinktas', value: '' });

    return options;
  }, [memberships]);

  return (
    <section>
      <Header>
        <div className={styles.leftHeaderItemsContainer}>
          <Breadcrumb
            title={config.PAGES.baEmployees}
            baseUrl={config.APP_PATHS.baEmployees}
          />
        </div>

        {/* <Toggle
          onChange={toggleStatus}
          checked={values.status}
          showMeta={true}
          onText="Aktyvus"
          offText="Deaktyvuotas"
        /> */}
      </Header>

      <PaperContainer>
        <Paper>
          <div className={styles.physicalCardToggler}>
            <Toggle
              onChange={toggleIsPhysicalCard}
              checked={isPhysicalCard}
              showMeta={true}
              onText="Fizinė kortelė"
              offText="Programėlė"
            />
          </div>
          <EmployeeInfo
            employee={values}
            onInputChanged={handleInputChange}
            isPhysicalCard={isPhysicalCard}
            isCreateMode={true}
            showImmediateTopUp={isPhysicalCard}
            onCheckboxChanged={handleCheckboxChange}
            membershipOptions={membershipOptions}
          />

          <div className={styles.submitContainer}>
            <Button color="primary" onClick={handleSubmit}>
              Išsaugoti
            </Button>
          </div>

          <WithSpinner loading={loading}> </WithSpinner>
        </Paper>
      </PaperContainer>
    </section>
  );
};

const mapStateToProps = state => ({
  employees: state.employees,
  memberships: state.memberships,
});

AddEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  employees: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    selectedEmployee: PropTypes.object.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(AddEmployee);
