import React, { useCallback, useRef, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './input.module.scss';
import InputElement from '../_elements/inputElement/inputElement';
import TextAreaElement from '../_elements/textareaElement/textareaElement';
import LabelElement from '../_elements/labelElement/labelElement';
import { Icon, Spinner } from '../../../components';
import { useClickOutside } from '../../../utils';

const Input = ({
  name,
  label,
  icon,
  type,
  value,
  onChange,
  classes,
  withLabel = true,
  helperText,
  required,
  isAutocomplete,
  results,
  autocompleteLoading,
  autocompleteError,
  onAutcompleteSelect,
  ...rest
}) => {
  const componentRef = useRef();
  const [isOpen, setIsOpen] = useClickOutside(componentRef);

  const renderResultsList = useMemo(() => {
    if (!results) return null;
    return results.map(({ label, value }) => {
      return (
        <li
          className={styles.autocompleteItem}
          onClick={() => {
            setIsOpen(false);
            onAutcompleteSelect(value);
          }}
          key={value}
        >
          {label}
        </li>
      );
    });
  }, [results]);

  const renderResults = useCallback(() => {
    if (autocompleteLoading) {
      return (
        <li className={styles.autocompleteItem}>
          <Spinner />
        </li>
      );
    }

    if (autocompleteError) {
      return (
        <li className={styles.autocompleteItem}>
          <h6 className={styles.autocompleteLink}>{autocompleteError}</h6>
        </li>
      );
    }

    if (results?.length) {
      return renderResultsList;
    } else {
      return (
        <li className={styles.autocompleteItem}>
          <h6 className={styles.autocompleteLink}>Nieko nerasta</h6>
        </li>
      );
    }
  }, [
    styles,
    autocompleteLoading,
    autocompleteError,
    results,
    renderResultsList,
  ]);

  return (
    <div
      className={cn(styles.inputGroup, classes.inputGroup)}
      style={{ position: 'relative' }}
      ref={componentRef}
    >
      {withLabel && label ? (
        <div className={styles.labelContainer}>
          <LabelElement label={label} htmlFor={name} />
          {icon && <Icon name={icon} classes={{ icon: styles.icon }} />}
          {required && <span className={styles.required}>&#42;</span>}
        </div>
      ) : (
        <></>
      )}

      {type === 'textarea' ? (
        <TextAreaElement
          name={name}
          onChange={onChange}
          value={value}
          required={required}
          {...rest}
        />
      ) : (
        <InputElement
          name={name}
          type={type}
          onChange={onChange}
          value={value}
          required={required}
          onFocus={() => setIsOpen(true)}
          autoComplete={isAutocomplete && 'off'}
          {...rest}
        />
      )}
      {isAutocomplete && isOpen && (
        <ul className={styles.autocompleteList}>{renderResults()}</ul>
      )}
      {helperText && <span className={styles.helperText}>{helperText}</span>}
    </div>
  );
};

Input.defaultProps = {
  classes: {},
  icon: null,
  withLabel: true,
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    inputGroup: PropTypes.string,
  }),
  withLabel: PropTypes.bool,
};

export default Input;
