import React, { createContext, useState } from 'react';

export const BusinessClientsContext = createContext();

export const BusinessClientsProvider = ({ children }) => {
  const [filterBody, setFilterBody] = useState();
  const [statusFilter, setStatusFilter] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [endDateLtFilter, setEndDateLtFilter] = useState();
  const [endDateGtFilter, setEndDateGtFilter] = useState();
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [discountFilter, setDiscountFilter] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [creditLimitLtFilter, setCreditLimitLtFilter] = useState();
  const [creditLimitGtFilter, setCreditLimitGtFilter] = useState();
  const [ongoingDebtLtFilter, setOngoingDebtLtFilter] = useState();
  const [ongoingDebtGtFilter, setOngoingDebtGtFilter] = useState();
  const [unpaidInvoicesLtFilter, setUnpaidInvoicesLtFilter] = useState();
  const [unpaidInvoicesGtFilter, setUnpaidInvoicesGtFilter] = useState();
  const [totalDebtLtFilter, setTotalDebtLtFilter] = useState();
  const [totalDebtGtFilter, setTotalDebtGtFilter] = useState();
  const [limitRemainderLtFilter, setLimitRemainderLtFilter] = useState();
  const [limitRemainderGtFilter, setLimitRemainderGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');
  const [activeTab, setActiveTab] = useState(0);

  return (
    <BusinessClientsContext.Provider
      value={{
        filterBody,
        setFilterBody,
        statusFilter,
        setStatusFilter,
        companyFilter,
        setCompanyFilter,
        endDateLtFilter,
        setEndDateLtFilter,
        endDateGtFilter,
        setEndDateGtFilter,
        employeesFilter,
        setEmployeesFilter,
        discountFilter,
        setDiscountFilter,
        userFilter,
        setUserFilter,
        creditLimitLtFilter,
        setCreditLimitLtFilter,
        creditLimitGtFilter,
        setCreditLimitGtFilter,
        ongoingDebtLtFilter,
        setOngoingDebtLtFilter,
        ongoingDebtGtFilter,
        setOngoingDebtGtFilter,
        unpaidInvoicesLtFilter,
        setUnpaidInvoicesLtFilter,
        unpaidInvoicesGtFilter,
        setUnpaidInvoicesGtFilter,
        totalDebtLtFilter,
        setTotalDebtLtFilter,
        totalDebtGtFilter,
        setTotalDebtGtFilter,
        limitRemainderLtFilter,
        setLimitRemainderLtFilter,
        limitRemainderGtFilter,
        setLimitRemainderGtFilter,
        sortOrder,
        setSortOrder,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </BusinessClientsContext.Provider>
  );
};
