export const locationsConstants = {
  SET_LOCATIONS_LOADING: 'locations/SET_LOCATIONS_LOADING',
  GET_LOCATIONS: 'locations/GET_LOCATIONS',
  SET_LOCATIONS: 'locations/SET_LOCATIONS',
  ADD_LOCATIONS: 'locations/ADD_LOCATIONS',
  ADD_LOCATIONS_PLACE: 'locations/ADD_LOCATIONS_PLACE',
  GET_SERVICES_TABLE_DATA: 'locations/GET_SERVICES_TABLE_DATA',
  GET_BOXES_TABLE_DATA: 'locations/GET_BOXES_TABLE_DATA',
  GET_SELECTED_LOCATION: 'locations/GET_SELECTED_LOCATION',
  SET_SELECTED_LOCATION: 'locations/SET_SELECTED_LOCATION',
  CLEAR_LOCATIONS: 'locations/CLEAR_LOCATIONS',
  EXPORT_LOCATIONS: 'locations/EXPORT_LOCATIONS',
  DOWNLOAD_LOCATIONS_QR: 'locations/DOWNLOAD_LOCATIONS_QR',
};
