import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import servicesBoxStyles from './servicesBoxStyles.module.scss';
import { actions } from './../../../state';
import Boxes from './../boxes/boxes';
import { InputElement } from './../../../components';
import { formattedDevices } from '../locations.utils';

const PaperTowelDispenser = ({ dispatch, data, requestError }) => {
  const [values, setValues] = useState({
    paperTowelPrice: data !== null ? data.options[0].price / 100 : '',
  });

  const [devices, setBoxesValues] = useState(data === null ? [] : data.devices);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    const options = [
      {
        type: 501,
        price: values.paperTowelPrice * 100,
      },
    ];
    const data = {
      options,
      devices: formattedDevices(devices),
    };
    dispatch(actions.addEditServices.pushPaperTowelDispenserAction(data));
  });

  const handleStateFromBoxes = x => {
    setBoxesValues(x);
  };

  return (
    <div className={servicesBoxStyles.mainContainer}>
      <table className={servicesBoxStyles.container}>
        <thead>
          <tr>
            <th>Pavadinimas</th>
            <th>Kaina su PVM (EUR)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Šluosčių automatas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="paperTowelPrice"
                type="number"
                onChange={handleInputChange}
                value={values.paperTowelPrice}
                invalid={requestError && !values.paperTowelPrice}
                placeholder="0.00"
                min="0"
                step="0.01"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Boxes
        handleStateFromBoxes={handleStateFromBoxes}
        devices={data === null ? [] : data.devices}
        location="paper_towel_dispenser"
        requestError={requestError}
      />
    </div>
  );
};

PaperTowelDispenser.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.shape({
    devices: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }),
};

export default connect()(PaperTowelDispenser);
