import React, { createContext, useState } from 'react';

export const LocationsContext = createContext();

export const LocationsProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [filteredBody, setFilteredBody] = useState();
  const [createdAtLtFilter, setCreatedAtLtFilter] = useState();
  const [createdAtGtFilter, setCreatedAtGtFilter] = useState();
  const [sortOrder, setSortOrder] = useState('asc');
  const [timeRangeFilterValue, setTimeRangeFilterValue] = useState();
  const [clearAllFilters, setClearAllFilters] = useState(false);

  return (
    <LocationsContext.Provider
      value={{
        activeTab,
        setActiveTab,
        filteredBody,
        setFilteredBody,
        createdAtLtFilter,
        setCreatedAtLtFilter,
        createdAtGtFilter,
        setCreatedAtGtFilter,
        sortOrder,
        setSortOrder,
        timeRangeFilterValue,
        setTimeRangeFilterValue,
        clearAllFilters,
        setClearAllFilters,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
