import React, { useEffect, useCallback, useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Header,
  HeaderActions,
  ButtonDownload,
  Title,
  Table,
  TableRow,
  TableCell,
  SelectAsyncFilter,
  SelectFilter,
  DateFilter,
  ButtonSimple,
} from '../../components';
import {
  formatFilename,
  formatMoney,
  mapCouponType,
  isFilterActive,
  formatDate,
  generateHeader,
} from '../../utils';

import styles from './coupons.module.scss';
import { config } from '../../api/config';
import { actions } from '../../state';
import { CouponsContext } from './coupons.context';

const Coupons = ({
  dispatch,
  coupons: { coupons, total, footer, header = {}, loading },
  match: { params, url },
  history,
  xlsLoading,
}) => {
  const {
    createdAtLtFilter,
    setCreatedAtLtFilter,
    createdAtGtFilter,
    setCreatedAtGtFilter,
    expiresAtLtFilter,
    setExpiresAtLtFilter,
    expiresAtGtFilter,
    setExpiresAtGtFilter,
    nameFilter,
    setNameFilter,
    typeFilter,
    setTypeFilter,
    sortOrder,
    setSortOrder,
    filterBody,
    setFilterBody,
  } = useContext(CouponsContext);

  const fetchCoupons = useCallback(
    data => {
      dispatch(actions.coupons.getCouponsAction(data));
    },
    [dispatch]
  );

  const clearCoupons = useCallback(() => {
    dispatch(actions.coupons.clearCouponsAction());
  }, [dispatch]);

  useEffect(() => {
    fetchCoupons(filterBody);
    return () => clearCoupons();
  }, [fetchCoupons, clearCoupons, filterBody]);

  const xlsExport = () => {
    const filename = formatFilename(config.PAGES.coupons);
    dispatch(actions.coupons.exportCouponsAction(filename, filterBody));
  };

  const handleSubmit = name => {
    const generatedFilter = [];

    if (typeFilter.length) {
      generatedFilter.push({ field: 'type', value: typeFilter });
    }

    if (createdAtGtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'gte',
        value: createdAtGtFilter,
        type: 'timestamp',
      });
    }
    if (createdAtLtFilter) {
      generatedFilter.push({
        field: 'created_at',
        expression: 'lte',
        value: createdAtLtFilter,
        type: 'timestamp',
      });
    }

    if (expiresAtGtFilter) {
      generatedFilter.push({
        field: 'expires_at',
        expression: 'gte',
        value: expiresAtGtFilter,
        type: 'timestamp',
      });
    }
    if (expiresAtLtFilter) {
      generatedFilter.push({
        field: 'expires_at',
        expression: 'lte',
        value: expiresAtLtFilter,
        type: 'timestamp',
      });
    }

    if (name) {
      setFilterBody({
        filter: {
          and: generatedFilter,
        },
        order: [
          {
            by: name,
            order: sortOrder,
          },
        ],
      });
    } else {
      setFilterBody();
    }
  };

  const clearFilters = () => {
    setTypeFilter([]);
    setNameFilter('');
    setCreatedAtLtFilter();
    setCreatedAtGtFilter();
    setExpiresAtLtFilter();
    setExpiresAtGtFilter();

    handleSubmit();
  };

  const tableHeader = [
    <div className={styles.tableHeader}>Pavadinimas</div>,
    // <SelectAsyncFilter
    //   title="Pavadinimas."
    //   name="name"
    //   valueType="string"
    //   params={{ model: 'coupon', field: 'name' }}
    //   filterState={nameFilter}
    //   setFilterState={setNameFilter}
    //   onSubmit={handleSubmit}
    //   sortOrder={sortOrder}
    //   setSortOrder={setSortOrder}
    //   isActive={isFilterActive('select', nameFilter)}
    // />,

    <SelectFilter
      title="Tipas"
      name="type"
      valueType="number"
      array={generateHeader(header.type, mapCouponType)}
      filterState={typeFilter}
      setFilterState={setTypeFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('select', typeFilter)}
    />,
    <div className={styles.tableHeader}>Suma</div>,
    <div className={styles.tableHeader}>Panaudoti</div>,
    <div className={styles.tableHeader}>Viso kuponų</div>,
    <DateFilter
      title="Galioja nuo"
      name="created_at"
      lt={createdAtLtFilter}
      setLt={setCreatedAtLtFilter}
      gt={createdAtGtFilter}
      setGt={setCreatedAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', createdAtLtFilter, createdAtGtFilter)}
    />,
    <DateFilter
      title="Galioja iki"
      name="expires_at"
      lt={expiresAtLtFilter}
      setLt={setExpiresAtLtFilter}
      gt={expiresAtGtFilter}
      setGt={setExpiresAtGtFilter}
      onSubmit={handleSubmit}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      isActive={isFilterActive('date', expiresAtLtFilter, expiresAtGtFilter)}
    />,
  ];

  const tableFooter = ['', '', '', '', '', '', ''];

  const renderTable = () =>
    coupons.map(
      ({
        id,
        name,
        created_at,
        expires_at,
        type,
        amount,
        discount_id,
        total_max_redeem_quantity,
        coupon_code_count,
        total_redeem_count,
      }) => (
        <TableRow url={config.APP_PATHS.couponDetails(id)} key={id}>
          <TableCell>{name}</TableCell>
          <TableCell>{mapCouponType(type)}</TableCell>
          <TableCell>{formatMoney(amount)}</TableCell>
          <TableCell>{total_redeem_count}</TableCell>
          <TableCell>{coupon_code_count}</TableCell>
          <TableCell>{formatDate(created_at)}</TableCell>
          <TableCell>{formatDate(expires_at)}</TableCell>
        </TableRow>
      )
    );

  return (
    <section>
      <Header>
        <Title total={total}>{config.PAGES.coupons}</Title>

        <HeaderActions>
          {filterBody && (
            <ButtonSimple type="filter" onClick={clearFilters}>
              Išvalyti filtrus
            </ButtonSimple>
          )}
          <ButtonDownload onClick={xlsExport} loading={xlsLoading} />
        </HeaderActions>
      </Header>

      <Table
        header={tableHeader}
        footer={tableFooter}
        isEmpty={!coupons.length}
        filter={true}
        loading={loading}
        rightAlignedFooterCells={[3, 4]}
      >
        {renderTable()}
      </Table>
    </section>
  );
};

const mapStateToProps = state => ({
  coupons: state.coupons,
  xlsLoading: state.download.xlsLoading,
  csvLoading: state.download.csvLoading,
});

Coupons.propTypes = {
  dispatch: PropTypes.func.isRequired,
  coupons: PropTypes.shape({
    coupons: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    header: PropTypes.shape({
      type: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    footer: PropTypes.shape({}),
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  xlsLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(Coupons);
