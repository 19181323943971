import React from 'react';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import _ from 'lodash';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';

import styles from './googleMaps.module.scss';
import { actions } from '../../state';
import mapPin from './../../assets/icons/mapPin.svg';

const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDYgKJ2r0pzNnjbN5LuJ98JxYYLcsOCbb4&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `370px`, width: '520px' }} />,
    mapElement: <div className={styles.mapElement} />,
  }),

  lifecycle({
    componentWillMount() {
      const refs = {};
      this.setState({
        bounds: null,
        center: {
          lat: 54.6852678697085,
          lng: 25.280707169708535,
        },
        markers: [{ lat: -34.397, lng: 150.644 }],
        inputValue: this.props.address
          ? `${this.props.address.address} ${this.props.address.city || ''}`
          : '',
        onSearchInputChange: value => {
          console.log(value);

          this.setState({ inputValue: value });
        },
        markerDragEnd: e => {
          const changedCoord = new window.google.maps.LatLng(
            e.latLng.lat(),
            e.latLng.lng()
          ).toJSON();
          this.props.dispatch(
            actions.addEditServices.pushNewCoordAction(changedCoord)
          );
        },
        onMapMounted: ref => {
          refs.map = ref;
          if (ref !== null && this.props.address !== undefined) {
            const coord = this.props.address.coordinates;
            const position = {
              lat: coord[0],
              lng: coord[1],
            };
            this.setState({
              markers: [{ position }],
            });

            ref.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
              position
            );
          }
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();
          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(
            nextMarkers,
            '0.position',
            this.state.center
          );
          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });

          const pl = places[0];

          const address = pl.formatted_address.split(',')[0];
          const coordinates = nextCenter.toJSON();
          let city = '';
          if (pl.address_components) {
            city = pl.address_components.filter(comp =>
              comp.types.includes('locality')
            )[0].long_name;
          } else {
            city = pl.vicinity;
          }
          const data = {
            city,
            address: address,
            coordinates: [coordinates.lat, coordinates.lng],
          };

          this.setState({
            inputValue: `${data.address} ${data.city || ''}`,
          });
          this.props.dispatch(actions.addEditServices.pushPlaceAction(data));
        },
      });
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (
        (prevProps.address && prevProps.address.address) !==
        (this.props.address && this.props.address.address)
      ) {
        this.setState({
          inputValue: `${this.props.address.address} ${this.props.address
            .city || ''}`,
        });
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <div>
      <div className={styles.searchBoxWrapper}>
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP}
          onPlacesChanged={props.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Įveskite plovyklos adresą"
            value={props.inputValue}
            onChange={e => props.onSearchInputChange(e.target.value)}
            style={{
              zIndex: `100`,
              borderWidth: 1,
              borderColor: props.invalidInput ? 'red' : 'transparent',
            }}
            className={styles.mapSearchInput}
          />
        </SearchBox>
      </div>

      <div className={styles.underSearchBox}>
        <p>Tempiant žymeklį patikslinkite lokaciją</p>
      </div>

      <GoogleMap
        ref={props.onMapMounted}
        defaultZoom={15}
        center={props.center}
        defaultOptions={{
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {props.markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            draggable={true}
            onDragEnd={props.markerDragEnd}
            icon={mapPin}
          />
        ))}
      </GoogleMap>
    </div>
  );
});

export default connect()(MapWithASearchBox);
