import { constants } from '../../constants';

const setEmployeesLoadingAction = flag => ({
  type: constants.employees.SET_EMPLOYEES_LOADING,
  flag,
});

const getEmployeesAction = body => ({
  type: constants.employees.GET_EMPLOYEES,
  body,
});

const setEmployeesAction = (employees, count, header, footer) => ({
  type: constants.employees.SET_EMPLOYEES,
  employees,
  count,
  header,
  footer,
});

const getEmployeeAction = id => ({
  type: constants.employees.GET_EMPLOYEE,
  id,
});

const getH2CardAction = id => ({
  type: constants.employees.GET_H2_CARD,
  id,
});

const setEmployeeAction = employee => ({
  type: constants.employees.SET_EMPLOYEE,
  employee,
});

const updateEmployee = (id, body, history) => ({
  type: constants.employees.UPDATE_EMPLOYEE,
  id,
  body,
  history,
});

const clearEmployeesAction = () => ({
  type: constants.employees.CLEAR_EMPLOYEES,
});

const exportEmployeesAction = (filename, body) => ({
  type: constants.employees.EXPORT_EMPLOYEES,
  filename,
  body,
});

const deleteEmployeeData = id => ({
  type: constants.employees.DELETE_EMPLOYEE_DATA,
  id,
});

const addEmployee = (body, history) => ({
  type: constants.employees.ADD_EMPLOYEE,
  body,
  history,
});

const addH2Card = (body, history) => ({
  type: constants.employees.ADD_H2_CARD,
  body,
  history,
});

const updateH2Card = (id, body, history) => ({
  type: constants.employees.UPDATE_H2_CARD,
  id,
  body,
  history,
});

const changeEmployeeStatus = (id, newStatus) => ({
  type: constants.employees.CHANGE_STATUS,
  id,
  newStatus,
});

const changeH2CardStatus = (id, newStatus) => ({
  type: constants.employees.CHANGE_H2_CARD_STATUS,
  id,
  newStatus,
});

const bulkMembershipAssign = (body, history) => ({
  type: constants.employees.BULK_MEMBERSHIP_ASSIGN,
  body,
  history,
});

export const employeesActions = {
  setEmployeesLoadingAction,
  getEmployeesAction,
  setEmployeesAction,
  getEmployeeAction,
  setEmployeeAction,
  updateEmployee,
  clearEmployeesAction,
  exportEmployeesAction,
  deleteEmployeeData,
  addEmployee,
  addH2Card,
  getH2CardAction,
  updateH2Card,
  changeEmployeeStatus,
  changeH2CardStatus,
  bulkMembershipAssign,
};
