import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../state';
import ButtonSimple from '../buttons/buttonSimple/buttonSimple';

const RemoveButton = ({ onClick, children, dispatch, title, desc }) => {
  const [showRed, setShowRed] = useState(false);
  const handleClick = () => {
    const payload = {
      modalName: 'confirmModal',
      title,
      desc,
      onConfirm: onClick,
    };
    dispatch(actions.modals.setModalVisible(payload));
  };

  return (
    <div
      onMouseEnter={() => setShowRed(true)}
      onMouseLeave={() => setShowRed(false)}
    >
      <ButtonSimple
        type={showRed ? 'remove-red' : 'remove'}
        onClick={handleClick}
      >
        {children}
      </ButtonSimple>
    </div>
  );
};
export default connect()(RemoveButton);
